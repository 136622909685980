import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';

export const ContratoUsuarioItem = ({ item }) => {
    const navigate = useNavigate();

    return (
        <Grid style={{ cursor: 'pointer' }} container spacing={1} onClick={() => { navigate(`/contrato-detalhe/${item.contrato_dts}`) }}>
            <Grid md={12}>
                <p style={{ fontWeight: 500, color: '#005EB8', margin: 0 }}>
                    <span style={{ fontWeight: '700', fontFamily: "Univers-Medium", fontSize: 16 }}>{item && item.contrato}</span>
                </p>
                <p style={{ fontWeight: 500, margin: '5px 0 0 0' }}>
                    <span style={{ fontFamily: "Campuni-Medium", fontWeight: 500, fontSize: 16, color: '#292D32' }}>{item && item.programa}</span>
                </p>
            </Grid>
            <Grid md={12}>
                <hr />
            </Grid>
        </Grid>
    )
}