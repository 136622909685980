import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { Pagination } from '@mui/material';
import { EditalItem } from "../../components/EditalItem";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { isMobile } from "react-device-detect";
import { ContratoItem } from "../../components/ContratoItem";
import { ContratoUsuarioItem } from "../../components/ContratoUsuarioItem";
import { useAuth } from "../../hooks/useAuth";

export const ContratosUsuarioPage = () => {

    const navigate = useNavigate();
    const { listarContratos } = useQuery();
    const [contratos, setContratos] = React.useState([]);
    const [protocolo, setProtocolo] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const { user } = useAuth();

    React.useEffect(() => {
        console.log(user);
        listar(user && user.cnpj);
    }, [])


    const listar = async (cnpj) => {
        setLoading(true);
        try {
            const result = await listarContratos(
                cnpj.replace(/\D/g, ''), ""
                // serializeQueryString(queryParams, '&'),
            );

            if (result && result.data && result.data.success) {
                setContratos(result && result.data && result.data.data.contratos);
                setProtocolo(result && result.data && result.data.data.protocolo);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={1} paddingX="0">
                <Grid md={12} xs={12}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>Protocolo</span>
                </Grid>
                <Grid md={12} xs={12}>
                    <div style={{ background: '#fff', borderRadius: 8, padding: 10, width: isMobile ? '100%' : 435, marginTop: 30, marginBottom: 30 }}>
                        <p style={{ fontFamily: 'AlegraSans-Medium', fontSize: 14, fontWeight: 500, color: '#666666', margin: 0 }}>Protocolo</p>
                        <p style={{ fontFamily: 'AlegraSans-Medium', fontSize: 14, fontWeight: 700, color: '#666666', margin: 0 }}>{protocolo && protocolo.titulo}</p>
                        <p style={{ fontFamily: 'AlegraSans-Medium', fontSize: 14, fontWeight: 500, color: '#999999', margin: '10px 0 0 0' }}>Válido até {protocolo && protocolo.data}</p>
                        {/* <p style={{ fontFamily: 'AlegraSans-Medium', fontSize: 14, fontWeight: 500, color: '#999999', margin: 0 }}>Última atualização: {protocolo && protocolo.atualizacao}</p> */}
                    </div>
                </Grid>
                <Grid md={12} xs={12}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>Contratos</span>
                </Grid>
                <Grid md={12} xs={12} marginTop={2}>
                    {/* <div
                        className="scrollbar"
                        id="style-3"
                        style={{ margin: 0, height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', padding: '0 10px' }}
                    > */}
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: ' center' }}>
                            <CircularProgress />
                        </div>
                    ) : null}
                    {contratos && contratos.length > 0 && contratos.map((contrato, index) => {
                        return (
                            <ContratoUsuarioItem key={index} item={contrato} />
                        )
                    })}
                    {/* </div> */}
                </Grid>
            </Grid>
        </Box >
    )
}