import * as React from "react";
import Grid from "@mui/material/Grid";
import Carousel from 'react-multi-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as CorouselResponsive } from 'react-responsive-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CarouselItemHome } from "../../components/CarouselItemHome";
import Button from "@mui/material/Button";
import { isMobile } from 'react-device-detect';
import { SvgIcon } from '@mui/material';
import { useQuery } from "../../hooks/useQuery";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const SectionNoticesPage = () => {
    const [customTransform, setCostumTransform] = React.useState(0);
    const { listaEditalPublic } = useQuery();
    const [editais, setEditais] = React.useState([]);
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide, totalItems } } = rest;
        if (totalItems >= 4 && currentSlide >= 1) {
            setCostumTransform(210);
        } else {
            setCostumTransform(0);
        }
        return (
            <div className="carousel-button-group" style={{ margin: '2rem 0' }}>
                <Button onClick={() => previous()}>
                    <SvgIcon style={{ fontSize: 60, transform: "rotate(180deg)" }}>
                        <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" >
                            <defs>
                                <style>
                                    {`
                                    .cls-1 {
                                        fill: none;
                                        stroke: #424f97;
                                        strokeWidth: 2px;
                                    }
                                    .cls-2 {
                                        fill: #424f97;
                                        strokeWidth: 0px;
                                    }
                                    `}
                                </style>
                            </defs>
                            <circle stroke-opacity={currentSlide === 0 ? 0.3 : 1} class="cls-1" cx="36" cy="36" r="35" />
                            <path fill-opacity={currentSlide === 0 ? 0.3 : 1} class="cls-2" d="M28.75,47.5v-22.11c0-1.53,1.85-2.3,2.93-1.22l11.06,11.06c.67.67.67,1.76,0,2.43l-11.06,11.06c-1.08,1.08-2.93.32-2.93-1.22Z" />
                        </svg>
                    </SvgIcon>
                </Button>
                <Button onClick={() => next()}>
                    <SvgIcon style={{ fontSize: 60 }}>
                        <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                            <defs>
                                <style>
                                    {`
                                    .cls-1 {
                                        fill: none;
                                        stroke: #424f97;
                                        strokeWidth: 2px;
                                    }
                                    .cls-2 {
                                        fill: #424f97;
                                        strokeWidth: 0px;
                                    }
                                    `}
                                </style>
                            </defs>
                            <circle class="cls-1" cx="36" cy="36" r="35" />
                            <path class="cls-2" d="M28.75,47.5v-22.11c0-1.53,1.85-2.3,2.93-1.22l11.06,11.06c.67.67.67,1.76,0,2.43l-11.06,11.06c-1.08,1.08-2.93.32-2.93-1.22Z" />
                        </svg>
                    </SvgIcon>
                </Button>
            </div >
        );
    };

    React.useEffect(() => {
        getEditais();
    }, []);

    const getEditais = async () => {
        const result = await listaEditalPublic();
        if (result && result.data) {
            setEditais(result.data.data);
        }
    }

    return (
        <section id="edital" style={{ width: '100%', margin: 0 }}>
            <Grid container paddingTop="1rem" paddingBottom="2rem" paddingX={isMobile ? "2rem" : "4rem"}>
                <Grid xs={12}>
                    <p style={{ margin: "3rem 0", fontSize: 32, color: '#3B4AFF', textAlign: 'center', fontWeight: 500 }}>Conheça nossos editais</p>
                </Grid>
                <Grid xs={12}>
                    {isMobile ? (
                        <CorouselResponsive
                            emulateTouch={true}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                        >
                            {editais && editais.map(e => {
                                return (
                                    <div><CarouselItemHome item={e} /></div>
                                )
                            })}
                        </CorouselResponsive>
                    ) : (
                        <Carousel
                            responsive={responsive}
                            slidesToSlide={1}
                            infinite={false}
                            swipeable={false}
                            arrows={false}
                            renderButtonGroupOutside={true}
                            customButtonGroup={<ButtonGroup />}
                            additionalTransfrom={customTransform}
                        >
                            {editais && editais.map(e => {
                                return (
                                    <div><CarouselItemHome item={e} /></div>
                                )
                            })}

                        </Carousel>
                    )}

                </Grid>
            </Grid>
        </section >
    )
}