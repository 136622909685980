import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import People from '../../assets/bg-edital.png';
import ImgEdital from '../../assets/imgedital.png';
import Quadrados from '../../assets/quadrados-cinza.png';
import Countdown from "../../components/CountDown";
import Button from "@mui/material/Button";
import DownloadIcon from '@mui/icons-material/Download';
import { useParams } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import dayjs from 'dayjs';
import CountdownTimer from "../../components/CountdownTimer";

export const ShowEdital = () => {

    const { id } = useParams();
    const { showEditalPublic } = useQuery();
    const [edital, setEdital] = React.useState();
    const url = process.env.REACT_APP_API_BASE_URL;

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            getEdital();
        }

    }, []);

    const getEdital = async () => {
        const result = await showEditalPublic(id);

        if (result && result.data) {
            setEdital(result.data.data);
        }
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container style={{ background: '#3B4AFF' }} spacing={1} paddingX="5rem" paddingY="4rem">
                <Grid sm={1}>
                    <img alt="" style={{ width: 60 }} className={'imgInitial'} src={Quadrados} />
                </Grid>
                <Grid sm={6}>
                    <p style={{ fontSize: 40, color: 'white', fontWeight: 'bold', margin: '10px 0' }}>{edital && edital.titulo}</p>
                    <p style={{ color: '#fff', margin: '10px 0 10px 0', fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 18 }}>Data da publicação: {edital && dayjs(edital.data_abertura).format('DD/MM/YYYY HH:mm')}</p>
                    {edital && edital.permanente == 0 ? (
                        <p style={{ color: '#fff', margin: 0, fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 18 }}>Data fechamento: {edital && dayjs(edital.data_fechamento).format('DD/MM/YYYY HH:mm')}</p>
                    ) : null}
                    <div style={{ marginBottom: 3 }}>
                        {edital && edital.processos && edital.processos.length > 0 ? (
                            <Box style={{ marginTop: 40, background: edital && edital.processos.length > 0 && edital.processos[edital.processos.length - 1].status == 'A' ? '#84F4BC' : edital && edital.processos.length > 0 && edital.processos[edital.processos.length - 1].status == 'P' ? '#FFA500' : '#CCCCCC', width: '50%', height: 60, borderTopLeftRadius: 5, borderTopRightRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {edital.processos[edital.processos.length - 1].status == 'A' ? (<b>Processo Aberto</b>) : edital.processos[edital.processos.length - 1].status == 'P' ? (<b>Previsto</b>) : (<b>Processo Fechado</b>)}

                            </Box>
                        ) : (
                            <Box style={{ marginTop: 40, background: '#CCCCCC', width: '50%', height: 60, borderTopLeftRadius: 5, borderTopRightRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <b>Sem processo</b>
                            </Box>
                        )}

                        {edital && edital.processos && edital.processos.length > 0 ? (
                            <CountdownTimer targetDate={new Date(edital.processos[edital.processos.length - 1] && edital.processos[edital.processos.length - 1].data_abertura)} />
                        ) : (
                            <CountdownTimer targetDate={null} />
                        )}
                    </div>

                </Grid>
                <Grid sm={5} marginBottom={2} textAlign={'left'}>
                    <img alt="" style={{ width: 400 }} className={'imgInitial'} src={People} />
                </Grid>
            </Grid>
            <Grid container style={{ background: '#eFeFeF' }} spacing={1} paddingX="4rem">
                <Grid sm={6} padding={10}>
                    <img alt="" src={edital && edital.image ? `${url}/storage/${edital.image}` : ImgEdital} style={{ width: '100%', borderLeft: '16px solid #005EB8;' }} />
                </Grid>
                <Grid sm={6}>
                    {edital && edital.processos && edital.processos.length > 0 ? (
                        <>
                            <p style={{ color: '#005EB8', margin: '80px 0 0 0', fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 32 }}>{edital.status == 'A' && dayjs().isAfter(dayjs(edital.processos[edital.processos.length - 1].data_abertura)) && dayjs().isBefore(dayjs(edital.processos[edital.processos.length - 1].data_fechamento)) ? 'Inscrições abertas!' : ''}</p>
                            <p style={{ color: '#666666', margin: '10px 0 10px 0', fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 18 }}>Data abertura: {dayjs(edital.processos[edital.processos.length - 1].data_abertura).format('DD/MM/YYYY HH:mm')}</p>
                            <p style={{ color: '#666666', margin: 0, fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 18 }}>Data fechamento: {dayjs(edital.processos[edital.processos.length - 1].data_fechamento).format('DD/MM/YYYY HH:mm')}</p>
                        </>
                    ) : null}


                    <p style={{ color: '#005EB8', fontWeight: 400, fontFamily: 'Campuni-Medium' }}>{edital && edital.texto}</p>
                    {edital && edital.processos.length > 0 && edital.processos[edital.processos.length - 1].documento ? (
                        <p
                            style={{ color: '#005EB8', fontWeight: '700', cursor: 'pointer', fontSize: 18 }}
                            onClick={() => window.open(`${url}/storage/${edital.processos[edital.processos.length - 1].documento}`, '_blank').focus()}
                        >
                            Clique aqui para acessar o Comunicado de abertura
                        </p>
                    ) : null}
                    <Button
                        style={{ width: '100%', height: 41, marginTop: 10, background: '#ffffff' }}
                        endIcon={<DownloadIcon color="info" />}
                        color="info"
                        variant="outlined"
                        onClick={() => { window.open(`${url}/storage/${edital && edital.documento}`, '_blank').focus(); }}>
                        Baixar Edital
                    </Button>
                </Grid>
            </Grid>
        </Box >
    )
}