import * as React from "react";
import { IconButton, SvgIcon } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export const CheckBoxItem = ({ doc, setCheckedDocumentos, checkedDocumentos, isCheckAllDocumentos, editarDocumento, documentos }) => {

    const [checked, setChecked] = React.useState(false);


    React.useEffect(() => {
        if (isCheckAllDocumentos) {

        } else {
            setChecked(false);
        }

        if (checkedDocumentos.find(d => d == doc.id)) {
            setChecked(true);
        }

    }, [isCheckAllDocumentos, documentos, checkedDocumentos])

    const svgEdit = () => {

        return (
            <SvgIcon style={{ cursor: 'pointer' }}>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 2.125H3.54167C3.16594 2.125 2.80561 2.27426 2.53993 2.53993C2.27426 2.80561 2.125 3.16594 2.125 3.54167V13.4583C2.125 13.8341 2.27426 14.1944 2.53993 14.4601C2.80561 14.7257 3.16594 14.875 3.54167 14.875H13.4583C13.8341 14.875 14.1944 14.7257 14.4601 14.4601C14.7257 14.1944 14.875 13.8341 14.875 13.4583V8.5" stroke="#005EB8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.015 1.85935C13.2968 1.57756 13.679 1.41925 14.0775 1.41925C14.476 1.41925 14.8582 1.57756 15.14 1.85935C15.4218 2.14114 15.5801 2.52334 15.5801 2.92185C15.5801 3.32037 15.4218 3.70256 15.14 3.98435L8.49935 10.625L5.66602 11.3333L6.37435 8.49998L13.015 1.85935Z" stroke="#005EB8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }

    const handleChange = (event) => {
        console.log(event.target.checked);
        if (event.target.checked) {
            let aux = checkedDocumentos;
            if (!aux.find(a => a === event.target.value)) {
                aux.push(parseInt(event.target.value));
                console.log(aux);
            }
            setCheckedDocumentos(aux);
            setChecked(true);
        } else {
            let aux = checkedDocumentos;
            if (aux.find(a => a == parseInt(event.target.value))) {
                const index = aux.indexOf(parseInt(event.target.value));
                if (index > -1) {
                    aux.splice(index, 1);
                }
                setCheckedDocumentos(aux);
            }
            console.log(aux);
            setChecked(false);
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FormControlLabel
                checked={checked}
                label={doc.nome || doc.titulo}
                control={<Checkbox value={doc.id} onChange={(e) => { handleChange(e) }} />}
            />
            {editarDocumento !== null ? (
                <IconButton onClick={() => { editarDocumento(doc) }}>
                    {svgEdit()}
                </IconButton>
            ) : null}
        </div>
    )
}