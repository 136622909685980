import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { CircularProgress, IconButton, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from "../../hooks/useSnackbar";
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQuery } from "../../hooks/useQuery";
import dayjs from 'dayjs';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import ImageIcon from '@mui/icons-material/Image';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputMask from "react-input-mask";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import 'dayjs/locale/pt-br';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl } from '@mui/material';


const TIPO_ARQUIVOS_PERMITIDOS = [
    'pdf',
];


const IMG_ARQUIVOS_PERMITIDOS = [
    'jpg',
    'png',
    'jpeg'
];


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const EditalStep1Page = ({ edital, setEdital, activeStep, setActiveStep }) => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();
    const [arquivo, setArquivo] = React.useState();
    const [imageFile, setImageFile] = React.useState();
    const [checkedIsAberto, setCheckedIsAberto] = React.useState(false);
    const [titulo, setTitulo] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [loadingEdital, setLoadingEdital] = React.useState(false);
    const [isRascunho, setIsRascunho] = React.useState(false);
    const [tipoEditais, setTipoEditais] = React.useState([]);
    const [msgErrorTitulo, setMsgErrorTitulo] = React.useState("");
    const [errorTitulo, setErrortitulo] = React.useState(false);
    const { editalId } = useParams();
    const { listaTipoEditais, editalStep1, showEdital, removeDocumentoEdital, removeImageEdital, saveTipoEdital, listarUsersFluig } = useQuery();
    const inputFile = React.useRef(null);
    const inputImage = React.useRef(null);
    const [usersFluig, setUsersFluig] = React.useState([]);
    const [ateste, setAteste] = React.useState();

    React.useEffect(() => {
        buscarTipoEdital();
        getUsersFluig();
        if (editalId) {
            getEdital();
        }
    }, []);

    const getEdital = async () => {
        setLoadingEdital(true);
        const result = await showEdital(editalId);
        setLoadingEdital(false);
        if (result && result.data) {
            setEdital(result.data.data);
            setValue("titulo", result.data.data.titulo);
            setValue("codigo_ano", result.data.data.codigo_ano);
            setValue("data_abertura", dayjs(result.data.data.data_abertura).locale('pt-br'));
            setValue("data_encerramento", dayjs(result.data.data.data_fechamento).locale('pt-br'));
            setValue("tipo_edital_id", result.data.data.tipo_edital_id);
            setAteste(usersFluig.find(res => res.login == result.data.data.responsavel));
            if (result.data.data.permanente == 1) {
                setCheckedIsAberto(true);
            }
            setValue("texto", result.data.data.texto);
            setValue("resumo", result.data.data.resumo);
        }
    }

    const buscarTipoEdital = async () => {
        const result = await listaTipoEditais();
        if (result && result.data) {
            setTipoEditais(result.data.data);
        }
    }


    const handleClose = () => {
        setOpen(false);
        setTitulo(undefined);
    }

    const salvarTipoEdital = async () => {
        if (titulo == "" || titulo == undefined) {
            return;
        }

        let body = {
            titulo: titulo
        }

        const result = await saveTipoEdital(body);

        if (result && result.data) {
            setOpen(false);
            buscarTipoEdital();

        }


    }


    const onSubmit = (data) => {

        // if (imageFile == undefined && (edital && edital.image == null)) {
        //     snackbar.showSnackbar('Anexe uma imagem antes de continuar.', '#DC143C');

        //     return;
        // }
        if (arquivo == undefined && (edital && edital.documento == null)) {
            snackbar.showSnackbar('Anexe um arquivo antes de continuar.', '#DC143C');
            return;
        }

        if (edital && edital.id) {
            data.id = edital.id;
        }

        data.responsavel = ateste && ateste.login;
        data.image = imageFile;
        data.documento = arquivo;
        data.permanente = checkedIsAberto ? 1 : 0;
        console.log(data.permanente);
        data.data_abertura = dayjs(data.data_abertura).locale('pt-br').format("YYYY-MM-DD HH:mm");

        if (dayjs(data.data_encerramento).locale('pt-br').format("YYYY-MM-DD HH:mm") !== "Invalid date") {
            data.data_fechamento = dayjs(data.data_encerramento).locale('pt-br').format("YYYY-MM-DD HH:mm");
        } else {
            delete data.data_encerramento;
        }

        saveStep1(data);
    }

    const onErrors = (error) => {

    }

    const saveStep1 = async (data) => {
        try {
            setLoading(true);
            const result = await editalStep1(data);

            if (result && result.data) {
                setEdital(result.data.data);
                if (isRascunho) {
                    navigate("/editais");
                } else {
                    setActiveStep(activeStep + 1);
                }

            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }

    const removerDocumento = async (id) => {
        try {
            setLoading(true);
            const result = await removeDocumentoEdital(id);

            if (result && result.data) {
                setEdital(result.data.data);
            }
            inputFile.current.value = '';
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const removerImage = async (id) => {
        try {
            setLoading(true);
            const result = await removeImageEdital(id);

            if (result && result.data) {
                setEdital(result.data.data);
            }
            inputImage.current.image = '';
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleCheck = (event) => {
        setCheckedIsAberto(event.target.checked);
    };

    const onChangeFile = (event) => {
        const files = [...event.target.files];
        console.log(files);

        if (files.length > 0) {
            const file = files[0];

            const extension = file.name.split('.').pop();

            if (!TIPO_ARQUIVOS_PERMITIDOS.includes(extension.toLowerCase())) {
                snackbar.showSnackbar(
                    'Tipo de arquivo inválido, insira arquivo do tipo : ' +
                    TIPO_ARQUIVOS_PERMITIDOS.join(','), '#DC143C'
                );
                inputFile.current.value = '';
                return;
            }

            setArquivo(file);
        }

    };

    const onChangeImage = (event) => {
        const files = [...event.target.files];
        console.log(files);

        if (files.length > 0) {
            const file = files[0];

            const extension = file.name.split('.').pop();

            if (!IMG_ARQUIVOS_PERMITIDOS.includes(extension.toLowerCase())) {
                snackbar.showSnackbar(
                    'Tipo de arquivo inválido, insira arquivo dos tipos : ' +
                    IMG_ARQUIVOS_PERMITIDOS.join(','), '#DC143C'
                );
                inputImage.current.value = '';
                return;
            }

            setImageFile(file);
        }

    };

    const getUsersFluig = async () => {
        const response = await listarUsersFluig();

        if (response && response.data && response.data.data) {
            let fluigUsers = [];
            response.data.data.map((res) => {
                fluigUsers.push({
                    matricula: res.value[0],
                    nome: res.value[1],
                    login: res.value[2],
                });
            });
            console.log(fluigUsers);
            setUsersFluig(fluigUsers);
        }
    };


    const svgAnexo = () => {

        return (
            <SvgIcon>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.54449 16.3878C-0.542293 14.2683 -0.503401 10.8503 1.59399 8.73848L9.08542 1.19533C10.6681 -0.398373 13.2408 -0.398513 14.8237 1.19533C16.3912 2.77363 16.3931 5.32017 14.8237 6.90034L8.29335 13.4693C7.2271 14.5429 5.48503 14.5279 4.43685 13.4343C3.42703 12.3806 3.45942 10.7106 4.4887 9.6742L9.62238 4.51203C9.84317 4.29006 10.205 4.28626 10.4305 4.50356L11.2469 5.29032C11.4725 5.50766 11.4763 5.86379 11.2555 6.08577L6.12238 11.2474C5.94624 11.4248 5.93538 11.7195 6.09924 11.8905C6.25538 12.0534 6.50085 12.0561 6.65952 11.8963L13.1898 5.32734C13.8903 4.62204 13.8903 3.47373 13.1895 2.76804C12.5041 2.07803 11.4053 2.07768 10.7197 2.76804L3.22821 10.3112C1.98667 11.5613 1.96753 13.5849 3.18567 14.8222C4.40031 16.0559 6.3637 16.0574 7.5806 14.8322L13.7256 8.64475C13.9462 8.4226 14.308 8.41859 14.5337 8.63575L15.3507 9.42198C15.5764 9.63914 15.5805 9.99528 15.3598 10.2174L9.21485 16.4049C7.08713 18.5472 3.64545 18.5218 1.54449 16.3878Z" fill="white" />
                </svg>
            </SvgIcon>
        )
    }

    return (
        <Box style={{ width: '100%', marginTop: 20 }}>
            {loadingEdital ? (
                <Grid md={12} style={{ marginTop: 20 }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                    <Grid container spacing={1} paddingX="0">
                        <Grid xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Criar Edital</span>
                        </Grid>
                        <Grid md={12}>
                            <Box style={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                                <Button
                                    style={{ width: isMobile ? '100%' : 'auto', height: 41 }}
                                    variant="contained"
                                    color="info"
                                    component="label"
                                    endIcon={<ImageIcon />}
                                >
                                    Anexar imagem
                                    <input
                                        onChange={onChangeImage}
                                        ref={inputImage}
                                        hidden
                                        accept={IMG_ARQUIVOS_PERMITIDOS.join(',')}
                                        type="file"
                                    />
                                </Button>
                            </Box>
                            {imageFile ? (
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                    <span>{imageFile.name}</span>
                                    <IconButton
                                        onClick={() => {
                                            setImageFile(undefined);
                                            inputImage.current.value = '';
                                        }} style={{ cursor: 'pointer', fontSize: 16 }} color="error">Remover</IconButton>
                                </Box>

                            ) : null}
                            {edital && edital.image ? (
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                    <span>{edital.image.split('/')[3]}</span>
                                    <IconButton onClick={() => removerImage(edital.id)} style={{ cursor: 'pointer', fontSize: 16 }} color="error">Remover</IconButton>
                                </Box>

                            ) : null}
                        </Grid>
                        <Grid md={11} xs={12}>
                            <Grid container>
                                <>
                                    <Grid md={8}>
                                        <Controller
                                            name="titulo"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ required: 'campo é obrigatório' }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Título"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid md={4}>
                                        <Controller
                                            name="codigo_ano"
                                            defaultValue={""}
                                            control={control}
                                            mask="999/9999"
                                            rules={{ required: 'campo é obrigatório' }}
                                            render={({
                                                field: { onChange, value, onBlur },
                                                fieldState: { error },
                                            }) => (
                                                <InputMask
                                                    style={{ marginTop: '5px' }}
                                                    mask="999/9999"
                                                    maskChar={""}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                    }}
                                                >
                                                    {(inputProps) => (
                                                        <TextField
                                                            style={{ marginTop: '5px' }}
                                                            margin="normal"
                                                            {...inputProps}
                                                            error={error}
                                                            helperText={error && error.message}
                                                            fullWidth
                                                            label="Código/Ano"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={4}>
                                        <Controller
                                            name="data_abertura"
                                            // defaultValue={''}
                                            rules={{
                                                required: 'Campo é obrigatório',
                                                validate: (value) => {
                                                    return value !== null || value !== undefined;
                                                },
                                            }}
                                            control={control}
                                            render={({
                                                field: { value, onChange },
                                                fieldState: { error },
                                            }) => (
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                    <Box style={{ paddingTop: 0, width: '100%' }}>
                                                        <DateTimePicker
                                                            // inputFormat="DD/MM/YYYY HH:mm"
                                                            defaultValue={null}
                                                            value={value}
                                                            label="Data e hora de abertura"
                                                            minDate={dayjs(new Date())}
                                                            onChange={(event) => {
                                                                onChange(event);
                                                            }}
                                                        />
                                                        {/* <MobileDatePicker
                                                        inputFormat="DD/MM/YYYY"
                                                        value={value}
                                                        defaultValue={undefined}
                                                        label="Data e Hora de Abertura"
                                                        minDate={Moment(new Date())}
                                                        onChange={(event) => {
                                                            onChange(event);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                label="Data e Hora de Abertura"
                                                                error={errors['data_abertura']}
                                                                style={{ width: '100%' }}
                                                                helperText={error && error.message}
                                                                {...params}
                                                            />
                                                        )}
                                                    /> */}
                                                    </Box>
                                                </LocalizationProvider>
                                            )}
                                        />
                                    </Grid>
                                    {!checkedIsAberto ? (
                                        <Grid md={4}>
                                            <Controller
                                                name="data_encerramento"
                                                // defaultValue={''}
                                                control={control}
                                                render={({
                                                    field: { value, onChange },
                                                    fieldState: { error },
                                                }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                        <Box style={{ paddingTop: 0, width: '100%' }}>

                                                            <DateTimePicker
                                                                // inputFormat="DD/MM/YYYY HH:mm"
                                                                defaultValue={null}
                                                                value={value}
                                                                label="Data e hora encerramento"
                                                                // minDate={dayjs(new Date())}
                                                                onChange={(event) => {
                                                                    onChange(event);
                                                                }}
                                                            />

                                                        </Box>
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </Grid>
                                    ) : null}
                                    <Grid md={4} xs={12}>
                                        <Controller
                                            name="tipo_edital_id"
                                            defaultValue={""}
                                            rules={{
                                                required: 'campo é obrigatório',
                                                validate: (value) => {
                                                    return value !== 0;
                                                },
                                            }}
                                            // defaultValue={fornecedor && fornecedor.natureza_juridica ? fornecedor.natureza_juridica : 0}
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    value={value}
                                                    style={{ height: 38 }}
                                                    error={error}
                                                    label="Tipo Edital"
                                                    select
                                                    className="inputSelect"
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    fullWidth
                                                >
                                                    <MenuItem onClick={() => { setOpen(true) }} value={0}>
                                                        <AddIcon /> Adicionar
                                                    </MenuItem>
                                                    <MenuItem disabled value={0}>
                                                        Selecione o tipo do edital
                                                    </MenuItem>
                                                    {tipoEditais && tipoEditais.map((res, index) =>
                                                        <MenuItem value={res.id}>
                                                            {`${res.nome}`}
                                                        </MenuItem>
                                                    )}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={12}>
                                        <p>Selecione o gestor do edital</p>
                                        <FormControl style={{ width: '100%' }}>
                                            <Autocomplete
                                                disablePortal
                                                value={ateste}
                                                options={usersFluig}
                                                getOptionLabel={(option) =>
                                                    `${option.login} - ${option.nome}`
                                                }
                                                onChange={(event, newValue) => {
                                                    console.log(newValue);
                                                    setAteste(newValue);
                                                    // setNotaFiscal(notaFiscal);
                                                }}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid md={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedIsAberto}
                                                    onChange={handleCheck}
                                                    style={{ padding: '4px' }}
                                                />
                                            }
                                            label={'Permanentemente Aberto'}
                                        />
                                    </Grid>
                                    <Grid md={12} xs={12}>
                                        <Controller
                                            defaultValue={""}
                                            name="resumo"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    multiline
                                                    rows={3}
                                                    fullWidth
                                                    label="Resumo"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid md={12} xs={12}>
                                        <Controller
                                            defaultValue={""}
                                            name="texto"
                                            control={control}
                                            rules={{ required: 'Campo é obrigatório' }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    style={{ marginTop: '5px' }}
                                                    margin="normal"
                                                    value={value}
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    multiline
                                                    rows={5}
                                                    fullWidth
                                                    label="Texto"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            </Grid>
                        </Grid>
                        <Grid md={12} xs={12}>
                            <Box style={{ width: '100%' }}>
                                <Button
                                    style={{ width: isMobile ? '100%' : 'auto', height: 41 }}
                                    variant="contained"
                                    color="info"
                                    component="label"
                                    endIcon={svgAnexo()}
                                >
                                    Anexar arquivo(s)
                                    <input
                                        ref={inputFile}
                                        onChange={onChangeFile}
                                        hidden
                                        accept={TIPO_ARQUIVOS_PERMITIDOS.join(',')}
                                        type="file"
                                    />
                                </Button>
                            </Box>
                            {arquivo ? (
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                    <span>{arquivo.name}</span>
                                    <IconButton onClick={() => {
                                        setArquivo(undefined);
                                        inputFile.current.value = '';
                                    }} style={{ cursor: 'pointer', fontSize: 16 }} color="error">Remover</IconButton>
                                </Box>

                            ) : null}
                            {edital && edital.documento ? (
                                <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                    <span>{edital.documento.split('/')[3]}</span>
                                    <IconButton onClick={() => removerDocumento(edital.id)} style={{ cursor: 'pointer', fontSize: 16 }} color="error">Remover</IconButton>
                                </Box>

                            ) : null}

                        </Grid>
                        <Grid md={4} xs={12}>
                            <Button
                                style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                                color="primary" variant="outlined"
                                onClick={() => navigate('/editais')}
                                startIcon={<ArrowBackIcon color="primary" />} >
                                Voltar
                            </Button>
                        </Grid>
                        <Grid md={8} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                            <Button
                                style={{ width: isMobile ? '100%' : 177, height: 41, marginTop: 10, marginRight: isMobile ? 0 : 20, color: '#666666', borderColor: '#666666' }}
                                color="info" variant="outlined"
                                endIcon={<SaveIcon style={{ color: '#666666' }} />}
                                onClick={() => {
                                    setIsRascunho(true);
                                }}
                                type="submit"
                            >
                                Salvar rascunho
                            </Button>
                            <Button
                                type="submit"
                                style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                                variant="contained"
                                disabled={loading}
                                endIcon={loading ? <CircularProgress style={{ color: 'white' }} size={15} /> : <ArrowForwardIcon />}
                            >
                                Avançar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                        <Grid container spacing={1} marginBottom={5}>
                            <Grid md={12}>
                                <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold' }}>Adicionar tipo edital </p>
                                <CloseIcon onClick={() => handleClose()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                            </Grid>
                            <Grid md={12}>
                                <TextField
                                    style={{ marginTop: '5px' }}
                                    margin="normal"
                                    value={titulo}
                                    error={errorTitulo}
                                    helperText={msgErrorTitulo}
                                    onChange={(e) => {
                                        setTitulo(e.target.value);
                                    }}
                                    fullWidth
                                    label="Título"
                                />
                            </Grid>
                            <Grid mdOffset={9} md={3}>
                                <Button
                                    onClick={() => {
                                        salvarTipoEdital();
                                    }}
                                    style={{ width: '100%', height: 41, marginTop: 10 }}
                                    variant="contained"
                                    disabled={loading}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal >
        </Box >
    )
}