
import * as React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2';
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import MenuItem from "@mui/material/MenuItem";
import { useForm, Controller } from "react-hook-form";
import { useQuery } from "../../hooks/useQuery";
import { useSnackbar } from "../../hooks/useSnackbar";
import { validaCnpj } from "../../utils";

export const Step1Cadastro = ({ activeStep, setActiveStep, fornecedor, setFornecedor }) => {

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const snackbar = useSnackbar();
    const [naturezas, setNaturezas] = React.useState([]);
    const { getCompany, getNaturezaJuridica, cadastroStep1, showCadastroFornecedor } = useQuery();

    React.useEffect(() => {
        listarNaturezajuridica();
        if (fornecedor && fornecedor.id) {
            getCadastro();
        }
    }, []);

    const getCadastro = async () => {
        const result = await showCadastroFornecedor(fornecedor.id);
        setValue('cnpj', result.data.data.documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"))
        setFornecedor(result.data.data);

    }

    const listarNaturezajuridica = async () => {
        const result = await getNaturezaJuridica();

        if (result && result.data) {
            setNaturezas(result.data.data);
        }
    }

    const onChangeDocument = async (e) => {

        console.log(e.target.value.length);
        if (e.target.value.replace(/\D/g, "").length == 14) {
            const result = await getCompany(e.target.value.replace(/\D/g, ""));

            if (result && result.data && result.data.data) {
                console.log(result.data.data[0].RAZAOSOCIAL);
                setValue("cep", result.data.data[0].CEP);
                setValue("endereco", result.data.data[0].RUA);
                setValue("numero", result.data.data[0].NUMERO);
                setValue("complemento", result.data.data[0].COMPLEMENTO);
                setValue("bairro", result.data.data[0].BAIRRO);
                setValue("cidade", result.data.data[0].CIDADE);
                setValue("estado", result.data.data[0].ESTADO);
                setValue("razao_social", result.data.data[0].RAZAOSOCIAL);
                setValue("declaracao_enquadramento", result.data.data[0].DESC_PORTE);
                setValue("natureza_juridica", result.data.data[0].COD_NATUREZA_JUR);
            }
        }
    }

    const cadastrarFornecedor = async (data) => {
        try {
            const result = await cadastroStep1(data);
            if (result && result.data && result.data.data) {
                setFornecedor(result.data.data);
                setActiveStep(activeStep + 1);
            }
        } catch (error) {
            console.log(error);
            snackbar.showSnackbar(error.response.data.message, '#DC143C');
        }
    }

    const onSubmit = (data) => {
        data.cnpj = data.cnpj.replace(/\D/g, '');
        if (data.inscricao_municipal == '' || data.inscricao_municipal == null) {
            data.inscricao_municipal = null;
        } else {
            data.inscricao_municipal = data.inscricao_municipal.replace(/\D/g, '');
        }
        if (fornecedor && fornecedor.id) {
            data.id = fornecedor.id;
        }
        cadastrarFornecedor(data);
    }

    const onErrors = () => {

    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <Grid container spacing={2}>
                <Grid container sm={12} item >
                    <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 500 }}><b>Cadastre os seus dados !</b></p>
                </Grid>
                <Grid md={11} xs={12}>
                    <Grid container>
                        <>
                            <Grid md={4}>
                                <Controller
                                    name="cnpj"
                                    control={control}
                                    defaultValue={fornecedor && fornecedor.documento ? fornecedor.documento : ""}
                                    mask="99.999.999.9999-99"
                                    rules={{
                                        required: "campo é obrigatório",
                                        validate: (value) => {
                                            return validaCnpj(value)
                                        }
                                    }}
                                    render={({
                                        field: { onChange, onBlur, ref, value },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            style={{ marginTop: '5px' }}
                                            mask="99.999.999.9999-99"
                                            maskChar={""}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(event) => {
                                                onChange(event);
                                                onChangeDocument(event);
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    margin="normal"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    fullWidth
                                                    {...inputProps}
                                                    id="cnpj"
                                                    label="CNPJ"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                                {errors.cnpj && errors.cnpj.type === "validate" && <small style={{ color: 'red' }}>CNPJ Inválido</small>}
                            </Grid>
                            <Grid md={8}></Grid>
                            <Grid md={3}>
                                <Controller
                                    name="cep"
                                    defaultValue={fornecedor && fornecedor.cep ? fornecedor.cep : ""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="CEP"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={2}>
                                <Controller
                                    name="numero"
                                    defaultValue={fornecedor && fornecedor.numero ? fornecedor.numero : ""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            type="number"
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Número"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={2}>
                                <Controller
                                    name="complemento"
                                    control={control}
                                    defaultValue={fornecedor && fornecedor.complemento ? fornecedor.complemento : ""}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            label="Complemento"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="bairro"
                                    defaultValue={fornecedor && fornecedor.bairro ? fornecedor.bairro : ""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            label="Bairro"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={2}>
                                <Controller
                                    name="estado"
                                    control={control}
                                    defaultValue={"MG"}
                                    // rules={{
                                    //     required: "campo é obrigatório",
                                    // }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            label="Estado"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="endereco"
                                    defaultValue={fornecedor && fornecedor.endereco ? fornecedor.endereco : ""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Endereço"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="cidade"
                                    defaultValue={""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Cidade"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="pais"
                                    defaultValue={"BRASIL"}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="País"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}></Grid>
                            <Grid md={4}>
                                <Controller
                                    name="natureza_juridica"
                                    id="natureza_juridica"
                                    defaultValue={fornecedor && fornecedor.natureza_juridica ? fornecedor.natureza_juridica : 0}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            value={value}
                                            style={{ height: 38, marginTop: 5 }}
                                            error={error}
                                            label="Natureza Juridica"
                                            select
                                            className="inputSelect"
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem disabled value={0}>
                                                Selecione uma opção
                                            </MenuItem>
                                            {naturezas && naturezas.map((res, index) =>
                                                <MenuItem value={res.valor}>
                                                    {`${res.cod} ${res.descricao}`}
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="razao_social"
                                    rules={{
                                        required: "campo é obrigatório",
                                    }}
                                    defaultValue={fornecedor && fornecedor.razao_social ? fornecedor.razao_social : ""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Razão Social"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="declaracao_enquadramento"
                                    id="declaracao_enquadramento"
                                    rules={{
                                        required: 'Declaração de Enquadramento é obrigatório',
                                        validate: (value) => {
                                            return value !== 0;
                                        },
                                    }}
                                    defaultValue={fornecedor && fornecedor.declaracao_enquadramento ? fornecedor.declaracao_enquadramento : 0}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            value={value}
                                            style={{ height: 38, marginTop: 5 }}
                                            error={error}
                                            className="inputSelect"
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            fullWidth
                                            select
                                            label="Declaração de Enquadramento"
                                        >
                                            <MenuItem disabled value={0}>
                                                Selecione uma opção
                                            </MenuItem>
                                            <MenuItem value={'Microempresa'.toLocaleUpperCase()}>
                                                Microempresa (ME)
                                            </MenuItem>
                                            <MenuItem value={'Empresa de Pequeno Porte'.toLocaleUpperCase()}>
                                                Empresa de Pequeno Porte (EPP)
                                            </MenuItem>
                                            <MenuItem value={'Média Empresa'.toLocaleUpperCase()}>
                                                Média Empresa
                                            </MenuItem>
                                            <MenuItem value={'Grande Empresa'.toLocaleUpperCase()}>
                                                Grande Empresa
                                            </MenuItem>
                                            <MenuItem value={'MicroEmpreendedor Individual'.toLocaleUpperCase()}>
                                                MicroEmpreendedor Individual
                                            </MenuItem>
                                            <MenuItem value={'Não Aplicável'.toLocaleUpperCase()}>
                                                Não Aplicável
                                            </MenuItem>
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="inscricao_estadual"
                                    control={control}
                                    defaultValue={fornecedor && fornecedor.inscricao_estadual ? fornecedor.inscricao_estadual : ""}
                                    render={({
                                        field: { onChange, value, onBlur },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            style={{ marginTop: '5px' }}
                                            mask="99999999999999"
                                            maskChar={''}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(event) => {
                                                onChange(event);
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    margin="normal"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    fullWidth
                                                    {...inputProps}
                                                    id="inscricao_estadual"
                                                    label="Incrição Estadual"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                                <span style={{ color: '#999999', fontSize: 12, fontFamily: 'AlegraSans' }}>*Caso não possuir, deixar o campo em branco.</span>
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="inscricao_municipal"
                                    control={control}
                                    defaultValue={fornecedor && fornecedor.inscricao_municipal ? fornecedor.inscricao_municipal : ""}
                                    render={({
                                        field: { onChange, value, onBlur },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            style={{ marginTop: '5px' }}
                                            mask="999.999.999/9999"
                                            maskChar={''}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(event) => {
                                                onChange(event);
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    margin="normal"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    fullWidth
                                                    {...inputProps}
                                                    id="inscricao_municipal"
                                                    label="Incrição Municipal"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="tipo_fornecimento"
                                    id="tipo_fornecimento"
                                    rules={{
                                        required: 'campo é obrigatório',
                                        validate: (value) => {
                                            return value !== 0;
                                        },
                                    }}
                                    defaultValue={fornecedor && fornecedor.tipo_fornecimento ? fornecedor.tipo_fornecimento : 0}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            className="inputSelect"
                                            value={value}
                                            style={{ height: 38, marginTop: 5 }}
                                            error={error}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            fullWidth
                                            select
                                            label="Tipo fornecimento"
                                        >
                                            <MenuItem disabled value={0}>
                                                Selecione uma opção
                                            </MenuItem>
                                            <MenuItem value={'Produtos'}>Produtos</MenuItem>
                                            <MenuItem value={'Servicos'}>Serviços</MenuItem>
                                            <MenuItem value={'Prod/Ser'}>Prod/Ser</MenuItem>
                                            <MenuItem value={'PR Rural'}>PR Rural</MenuItem>
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid md={12}>
                                <Controller
                                    name="objeto_social"
                                    defaultValue={fornecedor && fornecedor.objeto_social ? fornecedor.objeto_social : ""}
                                    control={control}
                                    rules={{ required: 'Objeto social é obrigatório' }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            multiline
                                            rows={4}
                                            fullWidth
                                            placeholder="Digite aqui seu objeto social"
                                            label="Objeto Social"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid mdOffset={9} md={3} textAlign={'right'}>
                                <Button style={{ width: '182px', height: 41, marginTop: 10, color: '#fff' }} color="info" type="submit" variant="contained">Próximo</Button>
                            </Grid>
                        </>
                    </Grid>
                </Grid>
            </Grid>
        </form >
    )
}