import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2';
import People from '../../assets/people.png';
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

export const SectionInitialPage = ({ handleOpen }) => {
    const navigate = useNavigate();
    return (
        <section style={{ width: '100%', margin: 0, padding: 0, background: '#3B4AFF' }}>
            <Grid container spacing={1} paddingX={isMobile ? "0" : "4rem"} paddingY="2rem">
                <Grid sm={6} paddingX={5}>
                    <p style={{ fontSize: 18, color: '#FFB380', margin: '20px 0', letterSpacing: 2, fontWeight: 500, fontFamily: 'Campuni-Medium' }}>CREDENCIAMENTO</p>
                    <p style={{ fontSize: 40, color: 'white', fontWeight: '500', margin: '20px 0' }}>Transforme seu talento em oportunidade!</p>
                    <p style={{ fontSize: 24, color: 'white', margin: '20px 0', fontWeight: 400, fontFamily: 'AlegraSans' }}>Contribua para o crescimento das pequenas<br></br> empresas e impulsione sua carreira. Torne-se <br></br>um parceiro credenciado.</p>
                    <Button  onClick={() => navigate('/cadastro')} style={{ width: isMobile ? '100%' : '80%', height: 41, marginTop: 10, color: '#005EB8', }} color="secondary" variant="contained">Credencie sua empresa</Button>
                </Grid>
                <Grid sm={6} marginBottom={5} textAlign={'center'}>
                    <img alt="" className={'imgInitial'} src={People} />
                </Grid>
            </Grid>
        </section>
    )
}