import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useQuery } from "../../hooks/useQuery";
import { Pagination } from '@mui/material';
import { useSnackbar } from "../../hooks/useSnackbar";
import { CircularProgress } from "@mui/material";
import { EditalUsuarioItem } from "../../components/EditalUsuarioItem";

const mock = {
    titulo: 'teste',
    imagem: 'teste.png',
    processos: [
        { status: 'A' }
    ]
}

export const EditalUsuarioPage = () => {

    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const { listaEditais } = useQuery();
    const [valueTab, setValueTab] = React.useState("1");
    const [editais, setEditais] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(0);

    React.useEffect(() => {
        getEditais();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setEditais([]);
        setValueTab(newValue);
        setPage(1);
        console.log(newValue)
        switch (newValue) {
            case '1':
                getEditais(1, '');
                break;
            case '2':
                getEditais(1, 'P');
                break;
            case '3':
                getEditais(1, 'A');
                break;
            case '4':
                getEditais(1, 'E');
                break;
            case '5':
                getEditais(1, 'F');
                break;

            default:
                break;
        }

    };

    const UserTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        fontSize: '18px !important',
    }));

    const getEditais = async (page = 1, status = "") => {
        setLoading(true)
        const result = await listaEditais({ pagination: true, page: page, status: status });
        if (result && result.data && result.data.data) {
            setEditais(result.data.data.data);
            setPage(result.data.data.current_page);
            setLastPage(result.data.data.last_page);
            setTotal(result.data.data.total);
        }
        setLoading(false);
    }

    const handleChange = (event, value) => {
        setPage(value);
        switch (valueTab) {
            case '1':
                getEditais(value, '');
                break;
            case '2':
                getEditais(value, 'P');
                break;
            case '3':
                getEditais(value, 'A');
                break;
            case '5':
                getEditais(value, 'F');
                break;

            default:
                break;
        }
    };

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0">
                <Grid md={12} xs={12}>
                    <TabContext value={valueTab}>
                        <TabList value={valueTab} onChange={handleChangeTab} aria-label="lab API tabs example">
                            <UserTab label="Todos" value="1" />
                            <UserTab label="Previstos" value="2" />
                            <UserTab label="Abertos" value="3" />
                            <UserTab label="Finalizados" value="5" />
                        </TabList>
                        <TabPanel value="1">
                            <Grid container spacing={2}>
                                <Grid md={12}>
                                    {isMobile ? (
                                        <Grid xs={12}>
                                            {editais && editais.map((edital, index) => {
                                                return (
                                                    <EditalUsuarioItem item={edital} />
                                                )
                                            })}
                                        </Grid>
                                    ) : (
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', gridTemplateRows: '324px 206px', gridColumnGap: '15px', gridRowGap: '15px', justifyContent: 'start' }}>
                                            {editais && editais.map((edital, index) => {
                                                return (
                                                    <EditalUsuarioItem item={edital} />
                                                )
                                            })}
                                        </div>
                                    )}

                                </Grid>
                            </Grid>
                            <Box
                                marginTop={20}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pagination
                                    className="customPagination"
                                    variant="outlined"
                                    shape="rounded"
                                    count={lastPage}
                                    page={page}
                                    color={'primary'}
                                    onChange={handleChange}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={2}>
                                <Grid md={12}>
                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', gridTemplateRows: '324px 206px', gridColumnGap: '15px', gridRowGap: '15  px', justifyContent: 'start' }}>
                                        {editais && editais.map((edital, index) => {
                                            return (
                                                <EditalUsuarioItem item={edital} />
                                            )
                                        })}
                                    </div>
                                </Grid>
                            </Grid>
                            <Box
                                marginTop={20}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pagination
                                    className="customPagination"
                                    variant="outlined"
                                    shape="rounded"
                                    count={lastPage}
                                    page={page}
                                    color={'primary'}
                                    onChange={handleChange}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <Grid container spacing={2}>
                                <Grid md={12}>
                                    {isMobile ? (
                                        <Grid xs={12}>
                                            {editais && editais.map((edital, index) => {
                                                return (
                                                    <EditalUsuarioItem item={edital} />
                                                )
                                            })}
                                        </Grid>
                                    ) : (
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', gridTemplateRows: '324px 206px', gridColumnGap: '15px', gridRowGap: '15px', justifyContent: 'start' }}>
                                            {editais && editais.map((edital, index) => {
                                                return (
                                                    <EditalUsuarioItem item={edital} />
                                                )
                                            })}
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                            <Box
                                marginTop={20}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pagination
                                    className="customPagination"
                                    variant="outlined"
                                    shape="rounded"
                                    count={lastPage}
                                    page={page}
                                    color={'primary'}
                                    onChange={handleChange}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="5">
                            <Grid container spacing={2}>
                                <Grid md={12}>
                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', gridTemplateRows: '324px 206px', gridColumnGap: '15px', gridRowGap: '15px', justifyContent: 'start' }}>
                                        {isMobile ? (
                                            <Grid xs={12}>
                                                {editais && editais.map((edital, index) => {
                                                    return (
                                                        <EditalUsuarioItem item={edital} />
                                                    )
                                                })}
                                            </Grid>
                                        ) : (
                                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', gridTemplateRows: '324px 206px', gridColumnGap: '15px', gridRowGap: '15px', justifyContent: 'start' }}>
                                                {editais && editais.map((edital, index) => {
                                                    return (
                                                        <EditalUsuarioItem item={edital} />
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Box
                                marginTop={20}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pagination
                                    className="customPagination"
                                    variant="outlined"
                                    shape="rounded"
                                    count={lastPage}
                                    page={page}
                                    color={'primary'}
                                    onChange={handleChange}
                                />
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Box >
    )
}