import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useQuery } from "../../hooks/useQuery";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import dayjs from "dayjs";
import { DocumentoItem } from "../../components/DocumentoItem";
import { useAuth } from "../../hooks/useAuth";
import { DocumentoHistoricoItem } from "../../components/DocumentoIHistoricotem";
import { useSnackbar } from "../../hooks/useSnackbar";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

const TIPO_ARQUIVOS_PERMITIDOS = [
    'pdf',
];


export const ViewHistoricoPage = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const snackbar = useSnackbar();
    const { user } = useAuth();
    const [documentos, setDocumentos] = React.useState([]);
    const [documentosEnviados, setDocumentosEnviados] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [loadingRow, setLoadingRow] = React.useState(false);
    const { listarDocumentosPorUser, showProcesso, saveDocumentosEmpresa, listaDocumentosEnviados } = useQuery();
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(0);
    const [orderProcess, setOrderProcess] = React.useState(false);
    const [processo, setProcesso] = React.useState();
    const [filesArea, setFilesArea] = React.useState([]);
    const inputFile = React.useRef(null);

    React.useEffect(() => {
        if (user && id) {
            getProcesso();
            getDocumentos();
            buscarDocumentosEnviados();
        }

    }, []);

    const getDocumentos = async (isLoading = true) => {
        if (isLoading) {
            setLoading(true);
        } else {
            setLoadingRow(true);
        }

        const result = await listarDocumentosPorUser(user.user_id, id);
        if (result && result.data) {
            setDocumentos(result.data.data);
        }
        if (isLoading) {
            setLoading(false);
        } else {
            setLoadingRow(false);
        }

    }


    const buscarDocumentosEnviados = async () => {
        const result = await listaDocumentosEnviados('PF', id, user.fornecedor_id, user.user_id);
        if (result && result.data) {
            setDocumentosEnviados(result.data.data);
        }
    }

    const getProcesso = async () => {
        const result = await showProcesso(id);
        if (result && result.data) {
            setProcesso(result.data.data);
        }
    }

    const handleChange = (event, value) => {
        setPage(value);
        getDocumentos(value);
    };


    const svgDoc = () => {

        return (
            <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.25 1.25C5.58696 1.25 4.95107 1.51339 4.48223 1.98223C4.01339 2.45107 3.75 3.08696 3.75 3.75V16.25C3.75 16.913 4.01339 17.5489 4.48223 18.0178C4.95107 18.4866 5.58696 18.75 6.25 18.75H13.75C14.413 18.75 15.0489 18.4866 15.5178 18.0178C15.9866 17.5489 16.25 16.913 16.25 16.25V6.7675C16.2496 6.27039 16.0517 5.79379 15.7 5.4425L12.0588 1.79875C11.8846 1.62467 11.6778 1.4866 11.4502 1.39245C11.2227 1.29829 10.9788 1.24989 10.7325 1.25H6.25ZM5 3.75C5 3.41848 5.1317 3.10054 5.36612 2.86612C5.60054 2.6317 5.91848 2.5 6.25 2.5H10V5.625C10 6.12228 10.1975 6.59919 10.5492 6.95083C10.9008 7.30246 11.3777 7.5 11.875 7.5H15V16.25C15 16.5815 14.8683 16.8995 14.6339 17.1339C14.3995 17.3683 14.0815 17.5 13.75 17.5H6.25C5.91848 17.5 5.60054 17.3683 5.36612 17.1339C5.1317 16.8995 5 16.5815 5 16.25V3.75ZM14.7413 6.25H11.875C11.7092 6.25 11.5503 6.18415 11.4331 6.06694C11.3158 5.94973 11.25 5.79076 11.25 5.625V2.75875L14.7413 6.25Z" fill="#999999" />
                </svg>
            </SvgIcon>
        )
    }

    const onChangeDocArea = (event, docId, areaId, docAntigoId) => {
        const files = [...event.target.files];
        console.log(files);

        if (files.length > 0) {
            const file = files[0];

            const extension = file.name.split('.').pop();

            if (!TIPO_ARQUIVOS_PERMITIDOS.includes(extension.toLowerCase())) {
                snackbar.showSnackbar(
                    'Tipo de arquivo inválido, insira arquivo dos tipos : ' +
                    TIPO_ARQUIVOS_PERMITIDOS.join(','), '#DC143C'
                );
                inputFile.current.value = '';
                return;
            }

            let aux = [];
            aux.push({ tipo_documento_id: docId, area_id: areaId, arquivo: file });

            const data = {
                processoid: id,
                userid: user.user_id,
                fornecedorid: user.fornecedor_id,
                antigoid: docAntigoId
            }
            if (aux.length > 0) {
                data.documentosArea = aux;
            }


            saveStep(data);

        }

    };



    const saveStep = async (data) => {
        setLoadingSave(true);
        const result = await saveDocumentosEmpresa(data);
        buscarDocumentosEnviados();
        getDocumentos();
        setLoadingSave(false);
    }



    Array.prototype.orderBy = function (selector, desc = false) {
        return [...this].sort((a, b) => {
            a = selector(a);
            b = selector(b);

            if (a == b) return 0;
            return (desc ? a > b : a < b) ? -1 : 1;
        });
    }

    const orderFieldUsuario = () => {
        setDocumentos(documentos.orderBy(h => h.user.name, !orderProcess));
        setOrderProcess(!orderProcess);

    }


    const orderFieldDocumento = () => {
        setDocumentos(documentos.orderBy(h => h.tipo_documento.nome, !orderProcess));
        setOrderProcess(!orderProcess);

    }

    const orderFieldArea = () => {
        setDocumentos(documentos.orderBy(h => h.area && h.area.nome, !orderProcess));
        setOrderProcess(!orderProcess);

    }

    const orderFieldData = () => {
        setDocumentos(documentos.orderBy(h => h.created_at, !orderProcess));
        setOrderProcess(!orderProcess);

    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { navigate('/analise-documental', { replace: true }) }}
                        edge="start"
                    >
                        <span
                            style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 12, fontWeight: 'bold', fontFamily: "Campuni-Bold" }}
                        >
                            <ArrowBackIcon /> Voltar
                        </span>
                    </IconButton>
                </Grid>
                <Grid md={12} xs={12}>
                    <Card sx={{ background: '#F8F9FE' }}>
                        <CardContent style={{ padding: 20, borderLeft: 'solid 4px #005EB8' }}>
                            <Grid container spacing={5}>
                                <Grid md={12}>
                                    <p style={{ fontFamily: 'Campuni-Bold', fontSize: 18, margin: 0 }}>{processo && processo.titulo}</p>
                                    {/* <p style={{ fontFamily: 'AlegraSans-Medium', fontWeight: 700, fontSize: 16, color: '#005EB8', margin: 0 }}>{documentos && documentos.length > 0 && documentos[0].user.colaborador.documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</p> */}
                                </Grid>
                                <Grid md={6} style={{ padding: '0 15px 15px 15px' }}>

                                    <p style={{ color: '#666666', margin: 0, fontSize: 12, fontWeight: 700 }}>Vigência: <span style={{ fontWeight: 500 }}>{dayjs(processo && processo.data_abertura).format('DD/MM/YYYY')}</span></p>
                                    <p style={{ color: '#666666', margin: 0, fontSize: 12, fontWeight: 700 }}>Inscrição realizada em: <span style={{ fontWeight: 500 }}>{dayjs(processo && processo.aceite && processo.aceite.length > 0 && processo.aceite[0].created_at).format('DD/MM/YYYY HH:mm')}</span></p>
                                </Grid>
                                <Grid md={6} style={{ padding: '0 15px 15px 15px' }}>
                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end' }}>
                                        {svgDoc()}
                                        <span style={{ fontSize: 14, marginLeft: 5, color: '#666666', fontWeight: 700, fontFamily: 'Univers-Medium' }}>{documentos.length} Documentos enviados</span>
                                    </div>

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid md={12} xs={12}>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => orderFieldDocumento()} style={{ width: '25%', color: '#005EB8', fontSize: 16, fontFamily: 'Campuni-Bold', cursor: 'pointer' }}>Documentos <SortByAlphaIcon style={{ fontSize: 13 }} /></TableCell>
                                <TableCell onClick={() => orderFieldArea()} style={{ width: '15%', color: '#005EB8', fontSize: 16, fontFamily: 'Campuni-Bold', cursor: 'pointer' }}>Áreas <SortByAlphaIcon style={{ fontSize: 13 }} /></TableCell>
                                <TableCell onClick={() => orderFieldUsuario()} style={{ width: '15%', color: '#005EB8', fontSize: 16, fontFamily: 'Campuni-Bold', cursor: 'pointer' }}>Usuario <SortByAlphaIcon style={{ fontSize: 13 }} /></TableCell>
                                <TableCell onClick={() => orderFieldData()} style={{ width: '15%', color: '#005EB8', fontSize: 16, fontFamily: 'Campuni-Bold', cursor: 'pointer' }}>Data de envio <SortByAlphaIcon style={{ fontSize: 13 }} /></TableCell>
                                <TableCell style={{ width: '30%' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    {documentos && documentos.map(item => {
                                        return (
                                            <DocumentoHistoricoItem
                                                item={item}
                                                setDocumentos={setDocumentos}
                                                onChangeDocArea={onChangeDocArea}
                                                id={id}
                                                documentosEnviados={documentosEnviados}
                                                buscarDocumentosEnviados={buscarDocumentosEnviados}
                                            />
                                        )
                                    })}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                {/* <Grid mdOffset={8} md={4} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => {
                            saveStep();
                        }}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={filesArea.length == 0}
                        endIcon={loadingSave ? <CircularProgress style={{ color: 'white' }} size={15} /> : null}>
                        Enviar
                    </Button>
                </Grid> */}
            </Grid>
        </Box >
    )
}