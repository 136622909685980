import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { useQuery } from "../../hooks/useQuery";
import { isMobile } from "react-device-detect";
import { AlocacaoItem } from "../../components/AlocacaoItem";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const AlocacaoHorasPage = () => {

    const navigate = useNavigate();
    const { listarAlocacaoHoras } = useQuery();
    const [horas, setHoras] = React.useState([]);
    const [carregando, setCarregando] = React.useState(true);

    React.useEffect(() => {
        getLocacaoHora();
    }, [])

    const getLocacaoHora = async () => {
        const result = await listarAlocacaoHoras();
        if (result && result.data.data) {
            setHoras(result.data.data);
            setCarregando(false);
        }
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={1} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>Alocação de horas</span>
                </Grid>
                <Grid md={12} xs={12} marginTop={5}>
                    {isMobile ? (
                        <>
                            <div style={{ marginTop: 5 }}>
                                <AlocacaoItem title={'Alocadas este mês'} horas={horas && horas.alocadas_mes ? horas.alocadas_mes : '00:00'} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <AlocacaoItem title={'Alocadas futuras'} horas={horas && horas.alocadas_futuras ? horas.alocadas_futuras : '00:00'} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <AlocacaoItem title={'Alocadas passadas'} horas={horas && horas.alocadas_passadas ? horas.alocadas_passadas : '00:00'} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <AlocacaoItem title={'Totais horas alocadas'} horas={horas && horas.total ? horas.total : '00:00'} />
                            </div>
                        </>

                    ) : (
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, auto)', gridTemplateRows: '68px 25px', gridColumnGap: '15px', gridRowGap: '15px', justifyContent: 'start' }}>
                            <AlocacaoItem title={'Alocadas este mês'} horas={horas && horas.alocadas_mes ? horas.alocadas_mes : '00:00'} />
                            <AlocacaoItem title={'Alocadas futuras'} horas={horas && horas.alocadas_futuras ? horas.alocadas_futuras : '00:00'} />
                            <AlocacaoItem title={'Alocadas passadas'} horas={horas && horas.alocadas_passadas ? horas.alocadas_passadas : '00:00'} />
                            <AlocacaoItem title={'Totais horas alocadas'} horas={horas && horas.total ? horas.total : '00:00'} />
                        </div>
                    )}

                </Grid>
                <Grid md={4} xs={12}>
                    <p style={{ fontSize: 16, color: '#666666', fontWeight: 500, fontFamily: 'Campuni-Medium' }}>Meses anteriores</p>
                    {horas && horas.meses_anteriores && Object.keys(horas.meses_anteriores).map((i) => (
                        <div key={i} style={{ marginBottom: 10 }} >
                            <AlocacaoItem title={horas.meses_anteriores[i].mes} horas={horas.meses_anteriores[i].horas} />
                        </div>
                    ))}
                </Grid>
                <Grid md={4} xs={12}>
                    <p style={{ fontSize: 16, color: '#666666', fontWeight: 500, fontFamily: 'Campuni-Medium' }}>Próximos Meses</p>
                    {horas && horas.proximos_meses && Object.keys(horas.proximos_meses).map((i) => (
                        <div key={i} style={{ marginBottom: 10 }} >
                            <AlocacaoItem title={horas.proximos_meses[i].mes} horas={horas.proximos_meses[i].horas} />
                        </div>
                    ))}
                </Grid>

            </Grid>

            <Modal
                open={carregando}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12} display={'flex'} justifyContent={'center'} marginTop={5}>
                            <CircularProgress size={20} />
                        </Grid>
                        <Grid md={12}>
                            <p style={{ fontSize: 18, color: '#666666', fontWeight: '500', textAlign: 'center' }}>Aguarde enquanto carregamos as informações...</p>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}