import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Moment from 'moment';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { isMobile } from "react-device-detect";
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { useSnackbar } from "../hooks/useSnackbar";
import { useQuery } from "../hooks/useQuery";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import dayjs from "dayjs";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const DemandaItem = ({ demanda }) => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const verifyHoraRodizio = () => {

        const date1 = dayjs(demanda && demanda.data_en);
        const date2 = dayjs();

        const diff = date2.diff(date1, 'day', true);
        const days = Math.floor(diff);
        const hours = Math.floor((diff - days) * 24);

        if (days > 1) {
            return false;
        }

        if (days == 1 && hours >= 0) {
            return false;
        }

        if (days == 0 && hours <= 24) {
            return true;
        }


        return false;
    }

    const svgCalendar = () => {

        return (
            <SvgIcon style={{ width: 16, height: 16 }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 2H11V1H10V2H6V1H5V2H3C2.45 2 2 2.45 2 3V13C2 13.55 2.45 14 3 14H13C13.55 14 14 13.55 14 13V3C14 2.45 13.55 2 13 2ZM13 13H3V6H13V13ZM13 5H3V3H5V4H6V3H10V4H11V3H13V5Z" fill="#666666" />
                </svg>
            </SvgIcon>
        )
    }

    const svgAlert = () => {

        return (
            <SvgIcon style={{ width: 61, height: 65 }}>
                <svg width="61" height="55" viewBox="0 0 61 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M60.3135 46.5986C62.2682 49.9869 59.8146 54.2222 55.9103 54.2222H5.08908C1.17735 54.2222 -1.26498 49.9803 0.685963 46.5986L26.0969 2.54008C28.0526 -0.849763 32.9509 -0.843621 34.9031 2.54008L60.3135 46.5986ZM30.5 37.4896C27.8096 37.4896 25.6285 39.6707 25.6285 42.3611C25.6285 45.0516 27.8096 47.2326 30.5 47.2326C33.1905 47.2326 35.3716 45.0516 35.3716 42.3611C35.3716 39.6707 33.1905 37.4896 30.5 37.4896ZM25.8749 19.979L26.6605 34.3818C26.6973 35.0557 27.2545 35.5833 27.9294 35.5833H33.0706C33.7455 35.5833 34.3028 35.0557 34.3395 34.3818L35.1251 19.979C35.1648 19.251 34.5852 18.6389 33.8562 18.6389H27.1438C26.4147 18.6389 25.8352 19.251 25.8749 19.979Z" fill="#CCCCCC" />
                </svg>
            </SvgIcon>
        )
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    }


    return (
        <Grid onClick={() => navigate(`/demanda-detalhe/${demanda.id}`)} container spacing={1} style={{ margin: '5px 0', background: '#cccccc30', borderBottom: 'solid 3px #005EB8', minHeight: 137 }}>
            <Grid md={2} xs={6}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 4 }}>
                    {svgCalendar()}
                    <span style={{ fontFamily: 'Univers-Medium', fontSize: 12, fontWeight: 700, color: '#666666' }}>{demanda && dayjs(demanda.data_en).format('DD/MM/YYYY HH:mm')}</span>
                </div>

            </Grid>
            <Grid md={10} xs={0}></Grid>
            <Grid md={8} xs={12} style={{ padding: 0 }}>
                <p style={{ margin: '0 0 0 10px', fontWeight: 500 }}>
                    {demanda && demanda.titulo ? (
                        <div dangerouslySetInnerHTML={{ __html: demanda.titulo }} style={{ fontWeight: '500', fontFamily: "Campuni-Medium", fontSize: 16, color: '#666666' }} />
                    ) : null}

                </p>
            </Grid>
            <Grid md={4} xs={12} style={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>

                <Button
                    style={{
                        width: 'auto',
                        height: isMobile ? 'auto' : 21,
                        color: '#fff',
                        borderRadius: 8,
                        cursor: 'default',
                        background: demanda && demanda.status_usuario == 'A' ? '#6BAC54' : demanda.status_usuario == 'R' ? '#DC143C' : demanda.status_usuario == 'Cancelada' ? '#ccc' : demanda.status_usuario == 'Selecionado' ? '#6BAC54' : demanda.status_usuario == 'Manifestou o interesse' ? '#FFA500' : '#005EB8'
                    }}
                    variant="contained"
                >
                    {demanda && demanda.status_usuario == 'A' ? 'Aprovado' : demanda.status_usuario == 'R' ? 'Reprovado' : demanda.status_usuario == 'Cancelada' ? 'Cancelada' : demanda.status_usuario}
                </Button>


            </Grid>
            <Grid md={12} xs={12}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <LocationOnIcon style={{ color: '#005EB8' }} />
                    <span style={{ fontWeight: '500', fontFamily: "Campuni-Medium", fontSize: 12, color: '#005EB8' }}>{demanda && demanda.cidade}</span>
                </div>
            </Grid>
        </Grid>
    )
}