import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useQuery } from "../../hooks/useQuery";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from "../../hooks/useAuth";
import { CheckBoxUserItem } from "../../components/CheckBoxIUsertem";
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const Step2PJ = ({ activeStep, setActiveStep, processoId, processoFinalizado }) => {

    const snackbar = useSnackbar();
    const { user } = useAuth();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const [checkedAreas, setCheckedAreas] = React.useState([]);
    // const [isCheckAllFuncionarios, setIsCheckAllAreas] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingAreas, setLoadingAreas] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [areas, setAreas] = React.useState([]);
    const [areasSelecionadas, setAreasSelecionadas] = React.useState([]);
    const [funcionariosAll, setFuncionariosAll] = React.useState([]);
    const [expanded, setExpanded] = React.useState('panel0');
    const [isConsultor, setIsConsultor] = React.useState(false);
    const { saveAreasProcessoUser, listarAreasProcessoSelecionadas, listarFuncionarios, listarUserAreaProcesso } = useQuery();
    const [funcionarios, setFuncionarios] = React.useState([]);
    const [funcionariosId, setFuncionariosId] = React.useState([]);

    React.useEffect(() => {
        init();
    }, []);

    React.useEffect(() => {
        if (processoFinalizado) {
            setActiveStep(4)
        }
    }, [processoFinalizado]);

    React.useEffect(() => {
        if (funcionarios && funcionarios.length) {
            buscarAreas();
        }

    }, [funcionarios]);

    const init = async () => {
        await timeout(1500);
        await buscarFuncionarios();
        // await timeout(1500);
        // await buscarAreas();
    }

    const buscarAreas = async () => {
        setLoadingAreas(true);
        const result = await listarAreasProcessoSelecionadas(processoId, user.fornecedor_id);
        if (result && result.data) {

            let aux = [];
            let auxChecked = [];
            let auxAreaId = [];
            result.data.data.map(a => {
                aux.push(a.area);
                auxAreaId.push(a.area_id);
                auxChecked[a.area_id] = [];

            })
            setAreasSelecionadas(auxAreaId);

            setAreas(aux);

            const result2 = await listarUserAreaProcesso({ funcionarios: funcionarios, processoid: processoId });

            if (result2 && result2.data) {
                if (result2.data.data && !Array.isArray(result2.data.data)) {

                    let aux = auxChecked;
                    let keys = Object.keys(result2.data.data);
                    if (keys.length > 0) {
                        keys.forEach(r => {
                            aux[r] = result2.data.data[r];
                        })
                        setCheckedAreas(aux);
                    } else {
                        setCheckedAreas(auxChecked);
                    }
                }else{
                    setCheckedAreas(auxChecked);
                }

            }

        }
        setLoadingAreas(false);
    }

    const buscarUserAreaProcesso = async () => {
        setLoading(true);

        setLoading(false)
    }

    const buscarFuncionarios = async () => {
        setLoadingAreas(true);
        const result = await listarFuncionarios(user.fornecedor_id);
        if (result && result.data) {
            let aux = [];
            let funcsId = [];
            result.data.data.map(a => {
                aux.push(a);
                funcsId.push(a.id);
            })
            setFuncionarios(aux);
            setFuncionariosAll(aux);
            setFuncionariosId(funcsId);
        }
        setLoadingAreas(false);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangeAllAreas = (event) => {
        if (event.target.checked) {
            let areaAux = [];
            // areasAll.forEach(doc => {
            //     areaAux.push(doc.id);
            // });
            // setCheckedAreas(areaAux);
            // setIsCheckAllAreas(true);
        } else {
            setCheckedAreas([]);
            // setIsCheckAllAreas(false);
        }
    };

    const onSubmit = () => {

        console.log('area', checkedAreas);
        const data = {
            areas: checkedAreas,
            processoid: processoId,
            funcionarios: funcionariosId
        }
        console.log(data);
        saveStep(data);


    }

    const onErrors = (error) => {

    }

    const onChangeConsultor = (e) => {
        setIsConsultor(e.target.checked)
    }

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const saveStep = async (data) => {
        setLoadingSave(true);
        const result = await saveAreasProcessoUser(data);
        console.log(result.data);
        if (result && result.data) {
            await timeout(1500);
            setActiveStep(activeStep + 1);
        }
        setLoadingSave(false);

    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }


    return (
        <Box style={{ width: '100%', marginTop: 60 }}>
            <Grid container spacing={1} paddingX="0">
                {/* {loading || loadingAreas ? (
                    <Grid md={12} style={{ marginTop: 20 }}>
                        <CircularProgress />
                    </Grid>
                ) : ( */}
                <>
                    {/* <Grid md={12}>
                            <FormControlLabel value={isConsultor} control={<Switch checked={isConsultor} value={isConsultor} onChange={onChangeConsultor} />} label={"Irei participar desta área como consultor"} />
                        </Grid> */}
                    {areas && areas.map((area, index) => {
                        return (
                            <Grid md={12}>
                                <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        expandIcon={<KeyboardArrowDownIcon />}
                                    >
                                        <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>{area.nome}</span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} marginBottom={5}>
                                            <Grid md={12} style={{ marginTop: 20 }}>
                                                <div
                                                    className="scrollbar"
                                                    id="style-3"
                                                    style={{ height: 200, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', padding: '0 10px' }}
                                                >
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        {funcionarios && funcionarios.map((doc, index) => {
                                                            return (
                                                                <CheckBoxUserItem
                                                                    doc={doc}
                                                                    setCheckedDocumentos={setCheckedAreas}
                                                                    checkedDocumentos={checkedAreas}
                                                                    isCheckAllDocumentos={false}
                                                                    documentos={funcionarios}
                                                                    area_id={area.id}
                                                                />
                                                            )
                                                        })}
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )

                    })}

                </>
                {/* )} */}

                <Grid md={6} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid md={6} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => {
                            onSubmit();
                        }}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={loading || loadingAreas || loadingSave}
                        endIcon={loadingSave ? <CircularProgress style={{ color: 'white' }} size={15} /> : <ArrowForwardIcon />}>
                        Avançar
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={loading || loadingAreas}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12} display={'flex'} justifyContent={'center'} marginTop={5}>
                            <CircularProgress size={20} />
                        </Grid>
                        <Grid md={12}>
                            <p style={{ fontSize: 18, color: '#666666', fontWeight: '500', textAlign: 'center' }}>Aguarde enquanto carregamos os participantes...</p>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box >
    )
}