import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { chipClasses, CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useQuery } from "../../hooks/useQuery";
import Moment from 'moment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const EditalStep6Page = ({ edital, setEdital, activeStep, setActiveStep }) => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = React.useState(false);
    const { editalId } = useParams();
    const { showEdital, updateStatusEdital } = useQuery();
    const [ativar, setAtivar] = React.useState(false);
    const [itens, setItens] = React.useState([]);
    const [itensBasico, setItensBasico] = React.useState([]);


    React.useEffect(() => {

        getEdital();

    }, []);

    const uid = function () {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    const getEdital = async () => {
        setLoading(true);
        const result = await showEdital(editalId ? editalId : edital.id)

        if (result && result.data) {
            setEdital(result.data.data);
            const areaEdital = result.data.data.area_edital;
            
            let treeitem = {
                id: `area-selecionadas`,
                label: "Subáreas Selecionadas",
                children: []
            }
          
            let childAuxDocbasico = []
            result.data.data.documentos_edital.forEach(d => {
                childAuxDocbasico.push({
                    id: `negrito-docbasico-${uid()}`,
                    label: d.tipo_documento.nome
                });
            });

            let treeitemDoc = {
                id: `${uid()}documentos-selecionadas`,
                label: "Documentos Básicos",
                children: childAuxDocbasico
            }

            areaEdital.forEach(item => {
                let childAuxForm = [];
                let childAuxDoc = [];
                let childAuxCat = [];
                item.area.area_categorias.forEach(cat => {
                    childAuxCat.push({
                        id: `cat-${cat.categoria_id}-${cat.area_id}${uid()}`,
                        label: cat.categoria.nome
                    });
                })
                item.area.area_formacoes.forEach(form => {
                    childAuxForm.push({
                        id: `form-${form.formacao_id}-${form.area_id}${uid()}`,
                        label: form.formacao.nome
                    });
                })
                item.area.area_tipo_documentos.forEach(doc => {
                    childAuxDoc.push({
                        id: `doc-${doc.tipo_documento_id}-${doc.area_id}${uid()}`,
                        label: doc.tipo_documento.nome
                    });
                })

                treeitem.children.push({
                    id: `area-${item.area.id}${uid()}`,
                    label: item.area.nome,
                    children: [
                        {
                            id: `negrito-documentos-${item.area.id}${uid()}`,
                            label: 'Documentos',
                            children: childAuxDoc
                        },
                        {
                            id: `negrito-formacoes-${item.area.id}${uid()}`,
                            label: 'Formações',
                            children: childAuxForm
                        },
                        {
                            id: `negrito-categorias-${item.area.id}${uid()}`,
                            label: 'Áreas',
                            children: childAuxCat
                        }
                    ]
                });
            });
            setItens([treeitem]);
            setItensBasico([treeitemDoc]);
            setLoading(false);
        }
    }

    const finalizar = async () => {
        const result = await updateStatusEdital(edital.id, { status: ativar ? 'A' : 'P' });
        if (result && result.data) {
            snackbar.showSnackbar(`Edital criado com sucesso!`, '#05a55a');
            navigate("/editais");
        }
    }

    function svgMinus(props) {

        return (
            <SvgIcon {...props}>
                <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.2143 0H0.785714C0.351853 0 0 0.298542 0 0.666667V1.33333C0 1.70146 0.351853 2 0.785714 2H10.2143C10.6481 2 11 1.70146 11 1.33333V0.666667C11 0.298542 10.6481 0 10.2143 0Z" fill="#005EB8" />
                </svg>
            </SvgIcon>
        )
    }


    function svgPlus(props) {

        return (
            <SvgIcon  {...props}>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.92857 0.714286L3.92857 3.92857L0.714285 3.92857C0.319866 3.92857 -2.51409e-07 4.24844 -2.34168e-07 4.64286L-2.02946e-07 5.35714C-1.85705e-07 5.75156 0.319866 6.07143 0.714286 6.07143L3.92857 6.07143L3.92857 9.28571C3.92857 9.68013 4.24844 10 4.64286 10L5.35714 10C5.75156 10 6.07143 9.68013 6.07143 9.28571L6.07143 6.07143L9.28571 6.07143C9.68013 6.07143 10 5.75156 10 5.35714L10 4.64286C10 4.24844 9.68013 3.92857 9.28571 3.92857L6.07143 3.92857L6.07143 0.714286C6.07143 0.319866 5.75156 -2.51409e-07 5.35714 -2.34168e-07L4.64286 -2.02946e-07C4.24844 -1.85705e-07 3.92857 0.319866 3.92857 0.714286Z" fill="#005EB8" />
                </svg>
            </SvgIcon>
        )
    }


    return (
        <Box style={{ width: '100%', marginTop: 20 }}>

            <Grid container spacing={1} paddingX="0">
                <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Resumo</span>
                </Grid>
                <Grid md={12} xs={12}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Card>
                            <CardContent>
                                <p style={{ margin: 0 }}><span style={{ fontFamily: 'Campuni-Bold' }}>Edital:</span> {edital && edital.titulo}</p>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ margin: 0 }}><span style={{ fontFamily: 'Campuni-Bold' }}>Data e hora de abertura</span></p>
                                    {edital && edital.permanente == 0 ? (
                                        <p style={{ margin: 0 }}><span style={{ fontFamily: 'Campuni-Bold' }}>Data e hora de encerramento</span></p>
                                    ) : null}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ margin: 0, fontFamily: 'Univers-Medium' }}>{edital && Moment(edital.data_abertura).format('DD/MM/YYYY HH:mm')}</p>
                                    {edital && edital.permanente == 0 ? (
                                        <p style={{ margin: 0, fontFamily: 'Univers-Medium' }}>{edital && Moment(edital.data_fechamento).format('DD/MM/YYYY HH:mm')}</p>
                                    ) : null}

                                </div>
                                <div style={{ marginTop: 40 }}>
                                    <RichTreeView
                                        items={itensBasico}
                                        slots={{
                                            expandIcon: svgPlus,
                                            collapseIcon: svgMinus,
                                        }}
                                    />
                                    <RichTreeView
                                        items={itens}
                                        slots={{
                                            expandIcon: svgPlus,
                                            collapseIcon: svgMinus,
                                        }}
                                    />
                                </div>

                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Grid md={12}>
                    <FormControlLabel value={ativar} onChange={(e) => { setAtivar(e.target.checked) }} control={<Checkbox />} label="Deseja ativar o edital ao finalizar?" />
                </Grid>
                <Grid md={4} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid md={8} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => { navigate("/editais") }}
                        style={{ width: isMobile ? '100%' : 177, height: 41, marginTop: 10, marginRight: isMobile ? 0 : 20, color: '#666666', borderColor: '#666666' }}
                        color="info"
                        variant="outlined"
                        endIcon={<SaveIcon style={{ color: '#666666' }} />} >
                        Salvar rascunho
                    </Button>
                    <Button
                        onClick={() => finalizar()}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={loading}
                        endIcon={<ArrowForwardIcon />}
                    >
                        Criar Edital
                    </Button>
                </Grid>
            </Grid>
        </Box >
    )
}