import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import FaqBg from '../../assets/duvidasbg.png';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { SvgIcon } from '@mui/material';
import { isMobile } from "react-device-detect";

export const SectionFaqPage = () => {

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const svgPlus = () => {
        return (
            <SvgIcon>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.6875 7.90625H12.2188V1.4375C12.2188 0.64373 11.575 0 10.7812 0H9.34375C8.54998 0 7.90625 0.64373 7.90625 1.4375V7.90625H1.4375C0.64373 7.90625 0 8.54998 0 9.34375V10.7812C0 11.575 0.64373 12.2188 1.4375 12.2188H7.90625V18.6875C7.90625 19.4813 8.54998 20.125 9.34375 20.125H10.7812C11.575 20.125 12.2188 19.4813 12.2188 18.6875V12.2188H18.6875C19.4813 12.2188 20.125 11.575 20.125 10.7812V9.34375C20.125 8.54998 19.4813 7.90625 18.6875 7.90625Z" fill="#3B4AFF" />
                </svg>
            </SvgIcon>
        )
    }


    return (
        <section style={{ width: '100%', margin: 0, padding: 0, background: '#eFeFeF' }}>
            <Grid container spacing={2} paddingTop="1rem" paddingBottom="2rem" paddingX={isMobile ? "0" : "4rem"}>
                <Grid xs={12}>
                    <p style={{ fontSize: 32, color: '#3B4AFF', textAlign: 'center', fontWeight: 500 }}>Dúvidas frequentes</p>
                </Grid>
                <Grid md={6} xs={12} display={'flex'} justifyContent={'center'}>
                    <img alt="" src={FaqBg} style={{ width: 517, height: 398 }} />
                </Grid>
                <Grid md={6} xs={12}>
                    <Accordion expanded={expanded === `panel1`} onChange={handleChange(`panel1`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Posso participar como Pessoa Física do processo de seleção de credenciamento?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Não. Para se candidatar, é necessário se enquadrar como Pessoa Jurídica (PJ), com Cadastro Nacional de Pessoas Jurídica (CNPJ) registrado e ativo.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel2`} onChange={handleChange(`panel2`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Microempreendedor Individual (MEI) pode participar do Processo de Seleção de Credenciamento?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Sim, desde que a CNAE (Classificação Nacional das Atividades Econômicas) corresponda à natureza do serviço e/ou produto descritos no Edital da Seleção em que pretende se candidatar.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel3`} onChange={handleChange(`panel3`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Sou credenciado em outro Estado, posso me credenciar no Sebrae Minas?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Nos instrumentos convocatórios do Sebrae Minas não há restrições quanto a participação no processo seletivo de credenciamento.
                            </p>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                No entanto, orientamos verificar no Estado do qual você é credenciado se não há nenhuma restrição.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel4`} onChange={handleChange(`panel4`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel4-content"
                            id="panel4-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Se já me credenciei, preciso reenviar os documentos da minha empresa para participar de outro Processo de Seleção de Credenciamento?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Confira no Portal do Credenciamento se todos os documentos solicitados nas etapas do Processo de Seleção foram anexados corretamente. Caso seja necessário, complete a documentação.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel5`} onChange={handleChange(`panel5`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel5-content"
                            id="panel5-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Na minha empresa tenho mais de um profissional que participará do Processo de Seleção de Credenciamento. Cada um deles precisa ter seu próprio login na plataforma?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Sim, após ser indicado pelo representante legal da empresa, cada profissional deverá criar o seu login e preencher os dados do seu perfil.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel6`} onChange={handleChange(`panel6`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel6-content"
                            id="panel6-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Como comprovar meu vínculo profissional com a empresa candidata ao Processo de Seleção de Credenciamento?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                A comprovação se dá por meio de:
                                <ul>
                                    <li>Carteira de Trabalho assinada pela a empresa candidata;</li>
                                    <li>presença do seu nome no Quadro Societário do Contrato Social da empresa candidata;</li>
                                    <li>ou Contrato de Prestação de Serviços, quando o Edital permitir.</li>
                                </ul>
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel7`} onChange={handleChange(`panel7`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel7-content"
                            id="panel7-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Como comprovar minha experiência profissional exigida?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Preenchendo e enviando a “Declaração de Serviços Prestados”, conforme modelo disponível no Edital.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel8`} onChange={handleChange(`panel8`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel8-content"
                            id="panel8-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Na comprovação de experiência, a Declaração de Serviços Prestados pode ser emitida em nome da Pessoa Física?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Não. Para comprovar a experiência, é necessário que a declaração seja emitida por um CNPJ (tomador) e que o serviço tenha sido realizado por um CNPJ (prestador), indicando o profissional responsável pela execução.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel9`} onChange={handleChange(`panel9`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel9-content"
                            id="panel9-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Posso utilizar Declarações de Serviços Prestados emitidas pelo Sebrae Minas?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Não. Conforme previsto nos Editais, não é permitido utilizar Declarações de Serviços Prestados emitidas pelo Sebrae Minas.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel10`} onChange={handleChange(`panel10`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel10-content"
                            id="panel10-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Declarações de Serviços de outros estados podem ser usadas?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Sim, desde que atendam os requisitos do Edital.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel11`} onChange={handleChange(`panel11`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel11-content"
                            id="panel11-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Agrupe todos os documentos em um único arquivo PDF para enviá-los.</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Preenchendo e enviando a “Declaração de Serviços Prestados”, conforme modelo disponível no Edital.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel12`} onChange={handleChange(`panel12`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel12-content"
                            id="panel12-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Cada credenciado recebe uma Agenda pré-definida de Prestação de Serviços?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Não. As agendas serão enviadas para todos credenciados aptos a prestarem serviço, conforme a demanda e as regras de rodízio em cada Edital.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel13`} onChange={handleChange(`panel13`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel13-content"
                            id="panel13-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Como confiro os valores que receberei como Credenciado?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Cada Edital possui sua própria tabela de valor. Verifique essas informações no instrumento convocatório ao qual você deseja se candidatar.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === `panel14`} onChange={handleChange(`panel14`)}>
                        <AccordionSummary
                            expandIcon={svgPlus()}
                            aria-controls="panel14-content"
                            id="panel14-header"
                        >
                            <p><strong style={{fontWeight: 400, fontSize: 18, color: '#666666'}}>Para quais produtos e/ou áreas de conhecimento eu posso me credenciar?</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <hr></hr>
                            <p style={{fontWeight: 200, color: '#666666'}}>
                                Em todas aquelas em que o candidato conseguir comprovar a competência técnica exigida no Edital do seu interesse.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </section>
    )
}