import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "../../hooks/useQuery";
import { isMobile } from "react-device-detect";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../hooks/useSnackbar";
import { CheckBoxItem } from "../../components/CheckBoxItem";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InputAdornment } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";

const TIPO_ARQUIVOS_PERMITIDOS = [
    'pdf',
];

export const CriarEtapaProcessoPage = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();
    const { listaEditais, removeDocumentoProcesso, showEdital, showProcesso, saveProcesso, updateProcesso } = useQuery();
    const [loading, setLoading] = React.useState(false);
    const [arquivo, setArquivo] = React.useState();
    const [processo, setProcesso] = React.useState();
    const [editais, setEditais] = React.useState([]);
    const [checkedAreas, setCheckedAreas] = React.useState([]);
    const [isCheckAllAreas, setIsCheckAllAreas] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [areas, setAreas] = React.useState([]);
    const [areasAll, setAreasAll] = React.useState([]);
    const snackbar = useSnackbar();
    const { processoId } = useParams();

    React.useEffect(() => {
        getEditais();
        if (processoId) {
            getProcesso();
        }
    }, []);

    const getProcesso = async () => {
        const result = await showProcesso(processoId);
        if (result && result.data) {
            setProcesso(result.data.data);
            setValue("titulo", result.data.data.titulo);
            setValue("data_abertura", dayjs(result.data.data.data_abertura));
            setValue("data_encerramento", dayjs(result.data.data.data_fechamento));
            setValue("edital_id", result.data.data.edital_id);
            onChangeEdital(result.data.data.edital_id);
            setValue("texto", result.data.data.descricao);
            let aux = [];

            result.data.data.area_processo.map(a => {
                aux.push(a.area_id);
            })

            setCheckedAreas(aux);
        }
    }

    const getEditais = async (pagina = 1) => {
        const result = await listaEditais({ pagination: false });
        if (result && result.data && result.data.data) {
            setEditais(result.data.data);
        }
    }

    const onChangeEdital = async (id) => {
        let aux = [];

        const result = await showEdital(id);
        result.data.data.area_edital.forEach(res => {
            aux.push({ id: res.area_id, nome: res.area.nome })
        })

        setAreas(aux);
        setAreasAll(aux);
    }

    const filtrarAreas = (term) => {
        setAreas(areasAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const svgSearch = () => {
        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }

    const svgAnexo = () => {
        return (
            <SvgIcon>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.54449 16.3878C-0.542293 14.2683 -0.503401 10.8503 1.59399 8.73848L9.08542 1.19533C10.6681 -0.398373 13.2408 -0.398513 14.8237 1.19533C16.3912 2.77363 16.3931 5.32017 14.8237 6.90034L8.29335 13.4693C7.2271 14.5429 5.48503 14.5279 4.43685 13.4343C3.42703 12.3806 3.45942 10.7106 4.4887 9.6742L9.62238 4.51203C9.84317 4.29006 10.205 4.28626 10.4305 4.50356L11.2469 5.29032C11.4725 5.50766 11.4763 5.86379 11.2555 6.08577L6.12238 11.2474C5.94624 11.4248 5.93538 11.7195 6.09924 11.8905C6.25538 12.0534 6.50085 12.0561 6.65952 11.8963L13.1898 5.32734C13.8903 4.62204 13.8903 3.47373 13.1895 2.76804C12.5041 2.07803 11.4053 2.07768 10.7197 2.76804L3.22821 10.3112C1.98667 11.5613 1.96753 13.5849 3.18567 14.8222C4.40031 16.0559 6.3637 16.0574 7.5806 14.8322L13.7256 8.64475C13.9462 8.4226 14.308 8.41859 14.5337 8.63575L15.3507 9.42198C15.5764 9.63914 15.5805 9.99528 15.3598 10.2174L9.21485 16.4049C7.08713 18.5472 3.64545 18.5218 1.54449 16.3878Z" fill="white" />
                </svg>
            </SvgIcon>
        )
    }

    const onChangeFile = (event) => {
        const files = [...event.target.files];

        if (files.length > 0) {
            const file = files[0];
            const extension = file.name.split('.').pop();

            if (!TIPO_ARQUIVOS_PERMITIDOS.includes(extension.toLowerCase())) {
                snackbar.showSnackbar(
                    'Tipo de arquivo inválido, insira arquivo do tipo : ' +
                    TIPO_ARQUIVOS_PERMITIDOS.join(','), '#DC143C'
                );
                return;
            }

            setArquivo(file);
        }
    };

    const onSubmit = (data) => {
        if (arquivo == undefined && (processo && processo.documento == null)) {
            snackbar.showSnackbar('Anexe um arquivo antes de continuar.', '#DC143C');
            return;
        }

        data.documento = arquivo;
        data.data_abertura = dayjs(data.data_abertura).format("YYYY-MM-DD HH:mm");

        if (dayjs(data.data_encerramento).format("YYYY-MM-DD HH:mm") !== "Invalid date") {
            data.data_fechamento = dayjs(data.data_encerramento).format("YYYY-MM-DD HH:mm");
            delete data.data_encerramento;
        } else {
            delete data.data_encerramento;
        }

        data.areas = checkedAreas;
        if (processo && processo.id) {
            atualizarProcesso(data);
        } else {
            criarProcesso(data);
        }
    }

    const onErrors = (error) => {

    }

    const criarProcesso = async (data) => {
        try {
            setLoading(true);

            // Verifica se é para criar como previsto ou ativo.
            if (dayjs().isAfter(dayjs(data.data_abertura))) {
                data.status = "A";
            } else {
                data.status = "P";
            }

            const result = await saveProcesso(data);
            if (result && result.data) {
                setProcesso(result.data.data);
                snackbar.showSnackbar(`Processo salvo com sucesso!`, '#05a55a');
                navigate('/processos');
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const atualizarProcesso = async (data) => {
        try {
            setLoading(true);

            // Verifica se o status é Ativou ou Programado.
            if (processo.status == "A" || processo.status == "P") {
                // Verifica se é para criar como previsto ou ativo.
                if (dayjs().isAfter(dayjs(data.data_abertura))) {
                    data.status = "A";
                } else {
                    data.status = "P";
                }
            }

            console.log(data);

            const result = await updateProcesso(processo.id, data);
            if (result && result.data) {
                setProcesso(result.data.data);
                snackbar.showSnackbar(`Processo atualizado com sucesso!`, '#05a55a');
                navigate('/processos');
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const removerDocumento = async (id) => {
        try {
            setLoading(true);
            const result = await removeDocumentoProcesso(id);
            console.log(result);
            if (result && result.data) {
                setProcesso(result.data.data);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const childrenAreas = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {areas && areas.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedAreas}
                        checkedDocumentos={checkedAreas}
                        isCheckAllDocumentos={isCheckAllAreas}
                        documentos={areas}
                        editarDocumento={null}
                        key={index}
                    />
                )
            })}
        </Box>
    );

    const handleChangeAllAreas = (event) => {
        if (event.target.checked) {
            let areaAux = [];
            areasAll.forEach(doc => {
                areaAux.push(doc.id);
            });
            setCheckedAreas(areaAux);
            setIsCheckAllAreas(true);
        } else {
            setCheckedAreas([]);
            setIsCheckAllAreas(false);
        }
    };

    return (
        <Box style={{ width: '100%', marginTop: 20 }}>
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Grid container spacing={1} paddingX="0">
                    <Grid md={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Criar Etapas do Processo</span>
                    </Grid>
                    <Grid md={11} xs={12}>
                        <Grid container>
                            <>
                                <Grid md={12}>
                                    <Controller
                                        name="titulo"
                                        control={control}
                                        defaultValue={""}
                                        rules={{ required: 'campo é obrigatório' }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                style={{ marginTop: '5px' }}
                                                margin="normal"
                                                value={value}
                                                error={error}
                                                helperText={error && error.message}
                                                onChange={onChange}
                                                fullWidth
                                                label="Título"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid md={4} xs={12}>
                                    <Controller
                                        name="edital_id"
                                        defaultValue={""}
                                        rules={{
                                            required: 'campo é obrigatório',
                                            validate: (value) => {
                                                return value !== 0;
                                            },
                                        }}
                                        // defaultValue={fornecedor && fornecedor.natureza_juridica ? fornecedor.natureza_juridica : 0}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                value={value}
                                                style={{ height: 38 }}
                                                error={error}
                                                label="Edital"
                                                select
                                                className="inputSelect"
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onChangeEdital(e.target.value);
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem key={0} disabled value={0}>
                                                    Selecione o edital
                                                </MenuItem>
                                                {editais && editais.map((res, index) =>
                                                    <MenuItem key={1 + index} value={res.id}>
                                                        {`${res.titulo}`}
                                                    </MenuItem>
                                                )}
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                                <Grid md={4}>
                                    <Controller
                                        name="data_abertura"
                                        // defaultValue={''}
                                        rules={{
                                            required: 'Campo é obrigatório',
                                            validate: (value) => {
                                                return value !== null || value !== undefined;
                                            },
                                        }}
                                        control={control}
                                        render={({
                                            field: { value, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                <Box style={{ paddingTop: 0, width: '100%' }}>
                                                    <DateTimePicker
                                                        defaultValue={null}
                                                        value={value}
                                                        label="Data e hora de abertura"
                                                        minDate={dayjs(new Date())}
                                                        onChange={(event) => {
                                                            onChange(event);
                                                        }}
                                                    />
                                                </Box>
                                            </LocalizationProvider>
                                        )}
                                    />
                                </Grid>
                                <Grid md={4}>
                                    <Controller
                                        name="data_encerramento"
                                        // defaultValue={''}
                                        control={control}
                                        render={({
                                            field: { value, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                <Box style={{ paddingTop: 0, width: '100%' }}>

                                                    <DateTimePicker
                                                        defaultValue={null}
                                                        value={value}
                                                        label="Data e hora encerramento"
                                                        minDate={dayjs(new Date())}
                                                        onChange={(event) => {
                                                            onChange(event);
                                                        }}
                                                    />
                                                </Box>
                                            </LocalizationProvider>
                                        )}
                                    />
                                </Grid>
                                <Grid md={12} xs={12}>
                                    <Controller
                                        defaultValue={""}
                                        name="texto"
                                        control={control}
                                        rules={{ required: 'Campo é obrigatório' }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                style={{ marginTop: '5px' }}
                                                margin="normal"
                                                value={value}
                                                error={error}
                                                helperText={error && error.message}
                                                onChange={onChange}
                                                multiline
                                                rows={5}
                                                fullWidth
                                                label="Texto"
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        </Grid>
                    </Grid>
                    <Grid md={11} xs={12}>
                        <Box style={{ width: '100%' }}>
                            <Button
                                style={{ width: isMobile ? '100%' : 'auto', height: 41 }}
                                variant="contained"
                                color="info"
                                component="label"
                                endIcon={svgAnexo()}
                            >
                                Anexar arquivo(s)
                                <input
                                    onChange={onChangeFile}
                                    hidden
                                    accept={TIPO_ARQUIVOS_PERMITIDOS.join(',')}
                                    type="file"
                                />
                            </Button>
                        </Box>
                        {arquivo ? (
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                <span>{arquivo.name}</span>
                                <IconButton onClick={() => setArquivo(undefined)} style={{ cursor: 'pointer', fontSize: 16 }} color="error">Remover</IconButton>
                            </Box>

                        ) : null}
                        {processo && processo.documento ? (
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                <span>{processo.documento.split('/')[3]}</span>
                                <IconButton onClick={() => removerDocumento(processo.id)} style={{ cursor: 'pointer', fontSize: 16 }} color="error">Remover</IconButton>
                            </Box>

                        ) : null}

                    </Grid>
                    <Grid md={11} style={{ marginTop: 20 }}>
                        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                            <AccordionSummary
                                aria-controls="panel1-content"
                                id="panel1-header"
                                expandIcon={<KeyboardArrowDownIcon />}
                            >
                                <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Áreas selecionadas no edital</span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={1} marginBottom={5}>
                                    <Grid md={12}>
                                        <TextField
                                            id="customInput"
                                            placeholder="Buscar"
                                            fullWidth
                                            className="custom-class"
                                            onChange={(event) => {
                                                filtrarAreas(event.target.value);
                                            }}
                                            variant="standard"
                                            style={{ height: '38 !important', width: '100%', }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton>
                                                            {svgSearch()}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={12} style={{ marginTop: 20 }}>
                                        <div
                                            className="scrollbar"
                                            id="style-3"
                                            style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                        >
                                            {areas && areas.length == areasAll.length ? (
                                                <FormControlLabel
                                                    label="Marcar todos"
                                                    control={
                                                        <Checkbox
                                                            checked={checkedAreas.length === areas.length}
                                                            onChange={handleChangeAllAreas}
                                                        />
                                                    }
                                                />
                                            ) : null}
                                            {childrenAreas}
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid md={3}>
                        <Button
                            style={{ width: '100%', height: 41, marginTop: 10 }}
                            color="primary"
                            variant="outlined"
                            startIcon={<ArrowBackIcon color="primary" />}
                            onClick={() =>  navigate('/processos', { replace: true }) }
                        >
                            Voltar
                        </Button>
                    </Grid>
                    <Grid mdOffset={6} md={3}>
                        <Button
                            type="submit"
                            style={{ width: '100%', height: 41, marginTop: 10, color: '#fff' }}
                            variant="contained"
                            disabled={loading}
                            endIcon={loading ? <CircularProgress style={{ color: 'white' }} size={15} /> : null}
                        >
                            Concluir
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box >
    )
}