
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// default 10mb
export const isFileBig = (fileSize, maxSize = 10000) => {
  return Math.round(fileSize / 1024) > maxSize;
};

export const isCnpj = (cnpj) => {
  return /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/.test(
    cnpj
  );
};

export const serializeQueryString = (query, initialParams = "?") => {
  let queryString = "";
  for (let key in query) {
    if (queryString === "") {
      queryString = `${initialParams}${key}=${query[key]}`;
    } else {
      queryString += `&${key}=${query[key]}`;
    }
  }
  return queryString;
};

export const maskPhone = (phone) => {
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
  return phone;
};

export const maskCep = (cep) => {
  return cep.replace(/(\d{5})(\d{3})/g, "$1-$2");
};

export const maskCpf = (cpf) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
};

export const handleHttpError = (error) => {
  let msg = "";

  if (!error.response) {
    msg = "Houve um erro inesperado. Tente novamente mais tarde.";
  } else {
    const status = error.response.status;
    switch (status) {
      case 400:
        msg = error.response.data.message;
        break;
      case 404:
        msg = error.response.data.message
          ? error.response.data.message
          : "Houve um erro inesperado. Recurso não encontrado";
        break;
      case 422:
        Object.entries(error.response.data.errors).forEach(([key, value]) => {
          msg = value[0];
        });
        break;
      case 401:
        msg = error.response.data.message
          ? error.response.data.message
          : "Você não tem permissão para acessar esse recurso. Efetue o login novamete";
        break;
      case 500:
        console.log(error.response.data);
        msg = error.response.data.message
          ? error.response.data.message
          : "Houve um problema ao comunicar-se com o servidor. Tente novamente mais tarde.";
        break;
      default:
        msg = "Houve um erro inesperado. Tente novamente mais tarde.";
    }
  }

  return msg;
};

export const validaCpf = (val) => {
  if (val.length == 14) {
    var cpf = val.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = ((v1 * 10) % 11);

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = ((v2 * 10) % 11);

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }

  } else {
    return false;
  }
}

export const validaCnpj = (val) => {
  if (val.length == 18) {
    var cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = (v1 % 11);

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = (11 - v1);
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = (v2 % 11);

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = (11 - v2);
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const authDomain = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

const firebaseConfig = {
  apiKey: apiKey,
  authDomain:authDomain,
  projectId:projectId,
  storageBucket: "sebrae-credenciamento-v2-dev.firebasestorage.app",
  messagingSenderId: "850029817636",
  appId: "1:850029817636:web:ef187e0f6093289ce94db1"
};

console.log(firebaseConfig);

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);