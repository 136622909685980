import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useQuery } from "../../hooks/useQuery";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { FormacaoItem } from "../../components/FormacaoItem";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const EditalStep4Page = ({ edital, setEdital, activeStep, setActiveStep }) => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = React.useState(false);
    const [loadingCategoria, setLoadingCategoria] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [categorias, setCategorias] = React.useState([]);
    const [categoriasAll, setCategoriasAll] = React.useState([]);
    const [titulo, setTitulo] = React.useState();
    const [errorTitulo, setErrortitulo] = React.useState(false);
    const [msgErrorTitulo, setMsgErrorTitulo] = React.useState("");
    const [selectedCategoria, setSelectedCategoria] = React.useState();
    const { listaCategorias, saveCategoria, updateCategoria } = useQuery();

    React.useEffect(() => {
        buscarCategorias();
        console.log('edital', edital);
    }, []);

    const buscarCategorias = async () => {
        setLoadingCategoria(true);
        const result = await listaCategorias();
        if (result && result.data) {
            setCategorias(result.data.data);
            setCategoriasAll(result.data.data);
        }
        setLoadingCategoria(false);
    }

    const filtrarCategorias = (term) => {
        setCategorias(categoriasAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const editarCategoria = async (doc) => {
        setSelectedCategoria(doc);
        setTitulo(doc.nome);
        setOpen(true);
    }

    const onSubmit = () => {
        setActiveStep(activeStep + 1);
    }

    const onErrors = (error) => {

    }

    const handleClose = () => {
        setSelectedCategoria(undefined);
        setOpen(false);
        setTitulo(undefined);
    }

    const salvarCategoria = async () => {

        try {
            let body = {
                nome: titulo
            }

            if (selectedCategoria) {
                const result = await updateCategoria(selectedCategoria.id, body);

                if (result && result.data) {
                    snackbar.showSnackbar(`Categoria alterada com sucesso!`, '#05a55a');
                    buscarCategorias();
                    setOpen(false);
                }
            } else {
                const result = await saveCategoria(body);

                if (result && result.data) {
                    snackbar.showSnackbar(`Categoria salva com sucesso!`, '#05a55a');
                    buscarCategorias();
                    setOpen(false);
                }
            }

        } catch (error) {
            snackbar.showSnackbar('Erro ao criar a categoria.', '#DC143C');
        }
    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }


    return (
        <Box style={{ width: '100%', marginTop: 60 }}>
            <Grid container spacing={1} paddingX="0">
                <Grid xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Área</span>
                </Grid>
                <Grid md={9}>
                    <Box style={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                        <Button
                            style={{ width: 182, height: 40 }}
                            variant="contained"
                            color="info"
                            component="label"
                            onClick={() => setOpen(true)}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Adicionar
                        </Button>
                    </Box>
                </Grid>
                <Grid md={12} xs={12}>
                    <TextField
                        id="customInput"
                        placeholder="Buscar"
                        fullWidth
                        className="custom-class"
                        onChange={(event) => {
                            filtrarCategorias(event.target.value);
                        }}
                        variant="standard"
                        style={{ height: '38 !important', width: '100%', }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => { }}>
                                        {svgSearch()}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                {loadingCategoria ? (
                    <Grid md={12} style={{ marginTop: 20 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid md={12} xs={12} style={{ marginTop: 20 }}>
                        <div
                            className="scrollbar"
                            id="style-3"
                            style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', padding: '0 10px' }}
                        >
                            {categorias && categorias.map((categoria, index) => {
                                return (
                                    <FormacaoItem
                                        item={categoria}
                                        editarDocumento={editarCategoria}
                                    />
                                )
                            })}
                        </div>
                    </Grid>
                )}
                <Grid md={4} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid md={8} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => { navigate("/editais") }}
                        style={{ width: isMobile ? '100%' : 177, height: 41, marginTop: 10, marginRight: isMobile ? 0 : 20, color: '#666666', borderColor: '#666666' }}
                        color="info"
                        variant="outlined"
                        endIcon={<SaveIcon style={{ color: '#666666' }} />} >
                        Salvar rascunho
                    </Button>
                    <Button
                        onClick={() => {
                            onSubmit();
                        }}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={loading}
                        endIcon={loading ? <CircularProgress style={{ color: 'white' }} size={15} /> : <ArrowForwardIcon />}>
                        Avançar
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                        <Grid container spacing={1} marginBottom={5}>
                            <Grid md={12}>
                                <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold' }}>Adicionar Área </p>
                                <CloseIcon onClick={() => handleClose()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                            </Grid>
                            <Grid md={12}>
                                <TextField
                                    style={{ marginTop: '5px' }}
                                    margin="normal"
                                    value={titulo}
                                    error={errorTitulo}
                                    helperText={msgErrorTitulo}
                                    onChange={(e) => {
                                        setTitulo(e.target.value);
                                    }}
                                    fullWidth
                                    label="Título da área"
                                />
                            </Grid>
                            <Grid mdOffset={9} md={3}>
                                <Button
                                    onClick={() => {
                                        salvarCategoria();
                                    }}
                                    style={{ width: '100%', height: 41, marginTop: 10 }}
                                    variant="contained"
                                    disabled={loading}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal >
        </Box >
    )
}