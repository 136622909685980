import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useQuery } from "../../hooks/useQuery";
import MenuItem from "@mui/material/MenuItem";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import { exportToExcel } from 'react-json-to-excel';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
}
export const RelatoriosPage = () => {

    const navigate = useNavigate();
    const [comunicados, setComunicados] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const { listarDocumentosUsuarios, listaEditais, listaProcessos, downloadRelatorioProcesso, downloadRelatorioFornecedoresEdital, downloadRelatorioProcessoCredenciado, downloadRelatorioUsers } = useQuery();
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(0);
    const [value, setValue] = React.useState("1");
    const [editais, setEditais] = React.useState([]);
    const [selectedEdital, setSelectedEdital] = React.useState();
    const [processos, setProcessos] = React.useState();
    const [selectedProcesso, setSelectedProcesso] = React.useState();

    React.useEffect(() => {
        getEditais();
    }, []);



    const getEditais = async () => {
        const result = await listaEditais({ pagination: false });
        if (result && result.data && result.data.data) {
            setEditais(result.data.data);
        };
    }

    const onChangeEdital = (value) => {
        getProcessos(value);
    }

    const getProcessos = async (editalId) => {
        const result = await listaProcessos({ pagination: false, edital_id: editalId });
        if (result && result.data && result.data.data) {
            setProcessos(result.data.data);
        }
    }


    const UserTabs = styled(Tabs)({
        borderBottom: '1px solid #e8e8e8',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
    });

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const UserTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontFamily: 'Campuni-Medium !important',
        fontSize: '24px !important',
        color: '#CCCCCC',
        '&:hover': {
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#005EB8',
            fontWeight: 700,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }));

    const gerarRelatorioProcesso = async (page) => {
        setLoading(true);
        const result = await downloadRelatorioProcesso(selectedProcesso);
        if (result && result.data && result.data.data) {
            console.log(result.data);
            exportToExcel(result.data.data, 'relatorio');
        }
        setLoading(false);
    }



    const gerarRelatorioCredenciados = async (page) => {
        setLoading(true);
        const result = await downloadRelatorioProcessoCredenciado(selectedProcesso);
        if (result && result.data && result.data.data) {
            console.log(result.data);
            exportToExcel(result.data.data, 'relatorio');
        }
        setLoading(false);
    }


    const gerarRelatorioEmpresa = async (page) => {
        setLoading(true);
        const result = await downloadRelatorioFornecedoresEdital(selectedEdital);
        if (result && result.data && result.data.data) {
            console.log(result.data);
            exportToExcel(result.data.data, 'relatorio');
        }
        setLoading(false);
    }


    const gerarRelatorioUsers = async (page) => {
        setLoading(true);
        const result = await downloadRelatorioUsers(selectedProcesso);
        if (result && result.data && result.data.data) {
            console.log(result.data);
            exportToExcel(result.data.data, 'relatorio');
        }
        setLoading(false);
    }

    const handleChange = (event, value) => {
        setPage(value);
    };

    const svgComnunicado = () => {

        return (
            <SvgIcon>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41602 13.4583V3.54167C1.41602 3.16594 1.56527 2.80561 1.83095 2.53993C2.09662 2.27426 2.45696 2.125 2.83268 2.125H14.166C14.5417 2.125 14.9021 2.27426 15.1678 2.53993C15.4334 2.80561 15.5827 3.16594 15.5827 3.54167V13.4583C15.5827 13.8341 15.4334 14.1944 15.1678 14.4601C14.9021 14.7257 14.5417 14.875 14.166 14.875H2.83268C2.45696 14.875 2.09662 14.7257 1.83095 14.4601C1.56527 14.1944 1.41602 13.8341 1.41602 13.4583Z" stroke="white" strokeWidth="1.5" />
                    <path d="M1.41602 4.95831H15.5827M6.37435 9.91665H8.49935M8.49935 9.91665H10.6243M8.49935 9.91665V7.79165M8.49935 9.91665V12.0416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }


    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0">

                <Grid md={12} xs={12}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>Relatórios</span>
                </Grid>
                <Grid md={12} xs={12}>
                    <TabContext value={value}>
                        <UserTabs variant="scrollable" value={value} onChange={handleChangeTab} aria-label="ant example">
                            <UserTab label="Usuarios por processo" value="1" />
                            <UserTab label="Credenciados por processo" value="2" />
                            <UserTab label="Empresas por edital" value="3" />
                            <UserTab label="Usuarios" value="4" />
                        </UserTabs>
                        <TabPanel value="1">
                            <Grid container spacing={5} paddingX="0" marginLeft={2}>

                                <Grid md={4} xs={12} style={{ marginBottom: 20 }}>
                                    <TextField
                                        style={{ height: '8px !important' }}
                                        // label="Edital"
                                        defaultValue={0}
                                        variant="standard"
                                        select
                                        className="inputSelect"
                                        onChange={(e) => {
                                            setSelectedEdital(e.target.value);
                                            onChangeEdital(e.target.value)
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem disabled value={0}>
                                            Selecione o edital
                                        </MenuItem>
                                        {editais && editais.map((res, index) =>
                                            <MenuItem value={res.id}>
                                                {`${res.titulo}`}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>

                                <Grid md={4} xs={12}>
                                    <TextField
                                        style={{ height: '8px !important' }}
                                        // label="Tipo edital"
                                        defaultValue={0}
                                        variant="standard"
                                        select
                                        className="inputSelect"
                                        onChange={(e) => {
                                            setSelectedProcesso(e.target.value);
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem disabled value={0}>
                                            Selecione o processo
                                        </MenuItem>
                                        {processos && processos.map((res, index) =>
                                            <MenuItem value={res.id}>
                                                {`${res.titulo}`}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid md={3}>
                                    <Button
                                        onClick={() => {
                                            gerarRelatorioProcesso();
                                        }}
                                        style={{ width: '100%', height: 41, marginTop: 10 }}
                                        variant="contained"
                                    // disabled={loading}
                                    >
                                        Gerar relatório
                                        {loading ? (
                                            <CircularProgress style={{ color: 'white', marginLeft: 10 }} size={10} />
                                        ) : null}
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={5} paddingX="0" marginLeft={2}>

                                <Grid md={4} xs={12} style={{ marginBottom: 20 }}>
                                    <TextField
                                        style={{ height: '8px !important' }}
                                        // label="Edital"
                                        defaultValue={0}
                                        variant="standard"
                                        select
                                        className="inputSelect"
                                        onChange={(e) => {
                                            setSelectedEdital(e.target.value);
                                            onChangeEdital(e.target.value)
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem disabled value={0}>
                                            Selecione o edital
                                        </MenuItem>
                                        {editais && editais.map((res, index) =>
                                            <MenuItem value={res.id}>
                                                {`${res.titulo}`}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>

                                <Grid md={4} xs={12}>
                                    <TextField
                                        style={{ height: '8px !important' }}
                                        // label="Tipo edital"
                                        defaultValue={0}
                                        variant="standard"
                                        select
                                        className="inputSelect"
                                        onChange={(e) => {
                                            setSelectedProcesso(e.target.value);
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem disabled value={0}>
                                            Selecione o processo
                                        </MenuItem>
                                        {processos && processos.map((res, index) =>
                                            <MenuItem value={res.id}>
                                                {`${res.titulo}`}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid md={3}>
                                    <Button
                                        onClick={() => {
                                            gerarRelatorioCredenciados();
                                        }}
                                        style={{ width: '100%', height: 41, marginTop: 10 }}
                                        variant="contained"
                                    // disabled={loading}
                                    >
                                        Gerar relatório
                                        {loading ? (
                                            <CircularProgress style={{ color: 'white', marginLeft: 10 }} size={10} />
                                        ) : null}
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3">
                            <Grid container spacing={5} paddingX="0" marginLeft={2}>

                                <Grid md={4} xs={12} style={{ marginBottom: 20 }}>
                                    <TextField
                                        style={{ height: '8px !important' }}
                                        // label="Edital"
                                        defaultValue={0}
                                        variant="standard"
                                        select
                                        className="inputSelect"
                                        onChange={(e) => {
                                            setSelectedEdital(e.target.value);
                                            onChangeEdital(e.target.value)
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem disabled value={0}>
                                            Selecione o edital
                                        </MenuItem>
                                        {editais && editais.map((res, index) =>
                                            <MenuItem value={res.id}>
                                                {`${res.titulo}`}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid md={3}>
                                    <Button
                                        onClick={() => {
                                            gerarRelatorioEmpresa();
                                        }}
                                        style={{ width: '100%', height: 41, marginTop: 10 }}
                                        variant="contained"
                                    // disabled={loading}
                                    >
                                        Gerar relatório
                                        {loading ? (
                                            <CircularProgress style={{ color: 'white', marginLeft: 10 }} size={10} />
                                        ) : null}
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="4">
                            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                                <Grid md={3}>
                                    <Button
                                        onClick={() => {
                                            gerarRelatorioUsers();
                                        }}
                                        style={{ width: '100%', height: 41, marginTop: 10 }}
                                        variant="contained"
                                    // disabled={loading}
                                    >
                                        Gerar relatório
                                        {loading ? (
                                            <CircularProgress style={{ color: 'white', marginLeft: 10 }} size={10} />
                                        ) : null}
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
            <Modal
                open={loading}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12} display={'flex'} justifyContent={'center'} marginTop={5}>
                            <CircularProgress size={20} />
                        </Grid>
                        <Grid md={12}>
                            <p style={{ fontSize: 18, color: '#666666', fontWeight: '500', textAlign: 'center' }}>Aguarde enquanto carregamos as informações...</p>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box >
    )
}