import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
// import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./styles.css";
import { HomePage } from './pages/LandingPage/HomePage';
import { CadastroPage } from './pages/Cadastro/Cadastro';
import { ShowEdital } from './pages/LandingPage/ShowEdital';
import { ProtectedLayout } from './components/ProtectedLayout';
import { DashboardPage } from './pages/admin/DashboardPage';
import { ComunicadosPage } from './pages/admin/ComunicadosPage';
import { CriarComunicadoPage } from './pages/admin/CriarComunicadoPage';
import { EditaisPage } from './pages/admin/EditaisPage';
import { CriarEditalPage } from './pages/admin/CriarEditalPage';
import { EtapasProcessoPage } from './pages/admin/EtapasProcessoPage';
import { CriarEtapaProcessoPage } from './pages/admin/CriarEtapaProcessoPage';
import { AnaliseDocumentalPage } from './pages/admin/AnaliseDocumentalPage';
import { ViewDocumentoPage } from './pages/admin/ViewDocumentoPage';
import { UsuariosPage } from './pages/admin/UsuariosPage';
import { PessoaFisicaDetalhePage } from './pages/admin/PessoaFisicaDetalhePage';
import { PessoaJuridicaDetalhePage } from './pages/admin/PessoaJuridicaDetalhePage';
import { RelatoriosPage } from './pages/admin/RelatoriosPage';
import { ProtectedRoute } from './components/ProtectedRoute';
import { HomeUsuarioPage } from './pages/usuario/HomeUsuarioPage';
import { EditalUsuarioPage } from './pages/usuario/EditalUsuarioPage';
import { ContratosUsuarioPage } from './pages/usuario/ContratosUsuarioPage';
import { ContratosDetalheUsuarioPage } from './pages/usuario/ContratoDetalheUsuarioPage';
import { DemandasUsuarioPage } from './pages/usuario/DemandasUsuarioPage';
import { DemandaDetalheUsuarioPage } from './pages/usuario/DemandaDetalheUsuario';
import { ShowEditalUsuario } from './pages/usuario/ShowEditalUsuario';
import { AgendaUsuarioPage } from './pages/usuario/AgendaUsuarioPage';
import { PerfilUsuarioPage } from './pages/usuario/PerfilUsuarioPage';
import { InscricaoUsuarioPage } from './pages/usuario/InscricaoUsuarioPage';
import { HistoricoUsuarioPage } from './pages/usuario/HistoricoUsuarioPage';
import { ViewHistoricoPage } from './pages/usuario/ViewHistoricoPage';
import { AlocacaoHorasPage } from './pages/usuario/AlocacaoHorasUsuarioPage';
import { ComunicadosUsuarioPage } from './pages/usuario/ComunicadosUsuarioPage';
import { CadastrarColaborador } from './pages/usuario/CadastrarColaborador';
import { ProtectedRouteRepresentante } from './components/ProtectedRouteRepresentante';
import { PermissaoPage } from './pages/admin/PermissaoPage';
import { ProtectedRouteSuperAdmin } from './components/ProtectedRouteSuperAdmin';

const theme = createTheme({
  palette: {
    primary: {
      // main: "#3B4AFF",
      main: "#005EB8",
    },
    success: {
      main: "#6BAC54",
    },
    warning: {
      main: "#FFA500",
    },
    error: {
      main: "#DC143C",
    },
    info: {
      main: "#005EB8",
    },
    secondary: {
      main: "#fff",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/cadastro" element={<CadastroPage />} />
          <Route path="/edital/:id" element={<ShowEdital />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          {/* Rotas Admin */}
          <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
          <Route path="/comunicados" element={<ProtectedRoute><ComunicadosPage /></ProtectedRoute>} />
          <Route path="/editais" element={<ProtectedRoute><EditaisPage /></ProtectedRoute>} />
          <Route path="/processos" element={<ProtectedRoute><EtapasProcessoPage /></ProtectedRoute>} />
          <Route path="/criar-edital" element={<ProtectedRouteSuperAdmin><CriarEditalPage /></ProtectedRouteSuperAdmin>} />
          <Route path="/criar-edital/:editalId" element={<ProtectedRouteSuperAdmin><CriarEditalPage /></ProtectedRouteSuperAdmin>} />
          <Route path="/criar-comunicado" element={<ProtectedRouteSuperAdmin><CriarComunicadoPage /></ProtectedRouteSuperAdmin>} />
          <Route path="/criar-comunicado/:comunicadoId" element={<ProtectedRouteSuperAdmin><CriarComunicadoPage /></ProtectedRouteSuperAdmin>} />
          <Route path="/criar-processo" element={<ProtectedRouteSuperAdmin><CriarEtapaProcessoPage /></ProtectedRouteSuperAdmin>} />
          <Route path="/criar-processo/:processoId" element={<ProtectedRouteSuperAdmin><CriarEtapaProcessoPage /></ProtectedRouteSuperAdmin>} />
          <Route path="/analise-documental" element={<ProtectedRoute><AnaliseDocumentalPage /></ProtectedRoute>} />
          <Route path="/ver-documentos/:id" element={<ProtectedRoute><ViewDocumentoPage /></ProtectedRoute>} />
          <Route path="/usuarios" element={<ProtectedRoute><UsuariosPage /></ProtectedRoute>} />
          <Route path="/pessoa-fisica/:id" element={<ProtectedRoute><PessoaFisicaDetalhePage /></ProtectedRoute>} />
          <Route path="/pessoa-juridica/:id" element={<ProtectedRoute><PessoaJuridicaDetalhePage /></ProtectedRoute>} />
          <Route path="/relatorios" element={<ProtectedRoute><RelatoriosPage /></ProtectedRoute>} />
          <Route path="/permissoes/:id" element={<ProtectedRouteSuperAdmin><PermissaoPage /></ProtectedRouteSuperAdmin>} />
          {/* Rotas do usuario */}
          <Route path="/home" element={<HomeUsuarioPage />} />
          <Route path="/listar-editais" element={<EditalUsuarioPage />} />
          <Route path="/listar-contratos" element={<ContratosUsuarioPage />} />
          <Route path="/contrato-detalhe/:id" element={<ContratosDetalheUsuarioPage />} />
          <Route path="/listar-demandas" element={<DemandasUsuarioPage />} />
          <Route path="/demanda-detalhe/:id" element={<DemandaDetalheUsuarioPage />} />
          <Route path="/edital-usuario/:id" element={<ShowEditalUsuario />} />
          <Route path="/agenda-usuario" element={<AgendaUsuarioPage />} />
          <Route path="/perfil-usuario" element={<PerfilUsuarioPage />} />
          <Route path="/inscricao-usuario/:id" element={<InscricaoUsuarioPage />} />
          <Route path="/historico-usuario" element={<HistoricoUsuarioPage />} />
          <Route path="/alocacao-horas" element={<AlocacaoHorasPage />} />
          <Route path="/historico-usuario/:id" element={<ViewHistoricoPage />} />
          <Route path="/comunicados-user" element={<ComunicadosUsuarioPage />} />
          <Route path="/cadastrar-colaborador" element={<ProtectedRouteRepresentante><CadastrarColaborador /></ProtectedRouteRepresentante>} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
