import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import { IconButton, SvgIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
export const CarouselItemDashboard = ({ item }) => {
    const navigate = useNavigate();

    const svgCalendar = () => {

        return (
            <SvgIcon style={{ cursor: 'pointer' }}>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.66675 1.41666V3.54166" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.3333 1.41666V3.54166" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.47925 6.43875H14.5209" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.875 6.02082V12.0417C14.875 14.1667 13.8125 15.5833 11.3333 15.5833H5.66667C3.1875 15.5833 2.125 14.1667 2.125 12.0417V6.02082C2.125 3.89582 3.1875 2.47916 5.66667 2.47916H11.3333C13.8125 2.47916 14.875 3.89582 14.875 6.02082Z" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.49676 9.70417H8.50312" stroke="#ACAECF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.87518 9.70417H5.88154" stroke="#ACAECF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.87518 11.8292H5.88154" stroke="#ACAECF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }


    const svgCalendarStick = () => {

        return (
            <SvgIcon style={{ cursor: 'pointer' }}>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.6665 1.41667V3.54167" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.3335 1.41667V3.54167" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.479 6.43875H14.5207" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.5832 13.4583C15.5832 13.9896 15.4344 14.4925 15.1723 14.9175C14.6836 15.7392 13.784 16.2917 12.7498 16.2917C12.0344 16.2917 11.3828 16.0296 10.8869 15.5833C10.6673 15.3992 10.4761 15.1725 10.3273 14.9175C10.0653 14.4925 9.9165 13.9896 9.9165 13.4583C9.9165 11.8929 11.1844 10.625 12.7498 10.625C13.5998 10.625 14.3578 11.0004 14.8748 11.5883C15.314 12.0912 15.5832 12.7429 15.5832 13.4583Z" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.645 13.4583L12.3463 14.1596L13.855 12.7642" stroke="#ACAECF" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.875 6.02084V11.5883C14.3579 11.0004 13.6 10.625 12.75 10.625C11.1846 10.625 9.91667 11.8929 9.91667 13.4583C9.91667 13.9896 10.0654 14.4925 10.3275 14.9175C10.4763 15.1725 10.6675 15.3992 10.8871 15.5833H5.66667C3.1875 15.5833 2.125 14.1667 2.125 12.0417V6.02084C2.125 3.89584 3.1875 2.47917 5.66667 2.47917H11.3333C13.8125 2.47917 14.875 3.89584 14.875 6.02084Z" stroke="#ACAECF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.49676 9.70417H8.50312" stroke="#ACAECF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.87518 9.70417H5.88154" stroke="#ACAECF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.87518 11.8292H5.88154" stroke="#ACAECF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }



    return (

        <Card sx={{ maxWidth: 300, background: '#F8F9FE' }}>
            <CardContent style={{ padding: 0 }}>
                <div style={{ width: '100%', padding: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <span className="dots" style={{ color: '#005EB8', fontWeight: '700' }}>{item.titulo}</span>
                </div>
                <p style={{ padding: '10px', margin: 0, fontWeight: 200, display: 'flex', alignItems: 'center', fontFamily: 'Univers-Medium !important' }}>
                    {svgCalendar()} <span style={{ marginLeft: 10, color: '#666666' }}>Data de Início: </span> <span style={{ fontWeight: '700' }}>&nbsp;{dayjs(item.data_abertura).locale('pt-br').format('DD/MM/YYYY')}</span>
                </p>
                <p style={{ padding: '10px', margin: 0, fontWeight: 200, display: 'flex', alignItems: 'center', fontFamily: 'Univers-Medium !important' }}>
                    {svgCalendarStick()} <span style={{ marginLeft: 10, color: '#666666' }}>Data de Fim: </span> <span style={{ fontWeight: '700' }}>&nbsp;{dayjs(item.encerramento).locale('pt-br').format('DD/MM/YYYY')}</span>
                </p>
                <p style={{ padding: '10px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                    <InfoIcon style={{ color: "#ACAECF" }} /> <span style={{ marginLeft: 10, color: '#666666' }}>Status: </span>&nbsp; <Chip label={item.status == 'A' ? 'Aberto' : 'Fechado'} style={{ color: '#fff', height: 20 }} color={item.status == 'A' ? "success" : "primary"} />
                </p>
            </CardContent>
        </Card>

    )
}