import Box from "@mui/material/Box";
import * as React from "react";
import { SectionInitialPage } from "./SectionInitialPage";
import { SectionNoticesPage } from "./SectionNoticesPage";
import { SectionAboutPage } from "./SectionAboutPage";
import { SectionFaqPage } from "./SetionFaqPage";
import { useSearchParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { isMobile } from "react-device-detect";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const HomePage = () => {
    const [searchParams] = useSearchParams();
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        let param = searchParams.get('showmodal');
        if (param == 'visible') {
            setOpen(true);
        }

    }, [])

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <SectionInitialPage />
            <SectionNoticesPage />
            <SectionAboutPage />
            <SectionFaqPage />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12}>
                            <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold', textAlign: 'center' }}>Parabéns, seu cadastro foi realizado com sucesso! </p>
                            <CloseIcon onClick={() => setOpen(false)} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
                            <p style={{ color: '#666666', fontSize: 12, fontWeight: 400, fontFamily: "Campuni-Medium", textAlign: 'center' }}>Agora, sua empresa está registrada em nosso portal. </p>
                            <p style={{ color: '#666666', fontSize: 12, fontWeight: 400, fontFamily: "Campuni-Medium", textAlign: 'center' }}>Enviamos um e-mail para o endereço informado, contendo a senha inicial para acesso à sua conta. Utilize essa senha para fazer seu login. </p>
                            <p style={{ color: '#666666', fontSize: 12, fontWeight: 400, fontFamily: "Campuni-Medium", textAlign: 'center' }}>Caso tenha alguma dúvida ou precise de assistência, nossa equipe de suporte através do e-mail <b>credenciamento@sebraemg.com.br</b> está à disposição.  </p>
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <Button
                                style={{ width: 160, height: 41 }}
                                variant="contained"
                                color="primary"
                                onClick={() => setOpen(false)}
                                component="label"
                            >
                                Fechar
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </Box >
    )
}