import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from "../../hooks/useSnackbar";
import Checkbox from '@mui/material/Checkbox';
import { useQuery } from "../../hooks/useQuery";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CheckBoxItem } from "../../components/CheckBoxItem";
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../../hooks/useAuth";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const Step1PJ = ({ activeStep, setActiveStep, processoId, processoFinalizado }) => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const { user } = useAuth();
    const [checkedAreas, setCheckedAreas] = React.useState([]);
    const [isCheckAllAreas, setIsCheckAllAreas] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingAreas, setLoadingAreas] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [areas, setAreas] = React.useState([]);
    const [areasAll, setAreasAll] = React.useState([]);
    const [expanded, setExpanded] = React.useState('panel1');
    const { listaAreas, saveAreasSelecionadasProcesso, showArea, listarAreasProcesso, listarAreasProcessoSelecionadas } = useQuery();

    React.useEffect(() => {
        buscarAreas();
        buscarAreasSelecionadas();
    }, []);

    React.useEffect(() => {
        if (processoFinalizado) {
            setActiveStep(4)
        }
    }, [processoFinalizado]);

    React.useEffect(() => {
        if (areas && areas.length > 0)
            buscarAreasSelecionadas();
    }, [areas]);


    const buscarAreas = async () => {
        setLoadingAreas(true);
        const result = await listarAreasProcesso(processoId);
        if (result && result.data) {
            let aux = [];
            result.data.data.area_processo.map(a => {
                aux.push(a.area);
            })
            setAreas(aux);
            setAreasAll(aux);
        }
        setLoadingAreas(false);
    }

    const buscarAreasSelecionadas = async () => {
        setLoadingAreas(true);
        const result = await listarAreasProcessoSelecionadas(processoId, user.fornecedor_id);
        if (result && result.data) {
            let auxChecked = [];
            result.data.data.map(a => {
                auxChecked.push(a.area_id);
            })
            console.log(auxChecked)
            setCheckedAreas(auxChecked);

        }
        setLoadingAreas(false);
    }


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const filtrarAreas = (term) => {
        setAreas(areasAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }


    const handleChangeAllAreas = (event) => {
        if (event.target.checked) {
            let areaAux = [];
            areasAll.forEach(doc => {
                areaAux.push(doc.id);
            });
            setCheckedAreas(areaAux);
            setIsCheckAllAreas(true);
        } else {
            setCheckedAreas([]);
            setIsCheckAllAreas(false);
        }
    };


    const childrenAreas = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {areas && areas.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedAreas}
                        checkedDocumentos={checkedAreas}
                        isCheckAllDocumentos={isCheckAllAreas}
                        editarDocumento={null}
                        documentos={areas}
                    />
                )
            })}
        </Box>
    );

    const onSubmit = () => {
        console.log('area', checkedAreas);
        if (checkedAreas.length > 0) {
            const data = {
                areas: checkedAreas,
                processoid: processoId,
                fornecedorid: user.fornecedor_id
            }
            console.log(data);
            saveStep(data);
        } else {
            setActiveStep(activeStep + 1);
        }

    }

    const onErrors = (error) => {

    }

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const saveStep = async (data) => {
        setLoadingSave(true);
        const result = await saveAreasSelecionadasProcesso(data);
        console.log(result.data);
        if (result && result.data) {
            await timeout(1500);
            setActiveStep(activeStep + 1);


        }
        setLoadingSave(false);

    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }


    return (
        <Box style={{ width: '100%', marginTop: 60 }}>
            <Grid container spacing={1} paddingX="0">
                {loading || loadingAreas ? (
                    <Grid md={12} style={{ marginTop: 20 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid md={12}>
                            <Accordion expanded={expanded === `panel1`} onChange={handleChange(`panel1`)}>
                                <AccordionSummary
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    expandIcon={<KeyboardArrowDownIcon />}
                                >
                                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Seleção de áreas/ficha/produto</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1} marginBottom={5}>
                                        <Grid md={12}>
                                            <TextField
                                                id="customInput"
                                                placeholder="Buscar"
                                                fullWidth
                                                className="custom-class"
                                                onChange={(event) => {
                                                    filtrarAreas(event.target.value);
                                                }}
                                                variant="standard"
                                                style={{ height: '38 !important', width: '100%', }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton onClick={() => { }}>
                                                                {svgSearch()}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid md={12} style={{ marginTop: 20 }}>
                                            <div
                                                className="scrollbar"
                                                id="style-3"
                                                style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', padding: '0 10px' }}
                                            >
                                                {areas.length == areasAll.length ? (
                                                    <FormControlLabel
                                                        label="Marcar todos"
                                                        control={
                                                            <Checkbox
                                                                checked={checkedAreas.length === areas.length}
                                                                onChange={handleChangeAllAreas}
                                                            />
                                                        }
                                                    />
                                                ) : null}
                                                {childrenAreas}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                )}

                <Grid md={6} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid md={6} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => {
                            setActiveStep(activeStep + 1);
                            onSubmit();
                        }}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={loading || loadingAreas || loadingSave}
                        endIcon={loadingSave ? <CircularProgress style={{ color: 'white' }} size={15} /> : <ArrowForwardIcon />}>
                        Avançar
                    </Button>
                </Grid>
            </Grid>
        </Box >
    )
}