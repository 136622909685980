import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useQuery } from "../../hooks/useQuery";
import { useSearchParams } from "react-router-dom";
import { Step1Cadastro } from "./Step1Cadastro";
import { Step2Cadastro } from "./Step2Cadastro";
import { Step3Cadastro } from "./Step3Cadastro";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
export const CadastroPage = () => {

    const steps = [
        'Dados primários',
        'Cadastro representante'
    ];
    const [activeStep, setActiveStep] = React.useState(0);
    const [fornecedor, setFornecedor] = React.useState();

    const renderStepAtual = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Step1Cadastro activeStep={activeStep} setActiveStep={setActiveStep} fornecedor={fornecedor} setFornecedor={setFornecedor} />
                );
            case 1:
                return (
                    <Step3Cadastro activeStep={activeStep} setActiveStep={setActiveStep} fornecedor={fornecedor} setFornecedor={setFornecedor} />
                );
            default:
                break;
        }
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 15,
            paddingLeft: 10,
            paddingRight: 10
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(95deg, #005EB8 0%, #40BBFF 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(95deg, #005EB8 0%, #40BBFF 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 6,
            border: 0,
            backgroundColor: '#ddd',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        width: 36,
        fontWeight: 'bold',
        height: 36,
        display: 'flex',
        borderRadius: '50%',
        color: '#005EB8',
        justifyContent: 'center',
        border: 'solid 2px #005EB8',
        alignItems: 'center',
        backgroundColor: 'white',
    }));

    function ColorlibStepIcon(props) {
        console.log(props);
        const { active, completed, className, icon } = props;


        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                <span style={{ fontFamily: 'Univers-Medium' }}>{icon}</span>
            </ColorlibStepIconRoot>
        );
    }

    return (
        <Grid container spacing={0} paddingY="2rem" paddingX="4rem">
            <Grid sm={12}>
                <img style={{ width: 255 }} src={Logo} />
            </Grid>
            <Grid item sm={12} justifyContent={'center'} alignItems={'end'} display={'flex'} marginTop={5}>
                <Grid container spacing={0}>
                    <Grid item sm={12} justifyContent={'flex-start'} display={'flex'}>
                        <Box sx={{ width: '40%' }}>
                            <Stepper activeStep={activeStep} alternativeLabel style={{ marginBottom: 0 }} connector={<ColorlibConnector />}>
                                {steps.map((label, index) => (
                                    <Step key={index}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}><span style={{ color: '#005EB8', fontFamily: 'Campuni-Medium' }}>{label}</span></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{ marginLeft: 40, marginTop: 20 }} item sm={12}>
                {renderStepAtual()}
            </Grid>
        </Grid >
    );
};
