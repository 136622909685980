import * as React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Footer } from "../pages/LandingPage/Footer";
import Logo from '../assets/logo-branco.png'
import QuadradoCinza from '../assets/quadrados-cinza.png';
import QuadradoAzul from '../assets/quadrado-azul.png';
import LoginIcon from '@mui/icons-material/Login';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useForm, Controller } from "react-hook-form";
import { AES, enc } from 'crypto-js';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { useSnackbar } from "../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { handleHttpError, validaCnpj, validaCpf } from "../utils";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import ScrollIntoView from 'react-scroll-into-view';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from "../hooks/useQuery";
import { CircularProgress } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '80%' : 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const drawerWidth = 240;

export const HomeLayout = () => {

  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [isSebrae, setIsSebrae] = React.useState(false);
  const [showFab, setShowFab] = React.useState(true);
  const [scrollTop, setScrollTop] = React.useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user, logout, login, setUser } = useAuth();
  const outlet = useOutlet();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const { resetarSenha } = useQuery();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const snackbar = useSnackbar();
  const onErrors = (error) => {

  }
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MENU
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ScrollIntoView selector="#edital">
              <ListItemText primary={'Editais'} />
            </ScrollIntoView>

          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText onClick={() => navigate('/cadastro')} primary={'Credencie sua empresa'} />
          </ListItemButton>
        </ListItem>
        <ListItem onClick={handleOpen} disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Entrar'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );


  React.useEffect(() => {
    if (user && user.user_id) {
      navigate('/dashboard', { replace: true });
    } else {
      console.log(user);
    }

    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      if (e.target.documentElement.scrollTop > 200) {
        setShowFab(true)
      } else {
        setShowFab(false);
      }
    };


    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);

  }, [scrollTop])

  if (user && user.user && user.user.id) {
    return <Navigate to="/dashboard" replace />;
  } else {
    console.log(user);
  }



  const onSubmit = async (data) => {
    setLoading(true);

    try {
      let key = encHex.parse('0123456789abcdef0123456789abcdef');
      let iv = encHex.parse('abcdef9876543210abcdef9876543210');

      const cryptedPassword = AES.encrypt(data.senha, key, {
        iv: iv,
        padding: padZeroPadding,
      }).toString();

      let form;

      if (isSebrae) {
        form = {
          login: data.usuario,
          senha: cryptedPassword,
          tipo_acesso: 'sebrae'
        };
      } else {
        form = {
          cnpj: data.cnpj.replace(/\D/g, ''),
          cpf: data.cpf.replace(/\D/g, ''),
          senha: cryptedPassword,
          tipo_acesso: 'cadastro'
        };
      }

      const result = await login(form, setLoading);
      if (result && result.status && result.status === 200) {
        setLoading(false);
        setUser(result.data.data);
        setOpen(false);
        reset({
          cnpj: "",
          cpf: "",
          senha: ""
        });
        navigate('/dashboard');
      }
    } catch (error) {
      setLoading(false);
      snackbar.showSnackbar(handleHttpError(error), '#DC143C');
    }
  };

  const recuperarSenha = async () => {
    try {
      const result = await resetarSenha(email);

      if (result && result.data) {
        snackbar.showSnackbar('Solicitação concluida com sucesso!', '#6BAC54');
        handleCloseModal();
      }

    } catch (error) {
      snackbar.showSnackbar(handleHttpError(error), '#DC143C');
      handleCloseModal();
    }

  }

  const onChangeTipoLogin = (event) => {
    setIsSebrae(event.target.checked);
  }

  return (
    <div style={{ height: '100vh' }}>
      {showFab ? (
        <Fab onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} variant="extended" style={{ position: 'fixed', bottom: 10, right: 10, background: '#40BBFF' }} color="primary">
          <KeyboardArrowUpIcon sx={{ mr: 1 }} />
          Voltar ao topo
        </Fab>
      ) : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ position: "absolute", left: 0, top: 0, opacity: 0.1 }}>
            <img src={QuadradoCinza} style={{ width: 48, height: 151 }} />
          </div>
          <form onSubmit={handleSubmit(onSubmit, onErrors)} style={{ padding: '0 30px' }}>
            <Grid container spacing={1}>
              <Grid md={12}>
                <p style={{ fontSize: 24, color: '#005EB8', textAlign: 'center', fontWeight: 500 }}>Seja bem vindo</p>
              </Grid>
              <Grid md={12}>
                <p style={{ fontSize: 16, color: '#666666', textAlign: 'center', fontWeight: 400, margin: '0 0 40px 0' }}>Escolha a sua forma de acessar a plataforma de credenciados</p>
              </Grid>
              {!isSebrae ? (
                <Grid md={12}>
                  <Controller
                    name="cnpj"
                    control={control}
                    defaultValue={""}
                    mask="99.999.999.9999-99"
                    rules={{
                      required: "campo é obrigatório",
                      validate: (value) => {
                        return validaCnpj(value)
                      }
                    }}
                    render={({
                      field: { onChange, onBlur, ref, value },
                      fieldState: { error },
                    }) => (
                      <InputMask
                        mask="99.999.999.9999-99"
                        maskChar={""}
                        value={value}
                        onBlur={onBlur}
                        onChange={(event) => {
                          onChange(event);
                        }}
                      >
                        {(inputProps) => (
                          <TextField
                            margin="normal"
                            error={error}
                            helperText={error && error.message}
                            fullWidth
                            {...inputProps}
                            id="cnpj"
                            label="CNPJ"
                          />
                        )}
                      </InputMask>
                    )}
                  />
                  {errors.cnpj && errors.cnpj.type === "validate" && <small style={{ color: 'red' }}>CNPJ Inválido</small>}
                </Grid>
              ) : null}
              {!isSebrae ? (
                <Grid md={12}>
                  <Controller
                    name="cpf"
                    rules={{
                      required: 'Campo é obrigatório',
                      validate: (value) => {
                        return validaCpf(value)
                      }
                    }}
                    control={control}
                    defaultValue={""}
                    mask="999.999.999-99"
                    render={({
                      field: { onChange, onBlur, ref, value },
                      fieldState: { error },
                    }) => (
                      <InputMask
                        mask="999.999.999-99"
                        maskChar={''}
                        value={value}
                        onBlur={onBlur}
                        onChange={(event) => {
                          onChange(event);
                        }}
                      >
                        {(inputProps) => (
                          <TextField
                            margin="normal"
                            error={error}
                            helperText={error && error.message}
                            fullWidth
                            {...inputProps}
                            id="cpf"
                            label="CPF"
                          />
                        )}
                      </InputMask>
                    )}
                  />
                  {errors.documento && errors.documento.type === "validate" && <small style={{ color: 'red' }}>CPF Inválido</small>}
                </Grid>
              ) : null}
              {isSebrae ? (
                <Grid md={12}>
                  <Controller
                    name="usuario"
                    defaultValue={""}
                    control={control}
                    rules={{ required: 'campo é obrigatório' }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        style={{ marginTop: '5px' }}
                        margin="normal"
                        value={value}
                        error={error}
                        helperText={error && error.message}
                        onChange={onChange}
                        fullWidth
                        label="Usuário"
                      />
                    )}
                  />
                </Grid>
              ) : null}
              < Grid md={12}>
                <Controller
                  name="senha"
                  control={control}
                  rules={{
                    required: 'Senha é obrigatório',
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      margin="normal"
                      type={!showPassword ? 'text' : 'password'}
                      value={value}
                      error={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),

                      }}
                      helperText={error && error.message}
                      onChange={onChange}
                      fullWidth
                      label="Senha"
                    />
                  )}
                />
              </Grid>
              <Grid md={12} textAlign={'right'}>
                <span onClick={() => { setOpenModal(true) }} style={{ color: '#40BBFF', cursor: 'pointer', fontWeight: 700 }}>Esqueci a senha</span>
              </Grid>
              <Grid md={12}>
                <FormControlLabel control={<Switch onChange={onChangeTipoLogin} />} label="Sou colaborador sebrae" />
              </Grid>
              <Grid md={12}>
                <Button type="submit" style={{ width: '100%', height: 41, marginTop: 10, color: '#fff' }} disabled={loading} color="info" variant="contained">{loading ? <CircularProgress /> : null} Entrar</Button>
              </Grid>
              <Grid md={12}>
                <p style={{ fontWeight: '700', color: '#005EB8', textAlign: 'center' }}>Não possui cadastro ? <span style={{ color: '#40BBFF', cursor: 'pointer' }} onClick={() => { navigate('/cadastro'); setOpen(false); }}>Cadastre-se aqui</span></p>
              </Grid>
            </Grid>
            <div style={{ position: "absolute", right: 0, top: '40%', opacity: 0.1 }}>
              <img src={QuadradoAzul} style={{ width: 48, height: 151 }} />
            </div>
          </form>
        </Box>
      </Modal >
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1} marginBottom={5}>
            <Grid md={12}>
              <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold', textAlign: 'center' }}>Insira seu E-mail para continuar a solicitação: </p>
              <CloseIcon onClick={() => handleCloseModal()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
            </Grid>
            <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
              <TextField
                style={{ marginTop: '5px' }}
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                label="Email"
              />
            </Grid>
            <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                style={{ width: 160, height: 41 }}
                variant="contained"
                color="primary"
                onClick={() => recuperarSenha()}
                component="label"
              >
                Enviar
              </Button>
            </Grid>

          </Grid>
        </Box>
      </Modal>
      <Grid container spacing={1} paddingY="2rem" paddingX="4rem" style={{ margin: 0, background: '#3B4AFF' }}>
        {isMobile ? (
          <Grid xs={1} alignContent={'center'} display={'flex'}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon style={{ color: 'white' }} />
            </IconButton>
          </Grid>
        ) : null}
        <Grid xs={isMobile ? 11 : 3}>
          <img onClick={() => navigate('/')} style={{ width: 255, cursor: 'pointer' }} alt="" src={Logo} />
        </Grid>
        {isMobile ? null : (
          <Grid sm={7} xs={0} style={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <ScrollIntoView selector="#edital">
                <span style={{ minWidth: 100, color: 'white', fontWeight: 700, cursor: 'pointer', fontSize: 18, marginRight: 20 }}>Editais</span>
              </ScrollIntoView>
              <span onClick={() => navigate('/cadastro')} style={{ minWidth: 100, color: 'white', fontWeight: 700, cursor: 'pointer', fontSize: 18 }}>Credencie sua empresa</span>
            </Box>
          </Grid>
        )}
        {isMobile ? null : (
          <Grid sm={2} xs={0}>
            {user && user.token ? (
              <div onClick={() => { logout() }} style={{ display: 'flex' }}>
                <Avatar sx={{ bgcolor: 'secondary' }}>
                  <PersonIcon />
                </Avatar>
                <span style={{ marginLeft: 10, color: '#fff' }}>{user && user.nome_usuario}</span>
              </div>
            ) : (
              <Button onClick={() => handleOpen()} style={{ width: 128, height: 41, marginTop: 10, color: '#005EB8' }} color="secondary" endIcon={<LoginIcon />} variant="contained">Entrar</Button>
            )}
          </Grid>
        )}
      </Grid>

      <nav>
        <Drawer

          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      {outlet}
      <Footer />
    </div>
  );
};
