import { useAuth } from "../hooks/useAuth";
import { Navigate } from "react-router-dom";
export const ProtectedRouteRepresentante = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
      return <Navigate to="/" replace />;
    }

    if (user && user.role_id !== 1 && user.role_id !== 4 && !user.representante_legal) {
        return <Navigate to="/home" replace />;
    }
  
    return children;
  };