import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import { CircularProgress, IconButton, SvgIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
import CircleIcon from '@mui/icons-material/Circle';
import Modal from '@mui/material/Modal';
import { isMobile } from "react-device-detect";
import { useQuery } from "../hooks/useQuery";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};


export const ContratoItem = ({ item }) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [loadingDownload, setLoadingDownload] = React.useState(false);
    const { detalheContrato } = useQuery();
    const [contrato, setContrato] = React.useState();

    const svgDoc = () => {

        return (
            <SvgIcon style={{ cursor: 'pointer' }}>
                <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.83333 3.71875V0H0.625C0.278646 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.278646 14 0.625 14H9.375C9.72135 14 10 13.7074 10 13.3438V4.375H6.45833C6.11458 4.375 5.83333 4.07969 5.83333 3.71875ZM7.5 10.1719C7.5 10.3523 7.35938 10.5 7.1875 10.5H2.8125C2.64062 10.5 2.5 10.3523 2.5 10.1719V9.95312C2.5 9.77266 2.64062 9.625 2.8125 9.625H7.1875C7.35938 9.625 7.5 9.77266 7.5 9.95312V10.1719ZM7.5 8.42188C7.5 8.60234 7.35938 8.75 7.1875 8.75H2.8125C2.64062 8.75 2.5 8.60234 2.5 8.42188V8.20312C2.5 8.02266 2.64062 7.875 2.8125 7.875H7.1875C7.35938 7.875 7.5 8.02266 7.5 8.20312V8.42188ZM7.5 6.45312V6.67188C7.5 6.85234 7.35938 7 7.1875 7H2.8125C2.64062 7 2.5 6.85234 2.5 6.67188V6.45312C2.5 6.27266 2.64062 6.125 2.8125 6.125H7.1875C7.35938 6.125 7.5 6.27266 7.5 6.45312ZM10 3.3332V3.5H6.66667V0H6.82552C6.99219 0 7.15104 0.0683594 7.26823 0.191406L9.81771 2.87109C9.9349 2.99414 10 3.16094 10 3.3332Z" fill="#005EB8" />
                </svg>
            </SvgIcon>
        )
    }

    const handleClose = () => {
        setOpen(false);
    }

    const detalhesContrato = async (id) => {
        const result = await detalheContrato(id);
        if (result && result.data) {
            setContrato(result.data.data[0]);
        }
    }

    const downloadContrato = async () => {
        try {
            setLoadingDownload(true);
            downloadPdf(contrato.contrato_pdf, 'contrato.pdf');
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingDownload(false);
        }
    };

    async function downloadPdf(path, filename) {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.setAttribute('target', '_blank');
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    return (

        <Card sx={{ width: 214, background: '#FFFFFF' }}>
            <CardContent style={{ padding: 0 }}>
                <div style={{ width: '100%', padding: '10px 10px 0 10px', display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: '#005EB8', fontWeight: 700, fontFamily: "AlegraSans-Medium", fontSize: 14 }}>{item && item.programa}</span>
                </div>
                <p style={{ padding: '10px 10px 5px 10px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontWeight: 700, color: '#999999', fontFamily: "AlegraSans-Medium", fontSize: 12 }}>Contrato nº: </span> &nbsp;<span style={{ fontWeight: 400, color: '#999999', fontFamily: "AlegraSans-Medium", fontSize: 12 }}>{item && item.contrato}</span>
                </p>
                <p style={{ padding: '0 10px 10px 10px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontWeight: 700, color: '#999999', fontFamily: "AlegraSans-Medium", fontSize: 12 }}>Vigência:</span> &nbsp;<span style={{ fontWeight: 400, color: '#999999', fontFamily: "AlegraSans-Medium", fontSize: 12 }}>{item && item.fim}</span>
                </p>
                <p style={{ padding: '0 10px 10px 10px', margin: '10px 0 0 0', fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: 12, color: '#6BAC54', marginRight: 5, fontFamily: 'Campuni-Bold' }}>Ativo</span>
                    <CircleIcon color="success" fontSize="10" />
                </p>
                <p style={{ padding: '10px 10px 20px 10px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        color="inherit"
                        style={{ height: 24 }}
                        aria-label="open drawer"
                        onClick={() => console.log()}
                        edge="start"
                    >
                        {svgDoc()}
                        <span style={{ fontSize: 12, marginLeft: 5, color: '#005EB8', marginRight: 5, fontFamily: "Campuni-Bold" }} onClick={() => {  setOpen(true);detalhesContrato(item.contrato_dts); }}>Visualizar contrato</span>
                    </IconButton>
                </p>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid container spacing={1} marginBottom={5}>
                            <Grid md={8}>
                                <p style={{ fontSize: 18, color: '#005EB8', fontWeight: '700', fontFamily: 'AlegraSans-Medium' }}>{item && item.programa}</p>
                                <p style={{ padding: '0px 0px 5px 0px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontWeight: 700, color: '#999999', fontFamily: "AlegraSans-Medium", fontSize: 14 }}>Contrato nº: {item && item.contrato}</span>
                                </p>
                                <p style={{ padding: '0 10px 10px 0px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontWeight: 700, color: '#999999', fontFamily: "AlegraSans-Medium", fontSize: 14 }}>Vigência: {item && item.fim}</span>
                                </p>
                                <p style={{ padding: '0 10px 10px 0px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontWeight: 700, color: '#999999', fontFamily: "AlegraSans-Medium", fontSize: 14 }}>Cod. Fornecedor: {contrato ?  contrato.fornecedor : <CircularProgress size={10} />}</span>
                                </p>
                            </Grid>
                            <Grid md={4} textAlign={'right'}>
                                <Button
                                    onClick={() => {
                                        downloadContrato(item.contrato_dts);
                                    }}
                                    style={{ width: 'auto', height: 41, marginTop: 30 }}
                                    variant="contained"
                                    disabled={loadingDownload}
                                >
                                    Visualizar contrato
                                </Button>
                            </Grid>
                            <Grid md={8}>
                                <p style={{ fontSize: 14, color: '#005EB8', fontWeight: '700', fontFamily: 'AlegraSans-Medium' }}>{'Itens do contrato'}</p>
                                <div
                                    className="scrollbar"
                                    id="style-3"
                                    style={{ margin: 0, height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', padding: '0 10px' }}
                                >
                                    {item && item.item.map(res => {
                                        return (
                                            <>
                                                <p style={{ fontWeight: 500 }}>
                                                    <span style={{ fontFamily: "Campuni-Medium", fontWeight: 400, fontSize: 12, color: '#666666' }}>{res.item} - {res.descricao}</span>
                                                </p>
                                                <hr />
                                            </>
                                        )
                                    })}
                                </div>
                            </Grid>
                            <Grid md={4}></Grid>


                        </Grid>
                    </Box>
                </Modal >
            </CardContent>
        </Card >

    )
}