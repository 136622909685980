import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment';
import { useQuery } from "../hooks/useQuery";

export const AnaliseDocmentalItem = ({ item }) => {
    const navigate = useNavigate();
    const { aprovarReprovarDocumento } = useQuery();

    const svgDoc = () => {

        return (
            <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.25 1.25C5.58696 1.25 4.95107 1.51339 4.48223 1.98223C4.01339 2.45107 3.75 3.08696 3.75 3.75V16.25C3.75 16.913 4.01339 17.5489 4.48223 18.0178C4.95107 18.4866 5.58696 18.75 6.25 18.75H13.75C14.413 18.75 15.0489 18.4866 15.5178 18.0178C15.9866 17.5489 16.25 16.913 16.25 16.25V6.7675C16.2496 6.27039 16.0517 5.79379 15.7 5.4425L12.0588 1.79875C11.8846 1.62467 11.6778 1.4866 11.4502 1.39245C11.2227 1.29829 10.9788 1.24989 10.7325 1.25H6.25ZM5 3.75C5 3.41848 5.1317 3.10054 5.36612 2.86612C5.60054 2.6317 5.91848 2.5 6.25 2.5H10V5.625C10 6.12228 10.1975 6.59919 10.5492 6.95083C10.9008 7.30246 11.3777 7.5 11.875 7.5H15V16.25C15 16.5815 14.8683 16.8995 14.6339 17.1339C14.3995 17.3683 14.0815 17.5 13.75 17.5H6.25C5.91848 17.5 5.60054 17.3683 5.36612 17.1339C5.1317 16.8995 5 16.5815 5 16.25V3.75ZM14.7413 6.25H11.875C11.7092 6.25 11.5503 6.18415 11.4331 6.06694C11.3158 5.94973 11.25 5.79076 11.25 5.625V2.75875L14.7413 6.25Z" fill="#292D32" />
                </svg>
            </SvgIcon>
        )
    }

    const formataCPF = (cpf) => {
        //retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, "");

        //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    return (
        <Grid container spacing={1}>
            <Grid md={9}>
                <p style={{ margin: 0, fontWeight: 500, color: '#005EB8' }}>
                    <span style={{ fontWeight: 'bold', fontFamily: "Univers-Medium", fontSize: 18 }}>{item && formataCPF(item.colaborador.documento)}</span>
                </p>
                <p style={{ margin: 0, fontWeight: 500 }}>
                    <span onClick={() => { navigate(`/ver-documentos/${item.id}`) }} style={{ fontWeight: 500, fontSize: 18, color: '#292D32', cursor: 'pointer' }}>{item && item.name}</span>
                </p>
                <p style={{ margin: 0, fontWeight: 500 }}>
                    <span style={{ fontSize: 14, fontFamily: "Univers-Medium" }}>{item && item.fornecedor.razao_social}</span>
                </p>
            </Grid>
            <Grid md={3} textAlign={'right'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => { navigate(`/ver-documentos/${item.id}`) }}
                    edge="start"
                >
                    {svgDoc()}
                    <span style={{ fontSize: 14, marginLeft: 5, color: '#292D32' }}>{item.tipo_documentos.filter(doc => doc.status !== 'U').length} Documentos</span>
                </IconButton>
            </Grid>
            <Grid md={12} xs={12}>
                <hr />
            </Grid>
        </Grid>
    )
}