import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { validaCnpj, validaCpf } from "../../utils";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pt-br';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useQuery } from "../../hooks/useQuery";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../../hooks/useAuth";
import Button from '@mui/material/Button';
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "../../hooks/useSnackbar";
export const PerfilStep2Page = () => {

    const { user } = useAuth();
    const snackbar = useSnackbar();
    const { getNaturezaJuridica, showCadastroFornecedor, getCompany, cadastroStep1 } = useQuery();
    const navigate = useNavigate();
    const [valueTab, setValueTab] = React.useState("1");
    const [fornecedor, setFornecedor] = React.useState();
    const [colaboradores, setColaboradores] = React.useState([]);
    const [naturezas, setNaturezas] = React.useState([]);
    const [status, setStatus] = React.useState(false);
    const [mask, setMask] = React.useState('(99) 99999-9999');
    const [showPassword, setShowPassword] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(true);

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();

    React.useEffect(() => {
        if (user) {
            preencherFormulario();
        }
        listarNaturezajuridica();
    }, []);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const preencherFormulario = async () => {
        try {
            const result = await showCadastroFornecedor(user.fornecedor_id);

            if (result && result.data && result.data.data) {
                setValue("documento", result.data.data.documento);
                setValue("cep", result.data.data.cep);
                setValue("endereco", result.data.data.endereco);
                setValue("numero", result.data.data.numero);
                setValue("complemento", result.data.data.complemento);
                setValue("bairro", result.data.data.bairro);
                setValue("cidade", result.data.data.municipio && result.data.data.municipio.nome_municipio);
                setValue("estado", result.data.data.estado && result.data.data.estado.uf);
                setValue("razao_social", result.data.data.razao_social);
                setValue("nome_fantasia", result.data.data.razao_social);
                setValue("declaracao_enquadramento", result.data.data.declaracao_enquadramento);
                setValue("natureza_juridica", result.data.data.natureza_juridica);
                setValue("objeto_social", result.data.data.objeto_social);
                setValue("email", result.data.data.email);
                setValue("telefone", result.data.data.telefone);
                setValue("inscricao_estadual", result.data.data.inscricao_estadual);
                setValue("inscricao_municipal", result.data.data.inscricao_municipal);
                setValue('data_abertura', dayjs(result.data.data.data_abertura).format('DD/MM/YYYY'));
                setStatus(result.data.data.status == 'A' ? true : false);
                setFornecedor(result.data.data);
                setColaboradores(result.data.data.colaboradores);

            }
        } catch (error) {

        }
    };

    const listarNaturezajuridica = async () => {
        const result = await getNaturezaJuridica();

        if (result && result.data) {
            setNaturezas(result.data.data);
        }
    }

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const UserTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        fontSize: '18px !important',
    }));

    const onErrors = (error) => {

    }

    const cadastrarFornecedor = async (data) => {
        try {
            const result = await cadastroStep1(data);
            if (result && result.data && result.data.data) {
                setFornecedor(result.data.data);
                snackbar.showSnackbar('Cadastro atualizado com sucesso.', '#05a55a');
            }
        } catch (error) {
            console.log(error);
            snackbar.showSnackbar(error.response.data.message, '#DC143C');
        }
    }

    const onSubmit = (data) => {
        console.log(data);
        let aux = { ...fornecedor };
        aux.telefone = data.telefone;

        if (data.data_abertura) {
            aux.data_abertura = `${data.data_abertura.split('/')[2]}-${data.data_abertura.split('/')[1]}-${data.data_abertura.split('/')[0]}`;
        }
        aux.cnpj = aux.documento;
        console.log(aux);
        cadastrarFornecedor(aux);
    }
    const onChangeStatus = (event) => {
        setStatus(event.target.checked);
    }

    const atualizarDados = async (e) => {

        if (fornecedor && fornecedor.documento) {
            // setIsDisabled(false);
            setLoading(true);
            const result = await getCompany(fornecedor.documento.replace(/\D/g, ""));

            if (result && result.data && result.data.data) {
                console.log(result.data.data[0].RAZAOSOCIAL);
                setValue("cep", result.data.data[0].CEP);
                setValue("endereco", result.data.data[0].RUA);
                setValue("numero", result.data.data[0].NUMERO);
                setValue("complemento", result.data.data[0].COMPLEMENTO);
                setValue("bairro", result.data.data[0].BAIRRO);
                setValue("cidade", result.data.data[0].CIDADE);
                setValue("estado", result.data.data[0].ESTADO);
                setValue("razao_social", result.data.data[0].RAZAOSOCIAL);
                setValue("declaracao_enquadramento", result.data.data[0].DESC_PORTE);
                setValue("natureza_juridica", result.data.data[0].COD_NATUREZA_JUR);
                setValue("data_abertura", result.data.data[0].DATAABERTURA);
                let aux = { ...fornecedor };

                aux.cep = result.data.data[0].CEP;
                aux.cnpj = aux.documento;
                aux.endereco = result.data.data[0].RUA;
                aux.numero = result.data.data[0].NUMERO;
                aux.complemento = result.data.data[0].COMPLEMENTO;
                aux.bairro = result.data.data[0].BAIRRO;
                aux.cidade = result.data.data[0].CIDADE;
                aux.estado = result.data.data[0].ESTADO;
                aux.razao_social = result.data.data[0].RAZAOSOCIAL;
                aux.declaracao_enquadramento = result.data.data[0].DESC_PORTE;
                aux.natureza_juridica = result.data.data[0].COD_NATUREZA_JUR;
                if (result.data.data[0].DATAABERTURA) {
                    aux.data_abertura = `${result.data.data[0].DATAABERTURA.split('/')[2]}-${result.data.data[0].DATAABERTURA.split('/')[1]}-${result.data.data[0].DATAABERTURA.split('/')[0]}`;
                }


                cadastrarFornecedor(aux);
                console.log(aux);

            }
            setLoading(false);
            // setIsDisabled(true);
        }
    }



    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                <Grid md={12}>
                    <Box severity="info" style={{ fontWeight: 500, fontFamily: 'AlegraSans-Medium', background: '#DFDFDF', color: '#005EB8', padding: 10 }}>
                        Se houver algum dado errado ou desatualizado,
                        <span onClick={() => atualizarDados()} style={{ fontWeight: 'bold', cursor: 'pointer' }} > &nbsp;clique aqui &nbsp;</span>
                        para buscar as informações na Receita Federal.
                        {loading ? (
                            <CircularProgress size={10} />
                        ) : null}

                    </Box>
                </Grid>
                <Grid md={12} xs={12}>
                    <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                        <Grid container spacing={2}>
                            <Grid md={6}>
                                <Controller
                                    name="documento"
                                    // disabled={isDisabled}
                                    control={control}
                                    defaultValue={""}
                                    mask="99.999.999.9999-99"
                                    rules={{
                                        required: "campo é obrigatório",
                                    }}
                                    render={({
                                        field: { onChange, onBlur, ref, value },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            disabled={isDisabled}
                                            style={{ marginTop: '5px' }}
                                            mask="99.999.999.9999-99"
                                            maskChar={""}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(event) => {
                                                onChange(event);
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    disabled={isDisabled}
                                                    margin="normal"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    fullWidth
                                                    {...inputProps}
                                                    id="cnpj"
                                                    label="CNPJ"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                                {errors.cnpj && errors.cnpj.type === "validate" && <small style={{ color: 'red' }}>CNPJ Inválido</small>}
                            </Grid>
                            <Grid md={12}>
                                <Controller
                                    name="razao_social"
                                    rules={{
                                        required: "campo é obrigatório",
                                    }}
                                    // disabled={isDisabled}
                                    defaultValue={""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            disabled={isDisabled}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Razão Social"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={7}>
                                <Controller
                                    name="nome_fantasia"
                                    rules={{
                                        required: "campo é obrigatório",
                                    }}
                                    // disabled={isDisabled}
                                    defaultValue={""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            disabled={isDisabled}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Nome Fantasia"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={5}>
                                <Controller
                                    name="data_abertura"
                                    defaultValue={''}
                                    // disabled={isDisabled}
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        // <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br" localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                        //     <Box style={{ paddingTop: 5, width: '100%' }}>

                                        //         <DatePicker
                                        //             disabled={isDisabled}
                                        //             // inputFormat="DD/MM/YYYY HH:mm"
                                        //             defaultValue={null}
                                        //             value={value}
                                        //             label="Data de abertura"
                                        //             // minDate={dayjs(new Date())}
                                        //             onChange={(event) => {
                                        //                 onChange(event);
                                        //             }}
                                        //         />

                                        //     </Box>
                                        // </LocalizationProvider>
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            disabled={isDisabled}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Data Abertura"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={12}>
                                <Controller
                                    name="natureza_juridica"
                                    id="natureza_juridica"
                                    disabled={isDisabled}
                                    defaultValue={0}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            value={value}
                                            style={{ height: 38, marginTop: 5 }}
                                            error={error}
                                            disabled={isDisabled}
                                            label="Natureza Juridica"
                                            select
                                            className="inputSelect"
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value={0}>
                                                Selecione a Natureza Juridica
                                            </MenuItem>
                                            {naturezas && naturezas.map((res, index) =>
                                                <MenuItem value={res.valor}>
                                                    {`${res.cod} ${res.descricao}`}
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid md={6}>
                                <Controller
                                    name="telefone"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: 'Telefone é obrigatório' }}
                                    mask={mask}
                                    render={({
                                        field: { onChange, ref, value },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            style={{ marginTop: '5px' }}
                                            mask={mask}
                                            maskChar={'_'}
                                            onBlur={(e) => {
                                                const v = e.target.value.replace(/\D/g, '');
                                                if (v.length === 10) {
                                                    setMask('(99) 9999-9999');
                                                } else {
                                                    setMask('(99) 99999-9999');
                                                }
                                            }}
                                            onFocus={(e) => {
                                                setMask('(99) 99999-9999');
                                            }}
                                            onChange={onChange}
                                            value={value}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    margin="normal"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    fullWidth
                                                    {...inputProps}
                                                    id="telefone"
                                                    label="Telefone"
                                                    placeholder="Digite o telefone com DDD"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            <Grid md={6}></Grid>
                            <Grid md={12}>
                                <span
                                    style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 16, fontWeight: 500, fontFamily: "Campuni-Medium" }}
                                >Endereço</span>
                            </Grid>

                            <Grid md={6}>
                                <Controller
                                    name="cep"
                                    defaultValue={""}
                                    // disabled={isDisabled}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            disabled={isDisabled}
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="CEP"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={6}></Grid>
                            <Grid md={9}>
                                <Controller
                                    name="endereco"
                                    defaultValue={""}
                                    // disabled={isDisabled}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            disabled={isDisabled}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Logradouro"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="numero"
                                    defaultValue={""}
                                    control={control}
                                    // disabled={isDisabled}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            disabled={isDisabled}
                                            type="number"
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Número"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="bairro"
                                    defaultValue={""}
                                    control={control}
                                    // disabled={isDisabled}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            onChange={onChange}
                                            disabled={isDisabled}
                                            fullWidth
                                            label="Bairro"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="complemento"
                                    control={control}
                                    // disabled={isDisabled}
                                    defaultValue={""}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            disabled={isDisabled}
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            label="Complemento"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="cidade"
                                    // disabled={isDisabled}
                                    defaultValue={""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            disabled={isDisabled}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Cidade"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="estado"
                                    control={control}
                                    defaultValue={"MG"}
                                    // disabled={isDisabled}
                                    // rules={{
                                    //     required: "campo é obrigatório",
                                    // }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            disabled={isDisabled}
                                            value={value}
                                            onChange={onChange}
                                            fullWidth
                                            label="Estado"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid mdOffset={9} md={3} textAlign={'right'}>
                                <Button style={{ width: '182px', height: 41, marginTop: 10, color: '#fff' }} color="info" type="submit" variant="contained">Salvar</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Box >
    )
}