import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import 'dayjs/locale/pt-br';
import { useQuery } from "../../hooks/useQuery";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";
import { HistoricoItem } from "../../components/HistoricoItem";
import { CircularProgress } from "@mui/material";


export const HistoricoUsuarioPage = () => {

    const { user } = useAuth();
    const snackbar = useSnackbar();
    const { updatePassword, listaProcessosUser } = useQuery();
    const navigate = useNavigate();
    const [valueTab, setValueTab] = React.useState("1");
    const [loading, setLoading] = React.useState(false);
    const [processos, setProcessos] = React.useState([]);
    const [showPassword, setShowPassword] = React.useState(true);

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();

    React.useEffect(() => {
        if (user) {
            buscarProcessos();
        }

    }, []);

    const buscarProcessos = async (status = null) => {
        setLoading(true);
        const result = await listaProcessosUser(user.user_id, status);

        if (result && result.data) {
            if (Array.isArray(result.data.data)) {
                setProcessos(result.data.data);
            } else {
                let aux = [];
                Object.keys(result.data.data).forEach(i => {
                    aux.push(result.data.data[i]);
                });
                setProcessos(aux);

            }

        }
        setLoading(false);
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
        switch (newValue) {
            case '1':
                buscarProcessos();
                break;
            case '2':
                buscarProcessos('A');
                break;
            case '3':
                buscarProcessos('F');
                break;

            default:
                break;
        }
    };

    const UserTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        fontSize: '18px !important',
    }));

    const alterarSenha = async (data) => {
        const result = await updatePassword(user.user_id, { senha: data.senha });

        if (result && result.data) {
            snackbar.showSnackbar('Senha atualizada com sucesso.', '#05a55a');
        }


    }

    const onErrors = (error) => {

    }

    const onSubmit = (data) => {
        console.log(data);
        if (data.senha !== data.confirmar_senha) {
            snackbar.showSnackbar('As senhas não conferem!', '#DC143C');
            return;
        }

        alterarSenha(data);
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12}>
                    <TabContext value={valueTab}>
                        <TabList value={valueTab} onChange={handleChangeTab} aria-label="lab API tabs example">
                            <UserTab label="Todos" value="1" />
                            <UserTab label="Inscritos" value="2" />
                            <UserTab label="Encerrados " value="3" />
                        </TabList>
                        <TabPanel value="1">
                            {loading ? <CircularProgress /> : (
                                <Grid container spacing={2} paddingX="0">
                                    {processos && processos.map(p => {
                                        return (
                                            <Grid style={{ cursor: 'pointer' }} onClick={() => navigate(`/historico-usuario/${p.processo_id}`)} md={12} xs={12}>
                                                <HistoricoItem item={p} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value="2">
                            {loading ? <CircularProgress /> : (
                                <Grid container spacing={2} paddingX="0">
                                    {processos && processos.map(p => {
                                        return (
                                            <Grid style={{ cursor: 'pointer' }} onClick={() => navigate(`/historico-usuario/${p.processo_id}`)} md={12} xs={12}>
                                                <HistoricoItem item={p} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value="3">
                            {loading ? <CircularProgress /> : (
                                <Grid container spacing={2} paddingX="0">
                                    {processos && processos.map(p => {
                                        return (
                                            <Grid style={{ cursor: 'pointer' }} onClick={() => navigate(`/historico-usuario/${p.processo_id}`)} md={12} xs={12}>
                                                <HistoricoItem item={p} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            )}
                        </TabPanel>
                    </TabContext>
                </Grid>
                <Grid md={2} xs={0}></Grid>
            </Grid>
        </Box >
    )
}