import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { chipClasses, CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useQuery } from "../../hooks/useQuery";
import { useAuth } from "../../hooks/useAuth";


export const Step5PJ = ({ activeStep, setActiveStep, processoFinalizado, processoId }) => {

    const snackbar = useSnackbar();
    const { user } = useAuth();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = React.useState(false);
    const { editalId } = useParams();
    const { saveDocsToAnalise } = useQuery();
    const [ativar, setAtivar] = React.useState(false);
    const [itens, setItens] = React.useState([]);


    React.useEffect(() => {
        finalizar();

    }, []);



    const finalizar = async () => {
        const result = await saveDocsToAnalise({ userid: user.user_id, processoid: processoId });

    }

    function svgCheck(props) {

        return (
            <SvgIcon style={{ width: 131, height: 131 }}  {...props}>
                <svg width="131" height="131" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M55.9097 89.0475L32.75 65.8824L40.4681 58.1643L55.9097 73.6004L86.782 42.7227L94.5056 50.4462L55.9097 89.0475Z" fill="#6BAC54" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M5.45703 65.5002C5.45703 32.3408 32.3393 5.4585 65.4987 5.4585C98.6581 5.4585 125.54 32.3408 125.54 65.5002C125.54 98.6595 98.6581 125.542 65.4987 125.542C32.3393 125.542 5.45703 98.6595 5.45703 65.5002ZM65.4987 114.625C59.0475 114.625 52.6595 113.355 46.6994 110.886C40.7393 108.417 35.3238 104.798 30.7621 100.237C26.2004 95.6751 22.5819 90.2596 20.1131 84.2995C17.6444 78.3394 16.3737 71.9514 16.3737 65.5002C16.3737 59.049 17.6444 52.661 20.1131 46.7008C22.5819 40.7407 26.2004 35.3252 30.7621 30.7635C35.3238 26.2019 40.7393 22.5833 46.6994 20.1146C52.6595 17.6458 59.0475 16.3752 65.4987 16.3752C78.5275 16.3752 91.0226 21.5508 100.235 30.7635C109.448 39.9763 114.624 52.4714 114.624 65.5002C114.624 78.5289 109.448 91.0241 100.235 100.237C91.0226 109.45 78.5275 114.625 65.4987 114.625Z" fill="#6BAC54" />
                </svg>
            </SvgIcon>
        )
    }




    return (
        <Box style={{ width: '100%', marginTop: 20 }}>

            <Grid container spacing={1} paddingX="0">
                {processoFinalizado ? (
                    <Grid md={12}>
                        <p style={{ fontSize: 40, fontWeight: 700, color: '#005EB8', textAlign: 'center', margin: 0 }}>
                            Este processo foi finalizado !
                        </p>
                    </Grid>
                ) : (<Grid md={12}>
                    <p style={{ fontSize: 40, fontWeight: 700, color: '#005EB8', textAlign: 'center', margin: 0 }}>
                        Seus documentos foram enviados !
                    </p>
                    <p style={{ fontSize: 40, fontWeight: 700, color: '#005EB8', textAlign: 'center', margin: 0 }}>
                        Agora eles serão analisados por nossa equipe.
                    </p>
                    <p style={{ fontSize: 40, fontWeight: 700, color: '#005EB8', textAlign: 'center', margin: 0 }}>
                        Para acompanhar este e outros processos que você está                    </p>
                    <p style={{ cursor: 'pointer', fontSize: 40, fontWeight: 700, color: '#005EB8', textAlign: 'center', margin: 0 }}>
                        participando, <b onClick={() => { navigate('/historico-usuario') }} style={{ textDecoration: 'underline' }}>acesse aqui.</b>
                    </p>
                </Grid>

                )}

                <Grid md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {svgCheck()}
                </Grid>

                <Grid md={4}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
            </Grid>
        </Box >
    )
}