import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import { IconButton, SvgIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
export const AlocacaoItem = ({ title, horas }) => {
    const navigate = useNavigate();
    const svgHoras = () => {
        return (
            <SvgIcon style={{ width: 13, height: 13 }}>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 1.75C5.6875 1.75 1.75 5.6875 1.75 10.5C1.75 15.3125 5.6875 19.25 10.5 19.25C15.3125 19.25 19.25 15.3125 19.25 10.5C19.25 5.6875 15.3125 1.75 10.5 1.75ZM10.5 17.5C6.64125 17.5 3.5 14.3587 3.5 10.5C3.5 6.64125 6.64125 3.5 10.5 3.5C14.3587 3.5 17.5 6.64125 17.5 10.5C17.5 14.3587 14.3587 17.5 10.5 17.5ZM10.9375 6.125H9.625V11.375L14.175 14.175L14.875 13.0375L10.9375 10.675V6.125Z" fill="#005EB8" />
                </svg>
            </SvgIcon>
        )
    }

    return (
        <Card sx={{ width: 184, height: 68, background: '#FFFFFF' }}>
            <CardContent style={{ padding: 0 }}>
                <div style={{ width: '100%', padding: '10px 10px 0 10px', display: 'flex', justifyContent: 'space-between' }}>
                    <span className="dots" style={{ color: '#666666', fontWeight: '500', fontFamily: "AlegraSans-Medium", fontSize: 14 }}>{title}</span>
                </div>
                <p style={{ padding: '0 10px 10px 10px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                    {svgHoras()}
                    <span style={{ marginLeft: 5, fontWeight: 700, color: '#005EB8', fontSize: 16, fontFamily: "AlegraSans" }}>{horas}h</span>
                </p>
            </CardContent>
        </Card>
    )
}