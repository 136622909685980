import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { PessoaFisicaPage } from "./PessoaFisicaPage";
import { PessoaJuridicaPage } from "./PessoaJuridicaPage";
import { UsuariosInternoPage } from "./UsuariosInternoPage";

export const UsuariosPage = () => {

    const navigate = useNavigate();
    const [value, setValue] = React.useState("1");
    const [valuePessoa, setValuePessoa] = React.useState('PJ');

    React.useEffect(() => {
    }, []);

    const UserTabs = styled(Tabs)({
        borderBottom: '1px solid #e8e8e8',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
    });

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const UserTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontFamily: 'Campuni-Medium !important',
        fontSize: isMobile ? '14px !important' : '24px !important',
        color: '#CCCCCC',
        '&:hover': {
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#005EB8',
            fontWeight: 700,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }));

    const PessoaTabs = styled(Tabs)({
        borderBottom: '1px solid #e8e8e8',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
    });

    const handleChangeTabPessoa = (event, newValue) => {
        setValuePessoa(newValue);
    };

    const PessoaTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        textTransform: 'none',
        minWidth: isMobile ? '50%' : 141,
        fontFamily: 'Campuni-Medium !important',
        width: isMobile ? '50%' :161,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: '16px !important',
        borderRadius: 4,
        border: 'solid 1px #005EB8',
        color: '#005EB8',
        '&:hover': {
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: '#005EB8',
            fontWeight: 700,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }));

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0">

                <Grid md={12} xs={12}>
                    <TabContext value={value}>
                        <UserTabs value={value} onChange={handleChangeTab} aria-label="ant example">
                            <UserTab label="Usuários Externos" value="1" />
                            <div style={{ height: 30, width: 2, backgroundColor: '#999999', marginTop: 12 }}></div>
                            <UserTab label="Usuários Internos" value="2" />
                        </UserTabs>
                        <TabPanel value="1">
                            <TabContext value={valuePessoa}>
                                <PessoaTabs value={valuePessoa} onChange={handleChangeTabPessoa} style={{ marginTop: 30 }} aria-label="ant example">
                                    <PessoaTab label="Pessoa Jurídica" value="PJ" />
                                    <PessoaTab label="Pessoa Física" value="PF" />
                                </PessoaTabs>
                                <TabPanel value="PJ">
                                    <PessoaJuridicaPage />
                                </TabPanel>
                                <TabPanel value="PF">
                                    <PessoaFisicaPage />
                                </TabPanel>
                            </TabContext>
                        </TabPanel>
                        <TabPanel value="2">
                            <UsuariosInternoPage />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Box >
    )
}