import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { EditalStep1Page } from "./EditalStep1Page";
import { EditalStep2Page } from "./EditalStep2Page";
import { EditalStep3Page } from "./EditalStep3Page";
import { EditalStep4Page } from "./EditalStep4Page";
import { EditalStep5Page } from "./EditalStep5Page";
import { EditalStep6Page } from "./EditalStep6Page";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import { isMobile } from "react-device-detect";
const steps = [
    'Dados primários',
    'Documentos',
    'Formações',
    'Área',
    'Subárea',
    'Conclusão'
];

export const CriarEditalPage = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [isLoading, setIsLoading] = React.useState(false);
    const [edital, setEdital] = React.useState();

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStep = (step) => () => {
        if (edital && edital.id) {
            setActiveStep(step);
        }
    };

    const renderStepAtual = () => {
        switch (activeStep) {
            case 0:
                return (
                    <EditalStep1Page edital={edital} setEdital={setEdital} activeStep={activeStep} setActiveStep={setActiveStep} />
                );
            case 1:
                return (
                    <EditalStep2Page edital={edital} setEdital={setEdital} activeStep={activeStep} setActiveStep={setActiveStep} />
                );
            case 2:
                return (
                    <EditalStep3Page edital={edital} setEdital={setEdital} activeStep={activeStep} setActiveStep={setActiveStep} />
                );
            case 3:
                return (
                    <EditalStep4Page edital={edital} setEdital={setEdital} activeStep={activeStep} setActiveStep={setActiveStep} />
                );
            case 4:
                return (
                    <EditalStep5Page edital={edital} setEdital={setEdital} activeStep={activeStep} setActiveStep={setActiveStep} />
                );
            case 5:
                return (
                    <EditalStep6Page edital={edital} setEdital={setEdital} activeStep={activeStep} setActiveStep={setActiveStep} />
                );
            default:
                break;
        }
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 15,
            paddingLeft: 10,
            paddingRight: 10
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(95deg, #005EB8 0%, #40BBFF 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(95deg, #005EB8 0%, #40BBFF 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 6,
            border: 0,
            backgroundColor: '#ddd',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        width: 36,
        fontWeight: 'bold',
        height: 36,
        display: 'flex',
        borderRadius: '50%',
        color: '#005EB8',
        justifyContent: 'center',
        border: 'solid 2px #005EB8',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'white',
    }));

    function ColorlibStepIcon(props) {
        console.log(props);
        const { active, completed, className, icon } = props;


        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                <span style={{ fontFamily: 'Univers-Medium' }}>{icon}</span>
            </ColorlibStepIconRoot>
        );
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <Box padding={5}>
                    <Grid container spacing={10}>
                        <Grid item sm={12} display={'flex'} justifyContent={'flex-end'}>
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={activeStep} style={{ flexWrap: isMobile ? 'wrap' : 'initial', padding: '40px 0' }} alternativeLabel connector={<ColorlibConnector />}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                                {/* <StepButton color="inherit" onClick={handleStep(index)}>
                                                    {label}
                                                </StepButton> */}
                                                <StepLabel onClick={handleStep(index)} StepIconComponent={ColorlibStepIcon}><span style={{ cursor: 'pointer', color: '#005EB8', fontFamily: 'Campuni-Medium' }}>{label}</span></StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>

                                {renderStepAtual()}

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    )
}