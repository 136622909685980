import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from "react-router-dom";
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
import CircleIcon from '@mui/icons-material/Circle';
export const HistoricoItem = ({ item }) => {
    const navigate = useNavigate();

    return (

        <Card sx={{ background: '#F8F9FE' }}>
            <CardContent style={{ padding: 0 }}>
                <Grid container spacing={5} paddingX="0" marginLeft={2}>
                    <Grid md={10} xs={12}>
                        <p style={{ padding: '10px', margin: 0, fontWeight: 700, display: 'flex', alignItems: 'center', fontFamily: 'Univers-Medium !important' }}>
                            <span style={{ marginLeft: 10, color: '#005EB8' }}>{item.processo.titulo} </span>
                        </p>
                        <p style={{ padding: '10px', margin: 0, fontWeight: 700, display: 'flex', alignItems: 'center', fontFamily: 'Univers-Medium !important' }}>
                            <span style={{ marginLeft: 10, color: '#005EB8' }}>Prazo: </span> <span style={{ fontWeight: '500', color: '#999999' }}>&nbsp;{dayjs(item.processo.data_abertura).locale('pt-br').format('DD/MM/YYYY')}</span>
                        </p>
                        <p style={{ padding: '10px', margin: 0, fontWeight: 700, display: 'flex', alignItems: 'center', fontFamily: 'Univers-Medium !important' }}>
                            <span style={{ marginLeft: 10, color: '#005EB8' }}>Inscrição realizada: </span> <span style={{ fontWeight: '500', color: '#999999' }}>&nbsp;{dayjs(item.created_at).locale('pt-br').format('DD/MM/YYYY')}</span>
                        </p>
                    </Grid>
                    <Grid md={2} xs={12} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                        <p style={{ margin: '0', fontWeight: 500, width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            <CircleIcon style={{ color: item.status == 'A' == '#6BAC54' ? item.status == 'R' == '#FFED69' : '#FFB380' }} fontSize="10" />
                            <br />
                            <span style={{ textAlign: 'center', fontSize: 12, color: item.status == 'A' == '#6BAC54' ? item.status == 'R' == '#FFED69' : '#FFB380', fontFamily: 'Campuni-Bold', width: '100%' }}>
                                {item.status == 'A' == 'Aprovado' ? item.status == 'R' == 'Verificar documentos' : 'Em análise'}
                            </span>
                        </p>

                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    )
}