import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import MenuItem from "@mui/material/MenuItem";
import { useForm, Controller } from "react-hook-form";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from "../../hooks/useSnackbar";
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { useQuery } from "../../hooks/useQuery";
import { CheckBoxItem } from "../../components/CheckBoxItem";
import { InputAdornment } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const CriarComunicadoPage = () => {

    const TIPO_ARQUIVOS_PERMITIDOS = [
        'pdf',
    ];
    const snackbar = useSnackbar();
    const [arquivo, setArquivo] = React.useState();
    const { comunicadoId } = useParams();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const { listaAreas, listaEditais, listaProcessos, showAnuncio, removeDocumentoComunicado, saveAnuncio, updateAnuncio } = useQuery();
    const [expanded, setExpanded] = React.useState(false);
    const [checkedEditais, setCheckedEditais] = React.useState([]);
    const [text, setText] = React.useState('');
    const [checkedProcessos, setCheckedProcessos] = React.useState([]);
    const [isCheckAllProcessos, setIsCheckAllProcessos] = React.useState(false);
    const [checkedAreas, setCheckedAreas] = React.useState([]);
    const [isCheckAllAreas, setIsCheckAllAreas] = React.useState(false);
    const [isCheckAllEditais, setIsCheckAllEditais] = React.useState(false);
    const [loadingAreas, setLoadingAreas] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingProcessos, setLoadingProcesssos] = React.useState(false);
    const [loadingEdital, setLoadingEdital] = React.useState(false);
    const [comunicado, setComunicado] = React.useState();
    const [areas, setAreas] = React.useState([]);
    const [areasAll, setAreasAll] = React.useState([]);
    const [editais, setEditais] = React.useState([]);
    const [editaisAll, setEditaisAll] = React.useState([]);
    const [processos, setProcessos] = React.useState([]);
    const [processosAll, setProcessosAll] = React.useState([]);
    const [grupoNotificacao, setGrupoNotificacao] = React.useState();
    const inputFile = React.useRef(null);

    React.useEffect(() => {
        if (comunicadoId) {
            getAnuncio();
        }
        buscarAreas();
        getProcessos();
        getEditais();
    }, []);

    const getAnuncio = async () => {
        setLoading(true);
        const result = await showAnuncio(comunicadoId);
        if (result && result.data) {
            setComunicado(result.data.data);
            setValue('titulo', result.data.data.titulo);
            setText(result.data.data.texto);
            setValue('grupo_notificacao', result.data.data.grupo_notificacoes);
            setGrupoNotificacao(result.data.data.grupo_notificacoes);
            let areaAux = [];
            result.data.data.anuncio_area.map(a => {
                areaAux.push(a.area_id);
            })
            setCheckedAreas(areaAux);
            let editalAux = [];
            result.data.data.anuncio_edital.map(a => {
                editalAux.push(a.edital_id);
            })
            setCheckedEditais(editalAux);
            let processoAux = [];
            result.data.data.anuncio_processo.map(a => {
                processoAux.push(a.processo_id);
            })
            setCheckedProcessos(processoAux);

        }
        setLoading(false);
    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }

    const svgAnexo = () => {

        return (
            <SvgIcon>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.54449 16.3878C-0.542293 14.2683 -0.503401 10.8503 1.59399 8.73848L9.08542 1.19533C10.6681 -0.398373 13.2408 -0.398513 14.8237 1.19533C16.3912 2.77363 16.3931 5.32017 14.8237 6.90034L8.29335 13.4693C7.2271 14.5429 5.48503 14.5279 4.43685 13.4343C3.42703 12.3806 3.45942 10.7106 4.4887 9.6742L9.62238 4.51203C9.84317 4.29006 10.205 4.28626 10.4305 4.50356L11.2469 5.29032C11.4725 5.50766 11.4763 5.86379 11.2555 6.08577L6.12238 11.2474C5.94624 11.4248 5.93538 11.7195 6.09924 11.8905C6.25538 12.0534 6.50085 12.0561 6.65952 11.8963L13.1898 5.32734C13.8903 4.62204 13.8903 3.47373 13.1895 2.76804C12.5041 2.07803 11.4053 2.07768 10.7197 2.76804L3.22821 10.3112C1.98667 11.5613 1.96753 13.5849 3.18567 14.8222C4.40031 16.0559 6.3637 16.0574 7.5806 14.8322L13.7256 8.64475C13.9462 8.4226 14.308 8.41859 14.5337 8.63575L15.3507 9.42198C15.5764 9.63914 15.5805 9.99528 15.3598 10.2174L9.21485 16.4049C7.08713 18.5472 3.64545 18.5218 1.54449 16.3878Z" fill="white" />
                </svg>
            </SvgIcon>
        )
    }


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onSubmit = (data) => {

        data.anuncio_edital = checkedEditais;
        data.anuncio_processo = checkedProcessos;
        data.anuncio_area = checkedAreas;
        data.grupo_notificacoes = grupoNotificacao;
        data.texto = text;

        if (arquivo) {
            data.documento = arquivo;
        }

        if (comunicado && comunicado.id) {
            atualizarAnuncio(data)
        } else {
            salvarAnuncio(data);
        }

    }

    const salvarAnuncio = async (data) => {
        try {
            setLoading(true);
            const result = await saveAnuncio(data);

            if (result && result.data) {
                setComunicado(result.data.data);
                snackbar.showSnackbar(`Comunicado salvo com sucesso!`, '#05a55a');
                navigate('/comunicados');
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const atualizarAnuncio = async (data) => {
        try {
            setLoading(true);
            const result = await updateAnuncio(comunicadoId, data);

            if (result && result.data) {
                setComunicado(result.data.data);
                snackbar.showSnackbar(`Comunicado atualizado com sucesso!`, '#05a55a');
                navigate('/comunicados');
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const onErrors = (error) => {

    }


    const onChangeFile = (event) => {
        const files = [...event.target.files];
        console.log(files);

        if (files.length > 0) {
            const file = files[0];

            const extension = file.name.split('.').pop();

            if (!TIPO_ARQUIVOS_PERMITIDOS.includes(extension.toLowerCase())) {
                snackbar.showSnackbar(
                    'Tipo de arquivo inválido. Somente ' +
                    TIPO_ARQUIVOS_PERMITIDOS.join(','), '#DC143C'
                );
                inputFile.current.value = '';
                return;
            }

            // if (isFileBig(file.size)) {
            //     snackbar.showSnackbar('Tamanho de arquivo inválido. Máximo é 10MB', '#DC143C');
            //     return;
            // }

            setArquivo(file);
        }

    };

    const handleChangeAllAreas = (event) => {
        if (event.target.checked) {
            let areaAux = [];
            areasAll.forEach(doc => {
                areaAux.push(doc.id);
            });
            setCheckedAreas(areaAux);
            setIsCheckAllAreas(true);
        } else {
            setCheckedAreas([]);
            setIsCheckAllAreas(false);
        }
    };

    const handleChangeAllProcessos = (event) => {
        if (event.target.checked) {
            let processoAux = [];
            processosAll.forEach(doc => {
                processoAux.push(doc.id);
            });
            setCheckedProcessos(processoAux);
            setIsCheckAllProcessos(true);
        } else {
            setCheckedProcessos([]);
            setIsCheckAllProcessos(false);
        }
    };

    const handleChangeAllEditais = (event) => {
        if (event.target.checked) {
            let editalAux = [];
            editaisAll.forEach(doc => {
                editalAux.push(doc.id);
            });
            setCheckedEditais(editalAux);
            setIsCheckAllEditais(true);
        } else {
            setCheckedEditais([]);
            setIsCheckAllEditais(false);
        }
    };

    const buscarAreas = async () => {
        setLoadingAreas(true);
        const result = await listaAreas();
        if (result && result.data) {
            setAreas(result.data.data);
            setAreasAll(result.data.data);
        }
        setLoadingAreas(false);
    }


    const getProcessos = async () => {
        setLoadingProcesssos(true);
        const result = await listaProcessos({ pagination: false });
        if (result && result.data && result.data.data) {
            setProcessos(result.data.data);
            setProcessosAll(result.data.data);
        }
        setLoadingProcesssos(false);
    }



    const getEditais = async () => {
        setLoadingEdital(true);
        const result = await listaEditais({ pagination: false, status: 'A' });
        if (result && result.data && result.data.data) {
            setEditais(result.data.data);
            setEditaisAll(result.data.data);
        }
        setLoadingEdital(false);
    }

    const filtrarAreas = (term) => {
        setAreas(areasAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const filtrarProcessos = (term) => {
        setProcessos(processosAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const filtrarEditais = (term) => {
        setEditais(editaisAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const removerDocumento = async (id) => {
        try {
            setLoading(true);
            const result = await removeDocumentoComunicado(id);

            if (result && result.data) {
                setComunicado(result.data.data);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const childrenAreas = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {loadingAreas ? <CircularProgress /> : null}
            {areas && areas.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedAreas}
                        checkedDocumentos={checkedAreas}
                        isCheckAllDocumentos={isCheckAllAreas}
                        editarDocumento={null}
                        documentos={areas}
                    />
                )
            })}
        </Box>
    );

    const childrenProcessos = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {loadingProcessos ? <CircularProgress /> : null}
            {processos && processos.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedProcessos}
                        checkedDocumentos={checkedProcessos}
                        isCheckAllDocumentos={isCheckAllProcessos}
                        editarDocumento={null}
                        documentos={processos}
                    />
                )
            })}
        </Box>
    );

    const childrenEditais = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {loadingEdital ? <CircularProgress /> : null}
            {editais && editais.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedEditais}
                        checkedDocumentos={checkedEditais}
                        isCheckAllDocumentos={isCheckAllEditais}
                        editarDocumento={null}
                        documentos={editais}
                    />
                )
            })}
        </Box>
    );

    const changeGrupoNotificacao = (value) => {
        setGrupoNotificacao(value);
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Grid container spacing={1} paddingX="0">
                    <Grid md={12} xs={12}>
                        <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>Criar Comunicado</span>
                    </Grid>
                    <Grid md={12} xs={12}>
                        <Grid container>
                            <>
                                <Grid md={12}>
                                    <Controller
                                        name="titulo"
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: 'campo é obrigatório' }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                style={{ marginTop: '5px' }}
                                                margin="normal"
                                                value={value}
                                                error={error}
                                                helperText={error && error.message}
                                                onChange={onChange}
                                                fullWidth
                                                label="Título"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid md={12}>
                                    {/* <Controller
                                        name="texto"
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: 'Campo é obrigatório' }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                style={{ marginTop: '5px' }}
                                                margin="normal"
                                                value={value}
                                                error={error}
                                                helperText={error && error.message}
                                                onChange={onChange}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                label="Texto"
                                            />
                                        )}
                                    /> */}
                                    <ReactQuill style={{ height: 200 }} theme="snow" value={text} onChange={setText} />
                                </Grid>
                                <Grid md={12} style={{marginTop: 40}}>
                                    <label style={{ fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Notificar</label>
                                    <Controller
                                        name="grupo_notificacao"
                                        control={control}
                                        render={({ value }) => (
                                            <RadioGroup
                                                style={{ marginTop: 5 }}
                                                value={value}
                                                onChange={(e) => {
                                                    changeGrupoNotificacao(e.target.value);
                                                }}
                                            >
                                                <FormControlLabel checked={grupoNotificacao == 'T' ? true : false} style={{ height: 30 }} value="T" control={<Radio />} label="Todos" />
                                                <FormControlLabel checked={grupoNotificacao == 'E' ? true : false} style={{ height: 30 }} value="E" control={<Radio />} label="Editais" />
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                                <Grid md={12}>
                                    <Box style={{ width: '100%', marginTop: 40, marginBottom: 40 }}>
                                        <Button
                                            style={{ width: isMobile ? '100%' : 'auto', height: 41 }}
                                            variant="contained"
                                            color="info"
                                            component="label"
                                            endIcon={svgAnexo()}
                                        >
                                            Anexar arquivo(s)
                                            <input
                                                onChange={onChangeFile}
                                                hidden
                                                accept={TIPO_ARQUIVOS_PERMITIDOS.join(',')}
                                                type="file"
                                            />
                                        </Button>
                                    </Box>
                                    {arquivo ? (
                                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                            <span>{arquivo.name}</span>
                                            <IconButton onClick={() => {
                                                setArquivo(undefined);
                                                inputFile.current.value = '';
                                            }} style={{ cursor: 'pointer' }} color="error">Remover</IconButton>
                                        </Box>

                                    ) : null}
                                    {comunicado && comunicado.documento ? (
                                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                            <span>{comunicado.documento.split('/')[2]}</span>
                                            <IconButton onClick={() => removerDocumento(comunicado.id)} style={{ cursor: 'pointer', fontSize: 16 }} color="error">Remover</IconButton>
                                        </Box>

                                    ) : null}
                                </Grid>
                                {grupoNotificacao == 'E' ? (
                                    <Grid md={12} style={{ marginTop: 20 }}>
                                        <Accordion expanded={expanded === `panel1`} onChange={handleChange(`panel1`)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <p style={{ color: '#005EB8', fontSize: 16, fontFamily: "Campuni-Bold" }}><strong>Notificar editais abertos</strong></p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={1} marginBottom={5}>
                                                    <Grid md={12}>
                                                        <TextField
                                                            id="customInput"
                                                            placeholder="Buscar"
                                                            fullWidth
                                                            className="custom-class"
                                                            onChange={(event) => {
                                                                filtrarEditais(event.target.value);
                                                            }}
                                                            variant="standard"
                                                            style={{ height: '38 !important', width: '100%', }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <IconButton onClick={() => { }}>
                                                                            {svgSearch()}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid md={12} style={{ marginTop: 20 }}>
                                                        <div
                                                            className="scrollbar"
                                                            id="style-3"
                                                            style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                                        >
                                                            {editais.length == editaisAll.length ? (
                                                                <FormControlLabel
                                                                    label="Marcar todos"
                                                                    control={
                                                                        <Checkbox
                                                                            checked={checkedEditais.length === editais.length}
                                                                            onChange={handleChangeAllEditais}
                                                                        />
                                                                    }
                                                                />
                                                            ) : null}
                                                            {childrenEditais}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                ) : null}
                                {/* <Grid md={12}>
                                    <Accordion expanded={expanded === `panel2`} onChange={handleChange(`panel2`)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <p style={{ color: '#005EB8', fontSize: 16, fontFamily: "Campuni-Bold" }}><strong>Notificar áreas</strong></p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={1} marginBottom={5}>
                                                <Grid md={12}>
                                                    <TextField
                                                        id="customInput"
                                                        placeholder="Buscar"
                                                        fullWidth
                                                        className="custom-class"
                                                        onChange={(event) => {
                                                            filtrarAreas(event.target.value);
                                                        }}
                                                        variant="standard"
                                                        style={{ height: '38 !important', width: '100%', }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <IconButton onClick={() => { }}>
                                                                        {svgSearch()}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid md={12} style={{ marginTop: 20 }}>
                                                    <div
                                                        className="scrollbar"
                                                        id="style-3"
                                                        style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                                    >
                                                        {areas.length == areasAll.length ? (
                                                            <FormControlLabel
                                                                label="Marcar todos"
                                                                control={
                                                                    <Checkbox
                                                                        checked={checkedAreas.length === areas.length}
                                                                        onChange={handleChangeAllAreas}
                                                                    />
                                                                }
                                                            />
                                                        ) : null}
                                                        {childrenAreas}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid> */}
                                {grupoNotificacao == 'P' ? (
                                    <Grid md={12}>
                                        <Accordion expanded={expanded === `panel3`} onChange={handleChange(`panel3`)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3-content"
                                                id="panel3-header"
                                            >
                                                <p style={{ color: '#005EB8', fontSize: 16, fontFamily: "Campuni-Bold" }}><strong>Notificar processo de credenciamento</strong></p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={1} marginBottom={5}>
                                                    <Grid md={12}>
                                                        <TextField
                                                            id="customInput"
                                                            placeholder="Buscar"
                                                            fullWidth
                                                            className="custom-class"
                                                            onChange={(event) => {
                                                                filtrarProcessos(event.target.value);
                                                            }}
                                                            variant="standard"
                                                            style={{ height: '38 !important', width: '100%', }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <IconButton onClick={() => { }}>
                                                                            {svgSearch()}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid md={12} style={{ marginTop: 20 }}>
                                                        <div
                                                            className="scrollbar"
                                                            id="style-3"
                                                            style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                                        >
                                                            {processos.length == processosAll.length ? (
                                                                <FormControlLabel
                                                                    label="Marcar todos"
                                                                    control={
                                                                        <Checkbox
                                                                            checked={checkedProcessos.length === processos.length}
                                                                            onChange={handleChangeAllProcessos}
                                                                        />
                                                                    }
                                                                />
                                                            ) : null}
                                                            {childrenProcessos}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                ) : null}
                                <Grid md={3}>
                                    <Button
                                        style={{ width: '100%', height: 41, marginTop: 10 }}
                                        color="primary"
                                        variant="outlined"
                                        startIcon={<ArrowBackIcon color="primary" />}
                                        onClick={() => navigate('/comunicados', { replace: true })}
                                    >
                                        Voltar
                                    </Button>
                                </Grid>
                                <Grid mdOffset={6} md={3}>
                                    <Button
                                        style={{ width: '100%', height: 41, marginTop: 10, color: '#fff' }}
                                        disabled={loading}
                                        type="submit"
                                        endIcon={loading ? <CircularProgress size={15} style={{ color: 'white' }} /> : null}
                                        color="info"
                                        variant="contained">
                                        Enviar
                                    </Button>
                                </Grid>
                            </>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Box >
    )
}