import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { SvgIcon } from '@mui/material';
import Tab from '@mui/material/Tab';
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import 'dayjs/locale/pt-br';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from "../../hooks/useSnackbar";
import { CircularProgress } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { useQuery } from "../../hooks/useQuery";
import { CheckBoxItem } from "../../components/CheckBoxItem";
import { InputAdornment } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export const PermissaoPage = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const snackbar = useSnackbar();
    const { savePermmissions, getPermmissions, listaAreas, listaProcessos, listaFormacoes, getUser, listaTipoDocumentos } = useQuery();
    const [valueTab, setValueTab] = React.useState("1");
    const [loading, setLoading] = React.useState(false);
    const [loadingPermissoes, setLoadingPermissoes] = React.useState(false);
    const [usuario, setUsuario] = React.useState();
    const [expanded, setExpanded] = React.useState(false);
    const [checkedFormacoes, setCheckedFormacoes] = React.useState([]);
    const [checkedDocumentos, setCheckedDocumentos] = React.useState([]);
    const [isCheckAllDocumentos, setIsCheckAllDocumentos] = React.useState(false);
    const [isCheckAllFormacoes, setIsCheckAllFormacoes] = React.useState(false);
    const [checkedAreas, setCheckedAreas] = React.useState([]);
    const [checkedProcessos, setCheckedProcessos] = React.useState([]);
    const [isCheckAllAreas, setIsCheckAllAreas] = React.useState(false);
    const [isCheckAllProcessos, setIsCheckAllProcessos] = React.useState(false);
    const [loadingAreas, setLoadingAreas] = React.useState(false);
    const [loadingDocumentos, setLoadingDocumentos] = React.useState(false);
    const [loadingFormacao, setLoadingFormacao] = React.useState(false);
    const [loadingEdital, setLoadingEdital] = React.useState(false);
    const [areas, setAreas] = React.useState([]);
    const [areasAll, setAreasAll] = React.useState([]);
    const [processos, setProcessos] = React.useState([]);
    const [processosAll, setProcessosAll] = React.useState([]);
    const [formacoes, setFormacoes] = React.useState([]);
    const [formacoesAll, setFormacoesAll] = React.useState([]);
    const [documentos, setDocumentos] = React.useState([]);
    const [documentosAll, setDocumentosAll] = React.useState([]);
    const [resumo, setResumo] = React.useState();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();

    React.useEffect(() => {
        if (id) {
            buscaUser();
            buscarAreas();
            getProcessos();
            buscarFormacoes();
            buscarTipoDocumentos();
            buscarPermissions();
        }
    }, []);

    const buscaUser = async () => {
        const result = await getUser(id);

        if (result && result.data) {
            setUsuario(result.data.data);
        }
    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangeAllAreas = (event) => {
        if (event.target.checked) {
            let areaAux = [];
            areasAll.forEach(doc => {
                areaAux.push(doc.id);
            });
            setCheckedAreas(areaAux);
            setIsCheckAllAreas(true);
        } else {
            setCheckedAreas([]);
            setIsCheckAllAreas(false);
        }
    };

    const handleChangeAllProcessos = (event) => {
        if (event.target.checked) {
            let processsoAux = [];
            processosAll.forEach(doc => {
                processsoAux.push(doc.id);
            });
            setCheckedProcessos(processsoAux);
            setIsCheckAllProcessos(true);
        } else {
            setCheckedProcessos([]);
            setIsCheckAllProcessos(false);
        }
    };

    const handleChangeAllFormacoes = (event) => {
        if (event.target.checked) {
            let formacoesAux = [];
            formacoesAll.forEach(doc => {
                formacoesAux.push(doc.id);
            });
            setCheckedFormacoes(formacoesAux);
            setIsCheckAllFormacoes(true);
        } else {
            setCheckedFormacoes([]);
            setIsCheckAllFormacoes(false);
        }
    };

    const handleChangeAllDocumentos = (event) => {
        if (event.target.checked) {
            let docAux = [];
            documentos.forEach(doc => {
                docAux.push(doc.id);
            });
            setCheckedDocumentos(docAux);
            setIsCheckAllDocumentos(true);
        } else {
            setCheckedDocumentos([]);
            setIsCheckAllDocumentos(false);
        }
    };

    const buscarPermissions = async () => {
        setLoadingPermissoes(true);
        const result = await getPermmissions(id);
        if (result && result.data) {
            console.log(result.data.data);
            if (result.data.data.Areas) {
                setCheckedAreas(result.data.data.Areas);
            }

            if (result.data.data.Formacoes) {
                setCheckedFormacoes(result.data.data.Formacoes);
            }

            if (result.data.data.Processos) {
                setCheckedProcessos(result.data.data.Processos);
            }

            if (result.data.data.TipoDocumentos) {
                setCheckedDocumentos(result.data.data.TipoDocumentos);
            }

            setResumo(result.data.data.Resumo);
            setLoadingPermissoes(false);
        }
    }

    const buscarAreas = async (processoId = null) => {
        setLoadingAreas(true);
        const result = await listaAreas(processoId);
        if (result && result.data) {
            setAreas(result.data.data);
            setAreasAll(result.data.data);
        }
        setLoadingAreas(false);
    }

    const buscarTipoDocumentos = async (processoId = null) => {
        setLoadingDocumentos(true)
        const result = await listaTipoDocumentos(processoId);
        if (result && result.data) {
            setDocumentos(result.data.data);
            setDocumentosAll(result.data.data);
        }
        setLoadingDocumentos(false);
    }

    const buscarFormacoes = async (processoId = null) => {
        setLoadingFormacao(true);
        const result = await listaFormacoes(false,processoId);
        if (result && result.data) {
            setFormacoes(result.data.data);
            setFormacoesAll(result.data.data);
        }
        setLoadingFormacao(false);
    }

    const getProcessos = async () => {
        setLoadingEdital(true);
        const result = await listaProcessos({ pagination: false });
        if (result && result.data && result.data.data) {
            setProcessos(result.data.data);
            setProcessosAll(result.data.data);
        }
        setLoadingEdital(false);
    }

    const filtrarDocumentos = (term) => {
        setDocumentos(documentosAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const filtrarAreas = (term) => {
        setAreas(areasAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const filtrarFormacoes = (term) => {
        setFormacoes(formacoesAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const filtrarProcessos = (term) => {
        setProcessos(processosAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const onChangeProcessoArea = (id) => {
        buscarAreas(id);
    }

    const onChangeProcessoFormacao = (id) => {
        buscarFormacoes(id);
    }

    
    const onChangeProcessoDocumentos = (id) => {
        buscarTipoDocumentos(id);
    }

    const salvarPermissoes = async () => {
        setLoading(true);
        let data = [];
        if (checkedAreas && checkedAreas.length > 0) {
            checkedAreas.forEach(a => {
                data.push({ tipo: 'Area', tipo_id: a });
            });
        }
        if (checkedProcessos && checkedProcessos.length > 0) {
            checkedProcessos.forEach(a => {
                data.push({ tipo: 'Processo', tipo_id: a });
            });
        }
        if (checkedFormacoes && checkedFormacoes.length > 0) {
            checkedFormacoes.forEach(a => {
                data.push({ tipo: 'Formacao', tipo_id: a });
            });
        }
        if (checkedDocumentos && checkedDocumentos.length > 0) {
            checkedDocumentos.forEach(a => {
                data.push({ tipo: 'TipoDocumento', tipo_id: a });
            });
        }

        const result = await savePermmissions(id, { permissions: data });
        setLoading(false);
        buscarPermissions();
        snackbar.showSnackbar(`Permissões salvas com sucesso!`, '#05a55a');
        console.log(data);
    }

    const childrenAreas = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {loadingAreas ? <CircularProgress /> : null}
            {areas && areas.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedAreas}
                        checkedDocumentos={checkedAreas}
                        isCheckAllDocumentos={isCheckAllAreas}
                        editarDocumento={null}
                        documentos={areas}
                    />
                )
            })}
        </Box>
    );

    const childrenFormacoes = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {loadingFormacao ? <CircularProgress /> : null}
            {formacoes && formacoes.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedFormacoes}
                        checkedDocumentos={checkedFormacoes}
                        isCheckAllDocumentos={isCheckAllFormacoes}
                        editarDocumento={null}
                        documentos={formacoes}
                    />
                )
            })}
        </Box>
    );

    const childrenProcessos = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {loadingEdital ? <CircularProgress /> : null}
            {processos && processos.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedProcessos}
                        checkedDocumentos={checkedProcessos}
                        isCheckAllDocumentos={isCheckAllProcessos}
                        editarDocumento={null}
                        documentos={processos}
                    />
                )
            })}
        </Box>
    );


    const childrenDocumentos = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {documentos && documentos.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedDocumentos}
                        checkedDocumentos={checkedDocumentos}
                        isCheckAllDocumentos={isCheckAllDocumentos}
                        editarDocumento={null}
                        documentos={documentos}
                    />
                )
            })}
        </Box>
    );

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12} style={{ padding: '0 10px' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { navigate('/usuarios', { replace: true }) }}
                        edge="start"
                    >
                        <span
                            style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 12, fontWeight: 'bold', fontFamily: "Campuni-Bold" }}
                        >
                            <ArrowBackIcon /> Voltar
                        </span>
                    </IconButton>
                </Grid>

                <Grid md={12} style={{ padding: 10 }}>
                    <Card sx={{ maxWidth: '100%', background: '#F8F9FE', padding: 2 }}>
                        <CardContent style={{ padding: 0 }}>
                            <Grid container spacing={1}>

                                <Grid md={12} item >
                                    <p style={{ fontSize: 18, color: '#666666' }}><b>Nome</b></p>
                                    <p style={{ fontSize: 18, color: '#666666' }}>{usuario && usuario.name}</p>
                                </Grid>
                                {loadingPermissoes ? <CircularProgress /> : (
                                    <>
                                        <Grid md={12} item >
                                            <p style={{ fontSize: 18, color: '#666666' }}>
                                                <b>Processos permitidos</b>
                                                <Stack direction="row" spacing={1}>
                                                    {resumo && resumo.Processos && resumo.Processos.map(e => {
                                                        return (<Chip color="primary" label={e} />)
                                                    })}
                                                </Stack>
                                            </p>
                                        </Grid>
                                        <Grid md={12} item >
                                            <p style={{ fontSize: 18, color: '#666666' }}>
                                                <b>Áreas permitidas</b>
                                                <Stack direction="row" spacing={1}>
                                                    {resumo && resumo.Areas && resumo.Areas.map(a => {
                                                        return (<Chip color="primary" label={a} />)
                                                    })}
                                                </Stack>
                                            </p>
                                        </Grid>
                                        <Grid md={12} item >
                                            <p style={{ fontSize: 18, color: '#666666' }}>
                                                <b>Tipo de Documentos permitidas</b>
                                                <Stack direction="row" spacing={1}>
                                                    {resumo && resumo.TipoDocumentos && resumo.TipoDocumentos.map(a => {
                                                        return (<Chip color="primary" label={a} />)
                                                    })}
                                                </Stack>
                                            </p>
                                        </Grid>
                                        <Grid md={12} item >
                                            <p style={{ fontSize: 18, color: '#666666' }}>
                                                <b>Formações permitidas</b>
                                                <Stack direction="row" spacing={1}>
                                                    {resumo && resumo.Formacoes && resumo.Formacoes.map(f => {
                                                        return (<Chip color="primary" label={f} />)
                                                    })}
                                                </Stack>
                                            </p>
                                        </Grid>

                                    </>
                                )}

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid md={12} style={{ padding: 10 }}>
                    <Accordion expanded={expanded === `panel1`} onChange={handleChange(`panel1`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <p style={{ color: '#005EB8', fontSize: 16, fontFamily: "Campuni-Bold" }}><strong>Processos</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} marginBottom={5}>
                                <Grid md={12}>
                                    <TextField
                                        id="customInput"
                                        placeholder="Buscar"
                                        fullWidth
                                        className="custom-class"
                                        onChange={(event) => {
                                            filtrarProcessos(event.target.value);
                                        }}
                                        variant="standard"
                                        style={{ height: '38 !important', width: '100%', }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton onClick={() => { }}>
                                                        {svgSearch()}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid md={12} >
                                    <div
                                        className="scrollbar"
                                        id="style-3"
                                        style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                    >
                                        {processos.length == processosAll.length ? (
                                            <FormControlLabel
                                                label="Marcar todos"
                                                control={
                                                    <Checkbox
                                                        checked={checkedProcessos.length === processos.length}
                                                        onChange={handleChangeAllProcessos}
                                                    />
                                                }
                                            />
                                        ) : null}
                                        {childrenProcessos}
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid md={12} style={{ padding: 10 }}>
                    <Accordion expanded={expanded === `panel2`} onChange={handleChange(`panel2`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <p style={{ color: '#005EB8', fontSize: 16, fontFamily: "Campuni-Bold" }}><strong>Tipo Documentos</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} marginBottom={5}>
                                <Grid xs={12} style={{ marginBottom: 20 }}>
                                    <TextField
                                        style={{ height: '8px !important' }}
                                        // label="Edital"
                                        defaultValue={0}
                                        variant="standard"
                                        select
                                        className="inputSelect"
                                        onChange={(e) => {
                                            onChangeProcessoDocumentos(e.target.value)
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem disabled value={0}>
                                            Selecione o processo
                                        </MenuItem>
                                        {processosAll && processosAll.map((res, index) =>
                                            <MenuItem value={res.id}>
                                                {`${res.titulo}`}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>

                                <Grid md={12}>
                                    <TextField
                                        id="customInput"
                                        placeholder="Buscar"
                                        fullWidth
                                        className="custom-class"
                                        onChange={(event) => {
                                            filtrarDocumentos(event.target.value);
                                        }}
                                        variant="standard"
                                        style={{ height: '38 !important', width: '100%', }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton onClick={() => { }}>
                                                        {svgSearch()}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid md={12} >
                                    <div
                                        className="scrollbar"
                                        id="style-3"
                                        style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                    >
                                        {documentos.length == documentosAll.length ? (
                                            <FormControlLabel
                                                label="Marcar todos"
                                                control={
                                                    <Checkbox
                                                        checked={checkedDocumentos.length === documentos.length}
                                                        onChange={handleChangeAllDocumentos}
                                                    />
                                                }
                                            />
                                        ) : null}
                                        {childrenDocumentos}
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid md={12} style={{ padding: 10 }}>
                    <Accordion expanded={expanded === `panel3`} onChange={handleChange(`panel3`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <p style={{ color: '#005EB8', fontSize: 16, fontFamily: "Campuni-Bold" }}><strong>Áreas</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} marginBottom={5}>
                                <Grid xs={12} style={{ marginBottom: 20 }}>
                                    <TextField
                                        style={{ height: '8px !important' }}
                                        // label="Edital"
                                        defaultValue={0}
                                        variant="standard"
                                        select
                                        className="inputSelect"
                                        onChange={(e) => {
                                            onChangeProcessoArea(e.target.value)
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem disabled value={0}>
                                            Selecione o processo
                                        </MenuItem>
                                        {processosAll && processosAll.map((res, index) =>
                                            <MenuItem value={res.id}>
                                                {`${res.titulo}`}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </Grid>
                                <Grid md={12}>
                                    <TextField
                                        id="customInput"
                                        placeholder="Buscar"
                                        fullWidth
                                        className="custom-class"
                                        onChange={(event) => {
                                            filtrarAreas(event.target.value);
                                        }}
                                        variant="standard"
                                        style={{ height: '38 !important', width: '100%', }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton onClick={() => { }}>
                                                        {svgSearch()}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid md={12} >
                                    <div
                                        className="scrollbar"
                                        id="style-3"
                                        style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                    >
                                        {areas.length == areasAll.length ? (
                                            <FormControlLabel
                                                label="Marcar todos"
                                                control={
                                                    <Checkbox
                                                        checked={checkedAreas.length === areas.length}
                                                        onChange={handleChangeAllAreas}
                                                    />
                                                }
                                            />
                                        ) : null}
                                        {childrenAreas}
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid md={12} style={{ padding: 10 }}>
                    <Accordion expanded={expanded === `panel4`} onChange={handleChange(`panel4`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4-content"
                            id="panel4-header"
                        >
                            <p style={{ color: '#005EB8', fontSize: 16, fontFamily: "Campuni-Bold" }}><strong>Formações</strong></p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} marginBottom={5}>
                                <Grid md={12}>
                                    <Grid xs={12} style={{ marginBottom: 20 }}>
                                        <TextField
                                            style={{ height: '8px !important' }}
                                            // label="Edital"
                                            defaultValue={0}
                                            variant="standard"
                                            select
                                            className="inputSelect"
                                            onChange={(e) => {
                                                onChangeProcessoFormacao(e.target.value)
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem disabled value={0}>
                                                Selecione o processo
                                            </MenuItem>
                                            {processosAll && processosAll.map((res, index) =>
                                                <MenuItem value={res.id}>
                                                    {`${res.titulo}`}
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    </Grid>
                                    <TextField
                                        id="customInput"
                                        placeholder="Buscar"
                                        fullWidth
                                        className="custom-class"
                                        onChange={(event) => {
                                            filtrarFormacoes(event.target.value);
                                        }}
                                        variant="standard"
                                        style={{ height: '38 !important', width: '100%', }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton onClick={() => { }}>
                                                        {svgSearch()}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid md={12} style={{ marginTop: 20 }}>
                                    <div
                                        className="scrollbar"
                                        id="style-3"
                                        style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                    >
                                        {formacoes.length == formacoesAll.length ? (
                                            <FormControlLabel
                                                label="Marcar todos"
                                                control={
                                                    <Checkbox
                                                        checked={checkedFormacoes.length === formacoes.length}
                                                        onChange={handleChangeAllFormacoes}
                                                    />
                                                }
                                            />
                                        ) : null}
                                        {childrenFormacoes}
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid mdOffset={9} md={3}>
                    <Button
                        style={{ width: '100%', height: 41, marginTop: 10, color: '#fff' }}
                        disabled={loading}
                        onClick={() => salvarPermissoes()}
                        endIcon={loading ? <CircularProgress size={15} style={{ color: 'white' }} /> : null}
                        color="info"
                        variant="contained">
                        Salvar
                    </Button>
                </Grid>
            </Grid>
        </Box >
    )
}