import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';

export const UsuariosItem = ({ type, item }) => {
    const navigate = useNavigate();

    return (
        <Grid style={{ cursor: 'pointer' }} container spacing={1} onClick={() => { type == 'PJ' ? navigate(`/pessoa-juridica/${item.id}`) : navigate(`/pessoa-fisica/${item.id}`) }}>
            <Grid md={9}>
                <p style={{ fontWeight: 500, color: '#005EB8', margin: 0 }}>
                    <span style={{ fontWeight: '700', fontFamily: "Univers-Medium", fontSize: 16 }}>{type == 'PJ' ? item.documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : item.documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</span>
                </p>
                <p style={{ fontWeight: 500, margin: 0 }}>
                    <span style={{ fontFamily: "Campuni-Medium", fontWeight: 500, fontSize: 16, color: '#292D32' }}>{type == 'PJ' ? item.razao_social : item.nome}</span>
                </p>
                {type == 'PJ' ? (
                    <p style={{ fontWeight: 500, margin: 0 }}>
                        <span style={{ fontFamily: "Univers-Medium", fontWeight: 500, fontSize: 16, color: '#666666' }}>{item && item.colaboradores.length} Colaboradore(s)</span>
                    </p>
                ) : null}
            </Grid>
            <Grid md={3} textAlign={'right'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => { console.log() }}
                    edge="start"
                >
                    <span style={{ fontSize: 14, marginLeft: 5, color: item.status == 'A' ? "#6BAC54" : "#FFA500", marginRight: 5 }}>{item.status == 'A' ? "Ativo" : "Inativo"}</span>
                    <CircleIcon color={item.status == 'A' ? "success" : "warning"} fontSize="10" />
                </IconButton>
            </Grid>
            <Grid md={12} xs={12}>
                <hr />
            </Grid>
        </Grid>
    )
}