import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { CircularProgress, SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Moment from 'moment';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { isMobile } from "react-device-detect";
import { useQuery } from "../hooks/useQuery";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};
export const ComunicadoItem = ({ item, atualizaLista }) => {
    const navigate = useNavigate();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [justificativa, setJustificativa] = React.useState();
    const { deleteAnuncio } = useQuery();

    const svgEdit = () => {

        return (
            <SvgIcon>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 2.125H3.54167C3.16594 2.125 2.80561 2.27426 2.53993 2.53993C2.27426 2.80561 2.125 3.16594 2.125 3.54167V13.4583C2.125 13.8341 2.27426 14.1944 2.53993 14.4601C2.80561 14.7257 3.16594 14.875 3.54167 14.875H13.4583C13.8341 14.875 14.1944 14.7257 14.4601 14.4601C14.7257 14.1944 14.875 13.8341 14.875 13.4583V8.5" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.015 1.85935C13.2968 1.57756 13.679 1.41925 14.0775 1.41925C14.476 1.41925 14.8582 1.57756 15.14 1.85935C15.4218 2.14114 15.5801 2.52334 15.5801 2.92185C15.5801 3.32037 15.4218 3.70256 15.14 3.98435L8.49935 10.625L5.66602 11.3333L6.37435 8.49998L13.015 1.85935Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }

    const svgAlert = () => {

        return (
            <SvgIcon style={{ width: 61, height: 65 }}>
                <svg width="61" height="55" viewBox="0 0 61 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M60.3135 46.5986C62.2682 49.9869 59.8146 54.2222 55.9103 54.2222H5.08908C1.17735 54.2222 -1.26498 49.9803 0.685963 46.5986L26.0969 2.54008C28.0526 -0.849763 32.9509 -0.843621 34.9031 2.54008L60.3135 46.5986ZM30.5 37.4896C27.8096 37.4896 25.6285 39.6707 25.6285 42.3611C25.6285 45.0516 27.8096 47.2326 30.5 47.2326C33.1905 47.2326 35.3716 45.0516 35.3716 42.3611C35.3716 39.6707 33.1905 37.4896 30.5 37.4896ZM25.8749 19.979L26.6605 34.3818C26.6973 35.0557 27.2545 35.5833 27.9294 35.5833H33.0706C33.7455 35.5833 34.3028 35.0557 34.3395 34.3818L35.1251 19.979C35.1648 19.251 34.5852 18.6389 33.8562 18.6389H27.1438C26.4147 18.6389 25.8352 19.251 25.8749 19.979Z" fill="#CCCCCC" />
                </svg>
            </SvgIcon>
        )
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    }

    const excluirComunicado = async (id) => {
        setLoading(true);
        const result = await deleteAnuncio(id);
        if (result && result.data) {
            atualizaLista();
        }
        setLoading(false);
    }

    return (
        <Grid container spacing={1}>
            <Grid md={8}>
                <p style={{ paddingLeft: isMobile ? 0 : 10, margin: 0, fontWeight: 500 }}>
                    <span style={{ fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>{item.titulo}</span>
                </p>
                <span style={{ fontFamily: "Univers-Medium", marginLeft: isMobile ? 0 : 10, fontSize: 14 }}>Criado em: {Moment(item.created_at).format('DD/MM/YYYY')} ás {Moment(item.created_at).format('HH:mm')}</span>
            </Grid>
            <Grid md={2} textAlign={'right'}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => { navigate(`/criar-comunicado/${item.id}`) }}
                    edge="start"
                >
                    {svgEdit()}
                    <span style={{ fontSize: 14, marginLeft: 5 }}>Alterar</span>
                </IconButton>
            </Grid>
            <Grid md={2} textAlign={'right'}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => { setOpenConfirm(true) }}
                    edge="start"
                >
                    {<DeleteOutlineIcon style={{ color: '#666666 ' }} />}
                    <span style={{ fontSize: 14, marginLeft: 5, color: '#666666' }}>Excluir</span>
                </IconButton>
            </Grid>
            <Grid md={12} xs={12}>
                <hr />
            </Grid>
            <Modal
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12} display={'flex'} justifyContent={'center'} marginTop={5}>
                            {svgAlert()}
                        </Grid>
                        <Grid md={12}>
                            <p style={{ fontSize: 18, color: '#666666', fontWeight: '500', textAlign: 'center' }}>Tem certeza que deseja excluir este comunicado?</p>
                        </Grid>
                        {/* <Grid md={12}>
                            <TextField
                                style={{ marginTop: '5px' }}
                                margin="normal"
                                value={justificativa}
                                onChange={(event) => { setJustificativa(event.target.value) }}
                                multiline
                                rows={3}
                                fullWidth
                                label="Justificativa"
                            />
                        </Grid> */}
                        <Grid md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <IconButton
                                color="inherit"
                                style={{ height: 24, width: 100 }}
                                aria-label="open drawer"
                                onClick={() => setOpenConfirm(false)}
                                edge="start"
                            >
                                <span style={{ fontSize: 12, color: '#F4455A', fontWeight: '700' }}>Cancelar</span>
                            </IconButton>
                            <Button
                                onClick={() => {
                                    excluirComunicado(item.id);
                                }}
                                style={{ width: '100px', height: 41 }}
                                variant="contained"

                            >
                                {loading ? (
                                    <CircularProgress size={20} />
                                ) : null}

                                Excluir
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
        </Grid>
    )
}