import * as React from "react";
import { IconButton, SvgIcon } from '@mui/material';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import { useForm, Controller } from "react-hook-form";

const TIPO_ARQUIVOS_PERMITIDOS = [
    'pdf',
];


export const DocumentoFormacaoItem = ({ docA, documentosEnviados, buscarDocumentosFormacao }) => {

    const inputFile = React.useRef(null);
    const url = process.env.REACT_APP_API_BASE_URL;
    const [selectFormacao, setSelectFormacao] = React.useState();
    const {
        control,
        setValue,
        formState: { errors },
    } = useForm();

    React.useEffect(() => {
        if (documentosEnviados) {
            let find = documentosEnviados && documentosEnviados.areas && documentosEnviados.areas[docA[0].area.nome] && documentosEnviados.areas[docA[0].area.nome].find(d => d.formacao_id != null);
            if (find) {
                setSelectFormacao(find.formacao_id);
                buscarDocumentosFormacao(find.formacao_id);
            }
        }
    }, [documentosEnviados])

    React.useEffect(() => {
        console.log(selectFormacao);
        setValue('formacao', selectFormacao);
    }, [selectFormacao])

    const svgAnexo = () => {

        return (
            <SvgIcon style={{ width: 15, height: 14 }}>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.54449 16.3878C-0.542293 14.2683 -0.503401 10.8503 1.59399 8.73848L9.08542 1.19533C10.6681 -0.398373 13.2408 -0.398513 14.8237 1.19533C16.3912 2.77363 16.3931 5.32017 14.8237 6.90034L8.29335 13.4693C7.2271 14.5429 5.48503 14.5279 4.43685 13.4343C3.42703 12.3806 3.45942 10.7106 4.4887 9.6742L9.62238 4.51203C9.84317 4.29006 10.205 4.28626 10.4305 4.50356L11.2469 5.29032C11.4725 5.50766 11.4763 5.86379 11.2555 6.08577L6.12238 11.2474C5.94624 11.4248 5.93538 11.7195 6.09924 11.8905C6.25538 12.0534 6.50085 12.0561 6.65952 11.8963L13.1898 5.32734C13.8903 4.62204 13.8903 3.47373 13.1895 2.76804C12.5041 2.07803 11.4053 2.07768 10.7197 2.76804L3.22821 10.3112C1.98667 11.5613 1.96753 13.5849 3.18567 14.8222C4.40031 16.0559 6.3637 16.0574 7.5806 14.8322L13.7256 8.64475C13.9462 8.4226 14.308 8.41859 14.5337 8.63575L15.3507 9.42198C15.5764 9.63914 15.5805 9.99528 15.3598 10.2174L9.21485 16.4049C7.08713 18.5472 3.64545 18.5218 1.54449 16.3878Z" fill="#999999" />
                </svg>
            </SvgIcon>
        )
    }


    return (
        <>
            <Grid md={12} marginTop={2}>
                <span
                    onClick={() => setValue('formacao', 6)}
                    style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 14, fontWeight: 700, fontFamily: "AlegraSans-Medium" }}
                >
                    {'Selecione a sua formação'}
                </span>
                <form>
                    <Controller
                        name="formacao"
                        id="formcao"
                        defaultValue={selectFormacao ? selectFormacao : 0}
                        control={control}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <TextField
                                value={value}
                                style={{ height: 38, marginTop: 5 }}
                                label="Formações"
                                select
                                className="inputSelect"
                                onChange={(e) => {
                                    onChange(e);
                                    buscarDocumentosFormacao(e.target.value);
                                    setSelectFormacao(e.target.value);
                                }}
                                fullWidth
                            >
                                <MenuItem value={0}>
                                    Selecione
                                </MenuItem>
                                {docA && Array.isArray(docA) && docA.length > 0 && docA[0].area.area_formacoes && docA[0].area.area_formacoes.map((res, index) =>
                                    <MenuItem value={res.formacao_id}>
                                        {`${res.formacao && res.formacao.nome}`}
                                    </MenuItem>
                                )}
                            </TextField>
                        )}
                    />
                </form>
            </Grid>
        </>
    )
}