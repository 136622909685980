import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { Pagination } from '@mui/material';
import { EditalItem } from "../../components/EditalItem";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { isMobile } from "react-device-detect";
import { DemandaItem } from "../../components/DemandaItem";
import 'dayjs/locale/pt-br';
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../../hooks/useAuth";
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { styled } from '@mui/material/styles';
export const DemandasUsuarioPage = () => {

    const navigate = useNavigate();
    const { user } = useAuth();
    const { listarDemandas } = useQuery();
    const [contratos, setContratos] = React.useState([]);
    const [demandas, setDemandas] = React.useState([]);
    const [history, setHistory] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingMore, setLoadingMore] = React.useState(false);
    const [dataInicio, setDataInicio] = React.useState();
    const [dataFim, setDataFim] = React.useState();
    const [page, setPage] = React.useState(1);
    const [endInfinitScroll, setEndInfinitScroll] = React.useState(true);
    const [statusFilter, setStatusFilter] = React.useState();
    const [search, setSearch] = React.useState();
    const [valueTab, setValueTab] = React.useState("1");
    const svgComnunicado = () => {
        return (
            <SvgIcon>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41602 13.4583V3.54167C1.41602 3.16594 1.56527 2.80561 1.83095 2.53993C2.09662 2.27426 2.45696 2.125 2.83268 2.125H14.166C14.5417 2.125 14.9021 2.27426 15.1678 2.53993C15.4334 2.80561 15.5827 3.16594 15.5827 3.54167V13.4583C15.5827 13.8341 15.4334 14.1944 15.1678 14.4601C14.9021 14.7257 14.5417 14.875 14.166 14.875H2.83268C2.45696 14.875 2.09662 14.7257 1.83095 14.4601C1.56527 14.1944 1.41602 13.8341 1.41602 13.4583Z" stroke="white" strokeWidth="1.5" />
                    <path d="M1.41602 4.95831H15.5827M6.37435 9.91665H8.49935M8.49935 9.91665H10.6243M8.49935 9.91665V7.79165M8.49935 9.91665V12.0416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }


    React.useEffect(() => {
        // listar('09076987000100');
    }, [])

    const busca = async (status) => {
        listar(1, true, false, status);
    }

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const listar = async (pagina = 1, isSerch = false, clear = false, status = undefined) => {
        // const url = `https://picsum.photos/v2/list?page=${page}&limit=10
        setLoadingMore(true);
        setPage(pagina);

        let filters = "";
        if (status) {
            filters += `&status=${status}`;
        }

        if (search) {
            filters += `&buscar=${search}`;
        }

        try {
            const result = await listarDemandas(`page=${pagina}&colaboradorid=${user.colaborador_id}${clear ? '' : filters}`);
            if (isSerch) {
                setDemandas(result.data.data.open);
                setHistory(result.data.data.history);
            } else {
                setDemandas([...demandas, ...result.data.data.open]);
                setHistory([...history, ...result.data.data.history]);
            }


            setLoadingMore(false);

        } catch (error) {
            setEndInfinitScroll(false);
        }
    };


    React.useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            busca();
        }, 500);
        return () => clearTimeout(delayInputTimeoutId);
    }, [search, 500]);

    const UserTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        fontSize: '18px !important',
    }));

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={1} paddingX="0">
                <Grid md={12} xs={12}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>Demandas</span>
                </Grid>
                <Grid md={6} xs={12} style={{ marginBottom: 20 }}>
                    <TextField
                        id="customInput"
                        placeholder="Busque por evento, turma ou cidade"
                        fullWidth
                        value={search}
                        className="custom-class"
                        onChange={(event) => {
                            setSearch(event.target.value);
                        }}
                        variant="standard"
                        style={{ height: '38 !important', width: isMobile ? '100%' : 436 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => { busca() }}>
                                        {svgSearch()}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid md={4} xs={12}>
                    <TextField
                        value={statusFilter}
                        style={{ height: 40, marginTop: 20, width: '100%' }}
                        label="Status"
                        select
                        className="inputSelect"
                        onChange={(e) => {
                            setStatusFilter(e.target.value);
                            busca(e.target.value);
                        }}
                        fullWidth
                    >
                        <MenuItem disabled value={0}>
                            Selecione o status
                        </MenuItem>
                        <MenuItem value={'Selecionado'}>
                            {`Selecionado`}
                        </MenuItem>
                        <MenuItem value={'Não Selecionado'}>
                            {`Não Selecionado`}
                        </MenuItem>
                        <MenuItem value={'Indicado'}>
                            {`Indicado`}
                        </MenuItem>
                        <MenuItem value={'Inapto'}>
                            {`Inapto`}
                        </MenuItem>
                        <MenuItem value={'Manifestou o interesse'}>
                            {`Manifestou o interesse`}
                        </MenuItem>
                        <MenuItem value={'Não Manifestou'}>
                            {`Não Manifestou`}
                        </MenuItem>
                        <MenuItem value={'Demanda recusada'}>
                            {`Demanda recusada`}
                        </MenuItem>
                        <MenuItem value={'Cancelado'}>
                            {`Cancelado`}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid md={2} xs={12}>
                    <Button
                        onClick={() => {
                            setLoading(true);
                            setSearch("");
                            setStatusFilter(undefined);
                            setDataFim(null)
                            setDataInicio(null);
                            setTimeout(() => {
                                listar(1, true, true);
                                setLoading(false)
                            }, 1000)

                        }}
                        color="primary"
                        style={{ height: 42, width: '100%', marginTop: 20 }}
                        variant="contained"
                    >
                        Limpar filtros    {loading ? (
                            <CircularProgress style={{ color: 'white', marginLeft: 5 }} size={20} />
                        ) : null}
                    </Button>
                </Grid>
                <Grid marginTop={5} md={12} xs={12}>
                    <TabContext value={valueTab}>
                        <TabList value={valueTab} onChange={handleChangeTab} aria-label="lab API tabs example">
                            <UserTab label="Abertas" value="1" />
                            <UserTab label="Historico" value="2" />
                        </TabList>
                        <TabPanel value="1">
                            <Grid container spacing={2}>
                                {demandas && demandas.map(demanda => (
                                    <Grid item md={12} style={{ width: '100%', cursor: 'pointer' }} key={`aberta${demanda.id}`}>
                                        <DemandaItem demanda={demanda} />
                                    </Grid>
                                ))}
                                {demandas && demandas.length === 0 ? (
                                    <Grid md={12}>
                                        <p style={{ width: '100%', textAlign: 'center' }}>Nenhuma demanda.</p>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={2}>
                                {history && history.map(demanda => (
                                    <Grid item md={12} style={{ width: '100%', cursor: 'pointer' }} key={`history${demanda.id}`}>
                                        <DemandaItem demanda={demanda} />
                                    </Grid>
                                ))}
                                {history && history.length === 0 ? (
                                    <Grid md={12}>
                                        <p style={{ width: '100%', textAlign: 'center' }}>Nenhuma demanda.</p>
                                    </Grid>
                                ) : null}
                                <Grid md={12} style={{ width: '100%' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Button
                                        style={{ width: 'auto', height: 41, marginTop: 10, color: '#fff' }}
                                        disabled={loadingMore}
                                        type="submit"
                                        endIcon={loadingMore ? <CircularProgress size={15} style={{ color: 'white' }} /> : null}
                                        color="info"
                                        onClick={() => listar(page + 1)}
                                        variant="contained">
                                        Carregar mais
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>

                    {/* </InfiniteScroll> */}

                </Grid>
            </Grid>
        </Box >
    )
}