import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Sebrae from '../../assets/sebrae.png';
import Logo from '../../assets/logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
export const Footer = () => {

    return (
        <section style={{ width: '100%', margin: 0 }}>
            <Grid container paddingY="2rem" paddingX="4rem">
                <Grid className={'footerResp'} md={4} xs={12}>
                    <Box className={'textCenter'}>
                        <span style={{ color: '#025eb8', fontWeight: 400, padding: '5px 0px' }} >Conheça o Sebrae Minas</span>
                    </Box>
                    <Box className={'textCenter'}>
                        <span style={{ color: '#025eb8', fontWeight: 400, padding: '5px 0px' }} >Agência Sebrae de Notícias</span>
                    </Box>
                    <Box style={{ cursor: 'pointer' }} className={'textCenter'}>
                        <span style={{ color: '#025eb8', fontWeight: 400, padding: '5px 0px' }} onClick={() => window.open('https://loja.sebraemg.com.br/', '_blank')}>Loja Sebrae Minas</span>
                    </Box>
                    <Box style={{ cursor: 'pointer' }} className={'textCenter'}>
                        <span style={{ color: '#025eb8', fontWeight: 400, padding: '5px 0px' }} onClick={() => window.location = 'mailto:credenciamento@sebraemg.com.br'} >Contato</span>
                    </Box>
                    <Box style={{ cursor: 'pointer' }} className={'textCenter'}>
                        <span style={{ color: '#025eb8', fontWeight: 400, padding: '5px 0px' }} onClick={() => window.open('https://sebrae.com.br/sites/PortalSebrae/faleconosco#:~:text=Mas%20n%C3%A3o%20se%20preocupe%2C%20voc%C3%AA,570%200800%20para%20maiores%20orienta%C3%A7%C3%B5es.', '_blank')}>SAC</span>
                    </Box>
                    <Box className={'textCenter'}>
                        <span style={{ color: '#025eb8', fontWeight: 400, padding: '5px 0px' }} >Termos de uso</span>
                    </Box>
                    <Box className={'textCenter'}>
                        <span style={{ color: '#025eb8', fontWeight: 400, padding: '5px 0px' }} >Politica de privacidade</span>
                    </Box>

                </Grid>
                <Grid className={'footerResp'} md={4} xs={12} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img alt="" width={97} height={52} src={Sebrae} />
                    </Box>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <FacebookIcon style={{ color: '#025eb8' }} />
                        <InstagramIcon style={{ color: '#025eb8' }} />
                        <LinkedInIcon style={{ color: '#025eb8' }} />
                        <TwitterIcon style={{ color: '#025eb8' }} />
                        <YouTubeIcon style={{ color: '#025eb8' }} />
                    </Box>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <span style={{ color: '#025eb8', fontWeight: 400 }} >Central de Atendimento:</span>
                    </Box>
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <span style={{ color: '#025eb8', fontWeight: 400 }} >0800 570 0800</span>
                    </Box>
                </Grid>
                <Grid className={'footerResp'} md={4} xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img alt="" width={255} height={76} src={Logo} />
                </Grid>
            </Grid>
        </section>
    )

}