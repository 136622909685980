import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useQuery } from "../../hooks/useQuery";
import { Pagination } from '@mui/material';
import { useSnackbar } from "../../hooks/useSnackbar";
import { CircularProgress } from "@mui/material";
import { ComunicadoUsuarioItem } from "../../components/ComunicadoUsuarioItem";
import { useAuth } from "../../hooks/useAuth";

const mock = {
    titulo: 'teste',
    imagem: 'teste.png',
    processos: [
        { status: 'A' }
    ]
}

export const ComunicadosUsuarioPage = () => {

    const navigate = useNavigate();
    const { user } = useAuth();
    const snackbar = useSnackbar();
    const [searchParams] = useSearchParams();
    const { listaEditais, listarComuicadosUser } = useQuery();
    const [valueTab, setValueTab] = React.useState("1");
    const [comunicados, setComunicados] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(0);

    React.useEffect(() => {
        if (searchParams.get('tab')) {
            setValueTab(searchParams.get('tab'))
        }
        if (user) {
            getComunicados(1, undefined);
        }
    }, []);

    const handleChangeTab = (event, newValue) => {
        setComunicados([]);
        setValueTab(newValue);
        setPage(1);
        console.log(newValue)
        switch (newValue) {
            case '1':
                getComunicados(1, undefined);
                break;
            case '2':
                getComunicados(1, 0);
                break;
            case '3':
                getComunicados(1, 1);
                break;

            default:
                break;
        }

    };

    const UserTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        fontSize: '18px !important',
    }));

    const getComunicados = async (page = 1, lido = undefined) => {
        setLoading(true)
        let query = {};

        if (lido === undefined) {
            query = { page: page, userId: user.user_id };
        } else {
            query = { page: page, lido: lido, userId: user.user_id };
        }
        const result = await listarComuicadosUser(query);
        if (result && result.data && result.data.data) {
            setComunicados(result.data.data.data);
            setPage(result.data.data.current_page);
            setLastPage(result.data.data.last_page);
            setTotal(result.data.data.total);
        }
        setLoading(false);
    }

    const handleChange = (event, value) => {
        setPage(value);
        switch (valueTab) {
            case '1':
                getComunicados(value, '');
                break;
            case '2':
                getComunicados(value, 'P');
                break;
            case '3':
                getComunicados(value, 'A');

            default:
                break;
        }
    };

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>Comunicados</span>
                </Grid>
                <Grid md={12} xs={12}>
                    <TabContext value={valueTab}>
                        <TabList value={valueTab} onChange={handleChangeTab} aria-label="lab API tabs example">
                            <UserTab label="Todos" value="1" />
                            <UserTab label="Não lidos" value="2" />
                            <UserTab label="Lidos" value="3" />
                        </TabList>
                        <TabPanel value="1">
                            {loading ? (<CircularProgress />) : (
                                <Grid container spacing={2}>
                                    {comunicados && comunicados.map((edital, index) => {
                                        return (
                                            <Grid md={12}>
                                                <ComunicadoUsuarioItem item={edital} refresh={getComunicados} status={null} />
                                            </Grid>
                                        )
                                    })}
                                    {comunicados && comunicados.length === 0 ? (
                                        <Grid md={12}>
                                            <p style={{ width: '100%', textAlign: 'center' }}>Nenhum comunicado.</p>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            )}

                            <Box
                                marginTop={10}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pagination
                                    className="customPagination"
                                    variant="outlined"
                                    shape="rounded"
                                    count={lastPage}
                                    page={page}
                                    color={'primary'}
                                    onChange={handleChange}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            {loading ? (<CircularProgress />) : (
                                <Grid container spacing={2}>
                                    {comunicados && comunicados.map((edital, index) => {
                                        return (
                                            <Grid md={12}>
                                                <ComunicadoUsuarioItem item={edital} refresh={getComunicados} status={0} />
                                            </Grid>
                                        )
                                    })}

                                    {comunicados && comunicados.length === 0 ? (
                                        <Grid md={12}>
                                            <p style={{ width: '100%', textAlign: 'center' }}>Nenhum comunicado.</p>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            )}
                            <Box
                                marginTop={10}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pagination
                                    className="customPagination"
                                    variant="outlined"
                                    shape="rounded"
                                    count={lastPage}
                                    page={page}
                                    color={'primary'}
                                    onChange={handleChange}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            {loading ? (<CircularProgress />) : (
                                <Grid container spacing={2}>
                                    {comunicados && comunicados.map((edital, index) => {
                                        return (
                                            <Grid md={12}>
                                                <ComunicadoUsuarioItem item={edital} refresh={getComunicados} status={1} />
                                            </Grid>
                                        )
                                    })}
                                    {comunicados && comunicados.length === 0 ? (
                                        <Grid md={12}>
                                            <p style={{ width: '100%', textAlign: 'center' }}>Nenhum comunicado.</p>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            )}
                            <Box
                                marginTop={10}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Pagination
                                    className="customPagination"
                                    variant="outlined"
                                    shape="rounded"
                                    count={lastPage}
                                    page={page}
                                    color={'primary'}
                                    onChange={handleChange}
                                />
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Box >
    )
}