import * as React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2';
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "../../hooks/useQuery";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import { validaCpf } from "../../utils";
import SaveIcon from '@mui/icons-material/Save';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useAuth } from "../../hooks/useAuth";

export const CadastrarColaborador = () => {

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors },
    } = useForm();
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { cadastroStep3, getColaborador, deletarColaborador, showCadastroFornecedor } = useQuery();
    const [colaboradores, setColaboradores] = React.useState();
    const [colaborador, setColaborador] = React.useState();
    const [mask, setMask] = React.useState('(99) 99999-9999');
    const [mask2, setMask2] = React.useState('(99) 99999-9999');
    const [radioValue, setRadioValue] = React.useState('nao');
    const [checkRepresentanteLegal, setCheckRepresentanteLegal] = React.useState(false);
    const [radioValueTipo, setRadioValueTipo] = React.useState('clt');
    const [showPassword, setShowPassword] = React.useState(true);

  
    React.useEffect(() => {
        if (user && user.fornecedor_id) {
            preencherFormulario( user.fornecedor_id);
        }
    }, []);

    const preencherFormulario = async (id) => {
        try {
            const result = await showCadastroFornecedor(id);

            if (result && result.data && result.data.data) {
                // setFornecedor(result.data.data);
                setColaboradores(result.data.data.colaboradores);
            }
        } catch (error) {
            //   navigate('/dashboard/listar-cadastros');
        }
    };

    const onSubmit = (data) => {
        data.representante_legal = checkRepresentanteLegal ? 1 : 0;
        data.fornecedor_id = user.fornecedor_id;
        data.tipo = radioValueTipo;
        if (colaborador && colaborador.id) {
            data.id = colaborador.id;
        }
        cadastrarColaborador(data);
        console.log(data);
    };

    const onErrors = (error) => {

    }

    const cadastrarColaborador = async (data) => {
        try {
            // if (data.senha !== data.confirmar_senha) {
            //     snackbar.showSnackbar('Senhas nao conferem.', '#DC143C');
            //     return;
            // }
            data.documento = data.documento.replace(/\D/g, '');
            const result = await cadastroStep3(data);
            console.log(result);
            if (result && result.data && result.data.data) {
                setColaboradores(result.data.data);
                reset({
                    nome: '',
                    nome_social: '',
                    documento: '',
                    rg: '',
                    orgao_emissor: '',
                    cargo: '',
                    email: '',
                    telefone: '',
                    telefone_adicional: '',
                    // senha: '',
                    // confirmar_senha: ''
                });
                setColaborador(undefined);
                setCheckRepresentanteLegal(false);
            }
        } catch (error) {
            snackbar.showSnackbar(error.response.data.message, '#DC143C');
        }
    };

    const editColaborador = async (id) => {
        console.log(id);
        const result = await getColaborador(id);
        if (result && result.data && result.data.data) {
            const ColaboradorRes = result.data.data;
            setColaborador(ColaboradorRes);
            setValue('nome', ColaboradorRes.nome);
            setValue('cargo', ColaboradorRes.cargo);
            setValue('documento', ColaboradorRes.documento);
            setValue('rg', ColaboradorRes.rg);
            setValue('orgao_emissor', ColaboradorRes.orgao_emissor);
            setValue('email', ColaboradorRes.email);
            setValue('telefone', ColaboradorRes.telefone);
            setValue('telefone_adicional', ColaboradorRes.telefone_adicional);
            setRadioValueTipo(ColaboradorRes.tipo);
            setCheckRepresentanteLegal(
                ColaboradorRes.representante_legal === 1 ? true : false,
            );
        }
    };

    const deleteColaborador = async (id) => {
        const result = await deletarColaborador(id);
        if (result && result.data && result.data.data) {
            // setFornecedor(result.data.data);
            setColaboradores(result.data.data.colaboradores);
        }
    };

    const onChangeDocument = async (e) => {
        // if (e.target.value.replace(/\D/g, '').length == 11) {
        //     const response = await getCustomer(e.target.value.replace(/\D/g, ''));
        //     const result = await response.json();

        //     if (result && result.data && result.data.id) {
        //         setValue('nome', result.data.name);
        //         setValue('telefone', result.data.phone);
        //         setValue('email', result.data.email);
        //     }
        // }
    };

    const changeNomeSocial = (value) => {
        setRadioValue(value);
    }

    const handleCheck = (event) => {
        setCheckRepresentanteLegal(event.target.checked);
    };


    const changeTipo = (value) => {
        console.log(value);
        setRadioValueTipo(value);
    };


    return (
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <Grid container spacing={2}>
                <Grid md={11} xs={12}>
                    <Grid container>
                        <>
                            <Grid container sm={12} >
                                <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 500 }}><b>Cadastre os representantes/colaboradores de sua empresa</b></p>
                            </Grid>
                            <Grid md={12}>
                                <Alert severity="info" style={{ fontWeight: 500, fontFamily: 'AlegraSans' }}>Para os colaboradores/representantes adicionados será necessário vínculo através de: Contrato, CTPS, declaração, ata ou procuração </Alert>
                            </Grid>

                            <Grid md={3}>
                                <Controller
                                    name="documento"
                                    rules={{
                                        required: 'Campo é obrigatório',
                                        validate: (value) => {
                                            return validaCpf(value)
                                        }
                                    }}
                                    control={control}
                                    defaultValue={""}
                                    mask="999.999.999-99"
                                    render={({
                                        field: { onChange, onBlur, ref, value },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            style={{ marginTop: '5px' }}
                                            mask="999.999.999-99"
                                            maskChar={''}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(event) => {
                                                onChange(event);
                                                onChangeDocument(event);
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    margin="normal"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    fullWidth
                                                    {...inputProps}
                                                    id="cpf"
                                                    label="CPF"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                                {errors.documento && errors.documento.type === "validate" && <small style={{ color: 'red' }}>CPF Inválido</small>}
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="nome"
                                    rules={{
                                        required: 'Campo é obrigatório'
                                    }}
                                    defaultValue={""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Nome"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="rg"
                                    rules={{
                                        required: 'Campo é obrigatório'
                                    }}
                                    control={control}
                                    defaultValue={""}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="RG"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="orgao_emissor"
                                    rules={{
                                        required: 'Campo é obrigatório'
                                    }}
                                    defaultValue={""}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Órgão Emissor"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="cargo"
                                    rules={{
                                        required: 'Campo é obrigatório'
                                    }}
                                    control={control}
                                    defaultValue={""}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Cargo"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: 'Campo é obrigatório',
                                        pattern: {
                                            value:
                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: 'Insira um e-mail válido',
                                        },
                                    }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            style={{ marginTop: '5px' }}
                                            margin="normal"
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="E-mail"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="telefone"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: 'Telefone é obrigatório' }}
                                    mask={mask}
                                    render={({
                                        field: { onChange, ref, value },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            style={{ marginTop: '5px' }}
                                            mask={mask}
                                            maskChar={'_'}
                                            onBlur={(e) => {
                                                const v = e.target.value.replace(/\D/g, '');
                                                if (v.length === 10) {
                                                    setMask('(99) 9999-9999');
                                                } else {
                                                    setMask('(99) 99999-9999');
                                                }
                                            }}
                                            onFocus={(e) => {
                                                setMask('(99) 99999-9999');
                                            }}
                                            onChange={onChange}
                                            value={value}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    margin="normal"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    fullWidth
                                                    {...inputProps}
                                                    id="telefone"
                                                    label="Telefone1"
                                                    placeholder="Digite o telefone com DDD"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            <Grid md={3}>
                                <Controller
                                    name="telefone_adicional"
                                    control={control}
                                    defaultValue={""}
                                    mask={mask}
                                    render={({
                                        field: { onChange, ref, value },
                                        fieldState: { error },
                                    }) => (
                                        <InputMask
                                            style={{ marginTop: '5px' }}
                                            mask={mask2}
                                            maskChar={'_'}
                                            onBlur={(e) => {
                                                const v = e.target.value.replace(/\D/g, '');
                                                if (v.length === 10) {
                                                    setMask2('(99) 9999-9999');
                                                } else {
                                                    setMask2('(99) 99999-9999');
                                                }
                                            }}
                                            onFocus={(e) => {
                                                setMask2('(99) 99999-9999');
                                            }}
                                            onChange={onChange}
                                            value={value}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    margin="normal"
                                                    error={error}
                                                    helperText={error && error.message}
                                                    fullWidth
                                                    {...inputProps}
                                                    label="Telefone2"
                                                    id="telefone2"
                                                    placeholder="Digite o telefone com DDD"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            {/* <Grid md={4}>
                                <Controller
                                    name="senha"
                                    control={control}
                                    rules={{
                                        required: 'Senha é obrigatório',
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            margin="normal"
                                            type={!showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            onMouseUp={handleMouseUpPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),

                                            }}
                                            value={value}
                                            error={error}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Senha"
                                        />

                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                <Controller
                                    name="confirmar_senha"
                                    control={control}
                                    rules={{
                                        required: 'Confirmar senha é obrigatório',
                                    }}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            margin="normal"
                                            type={!showPassword ? 'text' : 'password'}
                                            value={value}
                                            error={error}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            onMouseUp={handleMouseUpPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),

                                            }}
                                            helperText={error && error.message}
                                            onChange={onChange}
                                            fullWidth
                                            label="Confirmar senha"
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid md={12}>
                                <label style={{ color: '#005EB8', fontSize: 18, fontWeight: 'bold' }}>Deseja informar o nome social ?</label>
                                <Controller
                                    name="socialChoice"
                                    control={control}
                                    defaultValue="sim"
                                    render={({ value }) => (
                                        <RadioGroup
                                            row
                                            value={value}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            onChange={(e) => {
                                                changeNomeSocial(e.target.value);
                                            }}
                                        >
                                            <FormControlLabel
                                                checked={radioValue === 'sim'}
                                                value="sim"
                                                control={<Radio />}
                                                label="Sim"
                                            />
                                            <FormControlLabel
                                                checked={radioValue === 'nao'}
                                                value="nao"
                                                control={<Radio />}
                                                label="Não"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </Grid>
                            <Grid md={4}>
                                {radioValue === 'sim' ? (
                                    <Controller
                                        name="nome_social"
                                        defaultValue={""}
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                style={{ marginTop: '5px' }}
                                                margin="normal"
                                                value={value}
                                                error={error}
                                                helperText={error && error.message}
                                                onChange={onChange}
                                                fullWidth
                                                label="Nome Social"
                                            />
                                        )}
                                    />
                                ) : null}
                            </Grid>
                            <Grid md={8}></Grid>
                            <Grid md={12}>
                                <FormControlLabel
                                    style={{ paddingLeft: '5px' }}
                                    control={
                                        <Checkbox
                                            checked={checkRepresentanteLegal}
                                            onChange={handleCheck}
                                            style={{ padding: '4px' }}
                                        />
                                    }
                                    label={'Cadastrar como representante legal'}
                                />
                            </Grid>

                            <Grid md={12}>
                                <Controller
                                    name="tipo"
                                    control={control}
                                    defaultValue="clt"
                                    rules={{ required: 'Tipo required' }}
                                    render={({ name, onBlur, onChange, value }) => (
                                        <RadioGroup
                                            row
                                            value={value}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            onChange={(e) => {
                                                changeTipo(e.target.value);
                                            }}
                                        >
                                            <FormControlLabel
                                                checked={radioValueTipo === 'clt'}
                                                value="clt"
                                                control={<Radio />}
                                                label="CLT"
                                            />
                                            <FormControlLabel
                                                checked={radioValueTipo === 'socio'}
                                                value="socio"
                                                control={<Radio />}
                                                label="Sócio"
                                            />
                                            <FormControlLabel
                                                checked={radioValueTipo === 'preposto'}
                                                value="preposto"
                                                control={<Radio />}
                                                label="Preposto"
                                            />
                                            <FormControlLabel
                                                checked={radioValueTipo === 'outros'}
                                                value="outros"
                                                control={<Radio />}
                                                label="Terceirizado"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </Grid>
                            <Grid md={6}>
                                <Button type="submit" style={{ width: 'auto', height: 41, marginTop: 10, color: '#fff' }} color="info" variant="contained" startIcon={<SaveIcon />}>
                                    {colaborador && colaborador.id ? (
                                        'Salvar'
                                    ) : (
                                        <span>
                                            Inserir{' '}
                                            <small style={{ marginLeft: 5 }}>
                                                (colaborador ou representante)
                                            </small>
                                        </span>
                                    )}
                                </Button>
                            </Grid>
                            <Grid md={6}></Grid>
                            {
                                colaboradores &&
                                colaboradores.length > 0 &&
                                colaboradores.map((res, index) => {
                                    return (
                                        <Grid md={12}>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid rgb(0, 102, 158)',
                                                    background: '#F9F9F9',
                                                    borderRadius: 10,
                                                    padding: 20,
                                                    justifyContent: 'space-between',
                                                    display: 'flex',
                                                }}
                                            >
                                                <div className="">
                                                    <span
                                                        style={{ color: 'rgb(0, 102, 158)', marginRight: 20 }}
                                                    >
                                                        Cadastro{index + 1}
                                                    </span>
                                                    <span>{res && res.nome}</span>
                                                    <span style={{ marginLeft: 20 }}>
                                                        {res && res.documento}
                                                    </span>
                                                    <span style={{ marginLeft: 20 }}>{res && res.email}</span>
                                                    {res && res.representante_legal ? (
                                                        <span style={{ marginLeft: 20 }}>
                                                            <b style={{ fontWeight: 700 }}>Representante Legal</b>
                                                        </span>
                                                    ) : null}
                                                    <span style={{ marginLeft: 20 }}>{res && res.tipo == 'outros' ? 'terceirizado' : res.tipo}</span>
                                                </div>
                                                <div>
                                                    <EditIcon
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => editColaborador(res && res.id)}
                                                        color="primary"
                                                    />
                                                    <DeleteOutlinedIcon
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => deleteColaborador(res && res.id)}
                                                        color="error"
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    );
                                })
                            }
                        </>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}   