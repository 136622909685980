import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from '../hooks/useCountdown';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import LineWhite from '../assets/Line.png';

const ExpiredNotice = () => {
    return (
        <div className="expired-notice">
            <span>Expired!!!</span>
            <p>Please select a future date and time.</p>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <div className='background-countdown' style={{ minWidth: 472, maxWidth: 472 }}>
            <Grid container spacing={1}>
                <Grid xs={3} justifyContent={'center'} display={'flex'} flexWrap={'wrap'}>
                    <p style={{ color: '#fff', fontSize: 40, margin: 0, width: '100%', textAlign: 'center' }}>{days}</p>
                    <span style={{ color: '#fff', fontSize: 18, fontFamily: 'Campuni-Light' }}>Dias</span>
                    <img src={LineWhite} style={{ position: 'absolute', height: 60, marginLeft: 110, marginTop: 5 }} />
                </Grid>
                <Grid xs={3} justifyContent={'center'} display={'flex'} flexWrap={'wrap'}>
                    <p style={{ color: '#fff', fontSize: 40, margin: 0, width: '100%', textAlign: 'center' }}> {hours}</p>
                    <span style={{ color: '#fff', fontSize: 18, fontFamily: 'Campuni-Light' }}>Horas</span>
                    <img src={LineWhite} style={{ position: 'absolute', height: 60, marginLeft: 110, marginTop: 5 }} />
                </Grid>
                <Grid xs={3} justifyContent={'center'} display={'flex'} flexWrap={'wrap'}>
                    <p style={{ color: '#fff', fontSize: 40, margin: 0, width: '100%', textAlign: 'center' }}>{minutes}</p>
                    <span style={{ color: '#fff', fontSize: 18, fontFamily: 'Campuni-Light' }}>Minutos</span>
                    <img src={LineWhite} style={{ position: 'absolute', height: 60, marginLeft: 110, marginTop: 5 }} />
                </Grid>
                <Grid xs={3} justifyContent={'center'} display={'flex'} flexWrap={'wrap'}>
                    <p style={{ color: '#fff', fontSize: 40, margin: 0, width: '100%', textAlign: 'center' }}> {seconds}</p>
                    <span style={{ color: '#fff', fontSize: 18, fontFamily: 'Campuni-Light' }}>Segundos</span>
                </Grid>
            </Grid>
        </div>
    );
};

const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return (
            <ShowCounter
                days={0}
                hours={0}
                minutes={0}
                seconds={0}
            />
        );
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;
