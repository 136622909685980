import * as React from "react";
import Box from "@mui/material/Box";
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import { useDrawingArea } from '@mui/x-charts/hooks';
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Carousel from 'react-multi-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as CorouselResponsive } from 'react-responsive-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CarouselItemDashboard } from "../../components/CarouselItemDashboard";
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useQuery } from "../../hooks/useQuery";

const StyledText = styled('text')(({ theme }) => ({
    textAnchor: 'middle',
    fontWeight: 700,
    color: '#11263C',
    dominantBaseline: 'central',
    fontSize: 25,
    fontFamily: 'Univers-Medium !important'
}));
const StyledText2 = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    color: '#ACAECF',
    fontWeight: 100,
    dominantBaseline: 'central',
    fontSize: 25,
    fontFamily: 'Univers-Medium !important'
}));

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


function PieCenterLabel({ children, }) {
    const { width, height, left, top } = useDrawingArea();
    return (
        <>
            <StyledText x={left + width / 2} y={(top - 10) + height / 2}>
                {children}
            </StyledText>
            <StyledText2 x={left + width / 2} y={(top + 20) + height / 2}>
                Total
            </StyledText2>
        </>

    );
}

export const DashboardPage = () => {

    const [processos, setProcessos] = React.useState([]);
    const [grafico, setGrafico] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [loadingGrafico, setLoadingGrafico] = React.useState(false);
    const [customTransform, setCostumTransform] = React.useState(0);
    const { listaProcessos, listarGrafico } = useQuery();

    const data = [
        { id: 0, value: grafico && grafico.ativos, label: `Ativos \n ${grafico && grafico.ativos} usuários`, color: '#005EB8' },
        { id: 1, value: grafico && grafico.inativos, label: `Inativos \n ${grafico && grafico.inativos} usuários`, color: '#00A3FF' },
        { id: 2, value: grafico && grafico.bloqueados, label: `Bloqueados \n ${grafico && grafico.Bloqueados} usuários`, color: '#F4455A' },
    ];

    React.useEffect(() => {
        getProcessos();
        getGrafico()
    }, []);



    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide, totalItems } } = rest;
        if (totalItems >= 4 && currentSlide >= 1) {
            setCostumTransform(80);
        } else {
            setCostumTransform(0);
        }
        return (
            <div className="carousel-button-group">
                <Button onClick={() => previous()}>
                    <ArrowBackIosNewIcon style={{ opacity: currentSlide === 0 ? 0.3 : 1, color: '#000' }} />
                </Button>
                <Button onClick={() => next()}>
                    <ArrowForwardIosIcon style={{ color: '#000' }} />
                </Button>
            </div >
        );
    };

    const getProcessos = async () => {
        setLoading(true)
        const result = await listaProcessos({ pagination: false, status: 'A' });
        if (result && result.data && result.data.data) {
            setProcessos(result.data.data);
        }
        setLoading(false);
    }

    const getGrafico = async () => {
        setLoadingGrafico(true);
        const result = await listarGrafico();
        if (result && result.data && result.data.data) {
            setGrafico(result.data.data);
        }
        setLoadingGrafico(false);
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={1} paddingX="0">
                <Grid xs={12}>
                    <span style={{ fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Processos em andamento</span>
                </Grid>
                <Grid xs={12} style={{ marginBottom: 80 }}>
                    {isMobile ? (
                        <CorouselResponsive
                            emulateTouch={true}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                        >
                            {processos && processos.map(item => {
                                return (
                                    <div key={item.id}><CarouselItemDashboard item={item} /></div>
                                )
                            })}
                        </CorouselResponsive>
                    ) : (
                        <>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Carousel
                                    style={{ maxWidth: '100%' }}
                                    responsive={responsive}
                                    slidesToSlide={1}
                                    infinite={false}
                                    swipeable={false}
                                    arrows={false}
                                    renderButtonGroupOutside={true}
                                    customButtonGroup={<ButtonGroup />}
                                    additionalTransfrom={customTransform}
                                >
                                    {processos ? processos.map((item) => {
                                        return (
                                            <div key={item.id}><CarouselItemDashboard item={item} /></div>
                                        );
                                    }) : <div />}

                                </Carousel>
                            )}

                        </>
                    )}

                </Grid>
                <Grid md={12} xs={12} style={{ maxWidth: 900 }}>
                    <span style={{ fontWeight: 700, }}>Usuários</span>
                    {loadingGrafico ? (
                        <CircularProgress />
                    ) : (
                        <PieChart
                            sx={{ "& .MuiChartsLegend-series text": { color: '#999999 !important', fontSize: "16px !important", fontFamily: 'Univers-Medium !important' }, }}
                            series={[
                                {
                                    data,
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    innerRadius: 200,
                                    outerRadius: 120,
                                },

                            ]}
                            slotProps={{
                                legend: {
                                    direction: 'column',
                                    position: { vertical: 'top', horizontal: isMobile ? 'middle' : 'right' },
                                    padding: 0,
                                    itemMarkWidth: 20,
                                    itemMarkHeight: 2,
                                    markGap: 5,
                                    itemGap: 10,
                                },
                            }}
                            margin={{ top: 100, bottom: 100, left: 100, right: 200 }}
                            height={isMobile ? 600 : 400}
                        >
                            <PieCenterLabel>{grafico && grafico.totalcredenciados}</PieCenterLabel>
                        </PieChart>
                    )}
                </Grid>
            </Grid>
        </Box >
    )
}