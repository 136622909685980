import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useQuery } from "../../hooks/useQuery";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import dayjs from "dayjs";
import { DocumentoItem } from "../../components/DocumentoItem";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
export const ViewDocumentoPage = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [documentos, setDocumentos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingRow, setLoadingRow] = React.useState(false);
    const [orderProcess, setOrderProcess] = React.useState(false);
    const { listarDocumentosPorUser, aprovarReprovarDocumento } = useQuery();
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(0);

    React.useEffect(() => {
        getDocumentos();
    }, []);

    const getDocumentos = async (isLoading = true) => {
        if (isLoading) {
            setLoading(true);
        } else {
            setLoadingRow(true);
        }

        const result = await listarDocumentosPorUser(id, null, 1);
        if (result && result.data) {
            setDocumentos(result.data.data);
        }
        if (isLoading) {
            setLoading(false);
        } else {
            setLoadingRow(false);
        }

    }


    const handleChange = (event, value) => {
        setPage(value);
        getDocumentos(value);
    };

    const svgComnunicado = () => {

        return (
            <SvgIcon>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41602 13.4583V3.54167C1.41602 3.16594 1.56527 2.80561 1.83095 2.53993C2.09662 2.27426 2.45696 2.125 2.83268 2.125H14.166C14.5417 2.125 14.9021 2.27426 15.1678 2.53993C15.4334 2.80561 15.5827 3.16594 15.5827 3.54167V13.4583C15.5827 13.8341 15.4334 14.1944 15.1678 14.4601C14.9021 14.7257 14.5417 14.875 14.166 14.875H2.83268C2.45696 14.875 2.09662 14.7257 1.83095 14.4601C1.56527 14.1944 1.41602 13.8341 1.41602 13.4583Z" stroke="white" strokeWidth="1.5" />
                    <path d="M1.41602 4.95831H15.5827M6.37435 9.91665H8.49935M8.49935 9.91665H10.6243M8.49935 9.91665V7.79165M8.49935 9.91665V12.0416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }

    const svgDoc = () => {

        return (
            <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.25 1.25C5.58696 1.25 4.95107 1.51339 4.48223 1.98223C4.01339 2.45107 3.75 3.08696 3.75 3.75V16.25C3.75 16.913 4.01339 17.5489 4.48223 18.0178C4.95107 18.4866 5.58696 18.75 6.25 18.75H13.75C14.413 18.75 15.0489 18.4866 15.5178 18.0178C15.9866 17.5489 16.25 16.913 16.25 16.25V6.7675C16.2496 6.27039 16.0517 5.79379 15.7 5.4425L12.0588 1.79875C11.8846 1.62467 11.6778 1.4866 11.4502 1.39245C11.2227 1.29829 10.9788 1.24989 10.7325 1.25H6.25ZM5 3.75C5 3.41848 5.1317 3.10054 5.36612 2.86612C5.60054 2.6317 5.91848 2.5 6.25 2.5H10V5.625C10 6.12228 10.1975 6.59919 10.5492 6.95083C10.9008 7.30246 11.3777 7.5 11.875 7.5H15V16.25C15 16.5815 14.8683 16.8995 14.6339 17.1339C14.3995 17.3683 14.0815 17.5 13.75 17.5H6.25C5.91848 17.5 5.60054 17.3683 5.36612 17.1339C5.1317 16.8995 5 16.5815 5 16.25V3.75ZM14.7413 6.25H11.875C11.7092 6.25 11.5503 6.18415 11.4331 6.06694C11.3158 5.94973 11.25 5.79076 11.25 5.625V2.75875L14.7413 6.25Z" fill="#999999" />
                </svg>
            </SvgIcon>
        )
    }

    Array.prototype.orderBy = function (selector, desc = false) {
        return [...this].sort((a, b) => {
            a = selector(a);
            b = selector(b);

            if (a == b) return 0;
            return (desc ? a > b : a < b) ? -1 : 1;
        });
    }

    const orderFieldProcesso = () => {
        setDocumentos(documentos.orderBy(h => h.processo.titulo, !orderProcess));
        setOrderProcess(!orderProcess);

    }


    const orderFieldDocumento = () => {
        setDocumentos(documentos.orderBy(h => h.tipo_documento.nome, !orderProcess));
        setOrderProcess(!orderProcess);

    }

    const orderFieldArea = () => {
        setDocumentos(documentos.orderBy(h => h.area && h.area.nome, !orderProcess));
        setOrderProcess(!orderProcess);

    }

    const orderFieldData = () => {
        setDocumentos(documentos.orderBy(h => h.created_at, !orderProcess));
        setOrderProcess(!orderProcess);

    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { navigate('/analise-documental', { replace: true }) }}
                        edge="start"
                    >
                        <span
                            style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 12, fontWeight: 'bold', fontFamily: "Campuni-Bold" }}
                        >
                            <ArrowBackIcon /> Voltar
                        </span>
                    </IconButton>
                </Grid>
                <Grid md={12} xs={12}>
                    <Card sx={{ background: '#F8F9FE' }}>
                        <CardContent style={{ padding: 20, borderLeft: 'solid 4px #005EB8' }}>
                            <Grid container spacing={5}>
                                <Grid md={12}>
                                    <p style={{ fontFamily: 'Campuni-Bold', fontSize: 18, margin: 0 }}>{documentos && documentos.length > 0 && documentos[0].user.name}</p>
                                    <p style={{ fontFamily: 'AlegraSans-Medium', fontWeight: 700, fontSize: 16, color: '#005EB8', margin: 0 }}>{documentos && documentos.length > 0 && documentos[0].user.colaborador.documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</p>
                                </Grid>
                                <Grid md={6} style={{ padding: '0 15px 15px 15px' }}>
                                    <p style={{ margin: 0, fontSize: 12, fontFamily: 'Campuni-Bold' }}>Empresa</p>
                                    <p style={{ color: '#666666', margin: 0, fontSize: 12, fontWeight: 700 }}>{documentos && documentos.length > 0 && documentos[0].user.fornecedor.razao_social}</p>
                                    <p style={{ color: '#666666', margin: 0, fontSize: 12, fontWeight: 700 }}>{documentos && documentos.length > 0 && documentos[0].user.fornecedor.documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</p>
                                </Grid>
                                <Grid md={6} style={{ padding: '0 15px 15px 15px' }}>
                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end' }}>
                                        {svgDoc()}
                                        <span style={{ fontSize: 14, marginLeft: 5, color: '#666666', fontWeight: 700, fontFamily: 'Univers-Medium' }}>{documentos.length} Documentos enviados</span>
                                    </div>

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid md={12} xs={12}>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => orderFieldDocumento()} style={{ width: '25%', color: '#005EB8', fontSize: 16, fontFamily: 'Campuni-Bold', cursor: 'pointer' }}>Documentos <SortByAlphaIcon style={{ fontSize: 13 }} /></TableCell>
                                <TableCell onClick={() => orderFieldArea()} style={{ width: '15%', color: '#005EB8', fontSize: 16, fontFamily: 'Campuni-Bold', cursor: 'pointer' }}>Áreas <SortByAlphaIcon style={{ fontSize: 13 }} /></TableCell>
                                <TableCell onClick={() => orderFieldProcesso()} style={{ width: '15%', color: '#005EB8', fontSize: 16, fontFamily: 'Campuni-Bold', cursor: 'pointer' }}>Processo <SortByAlphaIcon style={{ fontSize: 13 }} /></TableCell>
                                <TableCell onClick={() => orderFieldData()} style={{ width: '15%', color: '#005EB8', fontSize: 16, fontFamily: 'Campuni-Bold', cursor: 'pointer' }}>Data de envio <SortByAlphaIcon style={{ fontSize: 13 }} /></TableCell>
                                <TableCell style={{ width: '30%' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    {documentos && documentos.map(item => {
                                        return (
                                            <DocumentoItem item={item} setDocumentos={setDocumentos} id={id} />
                                        )
                                    })}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Box >
    )
}