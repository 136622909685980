import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import axios from "axios";

const QueryContext = createContext();

export const QueryProvider = ({ children }) => {
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const url = process.env.REACT_APP_API_URL;

    axios.interceptors.response.use(
        (response) => {
            // block to handle success case
            if (
                response &&
                response.data &&
                response.data.error &&
                response.data.error == 'Accesso negado.'
            ) {
                logout();
            }

            return response;
        },
        function (error) {
            // block to handle error case
            return Promise.reject(error);
        },
    );

    const getCompany = async (cnpj) => {
        return axios.get(`${url}/dados-receita?cnpj=${cnpj}`);
    };

    const getNaturezaJuridica = async () => {
        return axios.get(`${url}/natureza-juridica`);
    };

    const cadastroStep1 = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        return axios.post(`${url}/cadastro-step-1`, payload, config);
    };

    const cadastroStep2 = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        return axios.post(`${url}/cadastro-step-2`, payload, config);
    };

    const cadastroStep3 = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        return axios.post(`${url}/cadastro-step-3`, payload, config);
    };

    const getColaborador = async (id) => {
        return axios.get(`${url}/colaborador/${id}`);
    };

    const showCadastroFornecedor = async (id) => {
        return axios.get(`${url}/cadastro-fornecedor/${id}`);
    };

    const deletarColaborador = (id) => {
        return axios.delete(`${url}/colaborador/${id}`);
    };


    const listaEditais = (params) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        let query = params.pagination ? `?pagination=true&page=${params.page}&status=${params.status ? params.status : ''}` : `?status=${params.status ? params.status : ''}`;
        return axios.get(`${url}/lista-edital${query}`, config);
    }

    const editalStep1 = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/edital-step-1`, payload, config);
    };

    const editalStep2 = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/edital-step-2`, payload, config);
    };

    const editalStep5 = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/edital-step-5`, payload, config);
    };

    const listaTipoEditais = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        return axios.get(`${url}/listar-tipo-edital`, config);
    }

    const listaTipoDocumentos = (processoId = null) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        let query = processoId != null ? `?processoId=${processoId}` : '';
        return axios.get(`${url}/listar-tipo-documentos${query}`, config);
    }


    const saveTipoDocumento = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/tipo-documento`, payload, config);
    };

    const updateTipoDocumento = (id, payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/tipo-documento/${id}`, payload, config);
    };

    const deleteTipoDocumento = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.delete(`${url}/tipo-documento/${id}`, config);
    };

    const showTipoDocumento = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/tipo-documento/${id}`, config);
    };

    const listaFormacoes = (otimizar = false, processoId = null) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        let endpoint = "";

        if (otimizar && processoId != null) {
            endpoint = `${url}/listar-formacoes?otimizar=1&processoId=${processoId}`;
        } else if (!otimizar && processoId != null) {
            endpoint = `${url}/listar-formacoes?processoId=${processoId}`;
        } else if (otimizar && processoId == null) {
            endpoint = `${url}/listar-formacoes?otimizar=1`;
        } else {
            endpoint = `${url}/listar-formacoes`;
        }
        return axios.get(endpoint, config);
    }


    const saveFormacao = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/formacao`, payload, config);
    };

    const updateFormacao = (id, payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/formacao/${id}`, payload, config);
    };

    const deleteFormacao = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.delete(`${url}/formacao/${id}`, config);
    };

    const showFormacao = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/formacao/${id}`, config);
    };

    const listaCategorias = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        return axios.get(`${url}/listar-categorias`, config);
    }


    const saveCategoria = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/categoria`, payload, config);
    };

    const updateCategoria = (id, payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/categoria/${id}`, payload, config);
    };

    const deleteCategoria = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.delete(`${url}/categoria/${id}`, config);
    };

    const showCategoria = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/categoria/${id}`, config);
    };

    const showEdital = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/edital/${id}`, config);
    };

    const listaAreas = (processoId = null) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        let query = processoId != null ? `?processoId=${processoId}` : '';
        return axios.get(`${url}/listar-areas${query}`, config);
    }


    const saveArea = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/area`, payload, config);
    };

    const updateArea = (id, payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/area/${id}`, payload, config);
    };

    const deleteArea = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.delete(`${url}/area/${id}`, config);
    };

    const showArea = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/area/${id}`, config);
    };

    const updateStatusEdital = (id, data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/update-status-edital/${id}`, data, config);
    };

    const removeDocumentoEdital = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/remove-documento-edital/${id}`, {}, config);
    };

    const removeImageEdital = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/remove-image-edital/${id}`, {}, config);
    };

    const listaProcessos = (params) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        let query = "";
        if (params.edital_id) {
            query = params.pagination ? `?pagination=true&page=${params.page}&edital_id=${params.edital_id}` : params.status ? `?status=${params.status}&edital_id=${params.edital_id}` : `?edital_id=${params.edital_id}`;
        } else {
            query = params.pagination ? `?pagination=true&page=${params.page}` : params.status ? `?status=${params.status}` : '';
        }

        return axios.get(`${url}/listar-processos${query}`, config);
    }

    const showProcesso = (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        return axios.get(`${url}/processo/${id}`, config);
    }

    const saveProcesso = (data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/processo`, data, config);
    };


    const deleteProcesso = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.delete(`${url}/processo/${id}`, config);
    };

    const updateProcesso = (id, data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/processo/${id}`, data, config);
    };

    const updateStatusProcesso = (id, data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/update-status-processo/${id}`, data, config);
    };

    const removeDocumentoProcesso = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/remove-documento-processo/${id}`, {}, config);
    };

    const listaAnuncios = (params) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        let query = params.pagination ? `?pagination=true&page=${params.page}` : '';
        return axios.get(`${url}/listar-anuncios${query}`, config);
    }


    const saveAnuncio = (payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/anuncio`, payload, config);
    };

    const updateAnuncio = (id, payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/anuncio/${id}`, payload, config);
    };

    const deleteAnuncio = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.delete(`${url}/anuncio/${id}`, config);
    };

    const showAnuncio = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/anuncio/${id}`, config);
    };


    const listaEditalPublic = () => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        return axios.get(`${url}/editais-public`, config);
    };


    const showEditalPublic = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        return axios.get(`${url}/edital-public/${id}`, config);
    };

    const listarDocumentosUsuarios = (params) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };
        let query = params.pagination ? `?pagination=true&page=${params.page}&search=${params.search}` : '';
        return axios.get(`${url}/listar-documentos${query}`, config);
    };

    const listarDocumentosPorUser = (id, processoid, permissions = 0) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };
        let param = processoid ? `?processoid=${processoid}&permission=${permissions}` : `?permission=${permissions}`;
        return axios.get(`${url}/listar-documentos-usuario/${id}${param}`, config);
    };


    const listarPf = (params) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };
        let query = params.pagination ? `?pagination=true&page=${params.page}&search=${params.search}` : '';
        return axios.get(`${url}/listar-pf${query}`, config);
    };

    const listarPj = (params) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };
        let query = params.pagination ? `?pagination=true&page=${params.page}&search=${params.search}` : '';
        return axios.get(`${url}/listar-pj${query}`, config);
    };

    const listarInternos = (params) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };
        let query = `?search=${params.search}`;
        return axios.get(`${url}/listar-externos${query}`, config);
    };

    const updateColaborador = (id, payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/colaborador/${id}`, payload, config);
    };

    const updatePassword = (id, payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/altera-senha-user/${id}`, payload, config);
    };
    const aprovarReprovarDocumento = (id, payload) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/aprovar-reprovar-documento/${id}`, payload, config);
    };

    const removeModelo = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/remove-modelo/${id}`, {}, config);
    };

    const listarContratos = async (cnpj, params = '') => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/contratos?cnpj=${cnpj}${params}`, config);
    };

    const detalheContrato = (contrato, filtro = '') => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/contrato?contrato=${contrato}${filtro}`, config);
    };

    const getColaboradorAdmin = async (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/colaborador-admin/${id}`, config);
    };

    const saveTipoEdital = (data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/tipo-edital`, data, config);
    };

    const removeDocumentoComunicado = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/remove-documento-comunicado/${id}`, {}, config);
    };

    const listarAreasProcesso = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-areas-processo?processoid=${id}`, config);
    };

    const listarAreasProcessoSelecionadas = (processoId, fornecedorId) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-areas-selecionadas-processo?processoid=${processoId}&fornecedorid=${fornecedorId}`, config);
    };

    const listarFuncionarios = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-funcionarios?fornecedorid=${id}`, config);
    };

    const saveAreasSelecionadasProcesso = (data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/areas-selecionadas-processo`, data, config);
    };

    const saveAreasProcessoUser = (data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/areas-processo-user`, data, config);
    };

    const listarUserAreaProcesso = (data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/listar-areas-processo-user`, data, config);
    };


    const listarDocumentosProcesso = (tipo, processoId) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-documentos-processo?processoid=${processoId}&tipo=${tipo}`, config);
    };

    const listarDocumentosAreaPj = (processoId, fornecedorId) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-documentos-area-pj?processoid=${processoId}&fornecedorid=${fornecedorId}`, config);
    };

    const listarDocumentosAreaPf = (processoId, fornecedorId, userId) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-documentos-area-pf?processoid=${processoId}&fornecedorid=${fornecedorId}&userid=${userId}`, config);
    };

    const saveDocumentosEmpresa = (data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/documentos-empresa`, data, config);
    };

    const listaResumoPj = (fornecedorId, processoid) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-resumo-pj?fornecedorid=${fornecedorId}&processoid=${processoid}`, config);
    };

    const listaResumoPf = (fornecedorId, processoid) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-resumo-pf?fornecedorid=${fornecedorId}&processoid=${processoid}`, config);
    };

    const listaDocumentosUser = (tipo, processoId) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-documentos-user?processoid=${processoId}&tipo=${tipo}`, config);
    };

    const listaDocumentosEnviados = (tipo, processoId, fornecedorId, userId) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-documentos-enviados?processoid=${processoId}&tipo=${tipo}&fornecedorid=${fornecedorId}&userid=${userId}`, config);
    };

    const listarUsersFluig = async () => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/users-fluig`, config);
    };

    const listarDemandas = async (query) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/demandas?${query}`, config);
    };


    const showDemanda = async (id, $colaboradorId) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/demanda/${id}?colaboradorid=${$colaboradorId}`, config);
    };

    const saveAceiteProcesso = (data) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/aceite-processo`, data, config);
    };

    const listaProcessosUser = async ($userId, status) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        let query = status !== null ? `&status=${status}` : '';

        return axios.get(`${url}/listar-processos-user?userid=${$userId}${query}`, config);
    };

    const listarEstados = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/estados`, config);
    };

    const listarMunicipios = (estado_id = '') => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/municipios?estado_id=${estado_id}`, config);
    };

    const downloadRelatorioProcesso = (processoId) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.post(`${url}/relatorios/candidados-por-processo/${processoId}`, {}, config);
    };

    const downloadRelatorioProcessoCredenciado = (processoId) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.post(`${url}/relatorios/credenciados-por-processo/${processoId}`, {}, config);
    };

    const downloadRelatorioFornecedoresEdital = (editalId) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.post(`${url}/relatorios/fornecedores-por-edital/${editalId}`, {}, config);
    };

    const downloadRelatorioUsers = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.post(`${url}/relatorios/usuarios`, {}, config);
    };

    const listarDocumentosUpload = (user_id, tipo_documento_id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/listar-documentos-upload?userid=${user_id}&tipodocumentoid=${tipo_documento_id}`, config);
    };


    const saveDocumentoUpload = (params) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/save-documento-upload`, params, config);
    };

    const listarGrafico = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };

        return axios.get(`${url}/dashboard`, config);
    };

    const listarAgenda = (id, data_inicio, data_fim) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        return axios.get(`${url}/agenda?colaboradorid=${id}&data_inicio=${data_inicio}&data_fim=${data_fim}`, config);
    };

    const getCep = (cep) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return axios.get(`${url}/consulta-cep?cep=${cep}`, config);
    };


    const listarComuicadosUser = (query) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        let param = query.lido == undefined ? `&page=${query.page}` : `&page=${query.page}&lido=${query.lido}`;
        return axios.get(`${url}/comunicados-usuario?userid=${query.userId}${param}`, config);
    };


    const lerComunicado = (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        return axios.get(`${url}/anuncio/read/${id}`, config);
    };

    const lerNotificacao = (id, user_id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        return axios.get(`${url}/notificacoes/read/${id}?user_id=${user_id}`, config);
    };

    const saveDocsToAnalise = (params) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/enviar-documentos-analise`, params, config);
    };


    const listarAlocacaoHoras = (id) => {
        let config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        return axios.get(`${url}/alocacao-horas`, config);
    };


    const changeStatusProcesso = (id, params) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/alterar-status-processo/${id}`, params, config);
    };

    const resetarSenha = (email) => {
        let config = {};
        return axios.get(`${url}/esqueci-senha?email=${email}`, config);
    };

    const buscarComunicadoEdital = (id) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/comunicado-edital?editalid=${id}`, config);
    };

    const validarInscricao = (isRepresLegal, processoId) => {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/verificar-inscricao?representante_legal=${isRepresLegal}&processoid=${processoId}`, config);
    };

    const savePermmissions = (id, params) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/permissions/${id}`, params, config);
    };


    const getPermmissions = (id) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/list-permissions/${id}`, config);
    };

    const getUser = (id) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/user/${id}`, config);
    };

    const newStatus = (id, params) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.post(`${url}/demanda/${id}/new_status`, params, config);
    }

    const listarDocumentosFormacao = (id) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            },
        };

        return axios.get(`${url}/listar-documentos-formacao?formacaoid=${id}`, config);
    };


    const value = useMemo(() => ({
        getCompany,
        getNaturezaJuridica,
        cadastroStep1,
        cadastroStep2,
        cadastroStep3,
        getColaborador,
        showCadastroFornecedor,
        deletarColaborador,
        listaEditais,
        editalStep1,
        editalStep2,
        listaTipoEditais,
        listaTipoDocumentos,
        saveTipoDocumento,
        updateTipoDocumento,
        deleteTipoDocumento,
        showTipoDocumento,
        listaFormacoes,
        saveFormacao,
        updateFormacao,
        deleteFormacao,
        showFormacao,
        listaCategorias,
        saveCategoria,
        updateCategoria,
        deleteCategoria,
        showCategoria,
        showEdital,
        listaAreas,
        saveArea,
        updateArea,
        deleteArea,
        showArea,
        editalStep5,
        updateStatusEdital,
        removeDocumentoEdital,
        removeImageEdital,
        listaProcessos,
        showProcesso,
        saveProcesso,
        updateProcesso,
        updateStatusProcesso,
        removeDocumentoProcesso,
        listaAnuncios,
        saveAnuncio,
        updateAnuncio,
        deleteAnuncio,
        showAnuncio,
        listaEditalPublic,
        showEditalPublic,
        listarDocumentosUsuarios,
        listarDocumentosPorUser,
        listarPf,
        listarPj,
        listarInternos,
        updateColaborador,
        aprovarReprovarDocumento,
        removeModelo,
        listarContratos,
        detalheContrato,
        getColaboradorAdmin,
        saveTipoEdital,
        deleteProcesso,
        removeDocumentoComunicado,
        listarAreasProcesso,
        saveAreasProcessoUser,
        saveAreasSelecionadasProcesso,
        listarAreasProcessoSelecionadas,
        listarFuncionarios,
        listarUserAreaProcesso,
        listarDocumentosProcesso,
        listarDocumentosAreaPj,
        saveDocumentosEmpresa,
        listaResumoPj,
        listarDocumentosAreaPf,
        listaResumoPf,
        listaDocumentosUser,
        listaDocumentosEnviados,
        listarUsersFluig,
        updatePassword,
        listarDemandas,
        showDemanda,
        saveAceiteProcesso,
        listaProcessosUser,
        listarEstados,
        listarMunicipios,
        downloadRelatorioProcesso,
        downloadRelatorioFornecedoresEdital,
        downloadRelatorioProcessoCredenciado,
        downloadRelatorioUsers,
        listarDocumentosUpload,
        saveDocumentoUpload,
        listarGrafico,
        listarAgenda,
        getCep,
        listarComuicadosUser,
        lerComunicado,
        saveDocsToAnalise,
        listarAlocacaoHoras,
        changeStatusProcesso,
        resetarSenha,
        buscarComunicadoEdital,
        validarInscricao,
        savePermmissions,
        getPermmissions,
        getUser,
        newStatus,
        lerNotificacao,
        listarDocumentosFormacao
    }));

    return (
        <QueryContext.Provider value={value}>{children}</QueryContext.Provider>
    );
};

export const useQuery = () => {
    return useContext(QueryContext);
};
