import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import { useQuery } from "../../hooks/useQuery";
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { UsuariosItem } from "../../components/UsuariosItem";
import { Pagination } from '@mui/material';
import { UsuariosPage } from "./UsuariosPage";
import { UsuariosInternoItem } from "../../components/UsuariosInternoItem";

export const UsuariosInternoPage = () => {

    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [lastPage, setLastPage] = React.useState(0);
    const { listarInternos } = useQuery();

    React.useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async (page) => {
        setLoading(true);
        const result = await listarInternos({ search: search });
        if (result && result.data) {
            setUsers(result.data.data);
        }
        setLoading(false);
    }


    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }

    const handleChange = (event, value) => {
        setPage(value);
        getUsers(value);
    };

    return (
        <Grid container>
            <Grid md={7} xs={12}>
                <TextField
                    placeholder="Buscar"
                    fullWidth
                    className="custom-class"
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    variant="standard"
                    style={{ height: '8px !important', marginTop: 15 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton onClick={() => { getUsers(1) }}>
                                    {svgSearch()}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid md={5}></Grid>
            <Grid md={8} xs={12} marginTop={5}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        {users && users.map(item => {
                            return (
                                <UsuariosInternoItem item={item} />
                            )
                        })}
                    </>
                )}

            </Grid>
            <Grid md={2} xs={12}></Grid>
        </Grid>
    )
}