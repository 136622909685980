import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import LineWhite from '../assets/Line.png';
import { isMobile } from 'react-device-detect';
const Countdown = ({ finalDate }) => {

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const daysRadius = mapNumber(Math.abs(days), 365, 0, 0, 360);
    const hoursRadius = mapNumber(Math.abs(hours), 24, 0, 0, 360);
    const minutesRadius = mapNumber(Math.abs(minutes), 60, 0, 0, 360);
    const secondsRadius = mapNumber(Math.abs(seconds), 60, 0, 0, 360);

    useEffect(() => {
        setInterval(() => {

            const then = new Date(finalDate).getTime();
            const now = new Date().getTime();
            const remaining = then - now;

            setDays(Math.floor(remaining / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            setMinutes(Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60)));
            setSeconds(Math.floor((remaining % (1000 * 60)) / 1000));

        }, 1000)
    }, [finalDate]);

    function mapNumber(number, in_min, in_max, out_min, out_max) {
        return (
            ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
        );
    }

    return (
        <div className='background-countdown' style={{ minWidth: isMobile ? 300 : 472, maxWidth: isMobile ? '100%' : 472 }}>
            <Grid container spacing={1}>
                <Grid xs={3} justifyContent={'center'} display={'flex'} flexWrap={'wrap'}>
                    <p style={{ color: '#fff', fontSize: 40, margin: 0, width: '100%', textAlign: 'center' }}>{finalDate == null ? 0 : days}</p>
                    <span style={{ color: '#fff', fontSize: 18, fontFamily: 'Campuni-Light' }}>Dias</span>
                    {!isMobile ? (
                        <img src={LineWhite} style={{ position: 'absolute', marginLeft: 110, marginTop: 5 }} />
                    ) : null}
                </Grid>
                <Grid xs={3} justifyContent={'center'} display={'flex'} flexWrap={'wrap'}>
                    <p style={{ color: '#fff', fontSize: 40, margin: 0, width: '100%', textAlign: 'center' }}> {finalDate == null ? 0 : hours}</p>
                    <span style={{ color: '#fff', fontSize: 18, fontFamily: 'Campuni-Light' }}>Horas</span>
                    {!isMobile ? (
                        <img src={LineWhite} style={{ position: 'absolute', marginLeft: 110, marginTop: 5 }} />
                    ) : null}
                </Grid>
                <Grid xs={3} justifyContent={'center'} display={'flex'} flexWrap={'wrap'}>
                    <p style={{ color: '#fff', fontSize: 40, margin: 0, width: '100%', textAlign: 'center' }}>{finalDate == null ? 0 : minutes}</p>
                    <span style={{ color: '#fff', fontSize: 18, fontFamily: 'Campuni-Light' }}>{isMobile ? 'Min.' : 'Minutos'}</span>
                    {!isMobile ? (
                        <img src={LineWhite} style={{ position: 'absolute', marginLeft: 110, marginTop: 5 }} />
                    ) : null}

                </Grid>
                <Grid xs={3} justifyContent={'center'} display={'flex'} flexWrap={'wrap'}>
                    <p style={{ color: '#fff', fontSize: 40, margin: 0, width: '100%', textAlign: 'center' }}> {finalDate == null ? 0 : seconds}</p>
                    <span style={{ color: '#fff', fontSize: 18, fontFamily: 'Campuni-Light' }}>{isMobile ? 'Seg.' : 'Segundos'}</span>
                </Grid>
            </Grid>
        </div>
    );
}

export default Countdown;