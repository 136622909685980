import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from "react-router-dom";
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
import CircleIcon from '@mui/icons-material/Circle';
import Modal from '@mui/material/Modal';
import { isMobile } from "react-device-detect";
import { useQuery } from "../hooks/useQuery";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};


export const ComunicadoUsuarioItem = ({ item, refresh, status }) => {
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_BASE_URL;

    const [open, setOpen] = React.useState(false);
    const { lerComunicado } = useQuery();

    const handelClose = () => {
        setOpen(false);
    }

    const read = async () => {
        const result = await lerComunicado(item.id);
        // refresh(1, status);
    }

    return (

        <Card sx={{ background: '#F8F9FE' }}>
            <CardContent style={{ padding: 0, minHeight: 127 }}>
                <Grid onClick={() => { setOpen(true); read(); }} container spacing={5} paddingX="0" marginLeft={2} style={{ minHeight: 127, cursor: 'pointer' }}>
                    <Grid md={0.2} xs={0} display={'flex'} alignItems={'center'} style={{ padding: '10px 0 10px 0' }}>
                        <div style={{ height: '70%', width: 7, background: item && item.lido ? '#CCCCCC' : '#CD5BE8', borderRadius: 20 }}></div>
                    </Grid>
                    <Grid md={11.8} xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                            <span style={{ fontWeight: 400, color: '#666666', fontSize: 12 }}>{item && item.comunicado.titulo} </span>
                            <span style={{ fontWeight: 400, color: '#666666', fontSize: 12, marginRight: 10, fontFamily: 'AlegraSans-Medium' }}>{dayjs(item && item.comunicado.created_at).format('DD/MM/YYYY HH:mm')}  {!item && item.lido ? (<CircleIcon style={{ color: '#F4455A' }} fontSize="10" />) : null}</span>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: item && item.comunicado.texto }} style={{ fontWeight: 500, color: item && item.lido ? '#999999' : '#292D32', fontSize: 16 }} />
                    </Grid>
                </Grid>
            </CardContent>
            <Modal
                open={open}
                onClose={handelClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12} marginTop={2}>
                            <p style={{ fontWeight: 400, color: '#005EB8', fontSize: 24, marginRight: 10, fontFamily: 'AlegraSans-Medium' }}>  {item && item.comunicado.titulo} </p>
                        </Grid>
                        <Grid md={12}>
                            <span style={{ fontWeight: 400, color: '#999999', fontSize: 12, marginRight: 10, fontFamily: 'AlegraSans-Medium' }}>{dayjs(item && item.comunicado.created_at).format('DD/MM/YYYY HH:mm')} </span>
                        </Grid>
                        <Grid md={12}>
                            <div
                                className="scrollbar"
                                id="style-3"
                                style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', marginLeft: 0 }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: item && item.comunicado.texto }} style={{ fontSize: 18, color: '#666666', fontWeight: 400 }} />

                                {item && item.comunicado.documento ? (
                                    <p style={{ fontSize: 18, color: '#666666', fontWeight: 400 }}>
                                        <IconButton onClick={() => {
                                            window.open(`${url}/storage/${item.comunicado.documento}`, '_blank').focus();
                                        }}>
                                            <span style={{ color: "#005EB8" }}>Visualizar anexo</span>
                                        </IconButton>
                                    </p>
                                ) : null}
                            </div>
                        </Grid>
                        <Grid md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Button
                                onClick={() => {
                                    console.log('aaqui');
                                    handelClose();
                                }}
                                style={{ width: '100px', height: 41 }}
                                variant="contained"
                            >
                                Fechar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
        </Card>
    )
}