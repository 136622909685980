import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import EditalImg from '../assets/edital.png';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from "react-router-dom";

export const CarouselItemHome = ({ item }) => {
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_BASE_URL;
    return (

        <Card sx={{ maxWidth: 300, minHeight: 480 }}>
            <CardMedia
                component="img"
                height="230"
                image={`${url}/storage/${item.image}`}
                alt="Edital"
            />
            <CardContent style={{ padding: 0 }}>
                {item && item.processos.length > 0 ? (
                    <div style={{ width: '100%', color: item.processos[item.processos.length - 1].status == 'A' ? 'white' : '#666666', background: item.processos[item.processos.length - 1].status == 'A' ? '#84F4BC' : item.processos[item.processos.length - 1].status == 'P' ? '#FFA500' : '#CCCCCC', padding: 10, textAlign: 'center', fontWeight: '500' }}>
                        {item.processos[item.processos.length - 1].status == 'A' ? 'Processo aberto' : item.processos[item.processos.length - 1].status == 'P' ? 'Previsto' : 'Processo fechado'}
                    </div>
                ) : (
                    <div style={{ width: '100%', color: '#666666', background: '#CCCCCC', padding: 10, textAlign: 'center', fontWeight: '500' }}>
                        Sem processo
                    </div>
                )}

                <p className="dots-2linhas" style={{ padding: '0 10px', margin: '15px 0', fontSize: 20, fontWeight: 700, color: '#444444' }}>{item && item.titulo}</p>
                <p className="dots-paragrafo" style={{ lineHeight: '23px', padding: '0 10px', margin: 0, fontWeight: 500, textAlign: 'justify', color: '#666666' }}>
                    {item && item.texto}
                </p>
            </CardContent>
            <CardActions>
                <div onClick={() => navigate(`/edital/${item && item.id}`)} style={{ margin: 0, color: '#005EB8', display: 'flex', alignContent: 'center', cursor: "pointer" }}>
                    <b>Mais informações</b>
                    <ArrowRightAltIcon style={{ marginLeft: 10 }} />
                </div>
            </CardActions>
        </Card>

    )
}