import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useAuth } from "../../hooks/useAuth";
import { isMobile } from "react-device-detect";
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import dayjs from "dayjs";

const mock = [
  {
    "id": 40239,
    "titulo": "Fator S Pr\u00e9-acelera presencial - Workshop 1 - Estrat\u00e9gia de Neg\u00f3cios para Startups: Business Model",
    "local": "Local: UFSJ",
    "carga_horaria": "2 horas",
    "periodo": "09/12/2024 14:00 a 09/12/2024 16:00"
  },
  {
    "id": 40239,
    "titulo": "Fator S Pr\u00e9-acelera presencial - Workshop 1 - Estrat\u00e9gia de Neg\u00f3cios para Startups: Business Model",
    "local": "Local: UFSJ",
    "carga_horaria": "2 horas",
    "periodo": "10/12/2024 14:00 a 10/12/2024 16:00"
  },
  {
    "id": 39678,
    "titulo": "Eli Ativa\u00e7\u00e3o - M\u00f3dulo 4 (op\u00e7\u00e3o on-line) - Acompanhamento do Plano de Trabalho",
    "local": "Local: Sebrae Varginha",
    "carga_horaria": "4 horas",
    "periodo": "02/12/2024 08:00 a 02/12/2024 12:00"
  },
  {
    "id": 39678,
    "titulo": "Eli Ativa\u00e7\u00e3o - M\u00f3dulo 4 (op\u00e7\u00e3o on-line) - Acompanhamento do Plano de Trabalho",
    "local": "Local: Sebrae Varginha",
    "carga_horaria": "4 horas",
    "periodo": "16/12/2024 08:00 a 16/12/2024 12:00"
  },
  {
    "id": 40242,
    "titulo": "Fator S Pr\u00e9-acelera presencial - Workshop 4 - Aspectos regulat\u00f3rios",
    "local": "Local: UFSJ",
    "carga_horaria": "2 horas",
    "periodo": "04/02/2025 14:00 a 04/02/2025 16:00"
  },
  {
    "id": 40243,
    "titulo": "Fator S Pr\u00e9-acelera on-line - Workshop 5 - Viabilidade Financeira",
    "local": "Local: UFSJ",
    "carga_horaria": "2 horas",
    "periodo": "17/02/2025 14:00 a 17/02/2025 16:00"
  },
  {
    "id": 40243,
    "titulo": "Fator S Pr\u00e9-acelera on-line - Workshop 5 - Viabilidade Financeira",
    "local": "Local: UFSJ",
    "carga_horaria": "2 horas",
    "periodo": "18/02/2025 14:00 a 18/02/2025 16:00"
  },
  {
    "id": 40248,
    "titulo": "Fator S Pr\u00e9-acelera presencial - Workshop 6 - Como elaborar um Pitch de sucesso",
    "local": "Local: UFSJ",
    "carga_horaria": "2 horas",
    "periodo": "03/03/2025 14:00 a 03/03/2025 16:00"
  },
  {
    "id": 40248,
    "titulo": "Fator S Pr\u00e9-acelera presencial - Workshop 6 - Como elaborar um Pitch de sucesso",
    "local": "Local: UFSJ",
    "carga_horaria": "2 horas",
    "periodo": "04/03/2025 14:00 a 04/03/2025 16:00"
  }
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '100%' : '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  paddingLeft: 4,
  paddingRight: 4
};

export const AgendaUsuarioPage = () => {

  const [modalDetalhes, setModalDetalhes] = React.useState({title: '', start: '', local: ''});

  const navigate = useNavigate();
  const { listarAgenda } = useQuery();
  const { user } = useAuth();
  const [agenda, setAgenda] = React.useState([]);
  const [carregando, setCarregando] = React.useState(true);

  React.useEffect(() => {
    if (user) {
      // getAgenda();
    }
  }, []);

  const handleCloseModalDetalhes = () => {
    setModalDetalhes({title: '', start: '', local: ''});
  };
  
  const getAgenda = async (data_inicio, data_fim) => {
    const result = await listarAgenda(user.colaborador_id, data_inicio, data_fim);
    if (result && result.data) {
      let agenda = [];
      result.data.data.map(row => {
        agenda.push({ 
          title: row.title,
          start: `20${row.start.split('/')[2]}-${row.start.split('/')[1]}-${row.start.split('/')[0]} ${row.period.split(' ')[0]}`,
          local: row.local,
          period: row.period,
          carga_horaria: row.carga_horaria
        });
      });
      setAgenda(agenda);
      setCarregando(false);
    }
  }

  function renderEventContent(eventInfo) {
    return (
      <div style={{ background: '#005EB8', color: '#fff', width: '-webkit-fill-available', padding: 5, fontSize: 'smaller', margin: 5 }}>
        <p style={{ margin: 0 }}>{dayjs(eventInfo.event.start).format('HH:mm')}</p>
        <span>{eventInfo.event.title.substr(1, 30)}...</span>
        <p style={{ margin: 0 }}>{eventInfo.event.extendedProps.local}</p>
      </div>
    )
  }
  // Function to render custom content in day cells
  const renderDayCellContent = (dayCellContent) => {
    return (
      <div>
        <div>{dayCellContent.dayNumberText}</div>
      </div>
    );
  };

  // Function to handle date clicks
  const handleDateClick = (arg) => {
    const data = {
      title: arg.event.title,
      start: dayjs(arg.event.start).format('HH:mm'),
      local: arg.event.extendedProps.local
    };
    setModalDetalhes(data);
  };
  const events = [
    { title: 'Meeting', start: new Date() }
  ]

  const handleMonthChange = (payload) => {
    setCarregando(true);
    getAgenda(dayjs(payload.startStr).format('DD/MM/YYYY'), dayjs(payload.endStr).format('DD/MM/YYYY'));
  };

  return (
    <Box style={{ width: '100%', margin: 0, padding: 0 }}>
      <Grid container spacing={1} paddingX="0" marginLeft={2}>
        <Grid md={12} xs={12} style={{ padding: '0 10px', background: 'white' }}>
          <FullCalendar
            plugins={[dayGridPlugin]}
            locale={'pt-br'}
            initialView='dayGridMonth'
            weekends={true}
            events={agenda}
            eventContent={renderEventContent}
            dayCellContent={renderDayCellContent}
            eventClick={handleDateClick}
            datesSet={handleMonthChange}
            headerToolbar={
              {
                left: 'prev,next',
                center: 'title',
                right: '' // user can switch between the two
              }
            }
          />
        </Grid>
      </Grid>

      <Modal
        open={modalDetalhes.title != ""}
        // onClose={handleCloseModalMotivo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1} marginBottom={5}>
            <Grid md={12}>
              <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold', textAlign: 'center' }}>Detalhes</p>
              <CloseIcon onClick={() => handleCloseModalDetalhes()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
            </Grid>
            <Grid md={12}>
              <p style={{ color: '#666666', fontSize: 12, fontWeight: 400, fontFamily: "Campuni-Medium", textAlign: 'center' }}>
                <strong>{modalDetalhes.title}</strong><br/><br/>
                <strong>Hora:</strong> {modalDetalhes.start}<br/>
                <strong>Local:</strong> {modalDetalhes.local}
              </p>
            </Grid>
            <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                style={{ width: 160, height: 41 }}
                variant="contained"
                color="primary"
                component="label"
                onClick={handleCloseModalDetalhes}
              >
                Fechar
              </Button>
            </Grid>

          </Grid>
        </Box>
      </Modal>

      <Modal
          open={carregando}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Box sx={style}>
              <Grid container spacing={1} marginBottom={5}>
                  <Grid md={12} display={'flex'} justifyContent={'center'} marginTop={5}>
                    <CircularProgress size={20} />
                  </Grid>
                  <Grid md={12}>
                    <p style={{ fontSize: 18, color: '#666666', fontWeight: '500', textAlign: 'center' }}>Aguarde enquanto carregamos as informações...</p>
                  </Grid>
              </Grid>
          </Box>
      </Modal>
    </Box>
  )
}