import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import { IconButton, SvgIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import 'dayjs/locale/pt-br';
import dayjs from "dayjs";
export const ProdutoItem = ({ item }) => {
    const navigate = useNavigate();

    

    return (

        <Card sx={{ width: 184, height: 68, background: '#FFFFFF' }}>
            <CardContent style={{ padding: 0 }}>
                <div style={{ width: '100%', padding: '10px 10px 0 10px', display: 'flex', justifyContent: 'space-between' }}>
                    <span className="dots" style={{ color: '#005EB8', fontWeight: '700', fontFamily: "AlegraSans-Medium", fontSize: 16 }}>Marketing</span>
                </div>
                <p style={{ padding: '0 10px 10px 10px', margin: 0, fontWeight: 500, display: 'flex', alignItems: 'center' }}>
                 <span style={{ fontWeight: 500, color: '#999999', fontFamily: "AlegraSans" }}>Nº</span>
                </p>
            </CardContent>
        </Card>

    )
}