import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { chipClasses, CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useQuery } from "../../hooks/useQuery";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAuth } from "../../hooks/useAuth";
import dayjs from "dayjs";

export const Step3PF = ({ activeStep, setActiveStep, processoId }) => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAuth();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = React.useState(false);
    const { editalId } = useParams();
    const { showEdital, listaResumoPf, listaResumoPj } = useQuery();
    const [ativar, setAtivar] = React.useState(false);
    const [itens, setItens] = React.useState([]);
    const [itensBasico, setItensBasico] = React.useState([]);


    React.useEffect(() => {
        buscarResumoPj()
        getDocs();
    }, []);

    const buscarResumoPj = async () => {

    }

    const uid = function () {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    const getDocs = async () => {
        // setLoading(true);
        // const result = await listaResumoPf(user.fornecedor_id, processoId);
        // // const result = await showEdital(editalId ? editalId : edital.id)

        // // if (result && result.data) {
        // //     setEdital(result.data.data);
        // //     const areaEdital = result.data.data.area_edital;
        // //     console.log(areaEdital);
        // let treeitem = {
        //     id: `area-selecionadas`,
        //     label: "Areas",
        //     children: []
        // }

        // let treeitemBasico = {
        //     id: `docs-basico`,
        //     label: "Documentos básicos",
        //     children: []
        // }
        // if (result.data.data.basicos) {
        //     let basicos = Object.keys(result.data.data.basicos);
        //     basicos.forEach(res => {
        //         result.data.data.basicos[res].forEach((b) => {
        //             let aux = {
        //                 id: `blue-${uid()}`,
        //                 label: res,
        //                 children: [{
        //                     id: `black-${uid()}`,
        //                     label: `${b.file} - ${dayjs(b.data).format('DD/MM/YYYY HH:mm')}`,
        //                 }]
        //             };
        //             treeitemBasico.children.push(aux);

        //         })

        //     })
        // }


        // let areas = Object.keys(result.data.data.areas);
        // areas.forEach(res => {
        //     let aux = {
        //         id: `area-${res}`,
        //         label: res,
        //         children: []
        //     };
        //     result.data.data.areas[res].forEach((b) => {
        //         let aux2 = {
        //             id: `blue-${uid()}`,
        //             label: b.nome,
        //             children: [
        //                 {
        //                     id: `black-${uid()}`,
        //                     label: `${b.file} - ${dayjs(b.data).format('DD/MM/YYYY HH:mm')}`,
        //                 }
        //             ]
        //         };
        //         aux.children.push(aux2);

        //     })
        //     treeitem.children.push(aux);
        // })
        // setLoading(false);
        // setItens([treeitem]);
        // setItensBasico([treeitemBasico]);
        //     setLoading(false);
        // }

        setLoading(true);
        const result = await listaResumoPf(user.fornecedor_id, processoId);
  
        let treeitem = {
            id: `${uid()}area-selecionadas`,
            label: "Areas",
            children: []
        }

        let treeitemBasico = {
            id: `${uid()}docs-basico`,
            label: "Documentos básicos",
            children: []
        }

       
        let users = Object.keys(result.data.data);
        users.forEach(u => {

            if (result.data.data[u].basicos) {
                let auxUB = {
                    id: `${uid()}user-basico-${u}`,
                    label: u,
                    children: []
                };
                let basicos = Object.keys(result.data.data[u].basicos);
                basicos.forEach(res => {
                    result.data.data[u].basicos[res].forEach((b) => {
                        let aux = {
                            id: `blue-${uid()}`,
                            label: res,
                            children: [{
                                id: `black-${uid()}`,
                                label: b.file == null ? `Aguardando de envio` : `${b.file} - ${dayjs(b.data).format('DD/MM/YYYY HH:mm')} - Status: ${b.status == 'E' ? 'Em Analise' : b.status == 'A' ? 'Aprovado' : b.status == 'R' ? 'Reprovado' : 'Enviado'}`,
                            }]
                        };
            
                        auxUB.children.push(aux);
                    })
    
                })
                treeitemBasico.children.push(auxUB);
            }


            if (result.data.data[u].areas) {
                let auxU = {
                    id: `${uid()}user-${u}`,
                    label: u,
                    children: []
                };

                let areas = Object.keys(result.data.data[u].areas);
                console.log(areas)
                areas.forEach(res => {
                    let aux = {
                        id: `${uid()}area-${res}`,
                        label: res,
                        children: []
                    };
                    result.data.data[u].areas[res].forEach((b) => {
                        let aux2 = {
                            id: `blue-${uid()}`,
                            label: b.nome,
                            children: [
                                {
                                    id: `black-${uid()}`,
                                    label: b.file == null ? `Aguardando de envio` : `${b.file} - ${dayjs(b.data).format('DD/MM/YYYY HH:mm')} - Status: ${b.status == 'E' ? 'Em Analise' : b.status == 'A' ? 'Aprovado' : b.status == 'R' ? 'Reprovado' : 'Enviado'}`,
                                }
                            ]
                        };
                        aux.children.push(aux2);

                    })
                    // treeitem.children.push(aux);
                    auxU.children.push(aux);
                })
                treeitem.children.push(auxU);
            }
          
        })
   
        setLoading(false);
        setItens([treeitem]);
        setItensBasico([treeitemBasico]);
    }

    const finalizar = async () => {
        // const result = await updateStatusEdital(edital.id, { status: ativar ? 'A' : 'P' });
        // if (result && result.data) {
        //     snackbar.showSnackbar(`Edital criado com sucesso!`, '#05a55a');
        //     navigate("/editais");
        // }
    }

    function svgMinus(props) {

        return (
            <SvgIcon {...props}>
                <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.2143 0H0.785714C0.351853 0 0 0.298542 0 0.666667V1.33333C0 1.70146 0.351853 2 0.785714 2H10.2143C10.6481 2 11 1.70146 11 1.33333V0.666667C11 0.298542 10.6481 0 10.2143 0Z" fill="#005EB8" />
                </svg>
            </SvgIcon>
        )
    }


    function svgPlus(props) {

        return (
            <SvgIcon  {...props}>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.92857 0.714286L3.92857 3.92857L0.714285 3.92857C0.319866 3.92857 -2.51409e-07 4.24844 -2.34168e-07 4.64286L-2.02946e-07 5.35714C-1.85705e-07 5.75156 0.319866 6.07143 0.714286 6.07143L3.92857 6.07143L3.92857 9.28571C3.92857 9.68013 4.24844 10 4.64286 10L5.35714 10C5.75156 10 6.07143 9.68013 6.07143 9.28571L6.07143 6.07143L9.28571 6.07143C9.68013 6.07143 10 5.75156 10 5.35714L10 4.64286C10 4.24844 9.68013 3.92857 9.28571 3.92857L6.07143 3.92857L6.07143 0.714286C6.07143 0.319866 5.75156 -2.51409e-07 5.35714 -2.34168e-07L4.64286 -2.02946e-07C4.24844 -1.85705e-07 3.92857 0.319866 3.92857 0.714286Z" fill="#005EB8" />
                </svg>
            </SvgIcon>
        )
    }


    return (
        <Box style={{ width: '100%', marginTop: 20 }}>

            <Grid container spacing={1} paddingX="0">

                <Grid md={12} xs={12}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Card>
                            <CardContent>
                                <div style={{ marginTop: 40 }}>
                                    <RichTreeView
                                        items={itensBasico}
                                        slots={{
                                            expandIcon: svgPlus,
                                            collapseIcon: svgMinus,
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <RichTreeView
                                        items={itens}
                                        slots={{
                                            expandIcon: svgPlus,
                                            collapseIcon: svgMinus,
                                        }}
                                    />
                                </div>

                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Grid md={4} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid md={8} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => setActiveStep(activeStep + 1)}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={loading}
                        endIcon={<ArrowForwardIcon />}
                    >
                        Enviar
                    </Button>
                </Grid>
            </Grid>
        </Box >
    )
}