import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { isMobile } from "react-device-detect";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from "dayjs";

export const ContratosDetalheUsuarioPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { detalheContrato } = useQuery();
    const [contrato, setContrato] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loadingDownload, setLoadingDownload] = React.useState(false);

    React.useEffect(() => {
        if (id) {
            detalhesContrato(id);
        }
    }, [])


    const detalhesContrato = async (id) => {
        setLoading(true);
        const result = await detalheContrato(id);
        if (result && result.data) {
            setContrato(result.data.data[0]);
        }
        setLoading(false);
    }

    const downloadContrato = async () => {
        try {
            setLoadingDownload(true);
            downloadPdf(contrato.contrato_pdf, 'contrato.pdf');
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingDownload(false);
        }
    };

    async function downloadPdf(path, filename) {
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.setAttribute('target', '_blank');
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }


    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={1} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12} style={{ padding: '0 10px' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { navigate('/listar-contratos', { replace: true }) }}
                        edge="start"
                    >
                        <span
                            style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 12, fontWeight: 'bold', fontFamily: "Campuni-Bold" }}
                        >
                            <ArrowBackIcon /> Voltar
                        </span>
                    </IconButton>
                </Grid>
                <Grid md={12} xs={12}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold", fontSize: 18 }}>Contratos</span>
                </Grid>
                <Grid md={12} xs={12}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Card sx={{ minWidth: 290, borderLeft: 'solid 8px #005EB8' }}>
                            <CardContent>
                                <div style={{ alignItems: 'center', display: 'flex'}}>
                                    <p style={{ width: '100%', fontSize: 18, fontWeight: 500, color: '#222222', margin: 0 }}>{contrato && contrato.programa}</p>
                                    <Button
                                        onClick={() => {
                                            downloadContrato(contrato && contrato.contrato_dts);
                                        }}
                                        style={{ width: '200px', height: 41, marginTop: 30 }}
                                        variant="contained"
                                        disabled={loadingDownload}
                                    >
                                        Visualizar contrato
                                    </Button>
                                </div>

                                <p style={{ width: '100%', fontSize: 16, fontWeight: 500, color: '#999999', margin: '5px 0 0 0' }}>{contrato && contrato.contrato}</p>
                                <p style={{ width: '100%', fontSize: 16, fontWeight: 500, color: '#222222', margin: '5px 0 0 0' }}>Vigência</p>
                                <p style={{ width: '100%', fontSize: 16, fontWeight: 500, color: '#999999', margin: '5px 0 0 0' }}>{contrato && dayjs(contrato.fim).format('DD/MM/YYYY')}</p>
                                <p style={{ width: '100%', fontSize: 16, fontWeight: 500, color: '#222222', margin: '5px 0 0 0' }}>Objeto</p>
                                <p style={{ width: '100%', fontSize: 16, fontWeight: 500, color: '#999999', margin: '5px 0 0 0' }}>{contrato && contrato.objeto}</p>
                                <p style={{ width: '100%', fontSize: 16, fontWeight: 500, color: '#222222', margin: '5px 0 0 0' }}>Itens do contrato</p>
                                <div
                                    className="scrollbar"
                                    id="style-3"
                                    style={{ margin: 0, height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', padding: '0 10px' }}
                                >
                                    {contrato && contrato.itens && contrato.itens.map(res => {
                                        return (
                                            <>
                                                <p style={{ fontWeight: 500 }}>
                                                    <span style={{ fontFamily: "Campuni-Medium", fontWeight: 400, fontSize: 12, color: '#666666' }}>{res.item} - {res.descricao}</span>
                                                </p>
                                                <hr />
                                            </>
                                        )
                                    })}
                                </div>
                            </CardContent>
                        </Card>
                    )}

                </Grid>

            </Grid>
        </Box >
    )
}