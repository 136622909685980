import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from "../../hooks/useSnackbar";
import Checkbox from '@mui/material/Checkbox';
import { useQuery } from "../../hooks/useQuery";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CheckBoxItem } from "../../components/CheckBoxItem";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';

const TIPO_ARQUIVOS_PERMITIDOS = [
    'pdf',
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const EditalStep2Page = ({ edital, setEdital, activeStep, setActiveStep }) => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const { editalId } = useParams();
    const [checkedDocumentos, setCheckedDocumentos] = React.useState([]);
    const [isCheckAllDocumentos, setIsCheckAllDocumentos] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingDocumentos, setLoadingDocumentos] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [documentos, setDocumentos] = React.useState([]);
    const [documentosAll, setDocumentosAll] = React.useState([]);
    const [arquivo, setArquivo] = React.useState();
    const [titulo, setTitulo] = React.useState();
    const [errorTitulo] = React.useState(false);
    const [msgErrorTitulo] = React.useState("");
    const [diasVigencia, setDiasVigencia] = React.useState();
    const [docUnico, setDocUnico] = React.useState(false);
    const [docBasico, setDocBasico] = React.useState(false);
    const [docPj, setDocPj] = React.useState(false);
    const [docPf, setDocPf] = React.useState(false);
    const [vigencia, setVigencia] = React.useState(false);
    const [mostrarModelo, setMostrarModelo] = React.useState(false);
    const [selectedDocumento, setSelectedDocumento] = React.useState();
    const { editalStep2, listaTipoDocumentos, saveTipoDocumento, updateTipoDocumento, showEdital, removeModelo } = useQuery();

    React.useEffect(() => {
        buscarTipoDocumentos();
        getEdital();

    }, []);

    const getEdital = async () => {
        const result = await showEdital(editalId ? editalId : edital.id);

        if (result && result.data) {
            setEdital(result.data.data);
            if (result.data.data.documentos_edital) {
                let auxDocs = [];
                result.data.data.documentos_edital.forEach(res => {
                    auxDocs.push(res.tipo_documento_id);
                });
                console.log(auxDocs);
                setCheckedDocumentos(auxDocs);
            }
        }
    }

    const buscarTipoDocumentos = async () => {
        setLoadingDocumentos(true)
        const result = await listaTipoDocumentos();
        if (result && result.data) {
            setDocumentos(result.data.data);
            setDocumentosAll(result.data.data);
        }
        setLoadingDocumentos(false);
    }

    const onChangeFile = (event) => {
        const files = [...event.target.files];

        if (files.length > 0) {
            const file = files[0];

            const extension = file.name.split('.').pop();

            if (!TIPO_ARQUIVOS_PERMITIDOS.includes(extension.toLowerCase())) {
                snackbar.showSnackbar(
                    'Tipo de arquivo inválido. Somente ' +
                    TIPO_ARQUIVOS_PERMITIDOS.join(','), '#DC143C'
                );
                return;
            }

            setArquivo(file);
        }

    };

    const filtrarDocumentos = (term) => {
        setDocumentos(documentosAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const handleChangeAllDocumentos = (event) => {
        if (event.target.checked) {
            let docAux = [];
            documentos.forEach(doc => {
                docAux.push(doc.id);
            });
            setCheckedDocumentos(docAux);
            setIsCheckAllDocumentos(true);
        } else {
            setCheckedDocumentos([]);
            setIsCheckAllDocumentos(false);
        }
    };

    const editarDocumento = async (doc) => {
        setSelectedDocumento(doc);
        setDocBasico(Boolean(Number(doc.basico)));
        let tipo = doc.tipo.split('|');
        tipo.map(res => {
            if (res == 'PJ') {
                setDocPj(true);
            } else {
                setDocPf(true);
            }
        })
        setDocUnico(Boolean(Number(doc.unico)));
        setVigencia(doc.dias_vigencia !== null ? true : false);
        setDiasVigencia(doc.dias_vigencia);
        setTitulo(doc.nome);
        setArquivo(undefined);
        setMostrarModelo(Boolean(Number(doc.mostrar_modelo)));
        setOpen(true);
    }

    const childrenDocumentos = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {documentos && documentos.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedDocumentos}
                        checkedDocumentos={checkedDocumentos}
                        isCheckAllDocumentos={isCheckAllDocumentos}
                        editarDocumento={editarDocumento}
                        documentos={documentos}
                    />
                )
            })}
        </Box>
    );

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }


    const svgAnexo = () => {

        return (
            <SvgIcon>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.54449 16.3878C-0.542293 14.2683 -0.503401 10.8503 1.59399 8.73848L9.08542 1.19533C10.6681 -0.398373 13.2408 -0.398513 14.8237 1.19533C16.3912 2.77363 16.3931 5.32017 14.8237 6.90034L8.29335 13.4693C7.2271 14.5429 5.48503 14.5279 4.43685 13.4343C3.42703 12.3806 3.45942 10.7106 4.4887 9.6742L9.62238 4.51203C9.84317 4.29006 10.205 4.28626 10.4305 4.50356L11.2469 5.29032C11.4725 5.50766 11.4763 5.86379 11.2555 6.08577L6.12238 11.2474C5.94624 11.4248 5.93538 11.7195 6.09924 11.8905C6.25538 12.0534 6.50085 12.0561 6.65952 11.8963L13.1898 5.32734C13.8903 4.62204 13.8903 3.47373 13.1895 2.76804C12.5041 2.07803 11.4053 2.07768 10.7197 2.76804L3.22821 10.3112C1.98667 11.5613 1.96753 13.5849 3.18567 14.8222C4.40031 16.0559 6.3637 16.0574 7.5806 14.8322L13.7256 8.64475C13.9462 8.4226 14.308 8.41859 14.5337 8.63575L15.3507 9.42198C15.5764 9.63914 15.5805 9.99528 15.3598 10.2174L9.21485 16.4049C7.08713 18.5472 3.64545 18.5218 1.54449 16.3878Z" fill="white" />
                </svg>
            </SvgIcon>
        )
    }

    const onSubmit = (isRascunho) => {

        if (checkedDocumentos.length > 0) {
            const data = {
                documentos: checkedDocumentos,
                edital_id: edital.id
            }
            console.log(data);
            saveStep2(data);
            if (isRascunho) {
                navigate("/editais");
            } else {
                setActiveStep(activeStep + 1);
            }

        } else {
            if (isRascunho) {
                navigate("/editais");
            } else {
                setActiveStep(activeStep + 1);
            }
        }

    }

    const onErrors = (error) => {

    }

    const saveStep2 = async (data) => {
        setLoading(true);
        const result = await editalStep2(data);
        console.log(result.data);
        if (result && result.data) {
            setEdital(result.data.data);
        }
        setLoading(false);
    }


    const removerModelo = async (id) => {
        try {
            setLoading(true);
            const result = await removeModelo(id);

            if (result && result.data) {
                setSelectedDocumento(result.data.data);
                listaTipoDocumentos();
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }


    const handleClose = () => {
        setSelectedDocumento(undefined);
        setOpen(false);
        setDocBasico(false);
        setDocPf(false);
        setDocPj(false);
        setDocUnico(false);
        setVigencia(false);
        setDiasVigencia(undefined);
        setTitulo(undefined);
        setArquivo(undefined);
        setMostrarModelo(false);
    }

    const salvarDocumento = async () => {

        try {
            let body = {
                basico: docBasico ? 1 : 0,
                unico: docUnico ? 1 : 0,
                modelo: mostrarModelo ? 1 : 0,
                nome: titulo,
            }

            if (docPj && docPf) {
                body.tipo = 'PJ|PF';
            } else if (docPj && !docPf) {
                body.tipo = 'PJ';
            } else {
                body.tipo = 'PF';
            }

            if (vigencia) {
                body.dias_vigencia = diasVigencia;
            }

            if (arquivo) {
                body.arquivo = arquivo;
            }

            if (selectedDocumento) {
                const result = await updateTipoDocumento(selectedDocumento.id, body);

                if (result && result.data) {
                    snackbar.showSnackbar(`Documento alterado com sucesso!`, '#05a55a');
                    buscarTipoDocumentos();
                    setOpen(false);
                }
            } else {
                const result = await saveTipoDocumento(body);

                if (result && result.data) {
                    snackbar.showSnackbar(`Documento salvo com sucesso!`, '#05a55a');
                    buscarTipoDocumentos();
                    setOpen(false);
                }
            }

        } catch (error) {
            snackbar.showSnackbar('Erro ao criar o documento.', '#DC143C');
        }
    }

    return (
        <Box style={{ width: '100%', marginTop: 60 }}>
            <Grid container spacing={1} paddingX="0">
                <Grid xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Documentos</span>
                </Grid>
                <Grid md={9}>
                    <Box style={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                        <Button
                            style={{ width: isMobile ? '100%' : 182, height: 40 }}
                            variant="contained"
                            color="info"
                            component="label"
                            onClick={() => setOpen(true)}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Adicionar
                        </Button>
                    </Box>
                </Grid>
                <Grid md={12} xs={12}>
                    <TextField
                        id="customInput"
                        placeholder="Buscar"
                        fullWidth
                        className="custom-class"
                        onChange={(event) => {
                            filtrarDocumentos(event.target.value);
                        }}
                        variant="standard"
                        style={{ height: '38 !important', width: '100%', }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => { }}>
                                        {svgSearch()}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                {loadingDocumentos  ? (
                    <Grid md={12} style={{ marginTop: 20 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid md={12} xs={12} style={{ marginTop: 20 }}>
                        <div
                            className="scrollbar"
                            id="style-3"
                            style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', padding: '0 10px' }}
                        >
                            {documentos.length == documentosAll.length ? (
                                <FormControlLabel
                                    label="Marcar todos"
                                    control={
                                        <Checkbox
                                            checked={checkedDocumentos.length === documentos.length}
                                            onChange={handleChangeAllDocumentos}
                                        />
                                    }
                                />
                            ) : null}
                            {childrenDocumentos}
                        </div>
                    </Grid>
                )}
                <Grid md={4} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid md={8} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => { onSubmit(true) }}
                        style={{ width: isMobile ? '100%' : 177, height: 41, marginTop: 10, marginRight: isMobile ? 0 : 20, color: '#666666', borderColor: '#666666' }}
                        color="info"
                        variant="outlined"
                        endIcon={<SaveIcon style={{ color: '#666666' }} />} >
                        Salvar rascunho
                    </Button>
                    <Button
                        onClick={() => {
                            onSubmit(false);
                        }}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={loading}
                        endIcon={loading ? <CircularProgress style={{ color: 'white' }} size={15} /> : <ArrowForwardIcon />}>
                        Avançar
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                        <Grid container spacing={1} marginBottom={5}>
                            <Grid md={12}>
                                <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold' }}>Novo documento </p>
                                <CloseIcon onClick={() => handleClose()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                            </Grid>
                            <Grid md={12}>
                                <Box style={{ width: '100%' }}>
                                    <Button
                                        style={{ width: 'auto', height: 40 }}
                                        variant="contained"
                                        color="info"
                                        component="label"
                                        endIcon={svgAnexo()}
                                    >
                                        Anexar arquivo(s)
                                        <input
                                            onChange={onChangeFile}
                                            hidden
                                            accept={TIPO_ARQUIVOS_PERMITIDOS.join(',')}
                                            type="file"
                                        />
                                    </Button>
                                </Box>
                                {arquivo ? (
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                        <span>{arquivo.name}</span>
                                        <DeleteIcon onClick={() => setArquivo(undefined)} style={{ cursor: 'pointer' }} color="error" />
                                    </Box>

                                ) : null}
                                {selectedDocumento && selectedDocumento.modelo ? (
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                        <span>{selectedDocumento.modelo.split('/')[3]}</span>
                                        <IconButton onClick={() => removerModelo(selectedDocumento.id)} style={{ cursor: 'pointer', fontSize: 16 }} color="error">Remover</IconButton>
                                    </Box>

                                ) : null}
                            </Grid>
                            <Grid md={12}>
                                <TextField
                                    style={{ marginTop: '5px' }}
                                    margin="normal"
                                    value={titulo}
                                    error={errorTitulo}
                                    helperText={msgErrorTitulo}
                                    onChange={(e) => {
                                        setTitulo(e.target.value);
                                    }}
                                    fullWidth
                                    label="Título"
                                />
                            </Grid>
                            <Grid md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={docUnico}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setDocUnico(true);
                                                } else {
                                                    setDocUnico(false);
                                                }
                                            }}
                                            style={{ padding: '4px' }}
                                        />
                                    }
                                    label={'Documento único'}
                                />
                            </Grid>
                            <Grid md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={docBasico}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setDocBasico(true);
                                                } else {
                                                    setDocBasico(false);
                                                }
                                            }}
                                            style={{ padding: '4px' }}
                                        />
                                    }
                                    label={'Documento básico'}
                                />
                            </Grid>
                            <Grid md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mostrarModelo}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setMostrarModelo(true);
                                                } else {
                                                    setMostrarModelo(false);
                                                }
                                            }}
                                            style={{ padding: '4px' }}
                                        />
                                    }
                                    label={'Mostrar modelo'}
                                />
                            </Grid>
                            <Grid md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={docPj}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setDocPj(true);
                                                } else {
                                                    setDocPj(false);
                                                }
                                            }}
                                            style={{ padding: '4px' }}
                                        />
                                    }
                                    label={'Documento PJ'}
                                />
                            </Grid>
                            <Grid md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={docPf}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setDocPf(true);
                                                } else {
                                                    setDocPf(false);
                                                }
                                            }}
                                            style={{ padding: '4px' }}
                                        />
                                    }
                                    label={'Documento PF'}
                                />
                            </Grid>
                            <Grid md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={vigencia}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setVigencia(true);
                                                } else {
                                                    setVigencia(false);
                                                    setDiasVigencia(0);
                                                }
                                            }}
                                            style={{ padding: '4px' }}
                                        />
                                    }
                                    label={'Vigência'}
                                />
                            </Grid>
                            <Grid md={2} display={'flex'} alignItems={'center'}>
                                <TextField
                                    style={{ marginTop: '5px', padding: '8px !important' }}
                                    variant="standard"
                                    type="number"
                                    onInput={(e) => {
                                        e.target.value = e.target.value
                                          .replace(/[^0-9.]/g, '');
                                      }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={diasVigencia}
                                    disabled={!vigencia}
                                    onChange={(e) => {
                                        setDiasVigencia(e.target.value);
                                    }}
                                    fullWidth
                                />
                                <span>dias</span>
                            </Grid>
                            <Grid md={6}></Grid>
                            <Grid mdOffset={9} md={3}>
                                <Button
                                    onClick={() => {
                                        salvarDocumento();
                                    }}
                                    style={{ width: '100%', height: 41, marginTop: 10 }}
                                    variant="contained"
                                    disabled={loading}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal >
        </Box >
    )
}