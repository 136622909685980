import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { Pagination } from '@mui/material';
import { EditalItem } from "../../components/EditalItem";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { isMobile } from "react-device-detect";
import { ProcessoItem } from "../../components/ProcessoItem";

export const EtapasProcessoPage = () => {

    const navigate = useNavigate();
    const { listaProcessos } = useQuery();
    const [processos, setProcessos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(0);
    
    const svgComnunicado = () => {
        return (
            <SvgIcon>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41602 13.4583V3.54167C1.41602 3.16594 1.56527 2.80561 1.83095 2.53993C2.09662 2.27426 2.45696 2.125 2.83268 2.125H14.166C14.5417 2.125 14.9021 2.27426 15.1678 2.53993C15.4334 2.80561 15.5827 3.16594 15.5827 3.54167V13.4583C15.5827 13.8341 15.4334 14.1944 15.1678 14.4601C14.9021 14.7257 14.5417 14.875 14.166 14.875H2.83268C2.45696 14.875 2.09662 14.7257 1.83095 14.4601C1.56527 14.1944 1.41602 13.8341 1.41602 13.4583Z" stroke="white" strokeWidth="1.5" />
                    <path d="M1.41602 4.95831H15.5827M6.37435 9.91665H8.49935M8.49935 9.91665H10.6243M8.49935 9.91665V7.79165M8.49935 9.91665V12.0416" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }


    React.useEffect(() => {
        getProcessos(1);
    }, [])

    const getProcessos = async (pagina = 1) => {
        setLoading(true)
        const result = await listaProcessos({ pagination: true, page: pagina });
        if (result && result.data && result.data.data) {
            setProcessos(result.data.data.data);
            setPage(result.data.data.current_page);
            setLastPage(result.data.data.last_page);
            setTotal(result.data.data.total);
        }
        setLoading(false);
    }

    const handleChange = (event, value) => {
        setPage(value);
        getProcessos(value);
    };

    // React.useEffect(() => {
    //     const delayInputTimeoutId = setTimeout(() => {
    //         getUsers(1);
    //     }, 500);
    //     return () => clearTimeout(delayInputTimeoutId);
    // }, [search, 500]);

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={1} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12} style={{ marginBottom: 20, width: isMobile ? '100%' : 636 }}>
                    <TextField
                        className="customSearchBar"
                        placeholder="Buscar processos"
                        fullWidth
                        onChange={(event) => {

                        }}
                        style={{ height: 38, width: '100%', margin: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => { }}>
                                        {svgSearch()}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid md={12} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 200, height: 41, marginTop: 10, marginBottom: 40, color: '#fff' }}
                        color="info"
                        onClick={() => navigate('/criar-processo')}
                        variant="contained"
                        startIcon={svgComnunicado()}
                    >
                        Novo processo
                    </Button>
                </Grid>
                <Grid md={12} xs={12}>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: ' center' }}>
                            <CircularProgress />
                        </div>
                    ) : null}
                    {processos && processos.length > 0 && processos.map((processo, index) => {
                        return (
                            <ProcessoItem key={index} processo={processo} atualizaLista={getProcessos} />
                        )
                    })}
                </Grid>
                <Box
                    marginTop={5}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Pagination
                    className="customPagination"
                        variant="outlined"
                        shape="rounded"
                        count={lastPage}
                        page={page}
                        color={'primary'}
                        onChange={handleChange}
                    />
                </Box>
            </Grid>
        </Box >
    )
}