import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import EditalImg from '../assets/edital.png';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export const EditalUsuarioItem = ({ item }) => {
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_BASE_URL;
    const [processo, setProcesso] = React.useState();

    React.useEffect(() => {
        retornaIdProcesso();
    }, [])

    const retornaIdProcesso = () => {
      const findProcessoAtivo =  item && item.processos.find(e => e.status == 'A');
      setProcesso(findProcessoAtivo);
    }
    return (

        <Card sx={{ maxWidth: 300, minHeight: 324, marginTop: isMobile ? 5 : 0 }}>
            <CardMedia
                component="img"
                height="200"
                image={`${url}/storage/${item.image}`}
                alt="Edital"
            />
            <CardContent style={{ padding: 0 }}>
                <p className="dots" style={{ padding: '0 10px', margin: '15px 0', fontSize: 14, fontWeight: 700, color: '#666666' }}>{item && item.titulo}</p>
            </CardContent>
            <CardActions style={{ flexWrap: 'wrap', padding: 0, margin: 0 }}>
                <div onClick={() => navigate(`/edital-usuario/${item && item.id}`)} style={{ marginLeft: 10, marginBottom: 10, fontSize: 14, color: '#005EB8', display: 'flex', alignContent: 'center', cursor: "pointer", width: '100%' }}>
                    <b>Mais informações</b>
                    <ArrowRightAltIcon style={{ marginLeft: 10 }} />
                </div>
                {item && item.processos.length > 0 ? (
                    <div style={{ margin: 0, width: '100%', color: processo ? '#005EB8' : '#666666', background:   item.processos[0].status == 'A' ? '#84F4BC' :   item.processos[0].status == 'P' ? '#FFA500' : '#CCCCCC', padding: 5, textAlign: 'center', fontWeight: '500' }}>
                        { item.processos[0].status == 'A' ? 'Processo aberto' :  item.processos[0].status == 'F' ? 'Processo fechado' : 'Previsto'}
                    </div>
                ) : (
                    <div style={{ margin: 0, width: '100%', color: '#666666', background: '#CCCCCC', padding: 5, textAlign: 'center', fontWeight: '500' }}>
                        Sem processo
                    </div>
                )}
            </CardActions>
        </Card>

    )
}