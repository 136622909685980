import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import 'dayjs/locale/pt-br';
import { useQuery } from "../../hooks/useQuery";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { PerfilStep1Page } from "./PerfilStep1Page";
import { PerfilStep2Page } from "./PerfilStep2Page";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "../../hooks/useSnackbar";

export const PerfilUsuarioPage = () => {

    const { user } = useAuth();
    const snackbar = useSnackbar();
    const { updatePassword } = useQuery();
    const navigate = useNavigate();
    const [valueTab, setValueTab] = React.useState("1");
    const [status, setStatus] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(true);

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();

    React.useEffect(() => {
    }, []);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const UserTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
        fontSize: '18px !important',
    }));

    const alterarSenha = async (data) => {
        const result = await updatePassword(user.user_id, { senha: data.senha });

        if (result && result.data) {
            snackbar.showSnackbar('Senha atualizada com sucesso.', '#05a55a');
        }
        

    }

    const onErrors = (error) => {

    }

    const onSubmit = (data) => {
        console.log(data);
        if (data.senha !== data.confirmar_senha) {
            snackbar.showSnackbar('As senhas não conferem!', '#DC143C');
            return;
        }

        alterarSenha(data);
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container spacing={5} paddingX="0" marginLeft={2}>
                <Grid md={12} xs={12} style={{ padding: '0 10px' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { navigate('/usuarios', { replace: true }) }}
                        edge="start"
                    >
                        <span
                            style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 12, fontWeight: 'bold', fontFamily: "Campuni-Bold" }}
                        >
                            <ArrowBackIcon /> Voltar
                        </span>
                    </IconButton>
                </Grid>
                <Grid md={12}>
                    <span
                        style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 18, fontWeight: 500, fontFamily: "Campuni-Medium" }}
                    >Perfil</span>
                </Grid>
                <Grid md={12} xs={12}>
                    <TabContext value={valueTab}>
                        <TabList value={valueTab} onChange={handleChangeTab} aria-label="lab API tabs example">
                            <UserTab label="Dados Pessoais" value="1" />
                            <UserTab label="Dados Jurídicos " value="2" />
                            <UserTab label="Segurança " value="3" />
                        </TabList>
                        <TabPanel value="1">
                            <PerfilStep1Page />
                        </TabPanel>
                        <TabPanel value="2">
                            <PerfilStep2Page />
                        </TabPanel>
                        <TabPanel value="3">
                            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                                <Grid container spacing={2}>
                                    <Grid md={4}>
                                        <Controller
                                            name="senha"
                                            control={control}
                                            rules={{
                                                required: 'Senha é obrigatório',
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    margin="normal"
                                                    type={!showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    onMouseUp={handleMouseUpPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),

                                                    }}
                                                    value={value}
                                                    error={error}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Senha"
                                                />

                                            )}
                                        />
                                    </Grid>
                                    <Grid md={4}>
                                        <Controller
                                            name="confirmar_senha"
                                            control={control}
                                            rules={{
                                                required: 'Confirmar senha é obrigatório',
                                            }}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    margin="normal"
                                                    type={!showPassword ? 'text' : 'password'}
                                                    value={value}
                                                    error={error}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    onMouseUp={handleMouseUpPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),

                                                    }}
                                                    helperText={error && error.message}
                                                    onChange={onChange}
                                                    fullWidth
                                                    label="Confirmar senha"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid mdOffset={9} md={3} textAlign={'right'}>
                                        <Button style={{ width: '182px', height: 41, marginTop: 10, color: '#fff' }} color="info" type="submit" variant="contained">Salvar</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                    </TabContext>
                </Grid>
                <Grid md={2} xs={0}></Grid>
            </Grid>
        </Box >
    )
}