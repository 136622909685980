import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import People from '../../assets/bg-edital.png';
import ImgEdital from '../../assets/banner_edital_credenciamento.png';
import EditalBg from '../../assets/editalshow.png';
import Countdown from "../../components/CountDown";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import dayjs from 'dayjs';
import { CircularProgress, SvgIcon } from '@mui/material';
import { useAuth } from "../../hooks/useAuth";
import CountdownTimer from "../../components/CountdownTimer";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { isMobile } from "react-device-detect";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};


export const ShowEditalUsuario = () => {

    const { id } = useParams();
    const { user } = useAuth();
    const { showEditalPublic, saveAceiteProcesso, buscarComunicadoEdital, validarInscricao } = useQuery();
    const [edital, setEdital] = React.useState();
    const [processo, setProcesso] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [termoAceite, setTermoAceite] = React.useState();
    const [isAceite, setIsAceite] = React.useState(false);
    const [openModalAceite, setOpenModalAceite] = React.useState(false);
    const [comunicadoEdital, setComunicadoEdital] = React.useState();
    const [isInscrito, setIsInscrito] = React.useState(false);
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_BASE_URL;

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            getEdital();
            getComunicadoEdital();

        }

    }, []);

    React.useEffect(() => {
        retornaIdProcesso();
    }, [edital]);


    React.useEffect(() => {
        if (processo) {
            verificaInscricao();
        }
    }, [processo]);



    const getEdital = async () => {
        const result = await showEditalPublic(id);

        if (result && result.data) {
            setEdital(result.data.data);

        }

    }

    const verificaInscricao = async () => {
        const result = await validarInscricao(user && user.representante_legal ? 1 : 0, processo && processo.id);

        if (result && result.data) {
            console.log(result.data.data);
            setIsInscrito(result.data.data);

        }

    }

    const getComunicadoEdital = async () => {
        const result = await buscarComunicadoEdital(id);

        if (result && result.data) {
            setComunicadoEdital(result.data.data);

        }

    }

    const aceite = async () => {

        const result = await saveAceiteProcesso({ processo_id: null, fornecedor_id: user.fornecedor_id, edital_id: id });

        if (result && result.data) {
            setTermoAceite(result.data.data);
            getEdital();
        }
        setLoading(false);
    }

    const retornaIdProcesso = () => {
        const findProcessoAtivo = edital && edital.processos.find(e => e.status == 'A');
        if (findProcessoAtivo == undefined && edital && edital.processos.length > 0) {
            setProcesso(edital && edital.processos[0]);
        } else {
            setProcesso(findProcessoAtivo);
        }
    }

    const svgCheck = () => {

        return (
            <SvgIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.99906 20.42L2.78906 14.21L5.61906 11.38L8.99906 14.77L18.8791 4.88L21.7091 7.71L8.99906 20.42Z" fill="#40BBFF" />
                </svg>
            </SvgIcon>
        )
    }


    const validaInscricao = () => {

        if ((edital && edital.aceite && !edital.aceite.find(a => a.user_id == user.user_id)) || (user.representante_legal == 0 && !isInscrito)) {
            return true;
        }

        if (processo && processo.status == 'F') {
            return false;
        }

        if (processo) {
            if (dayjs().isAfter(dayjs(processo.data_abertura)) && dayjs().isBefore(dayjs(processo.data_fechamento))) {
                return false;
            }
        } else {
            return true;
        }
        return true;
    }

    const validaEdital = () => {

        if (edital && edital.permanente == 0) {
            if (edital && edital.data_fechamento) {
                if (dayjs().isAfter(dayjs(edital.data_fechamento))) {
                    return true;
                }
            }
        }

        return false;
    }



    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            <Grid container style={{ backgroundImage: `url(${ImgEdital})`, backgroundRepeat: 'no-repeat', height: isMobile ? 200 : 350, backgroundSize: isMobile ? 'contain' : 'inherit' }} spacing={0} paddingX="0" paddingY="0">
                <Grid sm={12} style={{ display: "flex", alignItems: 'flex-end' }}>
                    <div style={{ marginBottom: 3 }}>
                        <Box style={{ marginTop: 40, background: edital && edital.processos.length > 0 && edital.processos[edital.processos.length - 1].status == 'A' ? '#84F4BC' : edital && edital.processos.length > 0 && edital.processos[edital.processos.length - 1].status == 'P' ?  '#FFA500' :'#CCCCCC', width: '50%', height: 60, borderTopLeftRadius: 5, borderTopRightRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {edital && edital.processos.length > 0 && edital.processos[edital.processos.length - 1].status == 'A' ? (<b>Processo Aberto</b>) : edital && edital.processos.length > 0 && edital.processos[edital.processos.length - 1].status == 'F' ? (<b>Processo Fechado</b>) : edital && edital.processos.length > 0 && edital.processos[edital.processos.length - 1].status == 'P' ? (<b>Previsto</b>) : (<b>Sem processo</b>)}

                        </Box>
                        {edital && edital.processos && edital.processos.length > 0 ? (
                            <CountdownTimer targetDate={new Date(edital.processos[edital.processos.length - 1] && edital.processos[edital.processos.length - 1].data_abertura)} />
                        ) : (
                            <Countdown finalDate={null} />
                        )}
                    </div>
                </Grid>

            </Grid>
            <Grid container spacing={2} paddingX="1rem">
                <Grid md={12}>
                    <p style={{ color: '#005EB8', margin: '80px 0 0 0', fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 32 }}>Edital: {edital && edital.titulo}</p>
                    <p style={{ color: '#666666', margin: '10px 0 10px 0', fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 18 }}>Data publicação: {edital && dayjs(edital.data_abertura).locale('pt-br').format('DD/MM/YYYY HH:mm')}</p>
                    {edital && edital.permanente == 0 ? (
                        <p style={{ color: '#666666', margin: 0, fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 18 }}>Data fechamento: {edital && dayjs(edital.data_fechamento).locale('pt-br').format('DD/MM/YYYY HH:mm')}</p>
                    ) : null}
                    {processo ? (
                        <>
                            <p style={{ color: '#005EB8', margin: '1px 0 0 0', fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 32 }}>Processo: {processo && processo.titulo}</p>
                            <p style={{ color: '#666666', margin: '10px 0 10px 0', fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 18 }}>Data abertura: {processo && dayjs(processo.data_abertura).locale('pt-br').format('DD/MM/YYYY HH:mm')}</p>
                            <p style={{ color: '#666666', margin: 0, fontWeight: 500, fontFamily: 'Campuni-Medium', fontSize: 18 }}>Data fechamento: {processo && dayjs(processo.data_fechamento).locale('pt-br').format('DD/MM/YYYY HH:mm')}</p>
                        </>
                    ) : null}

                    {/* <p style={{ color: '#005EB8', fontWeight: 400, fontFamily: 'Campuni-Medium', fontSize: 18 }}>{edital && edital.texto}</p> */}
                    <p style={{ color: '#005EB8', fontWeight: 400, fontFamily: 'Campuni-Medium', fontSize: 18 }}>
                        {edital && edital.texto}
                    </p>
                    {processo && processo.documento ? (
                        <p
                            style={{ color: '#005EB8', fontWeight: '700', cursor: 'pointer', fontSize: 18 }}
                            onClick={() => window.open(`${url}/storage/${processo.documento}`, '_blank').focus()}
                        >
                            Clique aqui para acessar o Comunicado de abertura
                        </p>
                    ) : null}

                </Grid>
                <Grid md={4}>
                    <Button
                        style={{ width: '100%', height: 57, marginTop: 10 }}
                        color="primary" variant="outlined"
                        onClick={() => window.open(`${url}/storage/${edital.documento}`, '_blank').focus()}
                    >
                        Acessar edital
                    </Button>
                </Grid>
                <Grid md={4}>
                    {edital && edital.aceite && edital.aceite.length > 0 && edital.aceite.find(a => a.user_id == user.user_id) ? (
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', height: 57 }}>
                            <p style={{ display: 'flex', justifyContent: 'center', color: '#40BBFF', fontSize: 18, fontWeight: 500, margin: 0 }}>Termos do Edital Aceito {svgCheck()}</p>
                            <p style={{ color: '#999999', fontSize: 12, fontWeight: 500, margin: 0 }}>Termo aceito em {dayjs(edital.aceite.find(a => a.fornecedor_id == user.fornecedor_id).created_at).format('DD/MM/YYYY HH:mm')}</p>
                        </Box>

                    ) : (
                        <Button
                            style={{ width: '100%', height: 57, marginTop: 10, background: '#40BBFF' }}
                            color="primary" variant="contained"
                            onClick={() => setOpenModalAceite(true)}
                            disabled={validaEdital()}
                        >
                            Aceitar termos do Edital
                        </Button>
                    )}

                </Grid>
                <Grid md={4}>
                    <Button
                        style={{ width: '100%', height: 57, marginTop: 10 }}
                        color="primary" variant="contained"
                        disabled={validaInscricao()}
                        onClick={() => navigate(`/inscricao-usuario/${processo && processo.id}`)}
                    >
                        {(processo && processo.status == 'F') || (isInscrito) ? 'Ver inscrição' : 'Inscreva-se'}
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={openModalAceite}
                onClose={() => setOpenModalAceite(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12}>
                            <div
                                className="scrollbar"
                                id="style-3"
                                style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                            >
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    Declaro,
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    I. Ser o representante legal da presente empresa;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    II. Estou ciente e aceito todos os termos e condições do edital do SEBRAE/MG;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    III. Estou ciente que o presente Edital não significa obrigatoriedade do SEBRAE/MG solicitar a prestação de serviço, não caracterizando qualquer expectativa de demanda;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    IV. Estou ciente que somente os profissionais destacados por esta empresa, nos termos do Edital do SEBRAE/MG, poderão atuar na prestação de serviços;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    V. Que nenhum dos profissionais indicados exerce cargo, função ou possuem emprego público ou privado que tenha natureza ou exija exclusividade no exercício da atividade. Após esta data, caso alguém de minha equipe venha a exercer qualquer atividade de natureza exclusiva, pública ou privada, comprometemo-nos a comunicar o fato imediatamente ao SEBRAE/MG;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    VI. Que os sócios, dirigentes e/ou profissionais desta, ou indicados por esta empresa não possuem parentes de até segundo grau, consanguíneos ou por afinidade, na condição de empregado, dirigente ou ocupante de função de confiança, do SEBRAE/MG. Após esta data, caso venha possuir, comprometemo-nos a comunicar o fato imediatamente ao SEBRAE/MG;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    VII. Que os sócios, dirigentes e/ou profissionais desta, ou indicados por esta empresa não figuram como cônjuges, companheiros ou parentes em linha reta ou colateral, por consanguinidade ou afinidade, até o segundo grau, de empregados ou dirigentes do SEBRAE/MG. Após esta data, caso venha figurar, comprometemo-nos a comunicar o fato imediatamente ao SEBRAE/MG;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    VIII. Que não nos encontramos sob a decretação de falência, dissolução ou liquidação;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    IX. Que não estamos suspensos do direito de licitar ou contratar com o Sistema Sebrae;
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    X. Que esta empresa não tem restrições de qualquer natureza resultantes de contratos firmados anteriormente com o Sistema Sebrae.
                                </p>  <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    XI. Que esta empresa não emprega ou empregará menores de 18 (dezoito) anos em trabalho noturno, perigoso ou insalubre e de qualquer trabalho a menores de 16 (dezesseis) anos, salvo na condição de aprendiz, a partir de 14 (quatorze) anos, de acordo com o disposto no inciso XXXIII do Art. 7º da Constituição da República Federativa do Brasil, de 05 de outubro de 1988.
                                </p>
                                <p style={{ fontSize: 18, color: '#666666', fontWeight: '500' }}>
                                    XII. Que estou ciente da responsabilidade em informar ao SEBRAE/MG qualquer alteração que ocorra na composição dos sócios e/ou acionistas, sob pena de suspensão ou descredenciamento.
                                </p>
                            </div>
                        </Grid>
                        <Grid md={12}>
                            <FormGroup onChange={(e) => {
                                if (e.target.checked) {
                                    setIsAceite(true);
                                } else {
                                    setIsAceite(false);
                                }
                            }}>
                                <FormControlLabel control={<Checkbox />} label="Aceite os termos do edital para poder se inscrever" />
                            </FormGroup>
                        </Grid>
                        <Grid md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <IconButton
                                color="inherit"
                                style={{ height: 24, width: 100 }}
                                aria-label="open drawer"
                                onClick={() => setOpenModalAceite(false)}
                                edge="start"
                            >
                                <span style={{ fontSize: 12, color: '#F4455A', fontWeight: '700' }}>Cancelar</span>
                            </IconButton>
                            <Button
                                onClick={() => {
                                    aceite();
                                    setOpenModalAceite(false);
                                }}
                                disabled={!isAceite}
                                style={{ width: '100px', height: 41 }}
                                variant="contained"

                            >
                                {loading ? (
                                    <CircularProgress size={20} />
                                ) : null}

                                Aceitar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
        </Box >
    )
}