import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";

export const SectionAboutPage = () => {
    const navigate = useNavigate();
    return (
        <section className="sectionAbout" style={{ width: '100%', margin: 0, padding: 0, minHeight: 500 }}>
            <Grid spacing={2} container className={'paddingAbout'}>
                <Grid xs={12}>
                    <p style={{ fontSize: 32, color: '#fff', textAlign: 'center', fontWeight: '500' }}>Porque ser um credenciado Sebrae/MG ?</p>
                </Grid>
                <Grid md={6} xs={12}>
                    <p style={{ fontSize: 16, color: '#FFF', fontWeight: '500' }}>Amplie seus horizontes de experiência com empreendedores de diferentes setores.</p>
                </Grid>
                <Grid md={6} xs={12}>
                    <p style={{ fontSize: 16, color: '#FFF', fontWeight: '500' }}>Expanda sua rede de contatos estratégicos em diversos segmentos</p>
                </Grid>
                <Grid md={6} xs={12}>
                    <p style={{ fontSize: 16, color: '#FFF', fontWeight: '500' }}>Tenha uma instituição de referência no seu portifólio  e eleve o status da sua empresa no mercado!</p>
                </Grid>
                <Grid md={6} xs={12}>
                </Grid>
                <Grid xs={12}>
                    <Button onClick={() => navigate('/cadastro')} style={{ width: '100%', height: 41, marginTop: 40, color: '#005EB8', fontSize: 18 }} color="secondary" variant="contained">Credencie sua empresa</Button>
                </Grid>
            </Grid>
        </section>
    )
}