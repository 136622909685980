import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./hooks/useAuth";
import { SnackbarProvider } from "./hooks/useSnackbar";
// import { ModalLoading } from "./hooks/useModalLoading";
import { QueryProvider } from "./hooks/useQuery";
import App from "./App";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

const theme = createTheme({
  palette: {
    primary: { main: "#3a34d2" },
  },
});

root.render(
  // <StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <QueryProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              {/* <ModalLoading> */}
              <App />
              {/* </ModalLoading> */}
            </SnackbarProvider>
          </ThemeProvider>
        </QueryProvider>
      </AuthProvider>
    </BrowserRouter>
  // </StrictMode>
);
