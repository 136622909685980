import Snackbar from "@mui/material/Snackbar";
import { createContext, useContext, useMemo, useState } from "react";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null);
  const [color, setColor] = useState(null);

  const showSnackbar = (msg, color) => {
    setMessage(msg);
    setColor(color);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
      }}
    >
      {children}
      <Snackbar
        ContentProps={{
          sx: { background: color }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={show}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      >
      </Snackbar>
    </SnackbarContext.Provider >
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error("useSnackbar não possuí context");
  }

  return context;
};
