import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { isMobile } from "react-device-detect";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputAdornment } from '@mui/material';
import { useQuery } from "../hooks/useQuery";
import { useSnackbar } from "../hooks/useSnackbar";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const UsuarioEmpresaItem = ({ item, fornecedor, atualizaLista }) => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openCompanies, setOpenCompanies] = React.useState(false);
    const [personName, setPersonName] = React.useState([]);
    const [selectTipo, setSelectTipo] = React.useState();
    const [abilityClose, setAbilityClose] = React.useState(false);
    const [fornecedores, setFornecedores] = React.useState([]);
    const [fornecedoresAll, setFornecedoresAll] = React.useState([]);
    const { listarPj, updateColaborador } = useQuery();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setSelectTipo(item.representante_legal == 1 ? 1 : item.funcionario == 1 ? 2 : 3);
        getUsers();
    }, []);

    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    }

    const handleCloseCompanies = () => {
        if (abilityClose) {
            setOpenCompanies(false);
        }

    }

    const svgAlert = () => {

        return (
            <SvgIcon style={{ width: 61, height: 65 }}>
                <svg width="61" height="55" viewBox="0 0 61 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M60.3135 46.5986C62.2682 49.9869 59.8146 54.2222 55.9103 54.2222H5.08908C1.17735 54.2222 -1.26498 49.9803 0.685963 46.5986L26.0969 2.54008C28.0526 -0.849763 32.9509 -0.843621 34.9031 2.54008L60.3135 46.5986ZM30.5 37.4896C27.8096 37.4896 25.6285 39.6707 25.6285 42.3611C25.6285 45.0516 27.8096 47.2326 30.5 47.2326C33.1905 47.2326 35.3716 45.0516 35.3716 42.3611C35.3716 39.6707 33.1905 37.4896 30.5 37.4896ZM25.8749 19.979L26.6605 34.3818C26.6973 35.0557 27.2545 35.5833 27.9294 35.5833H33.0706C33.7455 35.5833 34.3028 35.0557 34.3395 34.3818L35.1251 19.979C35.1648 19.251 34.5852 18.6389 33.8562 18.6389H27.1438C26.4147 18.6389 25.8352 19.251 25.8749 19.979Z" fill="#CCCCCC" />
                </svg>
            </SvgIcon>
        )
    }


    const svgEdit = () => {

        return (
            <SvgIcon>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 2.125H3.54167C3.16594 2.125 2.80561 2.27426 2.53993 2.53993C2.27426 2.80561 2.125 3.16594 2.125 3.54167V13.4583C2.125 13.8341 2.27426 14.1944 2.53993 14.4601C2.80561 14.7257 3.16594 14.875 3.54167 14.875H13.4583C13.8341 14.875 14.1944 14.7257 14.4601 14.4601C14.7257 14.1944 14.875 13.8341 14.875 13.4583V8.5" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.015 1.85935C13.2968 1.57756 13.679 1.41925 14.0775 1.41925C14.476 1.41925 14.8582 1.57756 15.14 1.85935C15.4218 2.14114 15.5801 2.52334 15.5801 2.92185C15.5801 3.32037 15.4218 3.70256 15.14 3.98435L8.49935 10.625L5.66602 11.3333L6.37435 8.49998L13.015 1.85935Z" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </SvgIcon>
        )
    }

    const handleChangeMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setPersonName(value);
    };

    const openModalEmpresas = () => {
        setAbilityClose(false);
        setOpenConfirm(false);
        setOpenCompanies(true);
    }

    const openModalConfirm = () => {
        setOpen(false);
        setOpenConfirm(true);
    }

    const atualizarTipo = async () => {
        let data = { ...item };
        if (selectTipo == '1') {
            data.representante_legal = 1;
            data.funcionario = 0;
            data.terceirizado = 0;
        } else if (selectTipo == 2) {
            data.representante_legal = 0;
            data.funcionario = 1;
            data.terceirizado = 0;
        } else {
            data.representante_legal = 0;
            data.funcionario = 0;
            data.terceirizado = 1;
        }

        try {
            setLoading(true);
            const result = await updateColaborador(data.id, data);
            if (result && result.data) {
                snackbar.showSnackbar(`Usuário atualizado com sucesso!`, '#05a55a');
            }
            setLoading(false);
            setOpen(false);
            atualizaLista();

        } catch (error) {
            snackbar.showSnackbar(`Erro ao atualizar usuário!`, '#DC143C');
            setLoading(false);
        }

    }



    const atualizarColaborador = async () => {
        let data = { ...item };

        if (personName.length > 0) {
            data.fornecedor_id = personName[0];
        } else {
            data.fornecedor_id = 1;

        }


        try {
            setLoading(true);
            const result = await updateColaborador(data.id, data);
            if (result && result.data) {
                if (personName.length > 0) {
                    snackbar.showSnackbar(`Usuário atualizado com sucesso!`, '#05a55a');
                }
            }
            setLoading(false);
            if (personName.length > 0) {
                setAbilityClose(true);
                setOpenCompanies(false);
                atualizaLista();
            }
       

        } catch (error) {
            snackbar.showSnackbar(`Erro ao atualizar usuário!`, '#DC143C');
            setLoading(false);
        }

    }


    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }

    
    const filtrarFornecedores = (term) => {
        setFornecedores(fornecedoresAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }


    const getUsers = async (page) => {
        const result = await listarPj({ pagination: false });
        if (result && result.data) {
            setFornecedores(result.data.data);
            setFornecedoresAll(result.data.data);
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid md={12}>
                <p style={{ fontWeight: 500, color: '#005EB8', margin: 0 }}>
                    <span style={{ fontWeight: '700', fontFamily: "Univers-Medium", fontSize: 16 }}>{item && item.documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</span>
                </p>
                <p style={{ fontWeight: 500, margin: 0, cursor: 'pointer' }}>
                    <span onClick={() => { navigate(`/pessoa-fisica/${item.id}`) }} style={{ fontFamily: "Campuni-Medium", fontWeight: 500, fontSize: 16, color: '#292D32' }}>{item && item.nome}</span>
                </p>
            </Grid>
            <Grid md={6}>
                {item && item.representante_legal == 1 ? (
                    <Button
                        style={{ width: 'auto', height: 30, color: '#fff', borderRadius: 8 }}
                        color={'primary'}
                        variant="contained"
                    >
                        Repres. Legal
                    </Button>
                ) : item && item.funcionario == 1 ? (
                    <Button
                        style={{ width: 'auto', height: 30, color: '#fff', borderRadius: 8 }}
                        color={'primary'}
                        variant="contained"
                    >
                        Funcionário
                    </Button>
                ) : (
                    <Button
                        style={{ width: 'auto', height: 30, color: '#fff', borderRadius: 8 }}
                        color={'primary'}
                        variant="contained"
                    >
                        Terceirizado
                    </Button>
                )}

            </Grid>
            <Grid md={3} textAlign={'right'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>

                <span style={{ fontSize: 14, marginLeft: 5, color: item && item.status == 'A' ? '#6BAC54' : '#FFA500', marginRight: 5, fontFamily: 'Campuni-Bold' }}>{item && item.status == 'A' ? 'Ativo' : 'Inativo'}</span>
                <CircleIcon color={item && item.status == 'A' ? "success" : "warning"} fontSize="10" />

            </Grid>
            <Grid md={3} textAlign={'right'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <IconButton
                    color="inherit"
                    style={{ height: 24 }}
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}
                    edge="start"
                >
                    {svgEdit()}
                    <span style={{ fontSize: 14, color: '#666666', marginLeft: 10 }}>
                        Editar
                    </span>
                </IconButton>
            </Grid>
            <Grid md={12} xs={12}>
                <hr />
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12}>
                            <p style={{ fontSize: 16, color: '#005EB8', fontWeight: '500' }}>{item && item.nome}</p>
                            {/* <CloseIcon onClick={() => handleClose()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} /> */}
                        </Grid>
                        <Grid md={8} style={{ background: '#F9F9F9', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ fontSize: 16, color: '#005EB8', fontWeight: '500' }}>{fornecedor && fornecedor.razao_social}</span>
                            <span style={{ fontSize: 16, color: '#005EB8', fontWeight: '400', fontFamily: 'Univers-Medium'  }}>{fornecedor && fornecedor.documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}</span>
                        </Grid>
                        <Grid md={2} style={{ marginLeft: 10, marginRight: 30, background: '#F9F9F9' }}>
                            <TextField
                                style={{ height: '8px !important' }}
                                variant="standard"
                                select
                                value={selectTipo}
                                className="inputSelect custom-select"
                                onChange={(e) => {
                                    setSelectTipo(e.target.value);
                                }}
                                fullWidth
                            >
                                <MenuItem key={1} value={1}>
                                    Representante Legal
                                </MenuItem>
                                <MenuItem  key={2} value={2}>
                                    Funcionário
                                </MenuItem>
                                <MenuItem  key={3} value={3}>
                                    Terceirizado
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid md={1} style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                color="inherit"
                                style={{ height: 24 }}
                                aria-label="open drawer"
                                onClick={() => openModalConfirm()}
                                edge="start"
                            >
                                <span style={{ fontSize: 12, color: '#F4455A', fontWeight: '700' }}>Desvincular</span>
                            </IconButton>
                        </Grid>
                        <Grid mdOffset={9} md={3} textAlign={'right'}>
                            <Button
                                onClick={() => {
                                    atualizarTipo()
                                }}
                                style={{ width: 'auto', height: 41, marginTop: 30 }}
                                variant="contained"
                                disabled={loading}
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
            <Modal
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12} display={'flex'} justifyContent={'center'} marginTop={5}>
                            {svgAlert()}
                        </Grid>
                        <Grid md={12}>
                            <p style={{ fontSize: 18, color: '#666666', fontWeight: '500', textAlign: 'center' }}>Tem certeza que deseja desvincular este usuário?</p>
                        </Grid>
                        <Grid md={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <IconButton
                                color="inherit"
                                style={{ height: 24, width: 100 }}
                                aria-label="open drawer"
                                onClick={() => setOpenConfirm(false)}
                                edge="start"
                            >
                                <span style={{ fontSize: 12, color: '#F4455A', fontWeight: '700' }}>Cancelar</span>
                            </IconButton>
                            <Button
                                onClick={() => {
                                    atualizarColaborador();
                                    openModalEmpresas();
                                }}
                                style={{ width: '100px', height: 41 }}
                                variant="contained"

                            >
                                Sim
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
            <Modal
                open={openCompanies}
                onClose={handleCloseCompanies}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12}>
                            <p style={{ fontSize: 16, color: '#005EB8', fontWeight: '500', fontFamily: 'Campuni-Bold' }}>{item && item.nome}</p>
                        </Grid>
                        <Grid md={10} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <TextField
                                id="customInput"
                                placeholder="Buscar"
                                fullWidth
                                className="custom-class"
                                onChange={(event) => {
                                    filtrarFornecedores(event.target.value)
                                }}
                                variant="standard"
                                style={{ height: '38 !important', width: '100%', }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton onClick={() => { }}>
                                                {svgSearch()}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid md={2}></Grid>
                        <Grid md={10} marginTop={5}>
                            <FormControl className="no-fieldset">
                                <Select
                                    multiple
                                    native
                                    fullWidth
                                    value={personName}
                                    onChange={handleChangeMultiple}
                                    inputProps={{
                                        id: 'select-multiple-native',
                                    }}
                                >
                                    {fornecedores && fornecedores.map((f) => (
                                        <option key={f.id} value={f.id}>
                                            {f.razao_social}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={2}></Grid>
                        <Grid mdOffset={4} md={4} textAlign={'right'}>
                            <Button
                                onClick={() => {
                                    setAbilityClose(true);
                                    setOpenCompanies(false);
                                    atualizaLista();
                                }}
                                style={{ width: 'auto', height: 41, marginTop: 30 }}
                                variant="contained"
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid md={4} textAlign={'right'}>
                            <Button
                                onClick={() => {
                                    atualizarColaborador();
                                }}
                                style={{ width: 'auto', height: 41, marginTop: 30 }}
                                variant="contained"
                                disabled={loading || personName.length == 0}
                            >
                                Vincular a esta empresa
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
        </Grid>
    )
}