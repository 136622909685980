import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import axios from "axios";
import { maskCpf } from "../utils";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;

  const login = async (data) => {
    return axios.post(url + "/login", data);
  };

  const recuperarSenha = async (data) => {
    return axios.post(url + "/recuperar-senha", data);
  };

  const alterarSenha = async (data) => {
    return axios.post(url + "/alterar-senha", data);
  };

  const cnpjSemPontos = () => {
    return user.cnpj.replace(/\D/g, "");
  };
  const cpfComPontos = () => {
    return maskCpf(user.cpf);
  };

  // const isUsuarioSebrae = user && user.tipo_usuar === "sebrae";
  // const isPrototocoloVencido = user && user.flg_prot_venc === "Sim";

  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      recuperarSenha,
      alterarSenha,
      logout,
      cnpjSemPontos,
      cpfComPontos,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
