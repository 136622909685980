import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import { isMobile } from "react-device-detect";
import { Step1PJ } from "./Step1PJ";
import { Step2PJ } from "./Step2PJ";
import { Step3PJ } from "./Step3PJ";
import { Step5PJ } from "./Step5PJ";
import { Step4PJ } from "./Step4PJ";
import { useParams, useSearchParams } from "react-router-dom";
import { Step1PF } from "./Step1PF";
import { useAuth } from "../../hooks/useAuth";
import { Step2PF } from "./Step2PF";
import { Step3PF } from "./Step3PJF";
import { useQuery } from "../../hooks/useQuery";
const stepsPJ = [
    'Área',
    'Selecionar candidatos',
    'Documentação PJ',
    'Documentação PF',
    'Resumo documentos',
    'Conclusao'
];

const stepsPF = [
    'Documentação',
    'Resumo doc. empresa',
    'Resumo documentos',
    'Conclusao'
];

export const InscricaoUsuarioPage = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [searchParams] = useSearchParams();
    const [skipped, setSkipped] = React.useState(new Set());
    const [isLoading, setIsLoading] = React.useState(false);
    const [processoFinalizado, setProcessoFinalizado] = React.useState(false);
    const { user } = useAuth();
    const { id } = useParams();
    const { showProcesso } = useQuery();


    React.useEffect(() => {
        if (id) {
            getProcesso(id);
        }
        if (searchParams.get('step')) {
            setActiveStep(parseInt(searchParams.get('step')))
        }

    }, []);


    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStep = (step) => () => {
        if (step !== 3) {
            setActiveStep(step);
        }
    };

    const renderStepAtualPJ = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Step1PJ activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            case 1:
                return (
                    <Step2PJ activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            case 2:
                return (
                    <Step3PJ activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            case 3:
                return (
                    <Step1PF activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            case 4:
                return (
                    <Step4PJ activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            case 5:
                return (
                    <Step5PJ activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            default:
                break;
        }
    };

    const getProcesso = async () => {
        const result = await showProcesso(id);
        if (result && result.data) {
            if (result.data.data.status == 'F') {
                setProcessoFinalizado(true);
            }
        }
    }



    const renderStepAtualPF = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Step1PF activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            case 1:
                return (
                    <Step2PF activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            case 2:
                return (
                    <Step3PF activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            case 3:
                return (
                    <Step5PJ activeStep={activeStep} setActiveStep={setActiveStep} processoId={id} processoFinalizado={processoFinalizado} />
                );
            default:
                break;
        }
    };

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 15,
            paddingLeft: 10,
            paddingRight: 10
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(95deg, #005EB8 0%, #40BBFF 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient(95deg, #005EB8 0%, #40BBFF 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 6,
            border: 0,
            backgroundColor: '#ddd',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        width: 36,
        fontWeight: 'bold',
        height: 36,
        display: 'flex',
        borderRadius: '50%',
        color: '#005EB8',
        justifyContent: 'center',
        border: 'solid 2px #005EB8',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'white',
    }));

    function ColorlibStepIcon(props) {
        console.log(props);
        const { active, completed, className, icon } = props;


        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                <span style={{ fontFamily: 'Univers-Medium' }}>{icon}</span>
            </ColorlibStepIconRoot>
        );
    }

    return (
        <Box style={{ width: '100%', margin: 0, padding: 0 }}>
            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <Box padding={5}>
                    <Grid container spacing={10}>
                        <Grid item sm={12} display={'flex'} justifyContent={'flex-end'}>
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={activeStep} style={{ flexWrap: isMobile ? 'wrap' : 'initial', padding: '40px 0' }} alternativeLabel connector={<ColorlibConnector />}>
                                    {user && user.representante_legal ?
                                        stepsPJ.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel onClick={handleStep(index)} StepIconComponent={ColorlibStepIcon}><span style={{ cursor: 'pointer', color: '#005EB8', fontFamily: 'Campuni-Medium' }}>{label}</span></StepLabel>
                                                </Step>
                                            );
                                        }) :
                                        stepsPF.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel onClick={handleStep(index)} StepIconComponent={ColorlibStepIcon}><span style={{ cursor: 'pointer', color: '#005EB8', fontFamily: 'Campuni-Medium' }}>{label}</span></StepLabel>
                                                </Step>
                                            );
                                        })}
                                </Stepper>

                                {user && user.representante_legal ? renderStepAtualPJ() : renderStepAtualPF()}

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    )
}