import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from "react-router-dom";
import { SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment';
import CircleIcon from '@mui/icons-material/Circle';
import { useAuth } from "../hooks/useAuth";

export const UsuariosInternoItem = ({ type, item }) => {
    const navigate = useNavigate();
    const { user } = useAuth();

    return (
        <Grid style={{ cursor: 'pointer' }} onClick={() => { user && user.role_id == 4 ? navigate(`/permissoes/${item.id}`) : console.log(); }} container spacing={1}>
            <Grid md={12}>
                <p style={{ fontWeight: 500 }}>
                    <span style={{ fontFamily: "Campuni-Medium", fontWeight: 500, fontSize: 16, color: '#292D32' }}>{item && item.name}</span>
                </p>
            </Grid>
            <Grid md={12} xs={12}>
                <hr />
            </Grid>
        </Grid>
    )
}