import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from "../../hooks/useSnackbar";
import Checkbox from '@mui/material/Checkbox';
import { useQuery } from "../../hooks/useQuery";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CheckBoxItem } from "../../components/CheckBoxItem";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from "@mui/material/MenuItem";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

export const EditalStep5Page = ({ edital, setEdital, activeStep, setActiveStep }) => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const [checkedDocumentos, setCheckedDocumentos] = React.useState([]);
    const [isCheckAllDocumentos, setIsCheckAllDocumentos] = React.useState(false);
    const [checkedAreas, setCheckedAreas] = React.useState([]);
    const [isCheckAllAreas, setIsCheckAllAreas] = React.useState(false);
    const [checkedDocumentosFormacao, setCheckedDocumentosFormacao] = React.useState([]);
    const [isCheckAllFocumentosFormacao, setIsCheckAllDocumentosformaacao] = React.useState(false);
    const [checkedFormacao, setCheckedFormacao] = React.useState([]);
    const [isCheckAllFormacoes, setIsCheckAllFormacoes] = React.useState(false);
    const [selectedCategoria, setSelectedCategoria] = React.useState();
    const [selectedArea, setSelectedArea] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [loadingAreas, setLoadingAreas] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [documentos, setDocumentos] = React.useState([]);
    const [documentosFormacao, setDocumentosFormacao] = React.useState([]);
    const [documentosAll, setDocumentosAll] = React.useState([]);
    const [formacoes, setFormacoes] = React.useState([]);
    const [formacoesAll, setFormacoesAll] = React.useState([]);
    const [categorias, setCategorias] = React.useState([]);
    const [categoriasAll, setCategoriasAll] = React.useState([]);
    const [areas, setAreas] = React.useState([]);
    const [areasAll, setAreasAll] = React.useState([]);
    const [titulo, setTitulo] = React.useState();
    const [errorTitulo, setErrortitulo] = React.useState(false);
    const [msgErrorTitulo, setMsgErrorTitulo] = React.useState("");
    const [expanded, setExpanded] = React.useState(false);
    const { showEdital, listaAreas, listaFormacoes, listaCategorias, editalStep5, listaTipoDocumentos, saveArea, updateArea, showArea } = useQuery();
    const { editalId } = useParams();

    React.useEffect(() => {
        buscarAreas();
        buscarTipoDocumentos();
        buscarFormacoes();
        buscarCategorias();
        getEdital();
        console.log('edital', edital);
    }, []);

    const getEdital = async () => {
        setLoading(true);
        const result = await showEdital(editalId ? editalId : edital.id);

        if (result && result.data) {
            setEdital(result.data.data);
            let auxArea = [];
            result.data.data.area_edital.forEach(item => {
                auxArea.push(item.area_id);
            });
            setCheckedAreas(auxArea);
        }
        setLoading(false);
    }

    const buscarTipoDocumentos = async () => {
        const result = await listaTipoDocumentos();
        if (result && result.data) {
            setDocumentos(result.data.data);
            setDocumentosFormacao(result.data.data);
            setDocumentosAll(result.data.data);
        }
    }

    const buscarFormacoes = async () => {
        const result = await listaFormacoes(true);
        if (result && result.data) {
            setFormacoes(result.data.data);
            setFormacoesAll(result.data.data);
        }
    }

    const buscarCategorias = async () => {
        const result = await listaCategorias();
        if (result && result.data) {
            setCategorias(result.data.data);
            setCategoriasAll(result.data.data);
        }
    }

    const buscarAreas = async () => {
        setLoadingAreas(true);
        const result = await listaAreas();
        if (result && result.data) {
            setAreas(result.data.data);
            setAreasAll(result.data.data);
        }
        setLoadingAreas(false);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const filtrarDocumentos = (term) => {
        setDocumentos(documentosAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const filtrarDocumentosFormacao = (term) => {
        setDocumentosFormacao(documentosAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const filtrarAreas = (term) => {
        setAreas(areasAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }


    const filtrarFormacoes = (term) => {
        setFormacoes(formacoesAll.filter((data) => JSON.stringify(data).toLowerCase().indexOf(term.toLowerCase()) !== -1));
    }

    const handleChangeAllDocumentos = (event) => {
        if (event.target.checked) {
            let docAux = [];
            documentosAll.forEach(doc => {
                docAux.push(doc.id);
            });
            setCheckedDocumentos(docAux);
            setIsCheckAllDocumentos(true);
        } else {
            setCheckedDocumentos([]);
            setIsCheckAllDocumentos(false);
        }
    };

    const handleChangeAllDocumentosFormacao = (event) => {
        if (event.target.checked) {
            let docAux = [];
            documentosAll.forEach(doc => {
                docAux.push(doc.id);
            });
            setCheckedDocumentosFormacao(docAux);
            setIsCheckAllDocumentosformaacao(true);
        } else {
            setCheckedDocumentosFormacao([]);
            setIsCheckAllDocumentosformaacao(false);
        }
    };

    const handleChangeAllAreas = (event) => {
        if (event.target.checked) {
            let areaAux = [];
            areasAll.forEach(doc => {
                areaAux.push(doc.id);
            });
            setCheckedAreas(areaAux);
            setIsCheckAllAreas(true);
        } else {
            setCheckedAreas([]);
            setIsCheckAllAreas(false);
        }
    };


    const handleChangeAllFormacoes = (event) => {
        if (event.target.checked) {
            let formacaoAux = [];
            formacoesAll.forEach(doc => {
                formacaoAux.push(doc.id);
            });
            setCheckedFormacao(formacaoAux);
            setIsCheckAllFormacoes(true);
        } else {
            setCheckedFormacao([]);
            setIsCheckAllFormacoes(false);
        }
    };

    const editarDocumento = async (area) => {
        const result = await showArea(area.id);
        console.log(result.data);
        const doc = result.data.data;
        setSelectedArea(doc);
        setTitulo(doc.nome);
        console.log(doc);

        let auxForm = [];
        doc.area_formacoes.forEach(item => {
            auxForm.push(item.formacao_id);
        });
        setCheckedFormacao(auxForm);

        let auxDoc = [];
        doc.area_tipo_documentos.forEach(item => {
            auxDoc.push(item.tipo_documento_id);
        });
        setCheckedDocumentos(auxDoc);

        doc.area_categorias.forEach(cat => {
            setSelectedCategoria(cat.categoria_id);
        });

        setOpen(true);
    }

    const salvarArea = async () => {

        try {
            let body = {
                nome: titulo,
                area_categoria: [selectedCategoria],
                area_formacao: checkedFormacao,
                area_tipo_documento: checkedDocumentos
            }

            if (selectedArea) {
                const result = await updateArea(selectedArea.id, body);

                if (result && result.data) {
                    snackbar.showSnackbar(`Area alterada com sucesso!`, '#05a55a');
                    buscarAreas();
                    setOpen(false);
                }
            } else {
                const result = await saveArea(body);

                if (result && result.data) {
                    snackbar.showSnackbar(`Area salvo com sucesso!`, '#05a55a');
                    buscarAreas();
                    setOpen(false);
                }
            }

            filtrarDocumentos("");
            filtrarFormacoes("");

        } catch (error) {
            snackbar.showSnackbar('Erro ao criar o formação.', '#DC143C');
        }
    }

    const childrenDocumentos = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {documentos && documentos.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedDocumentos}
                        checkedDocumentos={checkedDocumentos}
                        isCheckAllDocumentos={isCheckAllDocumentos}
                        editarDocumento={null}
                        documentos={documentos}
                    />
                )
            })}
        </Box>
    );

    const childrenDocumentosFormacao = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {documentosFormacao && documentosFormacao.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedDocumentosFormacao}
                        checkedDocumentos={checkedDocumentosFormacao}
                        isCheckAllDocumentos={setIsCheckAllDocumentosformaacao}
                        editarDocumento={null}
                        documentos={documentosFormacao}
                    />
                )
            })}
        </Box>
    );


    const childrenFormacoes = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {formacoes && formacoes.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedFormacao}
                        checkedDocumentos={checkedFormacao}
                        isCheckAllDocumentos={isCheckAllFormacoes}
                        editarDocumento={null}
                        documentos={formacoes}
                    />
                )
            })}
        </Box>
    );


    const childrenAreas = (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {areas && areas.map((doc, index) => {
                return (
                    <CheckBoxItem
                        doc={doc}
                        setCheckedDocumentos={setCheckedAreas}
                        checkedDocumentos={checkedAreas}
                        isCheckAllDocumentos={isCheckAllAreas}
                        editarDocumento={editarDocumento}
                        documentos={areas}
                    />
                )
            })}
        </Box>
    );

    const onSubmit = () => {
        console.log('area', checkedAreas);
        if (checkedAreas.length > 0) {
            const data = {
                areas: checkedAreas,
                edital_id: edital.id
            }
            console.log(data);
            saveStep5(data);
        } else {
            setActiveStep(activeStep + 1);
        }

    }

    const onErrors = (error) => {

    }

    const saveStep5 = async (data) => {
        setLoadingSave(true);
        const result = await editalStep5(data);
        console.log(result.data);
        if (result && result.data) {
            setEdital(result.data.data);
            setActiveStep(activeStep + 1);
        }
        setLoadingSave(false);

    }

    const handleClose = () => {
        setSelectedArea(undefined);
        setOpen(false);
        setTitulo(undefined);
        setCheckedDocumentos([]);
        setCheckedFormacao([]);
        filtrarDocumentos("");
        filtrarFormacoes("");
        setSelectedCategoria(undefined);
    }

    const svgSearch = () => {

        return (
            <SvgIcon style={{ transform: "rotate(180deg)" }}>
                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20.6972C14.9706 20.6972 19 16.2878 19 10.8486C19 5.40937 14.9706 1 10 1C5.02944 1 1 5.40937 1 10.8486C1 16.2878 5.02944 20.6972 10 20.6972Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M0.930411 3.45204C1.46041 5.20291 2.67041 5.37799 3.60041 3.84599C4.45041 2.4453 3.89041 1.29629 2.35041 1.29629C1.21041 1.28535 0.570411 2.25927 0.930411 3.45204Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

            </SvgIcon>
        )
    }


    return (
        <Box style={{ width: '100%', marginTop: 60 }}>
            <Grid container spacing={1} paddingX="0">
                <Grid xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Subáreas</span>
                </Grid>
                <Grid md={9}>
                    <Box style={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
                        <Button
                            style={{ width: 182, height: 40 }}
                            variant="contained"
                            color="info"
                            component="label"
                            onClick={() => setOpen(true)}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Criar subárea
                        </Button>
                    </Box>
                </Grid>
                <Grid md={12} xs={12}>
                    <TextField
                        id="customInput"
                        placeholder="Buscar"
                        fullWidth
                        className="custom-class"
                        onChange={(event) => {
                            filtrarAreas(event.target.value);
                        }}
                        variant="standard"
                        style={{ height: '38 !important', width: '100%', }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => { }}>
                                        {svgSearch()}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                {loading || loadingAreas ? (
                    <Grid md={12} style={{ marginTop: 20 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid md={12} xs={12} style={{ marginTop: 20 }}>
                            <div
                                className="scrollbar"
                                id="style-3"
                                style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll', padding: '0 10px' }}
                            >
                                {areas.length == areasAll.length ? (
                                    <FormControlLabel
                                        label="Marcar todos"
                                        control={
                                            <Checkbox
                                                checked={checkedAreas.length === areas.length}
                                                onChange={handleChangeAllAreas}
                                            />
                                        }
                                    />
                                ) : null}
                                {childrenAreas}
                            </div>
                        </Grid>
                    </>
                )}

                <Grid md={4} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid md={8} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => { navigate("/editais") }}
                        style={{ width: isMobile ? '100%' : 177, height: 41, marginTop: 10, marginRight: isMobile ? 0 : 20, color: '#666666', borderColor: '#666666' }}
                        color="info"
                        variant="outlined"
                        endIcon={<SaveIcon style={{ color: '#666666' }} />} >
                        Salvar rascunho
                    </Button>
                    <Button
                        onClick={() => {
                            onSubmit();
                        }}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={loading || loadingAreas || loadingSave}
                        endIcon={loadingSave ? <CircularProgress style={{ color: 'white' }} size={15} /> : <ArrowForwardIcon />}>
                        Avançar
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                        <Grid container spacing={1} marginBottom={5}>
                            <Grid md={12}>
                                <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold' }}>Adicionar Subáreas </p>
                                <CloseIcon onClick={() => handleClose()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                            </Grid>
                            <Grid md={12}>
                                <TextField
                                    style={{ marginTop: '5px' }}
                                    margin="normal"
                                    value={titulo}
                                    error={errorTitulo}
                                    helperText={msgErrorTitulo}
                                    onChange={(e) => {
                                        setTitulo(e.target.value);
                                    }}
                                    fullWidth
                                    label="Título"
                                />
                            </Grid>
                            <Grid md={6}>
                                <TextField
                                    value={selectedCategoria}
                                    style={{ height: 38 }}
                                    label="Área"
                                    select
                                    className="inputSelect"
                                    onChange={(e) => {
                                        setSelectedCategoria(e.target.value);
                                    }}
                                    fullWidth
                                >
                                    <MenuItem disabled value={0}>
                                        Selecione a área
                                    </MenuItem>
                                    {categorias && categorias.map((res, index) =>
                                        <MenuItem value={res.id}>
                                            {`${res.nome}`}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid md={12}>
                                <Accordion expanded={expanded === `panel1`} onChange={handleChange(`panel1`)}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        expandIcon={<KeyboardArrowDownIcon />}
                                    >
                                        <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Formações</span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} marginBottom={5}>
                                            <Grid md={12}>
                                                <TextField
                                                    id="customInput"
                                                    placeholder="Buscar"
                                                    fullWidth
                                                    className="custom-class"
                                                    onChange={(event) => {
                                                        filtrarFormacoes(event.target.value);
                                                    }}
                                                    variant="standard"
                                                    style={{ height: '38 !important', width: '100%', }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton onClick={() => { }}>
                                                                    {svgSearch()}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid md={12} style={{ marginTop: 20 }}>
                                                <div
                                                    className="scrollbar"
                                                    id="style-3"
                                                    style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                                >
                                                    {formacoes.length == formacoesAll.length ? (
                                                        <FormControlLabel
                                                            label="Marcar todos"
                                                            control={
                                                                <Checkbox
                                                                    checked={checkedFormacao.length === formacoes.length}
                                                                    onChange={handleChangeAllFormacoes}
                                                                />
                                                            }
                                                        />
                                                    ) : null}
                                                    {childrenFormacoes}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid md={12}>
                                <Accordion expanded={expanded === `panel2`} onChange={handleChange(`panel2`)}>
                                    <AccordionSummary
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        expandIcon={<KeyboardArrowDownIcon />}
                                    >
                                        <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Documentos</span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} marginBottom={5}>
                                            <Grid md={12}>
                                                <TextField
                                                    id="customInput"
                                                    placeholder="Buscar"
                                                    fullWidth
                                                    className="custom-class"
                                                    onChange={(event) => {
                                                        filtrarDocumentos(event.target.value);
                                                    }}
                                                    variant="standard"
                                                    style={{ height: '38 !important', width: '100%', }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton onClick={() => { }}>
                                                                    {svgSearch()}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid md={12} style={{ marginTop: 20 }}>
                                                <div
                                                    className="scrollbar"
                                                    id="style-3"
                                                    style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                                >
                                                    {documentos.length == documentosAll.length ? (
                                                        <FormControlLabel
                                                            label="Marcar todos"
                                                            control={
                                                                <Checkbox
                                                                    checked={checkedDocumentos.length === documentos.length}
                                                                    onChange={handleChangeAllDocumentos}
                                                                />
                                                            }
                                                        />
                                                    ) : null}
                                                    {childrenDocumentos}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {/* <Grid md={12}>
                                <Accordion expanded={expanded === `panel3`} onChange={handleChange(`panel3`)}>
                                    <AccordionSummary
                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                        expandIcon={<KeyboardArrowDownIcon />}
                                    >
                                        <span style={{ color: '#005EB8', fontWeight: 'bold', fontFamily: "Campuni-Bold" }}>Documentos por formação</span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1} marginBottom={5}>
                                            <Grid md={9} style={{ padding: '0 30px' }}>
                                                <TextField
                                                    value={selectedFormacao}
                                                    style={{ height: 38, marginTop: 12 }}
                                                    label="Formação"
                                                    select
                                                    className="inputSelect"
                                                    onChange={(e) => {
                                                        setSelectedFormacao(e.target.value);
                                                    }}
                                                    fullWidth
                                                >
                                                    <MenuItem disabled value={0}>
                                                        Selecione a formação
                                                    </MenuItem>
                                                    {formacoesAll && formacoesAll.map((res, index) =>
                                                        <MenuItem value={res.id}>
                                                            {`${res.nome}`}
                                                        </MenuItem>
                                                    )}
                                                </TextField>
                                            </Grid>
                                            <Grid md={3}>
                                                <Button
                                                    onClick={() => {
                                                        console.log();
                                                    }}
                                                    style={{ width: '100%', height: 41, marginTop: 5 }}
                                                    variant="contained"
                                                >
                                                    Adicionar
                                                </Button>
                                            </Grid>
                                            <Grid md={12} style={{ marginTop: 20 }}>
                                                <div
                                                    className="scrollbar"
                                                    id="style-3"
                                                    style={{ height: 300, width: '100%', overflowX: 'hidden', overFlowY: 'scroll' }}
                                                >
                                                    <FormControlLabel
                                                        label="Marcar todos"
                                                        control={
                                                            <Checkbox
                                                                checked={checkedDocumentos.length === documentos.length}
                                                                onChange={handleChangeAllDocumentos}
                                                            />
                                                        }
                                                    />
                                                    {childrenDocumentos}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid> */}
                            <Grid md={6}></Grid>
                            <Grid mdOffset={9} md={3}>
                                <Button
                                    onClick={() => {
                                        salvarArea();
                                    }}
                                    style={{ width: '100%', height: 41, marginTop: 10 }}
                                    variant="contained"
                                    disabled={loading}
                                >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal >
        </Box >
    )
}