import * as React from "react";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { isMobile } from 'react-device-detect';
import Button from "@mui/material/Button";
import { CircularProgress, SvgIcon } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useQuery } from "../../hooks/useQuery";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
import { useAuth } from "../../hooks/useAuth";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import dayjs from "dayjs";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingLeft: 4,
    paddingRight: 4
};

const TIPO_ARQUIVOS_PERMITIDOS = [
    'pdf',
];

export const Step3PJ = ({ activeStep, setActiveStep, processoId, processoFinalizado }) => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAuth();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();
    const [documentoAntigo, setDocumentoAntigo] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [loadingAreas, setLoadingAreas] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [documentosPj, setDocumentoPj] = React.useState([]);
    const [documentosAreas, setDocumentosAreas] = React.useState([]);
    const { listarDocumentosProcesso, listarDocumentosAreaPj, saveDocumentosEmpresa, listaDocumentosEnviados, listarDocumentosUpload, saveDocumentoUpload } = useQuery();
    const inputFile = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedTipoDocumento, setSelectedTipoDocumento] = React.useState();
    const [selectedArea, setSelectedArea] = React.useState();
    const [documentosEnviados, setDocumentosEnviados] = React.useState([]);
    const [motivo, setMotivo] = React.useState();
    const [openModalMotivo, setOpenModalMotivo] = React.useState(false);
    const [loadingUploads, setLoadingUploads] = React.useState(false);
    const [docUpload, setDocUploads] = React.useState([]);
    const [selectedDocUpload, setSelectedDocUpload] = React.useState();
    const url = process.env.REACT_APP_API_BASE_URL;

    React.useEffect(() => {
        buscarDocumentosPj();
        buscarDocumentosAreas();
        buscarDocumentosEnviados();

    }, []);

    React.useEffect(() => {
        if (processoFinalizado) {
            setActiveStep(4)
        }
    }, [processoFinalizado]);

    const handleClose = () => {
        setSelectedTipoDocumento(undefined);
        setOpen(false);
    };


    const handleCloseModal = () => {
        setSelectedTipoDocumento(undefined);
        setSelectedArea(undefined);
        setOpenModal(false);
    };

    const buscarDocumentosAreas = async () => {
        setLoadingAreas(true);
        const result = await listarDocumentosAreaPj(processoId, user.fornecedor_id);
        if (result && result.data) {
            setDocumentosAreas(result.data.data);
        }
        setLoadingAreas(false);
    }


    const buscarDocumentosPj = async () => {
        setLoading(true);
        const result = await listarDocumentosProcesso('PJ', processoId);
        if (result && result.data) {
            let aux = [];
            result.data.data.map(doc => {
                aux.push(doc.tipo_documento);
            });
            setDocumentoPj(aux);
        }
        setLoading(false);
    }


    const buscarDocumentosEnviados = async () => {
        const result = await listaDocumentosEnviados('PJ', processoId, user.fornecedor_id, user.user_id);
        if (result && result.data) {
            setDocumentosEnviados(result.data.data);
        }
    }


    const onSubmit = () => {
        setTimeout(() => {
            setActiveStep(activeStep + 1);
        }, 2500)
    }

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const saveStep = async (data) => {
        setLoadingSave(true);
        const result = await saveDocumentosEmpresa(data);
        buscarDocumentosEnviados();
        setLoadingSave(false);

    }


    const onErrors = (error) => {

    }

    const svgUploadModal = () => {

        return (
            <SvgIcon style={{ width: 68, height: 59 }}>
                <svg width="69" height="60" viewBox="0 0 69 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M36.0271 14.7458L36.1193 14.7733L36.1233 14.7688C36.561 14.8481 36.9951 14.586 37.1237 14.1519C38.2953 10.2152 41.9865 7.46504 46.0989 7.46504C46.5857 7.46504 46.9806 7.07016 46.9806 6.5833C46.9806 6.09643 46.5857 5.70156 46.0989 5.70156C41.0447 5.70156 36.7975 9.06665 35.4338 13.6493C35.2946 14.1162 35.5606 14.6067 36.0271 14.7458Z" fill="#005EB8" stroke="#F9FFF9" strokeWidth="0.3" />
                    <path d="M56.3438 42.4384H51.9534C51.5494 42.4384 51.2217 42.1107 51.2217 41.7067C51.2217 41.3027 51.5494 40.9749 51.9534 40.9749H56.3438C62.3956 40.9749 67.3197 36.0509 67.3197 29.999C67.3197 23.9471 62.3956 19.023 56.3438 19.023H56.2382C56.026 19.023 55.8242 18.9311 55.6852 18.7706C55.5462 18.6101 55.4834 18.3974 55.5138 18.1873C55.5791 17.7315 55.612 17.2737 55.612 16.8279C55.612 11.5829 51.3444 7.31531 46.0995 7.31531C44.059 7.31531 42.1131 7.95296 40.4719 9.15978C40.1112 9.42478 39.599 9.30718 39.3905 8.91047C34.7425 0.0596993 22.6023 -1.12887 16.3082 6.57053C13.6568 9.81417 12.615 14.0336 13.4498 18.146C13.5418 18.6002 13.1942 19.0236 12.7327 19.0236H12.4395C6.3876 19.0236 1.46353 23.9477 1.46353 29.9996C1.46353 36.0514 6.3876 40.9755 12.4395 40.9755H16.8298C17.2338 40.9755 17.5615 41.3032 17.5615 41.7072C17.5615 42.1113 17.2338 42.439 16.8298 42.439H12.4395C5.5805 42.439 0 36.8585 0 29.9995C0 23.3329 5.27155 17.8742 11.8651 17.5731C11.2457 13.3066 12.4301 9.00295 15.1751 5.64437C21.9138 -2.5996 34.828 -1.67556 40.2871 7.51707C42.0287 6.42522 44.0215 5.85244 46.0992 5.85244C52.4538 5.85244 57.4892 11.261 57.0486 17.58C63.5813 17.9463 68.7829 23.3763 68.7829 29.999C68.7829 36.8585 63.2024 42.4384 56.3434 42.4384L56.3438 42.4384Z" fill="#005EB8" />
                    <path d="M15.85 41.2935C15.85 51.4634 24.1237 59.737 34.2935 59.737C44.4634 59.737 52.737 51.4633 52.737 41.2935C52.737 31.1235 44.4634 22.85 34.2935 22.85C24.1235 22.85 15.85 31.1237 15.85 41.2935ZM17.6138 41.2935C17.6138 32.0966 25.0964 24.6138 34.2935 24.6138C43.4904 24.6138 50.9732 32.0964 50.9732 41.2935C50.9732 50.4904 43.4904 57.9732 34.2935 57.9732C25.0966 57.9732 17.6138 50.4905 17.6138 41.2935Z" fill="#005EB8" stroke="#F9FFF9" strokeWidth="0.3" />
                    <path d="M33.9398 48.6577C33.9398 49.0363 34.2469 49.3434 34.6256 49.3434C35.0041 49.3434 35.3113 49.0367 35.3113 48.6577V34.7291C35.3113 34.3504 35.0042 34.0434 34.6256 34.0434C34.2469 34.0434 33.9398 34.3504 33.9398 34.7291V48.6577Z" fill="#005EB8" stroke="#483EA8" strokeWidth="0.3" />
                    <path d="M34.6262 35.7004L30.8255 39.5011L34.6262 35.7004ZM34.6262 35.7004L38.4269 39.5012C38.5606 39.6349 38.7367 39.702 38.9118 39.702L34.6262 35.7004ZM29.8556 39.5012C30.1234 39.769 30.5578 39.7691 30.8254 39.5012L38.9118 39.702C39.0866 39.702 39.2628 39.6355 39.3967 39.5011C39.6645 39.2332 39.6645 38.7992 39.3967 38.5314L35.111 34.2457C34.8432 33.9779 34.4088 33.9778 34.1412 34.2457C34.1412 34.2458 34.1412 34.2458 34.1411 34.2458L29.8556 38.5314C29.5877 38.7993 29.5877 39.2333 29.8556 39.5012Z" fill="#005EB8" stroke="#483EA8" strokeWidth="0.3" />
                </svg>


            </SvgIcon>
        )
    }

    const svgAnexo = () => {

        return (
            <SvgIcon style={{ width: 15, height: 14 }}>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.54449 16.3878C-0.542293 14.2683 -0.503401 10.8503 1.59399 8.73848L9.08542 1.19533C10.6681 -0.398373 13.2408 -0.398513 14.8237 1.19533C16.3912 2.77363 16.3931 5.32017 14.8237 6.90034L8.29335 13.4693C7.2271 14.5429 5.48503 14.5279 4.43685 13.4343C3.42703 12.3806 3.45942 10.7106 4.4887 9.6742L9.62238 4.51203C9.84317 4.29006 10.205 4.28626 10.4305 4.50356L11.2469 5.29032C11.4725 5.50766 11.4763 5.86379 11.2555 6.08577L6.12238 11.2474C5.94624 11.4248 5.93538 11.7195 6.09924 11.8905C6.25538 12.0534 6.50085 12.0561 6.65952 11.8963L13.1898 5.32734C13.8903 4.62204 13.8903 3.47373 13.1895 2.76804C12.5041 2.07803 11.4053 2.07768 10.7197 2.76804L3.22821 10.3112C1.98667 11.5613 1.96753 13.5849 3.18567 14.8222C4.40031 16.0559 6.3637 16.0574 7.5806 14.8322L13.7256 8.64475C13.9462 8.4226 14.308 8.41859 14.5337 8.63575L15.3507 9.42198C15.5764 9.63914 15.5805 9.99528 15.3598 10.2174L9.21485 16.4049C7.08713 18.5472 3.64545 18.5218 1.54449 16.3878Z" fill="#999999" />
                </svg>
            </SvgIcon>
        )
    }

    const svgTrash = () => {

        return (
            <SvgIcon>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0389 1.69073H9.56314C9.54342 1.68388 9.52289 1.67944 9.50209 1.6774H7.93081V0.374233C7.9309 0.276299 7.89251 0.182276 7.82408 0.11233C7.75557 0.0423908 7.66243 0.00204298 7.56459 0H3.84854C3.74928 0 3.6541 0.0393695 3.58398 0.109575C3.51378 0.17978 3.47432 0.274959 3.47432 0.37423V1.6774H1.91103C1.89023 1.67944 1.8697 1.68388 1.84997 1.69073H0.374224C0.240477 1.69073 0.11694 1.762 0.0501219 1.8778C-0.0167073 1.99359 -0.0167073 2.13622 0.0501219 2.25202C0.116951 2.36781 0.240479 2.43917 0.374224 2.43917H1.57654L2.54804 11.4768C2.55844 11.5687 2.60234 11.6536 2.6714 11.7154C2.74036 11.777 2.82967 11.8111 2.92226 11.8112H8.49611C8.58773 11.8098 8.67571 11.7751 8.7437 11.7136C8.81159 11.652 8.85469 11.5678 8.865 11.4768L9.83642 2.45237H11.0388C11.1726 2.45237 11.2961 2.38109 11.3629 2.2653C11.4297 2.1495 11.4298 2.00678 11.3629 1.89107C11.2961 1.77528 11.1726 1.70392 11.0388 1.70392L11.0389 1.69073ZM4.23084 0.748452H7.18225V1.6774H4.23084V0.748452ZM8.15642 11.0573H3.25667L2.32773 2.45246H9.08527L8.15642 11.0573Z" fill="#E41D1D" />
                    <path d="M5.71016 10.1813C5.80943 10.1813 5.9046 10.1418 5.97481 10.0717C6.04502 10.0015 6.08439 9.9063 6.08439 9.80702V3.70242C6.08439 3.56867 6.01312 3.44513 5.89732 3.37831C5.78152 3.3114 5.63881 3.3114 5.52301 3.37831C5.40721 3.44514 5.33594 3.56867 5.33594 3.70242V9.80702C5.33594 9.90629 5.37531 10.0015 5.44551 10.0717C5.51572 10.1418 5.6109 10.1813 5.71017 10.1813H5.71016Z" fill="#E41D1D" />
                    <path d="M4.23291 10.1789H4.24882C4.34764 10.1748 4.44077 10.1317 4.50797 10.0591C4.57506 9.9865 4.61079 9.89026 4.60714 9.79143L4.38151 3.68682L4.38159 3.68673C4.37955 3.58675 4.3368 3.49184 4.26313 3.42412C4.18937 3.35649 4.09126 3.32192 3.99138 3.32841C3.89256 3.33259 3.79942 3.37569 3.73232 3.44829C3.66514 3.5209 3.6295 3.61714 3.63305 3.71597L3.85869 9.82058C3.8634 9.91683 3.90481 10.0077 3.97449 10.0743C4.04407 10.1409 4.13658 10.1784 4.23291 10.1789L4.23291 10.1789Z" fill="#E41D1D" />
                    <path d="M7.16616 10.1788H7.1794C7.27804 10.181 7.37358 10.1444 7.44555 10.077C7.51745 10.0094 7.56011 9.91637 7.56428 9.8178L7.78992 3.71319H7.78983C7.79348 3.61437 7.75775 3.51813 7.69066 3.44551C7.62347 3.37291 7.53034 3.32981 7.43151 3.32563C7.33046 3.31523 7.22969 3.34785 7.15388 3.41556C7.07808 3.48319 7.03435 3.5797 7.03338 3.68138L6.80508 9.78599H6.80517C6.8001 9.88614 6.83547 9.98417 6.90337 10.058C6.97117 10.1319 7.06591 10.1754 7.16615 10.1788L7.16616 10.1788Z" fill="#E41D1D" />
                </svg>
            </SvgIcon>
        )
    }

    const onChangeDocEmpresa = (event, docId, docAntigo) => {
        const files = [...event.target.files];
        console.log(files);

        if (files.length > 0) {
            const file = files[0];

            const extension = file.name.split('.').pop();

            if (!TIPO_ARQUIVOS_PERMITIDOS.includes(extension.toLowerCase())) {
                snackbar.showSnackbar(
                    'Tipo de arquivo inválido, insira arquivo dos tipos : ' +
                    TIPO_ARQUIVOS_PERMITIDOS.join(','), '#DC143C'
                );
                inputFile.current.value = '';
                return;
            }

            let aux = [];
            // let find = filesEmpresa.find(f => f.tipo_documento_id == docId);

            // if (find) {
            //     let newArray = aux.filter(v => v.tipo_documento_id !== docId);
            //     aux = newArray;
            //     aux.push({ tipo_documento_id: docId, arquivo: file });
            // } else {
            //     aux.push({ tipo_documento_id: docId, arquivo: file });
            // }
            aux.push({ tipo_documento_id: docId, arquivo: file });

            const data = {
                processoid: processoId,
                userid: user.user_id,
                fornecedorid: user.fornecedor_id,
                antigoid: docAntigo && docAntigo.id
            }
            if (aux.length > 0) {
                data.documentosEmpresa = aux;
            }


            saveStep(data);
        }

    };

    const onChangeDocArea = (event, docId, areaId, docAntigo) => {
        const files = [...event.target.files];
        console.log(files);

        if (files.length > 0) {
            const file = files[0];

            const extension = file.name.split('.').pop();

            if (!TIPO_ARQUIVOS_PERMITIDOS.includes(extension.toLowerCase())) {
                snackbar.showSnackbar(
                    'Tipo de arquivo inválido, insira arquivo dos tipos : ' +
                    TIPO_ARQUIVOS_PERMITIDOS.join(','), '#DC143C'
                );
                inputFile.current.value = '';
                return;
            }

            let aux = [];
            // let find = filesArea.find(f => f.tipo_documento_id == docId && f.area_id == areaId);
            aux.push({ tipo_documento_id: docId, area_id: areaId, arquivo: file });

            // if (find) {
            //     let newArray = aux.filter(v => v.tipo_documento_id !== docId && v.area_id !== areaId);
            //     aux = newArray;
            //     aux.push({ tipo_documento_id: docId, area_id: areaId, arquivo: file });
            // } else {
            //     aux.push({ tipo_documento_id: docId, area_id: areaId, arquivo: file });
            // }

            const data = {
                processoid: processoId,
                userid: user.user_id,
                fornecedorid: user.fornecedor_id,
                antigoid: docAntigo && docAntigo.id,
            }
            if (aux.length > 0) {
                data.documentosArea = aux;
            }


            saveStep(data);

            // console.log(aux)
            // setFilesArea(aux);
        }

    };

    const getDocumentosUpload = async (tipoDocumentoId) => {
        setLoadingUploads(true);
        const result = await listarDocumentosUpload(user.user_id, tipoDocumentoId);
        if (result && result.data) {
            setDocUploads(result.data.data);
        }
        setLoadingUploads(false);
    }

    const handleCloseModalMotivo = () => {
        setOpenModalMotivo(false);
        setMotivo('');
    };

    const handlechangeRadio = (e) => {
        setSelectedDocUpload(e.target.value);
    }

    const saveDocumentoFromList = async () => {
        setLoadingUploads(true);
        const result = await saveDocumentoUpload({ docid: selectedDocUpload, processoid: processoId });
        if (result && result.data) {
            setSelectedDocUpload(undefined);
            buscarDocumentosEnviados();
            setDocUploads([]);
            setOpenModal(false);
            setOpen(false);

        }
        setLoadingUploads(false);
    }

    return (
        <Box style={{ width: '100%', marginTop: 60 }}>
            <Grid container spacing={0} paddingX="0">
                {loading || loadingAreas ? (
                    <Grid md={12} style={{ marginTop: 20 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid md={12}>
                            <span
                                style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 16, fontWeight: 700, fontFamily: "Campuni-Medium" }}
                            >
                                Documentos empresa
                            </span>
                        </Grid>
                        {documentosPj && documentosPj.map(d => {
                            return (
                                <>
                                    <Grid md={9.5} xs={12} marginTop={2}>
                                        <Box style={{ width: '100%', background: 'white', height: 41, display: 'flex', alignItems: 'center', padding: 10 }}>
                                            <span style={{ color: '#999999', fontSize: 12, fontWeight: 500 }}>{d.nome}</span>
                                        </Box>
                                    </Grid>
                                    <Grid md={0.5} xs={0}></Grid>
                                    <Grid md={2} xs={12} marginTop={2}>
                                        <Box style={{ width: '100%' }}>
                                            <Button
                                                style={{ width: '100%', height: 41 }}
                                                variant="contained"
                                                color="info"
                                                onClick={() => {
                                                    setSelectedTipoDocumento(d);
                                                    getDocumentosUpload(d.tipo_documento_id);
                                                    setDocumentoAntigo(documentosEnviados && documentosEnviados.basicos && documentosEnviados.basicos[d.nome] && documentosEnviados.basicos[d.nome][0]);
                                                    setOpen(true);
                                                }}
                                                component="label"
                                            >
                                                <span style={{ fontSize: 12 }}> Enviar documento</span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    {documentosEnviados && documentosEnviados.basicos && documentosEnviados.basicos[d.nome] ? (
                                        <Grid md={9.5}>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                                <p style={{ fontSize: 12 }}>{svgAnexo()}&nbsp;{documentosEnviados.basicos[d.nome][0].nome_arquivo}</p>
                                                <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: 15, justifyContent: 'flex-start', height: 41, color: documentosEnviados.basicos[d.nome][0].status == 'E' ? '#FFB380' : documentosEnviados.basicos[d.nome][0].status == 'A' ? '#6BAC54' : documentosEnviados.basicos[d.nome][0].status == 'R' ? '#F4455A' : '#005EB8' }}>
                                                    <span style={{ marginRight: 20, color: '#005EB8', cursor: "pointer" }} onClick={() => { window.open(`${url}/storage/${documentosEnviados.basicos[d.nome][0].documento}`, '_blank').focus() }}>Ver documento</span>
                                                    <span style={{ marginRight: 20 }}>{documentosEnviados.basicos[d.nome][0].status == 'E' ? 'Em análise' : documentosEnviados.basicos[d.nome][0].status == 'A' ? 'Aprovado' : documentosEnviados.basicos[d.nome][0].status == 'R' ? 'Reprovado' : 'Enviado'} </span>
                                                    {documentosEnviados.basicos[d.nome][0].status == 'R' ? (
                                                        <Button
                                                            onClick={() => {
                                                                setMotivo(documentosEnviados.basicos[d.nome][0].motivo);
                                                                setOpenModalMotivo(true);
                                                            }}
                                                            style={{ width: '100%', height: 26, marginLeft: 10 }}
                                                            variant="contained"
                                                            color="info"
                                                            component="label"
                                                        >
                                                            Motivo
                                                        </Button>
                                                    ) : null}
                                                </Box>

                                            </Box>
                                        </Grid>
                                    ) : null}
                                </>
                            )
                        })}
                        <Grid md={12} marginBottom={2} marginTop={2}>
                            <span
                                style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 16, fontWeight: 700, fontFamily: "Campuni-Medium" }}
                            >
                                Documentos PJ por Área
                            </span>
                        </Grid>
                        {documentosAreas && documentosAreas.map(docA => {
                            return (
                                <>
                                    <Grid md={12} marginTop={2}>
                                        <span
                                            style={{ display: "flex", alignItems: 'center', color: '#005EB8', fontSize: 14, fontWeight: 700, fontFamily: "AlegraSans-Medium" }}
                                        >
                                            {docA && Array.isArray(docA) && docA.length > 0 && docA[0].area.nome}
                                        </span>
                                    </Grid>
                                    {docA && Array.isArray(docA) && docA.map(doc => {
                                        return (
                                            <>
                                                <Grid md={9.5} xs={12} marginTop={2}>
                                                    <Box style={{ width: '100%', background: 'white', height: 41, display: 'flex', alignItems: 'center', padding: 10 }}>
                                                        <span style={{ color: '#999999', fontSize: 12, fontWeight: 500 }}>{doc.tipo_documento.nome}</span>
                                                    </Box>
                                                </Grid>
                                                <Grid md={0.5} xs={0}></Grid>
                                                <Grid md={2} xs={12} marginTop={2}>
                                                    <Box style={{ width: '100%' }}>
                                                        <Button
                                                            style={{ width: '100%', height: 41 }}
                                                            variant="contained"
                                                            color="info"
                                                            component="label"
                                                            onClick={() => {
                                                                setSelectedArea(doc.area);
                                                                setSelectedTipoDocumento(doc.tipo_documento);
                                                                setDocumentoAntigo(documentosEnviados && documentosEnviados.areas && documentosEnviados.areas[doc.area.nome] && documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id));
                                                                getDocumentosUpload(doc.tipo_documento_id);
                                                                setOpenModal(true)
                                                            }}
                                                        >
                                                            <span style={{ fontSize: 12 }}>Enviar documento</span>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                {documentosEnviados && documentosEnviados.areas && documentosEnviados.areas[doc.area.nome] && documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id) ? (
                                                    <Grid md={9.5}>
                                                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                                            <p style={{ fontSize: 12 }}>{svgAnexo()}&nbsp;{documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).nome_arquivo}</p>

                                                            <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: 15, justifyContent: 'flex-start', height: 41, color: documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).status == 'E' ? '#FFB380' : documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).status == 'A' ? '#6BAC54' : documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).status == 'R' ? '#F4455A' : '#005EB8' }}>
                                                                <span style={{ marginRight: 20, color: '#005EB8', cursor: "pointer" }} onClick={() => { window.open(`${url}/storage/${documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).documento}`, '_blank').focus() }}>Ver documento</span>
                                                                <span style={{ marginRight: 20 }}>{documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).status == 'E' ? 'Em análise' : documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).status == 'A' ? 'Aprovado' : documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).status == 'R' ? 'Reprovado' : 'Enviado'}</span>
                                                                {documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).status == 'R' ? (
                                                                    <Button
                                                                        onClick={() => {
                                                                            setMotivo(documentosEnviados.areas[doc.area.nome].find(d => d.tipo_documento_id == doc.tipo_documento.id).motivo);
                                                                            setOpenModalMotivo(true);
                                                                        }}
                                                                        style={{ width: '100%', height: 26, marginLeft: 10 }}
                                                                        variant="contained"
                                                                        color="info"
                                                                        component="label"
                                                                    >
                                                                        Motivo
                                                                    </Button>
                                                                ) : null}
                                                            </Box>

                                                        </Box>
                                                    </Grid>
                                                ) : null}
                                            </>
                                        )
                                    })}
                                </>
                            )
                        })}
                    </>
                )}

                <Grid md={6} xs={12}>
                    <Button
                        style={{ width: isMobile ? '100%' : 151, height: 41, marginTop: 10 }}
                        color="primary"
                        variant="outlined"
                        startIcon={<ArrowBackIcon color="primary" />}
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Voltar
                    </Button>
                </Grid>
                <Grid md={6} xs={12} justifyContent={"flex-end"} display={'flex'} flexWrap={'wrap'}>
                    <Button
                        onClick={() => {
                            setActiveStep(activeStep + 1)
                            onSubmit();
                        }}
                        style={{ width: isMobile ? '100%' : 182, height: 41, marginTop: 10 }}
                        variant="contained"
                        disabled={loading || loadingAreas || loadingSave}
                        endIcon={loadingSave ? <CircularProgress style={{ color: 'white' }} size={15} /> : <ArrowForwardIcon />}>
                        Avançar
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12}>
                            <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold', textAlign: 'center' }}>Enviar documento </p>
                            <CloseIcon onClick={() => handleCloseModal()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            {svgUploadModal()}
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <p style={{ color: '#666666', fontSize: 12, fontWeight: 400, fontFamily: "Campuni-Medium", textAlign: 'center' }}>Formato PDF <br />tamanho máx. 25mb</p>
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <Button
                                style={{ width: 160, height: 41 }}
                                variant="contained"
                                color="info"
                                component="label"
                            >
                                Carregar arquivo
                                <input
                                    ref={inputFile}
                                    onChange={e => onChangeDocArea(e, selectedTipoDocumento && selectedTipoDocumento.id, selectedArea && selectedArea.id, documentoAntigo)}
                                    hidden
                                    accept={TIPO_ARQUIVOS_PERMITIDOS.join(',')}
                                    type="file"
                                />
                            </Button>
                        </Grid>
                        <Grid md={12}>
                            {selectedArea && selectedTipoDocumento && documentosEnviados && documentosEnviados.areas && documentosEnviados.areas[selectedArea.nome] && documentosEnviados.areas[selectedArea.nome].find(d => d.tipo_documento_id == selectedTipoDocumento.id) ? (
                                <Grid md={9.5}>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                        <p style={{ fontSize: 12 }}>{svgAnexo()}&nbsp;{documentosEnviados.areas[selectedArea.nome].find(d => d.tipo_documento_id == selectedTipoDocumento.id).nome_arquivo}&nbsp;|&nbsp;{dayjs(documentosEnviados.areas[selectedArea.nome].find(d => d.tipo_documento_id == selectedTipoDocumento.id).createdAt).format('DD/MM/YYYY')}</p>

                                        {/* <IconButton onClick={() => {
                                            inputFile.current.value = '';
                                            removeFileArea(selectedTipoDocumento, selectedArea);
                                        }} style={{ cursor: 'pointer', fontSize: 16 }} color="error">{svgTrash()}</IconButton> */}

                                    </Box>
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid md={12}>
                            <FormControl>
                                <FormLabel style={{ fontSize: 16, fontWeight: 500 }} id="demo-controlled-radio-buttons-group">Documentos carregados</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectedDocUpload}
                                    onChange={handlechangeRadio}
                                >
                                    {docUpload && docUpload.map(d => {
                                        return (
                                            <FormControlLabel className="custom-form-label" key={d.id} value={d.id} control={<Radio />} label={d.documento.split('/')[4]} />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <Button
                                style={{ width: 160, height: 41 }}
                                variant="contained"
                                color="info"
                                disabled={loadingUploads}
                                component="label"
                                onClick={() => {
                                    if (selectedDocUpload) {
                                        saveDocumentoFromList();
                                    } else {
                                        setOpenModal(false);
                                        setDocUploads([]);
                                    }

                                }}
                            >
                                Enviar
                                {loadingUploads ? (
                                    <CircularProgress style={{ marginLeft: 5 }} size={10} />
                                ) : null}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12}>
                            <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold', textAlign: 'center' }}>Enviar documento </p>
                            <CloseIcon onClick={() => handleClose()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            {svgUploadModal()}
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <p style={{ color: '#666666', fontSize: 12, fontWeight: 400, fontFamily: "Campuni-Medium", textAlign: 'center' }}>Formato PDF <br />tamanho máx. 25mb</p>
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <Button
                                style={{ width: 160, height: 41 }}
                                variant="contained"
                                color="info"
                                component="label"
                            >
                                Carregar arquivo
                                <input
                                    ref={inputFile}
                                    onChange={e => onChangeDocEmpresa(e, selectedTipoDocumento && selectedTipoDocumento.id, documentoAntigo)}
                                    hidden
                                    accept={TIPO_ARQUIVOS_PERMITIDOS.join(',')}
                                    type="file"
                                />
                            </Button>
                        </Grid>
                        <Grid md={12}>
                            {selectedTipoDocumento && documentosEnviados && documentosEnviados.basicos && documentosEnviados.basicos[selectedTipoDocumento.nome] ? (
                                <Grid md={9.5}>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0' }}>
                                        <p style={{ fontSize: 12 }}>{svgAnexo()}&nbsp;{documentosEnviados.basicos[selectedTipoDocumento.nome][0].nome_arquivo}&nbsp;|&nbsp;{dayjs(documentosEnviados.basicos[selectedTipoDocumento.nome][0].createdAt).format('DD/MM/YYYY')}</p>
                                    </Box>
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid md={12}>
                            <FormControl>
                                <FormLabel style={{ fontSize: 16, fontWeight: 500 }} id="demo-controlled-radio-buttons-group">Documentos carregados</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectedDocUpload}
                                    onChange={handlechangeRadio}
                                >
                                    {docUpload && docUpload.map(d => {
                                        return (
                                            <FormControlLabel className="custom-form-label" key={d.id} value={d.id} control={<Radio />} label={d.documento.split('/')[4]} />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <Button
                                style={{ width: 160, height: 41 }}
                                variant="contained"
                                color="info"
                                disabled={loadingUploads}
                                component="label"
                                onClick={() => {
                                    if (selectedDocUpload) {
                                        saveDocumentoFromList();
                                    } else {
                                        setOpen(false);
                                        setDocUploads([]);
                                    }

                                }}
                            >
                                Enviar
                                {loadingUploads ? (
                                    <CircularProgress style={{ marginLeft: 5 }} size={10} />
                                ) : null}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openModalMotivo}
                onClose={handleCloseModalMotivo}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={1} marginBottom={5}>
                        <Grid md={12}>
                            <p style={{ fontSize: 24, color: '#005EB8', fontWeight: 'bold', textAlign: 'center' }}>Motivo </p>
                            <CloseIcon onClick={() => handleCloseModalMotivo()} color="primary" style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10 }} />
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <p style={{ color: '#666666', fontSize: 12, fontWeight: 400, fontFamily: "Campuni-Medium", textAlign: 'center' }}>{motivo}</p>
                        </Grid>
                        <Grid md={12} style={{ justifyContent: 'center', display: 'flex' }}>
                            <Button
                                style={{ width: 160, height: 41 }}
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModalMotivo}
                                component="label"
                            >
                                Fechar
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </Box >
    )
}